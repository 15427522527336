import React from "react";
import { Contract } from "../interfaces/contracts/ContractsInterfaces";
import { Complex } from "../interfaces/complex/ComplexInterfaces";

interface ContextContractProps {
  selectedContractId?: string;
  selectedContractComplex?:Complex;
  setSelectedContractId: (contractId: string) => void;
  contracts: Contract[];
  setContracts: (contracts: Contract[]) => void;
  setSelectedContractComplex: (complex: Complex) => void;
}

export const ContextContract = React.createContext<ContextContractProps>(null);
