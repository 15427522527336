import { fetchWrapper } from "../utils/fetchWrapper";
import { BALANCE_ROUTE, PAYMENT_LINK_ROUTE, RENT_ROUTE } from "../constants/ApiRoutes";
import { PaymentLinkResponse, Rent } from "../interfaces/rents/RentsInterfaces";

const getPaymentLink = (contractId: string): Promise<PaymentLinkResponse> => {
  return fetchWrapper.get(PAYMENT_LINK_ROUTE + `?contractId=${contractId}`);
}

const getBalance = (contractId: string): Promise<number> => {
  return fetchWrapper.get(BALANCE_ROUTE + `?contractId=${contractId}`);
}

const getRents = (contractId: string): Promise<Rent[]> => {
  return fetchWrapper.get(RENT_ROUTE + `?contractId=${contractId}`);
}

export const rentService = {
  getPaymentLink,
  getBalance,
  getRents,
}
