import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Banner from "components/atoms/Banner";
import Section from "components/atoms/Section";
import Card from "components/atoms/Card";
import Row from "components/atoms/Row";
import { CardBody, CardText, CardTitle, Col } from "reactstrap";
import MapBasic from "components/molecules/MapBasic";
import { COMPLEX_ICON } from "../../assets/Assets";
import { alertService } from "services/AlertService";
import { Alert } from "interfaces/alerts/AlertInterfaces";
import AlertComponent from "components/atoms/Alert";
import { scrollTop } from "../../utils/utilFunctions";
import ContactsContainer from "../../components/molecules/ContactsContainer";
import { useContracts } from "../../hooks/useContracts";
import { ComplexDetailQuery, ComplexUrlKey } from "../../interfaces/complex/ComplexInterfaces";
import { complexService } from "../../services/ComplexService";
import { useQuery } from "@apollo/client";
import { COMPLEX_DETAIL_QUERY } from "../../constants/queries/ComplexQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import { getSimpleMarker } from "../../constants/map/MapConstants";
import { imageService } from "../../services/ImageService";

const YourComplexView: FunctionComponent<RouteProps> = ({}) => {
  const { selectedContract, contractsDropdown } = useContracts();

  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [complexUrlKey, setComplexUrlKey] = useState<string>();

  const { data: complexData, error } = useQuery<ComplexDetailQuery>(COMPLEX_DETAIL_QUERY, {
    variables: { urlKey: complexUrlKey },
    skip: !complexUrlKey,
  })
  handleApolloError("complex_detail_query", error)

  useEffect(() => {
    scrollTop();
    void alertService.findComplexAlerts()
      .then(response => setAlerts(response))
  }, [])

  useEffect(() => {
    if (selectedContract) {
      void complexService.findComplexUrlKeyByContractNumber(selectedContract)
        .then((result: ComplexUrlKey) => {
          setComplexUrlKey(result.urlKey)
        })
    }
  }, [selectedContract])

  const complex = complexData?.complexes[0];
  const photo = complex?.photo[0];
  const marker = complexService.mapComplexToMarkers(complex);
  const imageWithFormat = imageService.getImageUrlByFormat(photo);
  // TODO
  return (
    <Layout dashboard>
      <>
        {alerts.map((alert, index) => (
          <AlertComponent key={index} dismiss>{alert.description}</AlertComponent>
        ))}
        <Banner src={imageWithFormat || COMPLEX_ICON} alt="" className={imageWithFormat ? "" : "no-fit-cover"} insideContent={true} />

        <Section>
          <div className="d-flex justify-content-end">
            {contractsDropdown}
          </div>
          {complex && (
            <Card rounded>
              <Row gutters="none">
                <Col xs="12" md="4">
                  <CardBody className="bg-dark">
                    <CardTitle>{complex.label}</CardTitle>
                    <CardText>{complex.address}</CardText>
                    <CardText>{`${complex.zip_code} ${complex.city}`}</CardText>
                  </CardBody>
                </Col>
                <Col xs="12" md="8">
                  <MapBasic markers={[marker]} getIcon={getSimpleMarker} autoCenter size="sm" />
                </Col>
              </Row>
            </Card>
          )}
        </Section>

        {/*<Section>*/}
        {/*  <Card rounded>*/}
        {/*    <CardHeader>*/}
        {/*      <CardTitle>Action à mener pour votre résidence</CardTitle>*/}
        {/*    </CardHeader>*/}
        {/*    <CardBody>*/}
        {/*      <Card rounded>*/}
        {/*        <Row>*/}
        {/*          <Col xs="12" md="4">*/}
        {/*            <Figure>*/}
        {/*              <CardImg src={PLACEHOLDERS._600x400} />*/}
        {/*            </Figure>*/}
        {/*          </Col>*/}
        {/*          <Col xs="12" md="8">*/}
        {/*            <CardHeader>*/}
        {/*              <CardTitle className="h3" tag="h3">Enquête</CardTitle>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <CardText className="lead">Lorem ipsum dolor sit amet.</CardText>*/}
        {/*            </CardBody>*/}
        {/*            <CardFooter className="justify-content-end">*/}
        {/*              <Button tag="a" href="" title="" color="primary" size="sm">Répondre</Button>*/}
        {/*            </CardFooter>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*      </Card>*/}
        {/*      <Card rounded>*/}
        {/*        <Row>*/}
        {/*          <Col xs="12" md="4">*/}
        {/*            <Figure>*/}
        {/*              <CardImg src={PLACEHOLDERS._600x400} />*/}
        {/*            </Figure>*/}
        {/*          </Col>*/}
        {/*          <Col xs="12" md="8">*/}
        {/*            <CardHeader>*/}
        {/*              <CardTitle className="h3" tag="h3">Enquête</CardTitle>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <CardText className="lead">Lorem ipsum dolor sit amet.</CardText>*/}
        {/*            </CardBody>*/}
        {/*            <CardFooter className="justify-content-end">*/}
        {/*              <Button tag="a" href="" title="" color="primary" size="sm">Répondre</Button>*/}
        {/*            </CardFooter>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*      </Card>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Section>*/}

        {/*<Section>*/}
        {/*  <Card rounded>*/}
        {/*    <CardHeader>*/}
        {/*      <CardTitle>*/}
        {/*        Actualité de ma ville*/}
        {/*      </CardTitle>*/}
        {/*    </CardHeader>*/}
        {/*    <CardBody>*/}
        {/*      /!* TODO : mutualiser en utilisant le components/molecules/CardArticle *!/*/}
        {/*      <Row grid>*/}
        {/*        <Col xs="12" md="4">*/}
        {/*          <Card tag="a" noBorder>*/}
        {/*            <Badge pill color="DarkOliveGreen">Culture</Badge>*/}
        {/*            <Figure>*/}
        {/*              <CardImg src={PLACEHOLDERS._600x400} />*/}
        {/*            </Figure>*/}
        {/*            <CardHeader>*/}
        {/*              <CardTitle className="h3" tag="h3">Titre de l&apos;article</CardTitle>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel*/}
        {/*                libero ligula.</CardText>*/}
        {/*              <CardText><span className="Date">01/12/2020</span> | <span*/}
        {/*                className="Duration">3 minutes de lecture</span></CardText>*/}
        {/*            </CardBody>*/}
        {/*            <CardFooter>*/}
        {/*              <Button className="More" color="link"><Icon name="More" /> <span*/}
        {/*                className="Text">En savoir plus</span></Button>*/}
        {/*            </CardFooter>*/}
        {/*          </Card>*/}
        {/*        </Col>*/}
        {/*        <Col xs="12" md="4">*/}
        {/*          <Card tag="a" noBorder>*/}
        {/*            <Badge pill color="DarkOliveGreen">Culture</Badge>*/}
        {/*            <Figure>*/}
        {/*              <CardImg src={PLACEHOLDERS._600x400} />*/}
        {/*            </Figure>*/}
        {/*            <CardHeader>*/}
        {/*              <CardTitle className="h3" tag="h3">Titre de l&apos;article</CardTitle>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel*/}
        {/*                libero ligula.</CardText>*/}
        {/*              <CardText><span className="Date">01/12/2020</span> | <span*/}
        {/*                className="Duration">3 minutes de lecture</span></CardText>*/}
        {/*            </CardBody>*/}
        {/*            <CardFooter>*/}
        {/*              <Button className="More" color="link"><Icon name="More" /> <span*/}
        {/*                className="Text">En savoir plus</span></Button>*/}
        {/*            </CardFooter>*/}
        {/*          </Card>*/}
        {/*        </Col>*/}
        {/*        <Col xs="12" md="4">*/}
        {/*          <Card tag="a" noBorder>*/}
        {/*            <Badge pill color="DarkOliveGreen">Culture</Badge>*/}
        {/*            <Figure>*/}
        {/*              <CardImg src={PLACEHOLDERS._600x400} />*/}
        {/*            </Figure>*/}
        {/*            <CardHeader>*/}
        {/*              <CardTitle className="h3" tag="h3">Titre de l&apos;article</CardTitle>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel*/}
        {/*                libero ligula.</CardText>*/}
        {/*              <CardText><span className="Date">01/12/2020</span> | <span*/}
        {/*                className="Duration">3 minutes de lecture</span></CardText>*/}
        {/*            </CardBody>*/}
        {/*            <CardFooter>*/}
        {/*              <Button className="More" color="link"><Icon name="More" /> <span*/}
        {/*                className="Text">En savoir plus</span></Button>*/}
        {/*            </CardFooter>*/}
        {/*          </Card>*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Section>*/}

        <Section>
          <ContactsContainer title="db_caretaker" selectedContract={selectedContract} />
        </Section>
      </>
    </Layout>
  );
}

export default YourComplexView;
