import { isNaN } from "formik";

export const generateNumberSequence = (min: number, max: number): number[] => {
  const result = [];
  for (let i = min ; i <= max ; i++) {
    result.push(i);
  }
  return result;
}

export const isStringAValidNumber = (value: string): boolean => {
  return !isNaN(Number(value));
}
