import React, { FunctionComponent, ReactElement } from "react";
import { ComplexWithProperties } from "../../interfaces/complex/ComplexInterfaces";
import Icon from "../atoms/Icon";
import { FormattedMessage } from "react-intl";
import List from "../atoms/List";
import CountRow from "../atoms/CountRow";

interface ComplexCountProps {
  complex: ComplexWithProperties;
}

const ComplexCount: FunctionComponent<ComplexCountProps> = ({
  complex,
}): ReactElement => {
  return (
    <List unstyled>
      <CountRow labelId="apartment_count" count={complex.numberOfApartment} />
      <CountRow labelId="house_count" count={complex.numberOfHouse} />
      {complex.minRoom && complex.maxRoom && (
        <li>
          <Icon name="ArrowRight" className="mr-3" />
          <FormattedMessage
            id={`room_${complex.minRoom === complex.maxRoom ? "simple" : "multiple"}`}
            values={{ min: complex.minRoom, max: complex.maxRoom, count: complex.minRoom }}
          />
        </li>
      )}
      <CountRow labelId="parking_count" count={complex.numberOfParking} />
    </List>
  )
}

export default ComplexCount;
