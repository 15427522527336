import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import Card from "../atoms/Card";
import { Rent } from "../../interfaces/rents/RentsInterfaces";
import { formatDateToLitteralMonthYear, formatDateToStandardShort } from "../../utils/dateUtils";
import { FormattedMessage } from "react-intl";
import { capitalize, formatNumber } from "../../utils/utilFunctions";

interface PaymentItemProps {
  rent: Rent;
}

const PaymentItem: FunctionComponent<PaymentItemProps> = ({ rent }): ReactElement => {
  return (
      <Card noShadow color="primarylight" rounded roundedRadius="md">
        <CardHeader>
          <CardTitle tag="h4">
            {capitalize(formatDateToLitteralMonthYear(rent.terme))}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table hover responsive>
            <thead>
            <tr>
              <th />
              <th />
              <th><FormattedMessage id="rent_debit" /></th>
              <th><FormattedMessage id="rent_credit" /></th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><FormattedMessage id="rent_your_payment" /></th>
                <td />
                <td>-</td>
                <td>{formatNumber(rent.credit, 2)} €</td>
              </tr>
              <tr>
                <th scope="row"><FormattedMessage id="rent_quitance" /></th>
                <td>
                  <small className="text-muted">
                    <FormattedMessage id="rent_due_date" />
                    <span className="ml-2">{formatDateToStandardShort(rent.due_date)}</span>
                  </small>
                </td>
                <td>{formatNumber(rent.debit, 2)} €</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
  )
}

export default PaymentItem;
