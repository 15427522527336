import { SubmitEligibilityResponse } from "interfaces/formstepper/FormStepperInterfaces";
import React, { FunctionComponent } from "react";
import { TestEligibilityFail } from "./TestEligibilityFail";
import { TestEligibilityIntermediate } from "./TestEligibilityIntermediate";
import { TestEligibilitySocial } from "./TestEligibilitySocial";

interface FormEligibilityResultProps {
  eligibilityResult: SubmitEligibilityResponse
}
const FormEligibilityResult: FunctionComponent<FormEligibilityResultProps> = ({ eligibilityResult }) => {
  return eligibilityResult.socialEligibility ?
    <TestEligibilitySocial />
    : eligibilityResult.intermediateEligibility ?
      <TestEligibilityIntermediate outOfZone={eligibilityResult.intermediateOutOfZone} />
      : <TestEligibilityFail />
}

export default FormEligibilityResult
