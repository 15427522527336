import React, { FunctionComponent, ReactElement, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import Row from "../../../components/atoms/Row";
import { Col } from "reactstrap";
import CardTicket from "../../../components/molecules/CardTicket";
import Container from "../../../components/atoms/Container";
import { TicketCard, TicketItems, TicketItemsKeys } from "../../../interfaces/tickets/TicketsInterfaces";

interface TicketStepProps {
  name: string;
  selectedItem?: string;
  setSelectedItem: (item: string) => void;
  selectedCategory?: TicketItemsKeys;
  ticketItems: TicketItems;
}

const TicketStep: FunctionComponent<TicketStepProps> = ({
  name,
  selectedItem,
  setSelectedItem,
  selectedCategory,
  ticketItems
}): ReactElement => {

  const onCardClick = useCallback((item: string) => () => {
    setSelectedItem(selectedItem === item ? undefined : item)
  }, [selectedItem]);

  const items: TicketCard[] | undefined = ticketItems[selectedCategory] || ticketItems.all
  const width = Math.floor(12 / items.length);
  return (
    <Container className="h-100 d-flex flex-column justify-content-center">
      <div>
        <h6 className="h6 mb-4">
          <FormattedMessage id={name} />
        </h6>
        <Row flexFit grid>
          {items?.map((item: TicketCard) => (
            <Col key={item.name} lg={Math.max(width, 3)} md={Math.max(width, 4)} sm={Math.max(width, 6)}>
              <CardTicket
                name={item.name}
                icon={item.icon}
                selected={item.name === selectedItem}
                onClick={onCardClick(item.name)}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default TicketStep;
