import React, { FunctionComponent, useEffect, useState } from "react";

import { getSimpleMarker, MAP_DETAIL_ZOOM } from "../constants/map/MapConstants"

import { Col } from "reactstrap";

import Row from "components/atoms/Row";
import Section from "components/atoms/Section";
import Layout from "components/layout/Layout";
import Map from "components/atoms/Map";
import MapBasic from "../components/molecules/MapBasic";
import Heading from "components/molecules/Heading";
import Figure from "components/atoms/Figure";
import Img from "components/atoms/Img";
import Card from "components/atoms/Card";
import { useQuery } from "@apollo/client";
import { ComplexDetailQuery, ComplexWithProperties } from "interfaces/complex/ComplexInterfaces";
import { COMPLEX_DETAIL_QUERY } from "constants/queries/ComplexQueries";
import { useParams } from "react-router";
import _ from "lodash";
import { complexService } from "services/ComplexService";
import { MarkerInterface } from "interfaces/map/MapInterfaces";
import HighlightProperties from "../components/molecules/HighlightProperties";
import { PropertiesPhotoQuery, Property, SearchProperties } from "../interfaces/properties/PropertiesInterfaces";
import { PROPERTIES_PHOTO_QUERY } from "../constants/queries/PropertyQueries";
import { propertyService } from "../services/PropertyService";
import { INITIAL_SEARCH_PROPERTIES } from "../constants/properties/PropertiesConstants";
import { handleApolloError } from "../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { scrollTop } from "../utils/utilFunctions";
import { COMPLEX_DEFAULT_PHOTO } from "../assets/Assets";
import { FIGURE_RATIOS } from "../constants/common/CommonConstants";
import { imageService } from "../services/ImageService";
import ComplexCount from "../components/molecules/ComplexCount";

interface RouteParams {
  id: string;
}

const ComplexDetailView: FunctionComponent = () => {
  const { id } = useParams<RouteParams>()

  const { data, error } = useQuery<ComplexDetailQuery>(COMPLEX_DETAIL_QUERY, { variables: { urlKey: id } })
  handleApolloError("complex_detail_query", error)

  const [autoCenter, setAutoCenter] = useState<boolean>(true);
  const [searchProperties, setSearchProperties] = useState<SearchProperties>(INITIAL_SEARCH_PROPERTIES);
  const [properties, setProperties] = useState<Property[]>([]);
  const [complexWithCount, setComplexWithCount] = useState<ComplexWithProperties>();

  const complex = _.first(data?.complexes);

  const { data: propertiesPhotoData } = useQuery<PropertiesPhotoQuery>(
    PROPERTIES_PHOTO_QUERY,
    {
      variables: { ids: searchProperties.data.map((property: Property) => property.urlKey) },
      skip: searchProperties.data.length === 0,
      errorPolicy: "all"
    }
  );

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (complex) {
      void propertyService.getHighlights([complex.id])
        .then((result: SearchProperties) => {
          setSearchProperties(result)
        })
      void complexService.findWithProperties(complex.id)
        .then((result: ComplexWithProperties) => {
          setComplexWithCount(result);
        });
    }
  }, [complex])

  useEffect(() => {
    if (propertiesPhotoData) {
      setProperties(searchProperties.data.map(
        (property: Property) => propertyService.mapPropertyWithPhoto(property, propertiesPhotoData.properties))
      )
    }
  }, [searchProperties, propertiesPhotoData])


  const markers = [complexService.mapComplexToMarkers(complex)].filter((marker: MarkerInterface) => !!marker)
  return complex ? (
    <Layout>
      <React.Fragment>
        <Section>
          <Heading mainTitle={complex.label} center />
          <Figure fill={!complex.photo[0]} className="mb-5" cap ratio={FIGURE_RATIOS.THREE_TWO}>
            <Img src={imageService.getImageUrlByFormat(complex.photo[0]) || COMPLEX_DEFAULT_PHOTO} alt="" />
          </Figure>
        </Section>
        <Section className="ComplexDescription">
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
          >
            {complex.description}
          </ReactMarkdownWithHtml>
        </Section>
        <Section>
          <Card rounded>
            <Row grid>
              <Col xs="12" md="4" style={{ backgroundColor: "#383838" }}>
                <Section className="ComplexCardAddress">
                  <h2>{complex.label}</h2>
                  <p>{complex.address}</p>
                  <p>{`${complex.zip_code}, ${complex.city}`}</p>
                  <p>{!!complexWithCount && <ComplexCount complex={complexWithCount} />}</p>
                </Section>
              </Col>
              <Col xs="12" md="8">
                <Map>
                  <MapBasic
                    zoom={MAP_DETAIL_ZOOM}
                    latitude={complex.latitude}
                    longitude={complex.longitude}
                    markers={markers}
                    getIcon={getSimpleMarker}
                    setAutoCenter={setAutoCenter}
                    autoCenter={autoCenter}
                  />
                </Map>
              </Col>
            </Row>
          </Card>
        </Section>

        <HighlightProperties properties={properties} />

      </React.Fragment>
    </Layout>
  ) : null
}

export default ComplexDetailView
