import React, { FunctionComponent, ReactElement } from "react";
import Icon from "./Icon";
import { FormattedMessage } from "react-intl";

interface CountRowProps {
  labelId: string;
  count: number;
}

const CountRow: FunctionComponent<CountRowProps> = ({
  labelId,
  count,
}): ReactElement | null => {
  if (!count) {
    return null;
  }
  return (
    <li>
      <Icon name="ArrowRight" className="mr-3" />
      <FormattedMessage id={labelId} values={{ count }} />
    </li>
  )
}

export default CountRow;
