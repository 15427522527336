import { useLocation } from "react-router";

export const useQueryParams = (fields: string[]): Record<string, string> => {
  const queryParams = new URLSearchParams(useLocation().search);
  const result = {};
  fields.map((field: string) => {
    result[field] = queryParams.get(field) || undefined;
  });
  return result;
}
