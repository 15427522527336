import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from "react";
import { Ticket, TicketItemsKeys, TicketStepperTitle, TicketUser } from "../interfaces/tickets/TicketsInterfaces";
import {
  STEPPER_TITLES,
  TICKET_CONTACTS,
  TICKET_IMPORTANCES,
  TICKET_LOCALIZATIONS,
  TICKET_RELATIONSHIPS,
  TICKET_SCHEDULES,
  TICKET_SITUATIONS,
  TICKET_TYPES,
  TICKETS
} from "../constants/tickets/TicketsConstants";
import { ticketService } from "../services/TicketService";
import StepDescription from "../views/tenantspace/tickets/StepDescription";
import { useIntl } from "react-intl";
import StepContact from "../views/tenantspace/tickets/StepContact";
import TicketStep from "../views/tenantspace/tickets/TicketStep";
import StepCategory from "../views/tenantspace/tickets/StepCategory";
import StepTransfer from "../views/tenantspace/tickets/StepTransfer";

export interface UseTicket {
  step: number;
  ticket: Ticket;
  titles: TicketStepperTitle[];
  onSubmit: () => void;
  isDisabled: () => boolean;
  loading: boolean;
  getStepperContent: () => ReactElement | null;
  setStep: Dispatch<SetStateAction<number>>;
}

export const useTicket = (
  getUserInfo: () => TicketUser,
  openModal: () => void
): UseTicket => {
  const intl = useIntl();

  // Functional
  const [step, setStep] = useState<number>(1)
  const [ticket, setTicket] = useState<Ticket>({});
  const [loading, setLoading] = useState<boolean>(false);

  // Fields
  const [selectedCategory, setSelectedCategory] = useState<TicketItemsKeys>();
  const [selectedImportance, setSelectedImportance] = useState<string>();
  const [selectedLocalization, setSelectedLocalization] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedRelationship, setSelectedRelationship] = useState<string>();
  const [selectedSituation, setSelectedSituation] = useState<string>();
  const [numberOfPerson, setNumberOfPerson] = useState<string>("");
  const [sne, setSne] = useState<boolean>();
  const [sneId, setSneId] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [billed, setBilled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(getUserInfo().email);
  const [selectedContact, setSelectedContact] = useState<string>();
  const [selectedSchedule, setSelectedSchedule] = useState<string>();
  const [files, setFiles] = useState<File[]>([]);

  const isChild = TICKET_RELATIONSHIPS.new_occupant_in_the_accommodation[1].name === selectedRelationship
  const isPhone = TICKET_CONTACTS.callback_request[0].name === selectedContact;

  useEffect(() => {
    setSelectedLocalization(undefined)
    setSelectedImportance(undefined)
    setSelectedType(undefined)
    setSelectedContact(undefined)
    setSelectedSchedule(undefined)
    setNumberOfPerson(undefined)
    setSne(undefined)
    setSneId(undefined)
    setSelectedRelationship(undefined)
    setSelectedSituation(undefined)
    setDescription("")
    setFiles([])
  }, [selectedCategory])

  useEffect(() => {
    setSelectedSituation(undefined)
  }, [selectedRelationship])

  const getDescription = (description: string) => {
    switch (selectedCategory) {
      case TICKETS.LEAK:
        return `${intl.formatMessage({ id: "stepper_importance" })} : ${intl.formatMessage({ id: selectedImportance })}\n\n${description}`;
      case TICKETS.INSECT_OR_PEST:
        return `${intl.formatMessage({ id: "stepper_type" })} : ${intl.formatMessage({ id: selectedType })}\n\n${description}`;
      case TICKETS.BLACKOUT:
        return `${intl.formatMessage({ id: "stepper_localization" })} : ${intl.formatMessage({ id: selectedLocalization })}\n\n${description}`;
      case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
        return `${intl.formatMessage({ id: "stepper_relationship" })} : ${intl.formatMessage({ id: selectedRelationship })}\n` +
          (selectedSituation ? `${intl.formatMessage({ id: "stepper_situation" })} : ${intl.formatMessage({ id: selectedSituation })}\n` : "") +
          `\n${description}`;
      case TICKETS.REQUEST_FOR_TRANSFER:
        return `${intl.formatMessage({ id: "stepper_transfer" })} : ${intl.formatMessage({ id: "request_for_transfer_number_of_person" }, { count: numberOfPerson })}\n` +
          `${intl.formatMessage({ id: "request_for_transfer_id" })} : ${sneId}\n` +
          `\n${description}`;
      case TICKETS.CALLBACK_REQUEST:
        return `${intl.formatMessage({ id: "stepper_contact" })} ${intl.formatMessage({ id: selectedContact }).toLowerCase()} : ` +
          `${isPhone ? getUserInfo().phone : email}\n` +
          `${isPhone ? `${intl.formatMessage({ id: "stepper_schedule" })} : ${intl.formatMessage({ id: selectedSchedule })}\n` : ""}` +
          `\n${description}`;
      default:
        return description;
    }
  }

  const createTicket = (): void => {
    setLoading(true)
    void ticketService.create({
      ...ticket,
      ...getUserInfo(),
      description: getDescription(description)
    })
      .then((result: Ticket) => {
        if (files.length > 0) {
          return ticketService.addDocuments(result.id.toString(), getUserInfo().resident_reference, files)
            .then(openModal)
        } else {
          openModal()
        }
      })
      .finally(() => setLoading(false))
  }

  const sendEmail = () => {
    setLoading(true)
    void ticketService.sendEmail(
      {
        ...ticket,
        ...getUserInfo(),
        description: getDescription(description),
      },
      files,
    )
      .then(() => {
        openModal()
      })
      .finally(() => setLoading(false))
  }

  const proceedToNextStep = useCallback(() => {
    setStep((state: number) => state + 1)
  }, [])

  const updateName = useCallback((selectedCategory: TicketItemsKeys, item?: string) => {
    setTicket((state: Ticket) => ({
      ...state,
      name: intl.formatMessage({ id: selectedCategory + "_title" }) +
        (item ? ` ${intl.formatMessage({ id: `${item}_title` })}` : "")
    }))
  }, [])

  const onSubmit = (): void => {
    switch (step) {
      case 1:
        updateName(selectedCategory)
        break;
      case 2:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.BLOCKED_PIPES:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
            updateName(selectedCategory, selectedLocalization)
            break;
          case TICKETS.HOT_WATER_FAILURE:
            updateName(selectedCategory, selectedType)
            break;
          default:
            break;
        }
        break;
      case 3:
        switch (selectedCategory) {
          case TICKETS.BLOCKED_PIPES:
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
          case TICKETS.BLACKOUT:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
          case TICKETS.REQUEST_FOR_TRANSFER:
            createTicket();
            break;
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            if (isChild) {
              proceedToNextStep();
            } else {
              createTicket();
            }
            break;
          case TICKETS.CALLBACK_REQUEST:
            if (isPhone) {
              proceedToNextStep();
            } else {
              sendEmail();
            }
            break;
          default:
            proceedToNextStep();
        }
        break;
      case 4:
        if (selectedCategory === TICKETS.CALLBACK_REQUEST) {
          sendEmail();
        } else {
          createTicket();
        }
        break;
      default:
        break;
    }
    if (step <= 2) {
      proceedToNextStep();
    }
  }

  const isDisabled = (): boolean => {
    switch (step) {
      case 1:
        return !selectedCategory;
      case 2:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.BLOCKED_PIPES:
          case TICKETS.BLACKOUT:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
            return !selectedLocalization;
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
            return !selectedType;
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return !selectedRelationship;
          case TICKETS.REQUEST_FOR_TRANSFER:
            return !numberOfPerson || !sne || !sneId;
          case TICKETS.CALLBACK_REQUEST:
            return !selectedContact;
        }
        break;
      case 3:
        switch (selectedCategory) {
          case TICKETS.LEAK:
            return !selectedImportance;
          case TICKETS.BLOCKED_PIPES:
            return !description || !billed;
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
          case TICKETS.BLACKOUT:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
          case TICKETS.REQUEST_FOR_TRANSFER:
            return !description;
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return isChild ? !selectedSituation : !description;
          case TICKETS.CALLBACK_REQUEST:
            return isPhone ? !selectedSchedule : !description;
        }
        break;
      case 4:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
          case TICKETS.CALLBACK_REQUEST:
            return !description;
        }
        break;
    }
    return true;
  }

  const getStepperContent = (): ReactElement | null => {
    switch (step) {
      case 1:
        return <StepCategory selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      case 2:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.BLOCKED_PIPES:
          case TICKETS.BLACKOUT:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
            return (
              <TicketStep
                name="stepper_localization"
                selectedItem={selectedLocalization}
                setSelectedItem={setSelectedLocalization}
                selectedCategory={selectedCategory}
                ticketItems={TICKET_LOCALIZATIONS}
              />
            )
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
            return (
              <TicketStep
                name="stepper_type"
                ticketItems={TICKET_TYPES}
                setSelectedItem={setSelectedType}
                selectedItem={selectedType}
                selectedCategory={selectedCategory}
              />
            )
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return (
              <TicketStep
                name="stepper_relationship"
                ticketItems={TICKET_RELATIONSHIPS}
                setSelectedItem={setSelectedRelationship}
                selectedItem={selectedRelationship}
                selectedCategory={selectedCategory}
              />
            )
          case TICKETS.REQUEST_FOR_TRANSFER:
            return (
              <StepTransfer
                setNumberOfPerson={setNumberOfPerson}
                setSne={setSne}
                setSneId={setSneId}
                numberOfPerson={numberOfPerson}
                sne={sne}
                sneId={sneId}
              />
            )
          case TICKETS.CALLBACK_REQUEST:
            return (
              <StepContact
                phone={getUserInfo().phone}
                email={email}
                setEmail={setEmail}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
              />
            )
          default:
            return null;
        }
      case 3:
        switch (selectedCategory) {
          case TICKETS.LEAK:
            return (
              <TicketStep
                name="stepper_importance"
                selectedItem={selectedImportance}
                setSelectedItem={setSelectedImportance}
                selectedCategory={selectedCategory}
                ticketItems={TICKET_IMPORTANCES}
              />
            )
          case TICKETS.BLOCKED_PIPES:
            return (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
                billed={billed}
                setBilled={setBilled}
              />
            )
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
          case TICKETS.REQUEST_FOR_TRANSFER:
            return (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
              />
            )
          case TICKETS.BLACKOUT:
            return (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                descriptionType={selectedLocalization === TICKET_LOCALIZATIONS[selectedCategory][2].name ? 2 : 1}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
              />
            )
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return isChild ? (
              <TicketStep
                name="stepper_situation"
                ticketItems={TICKET_SITUATIONS}
                setSelectedItem={setSelectedSituation}
                selectedItem={selectedSituation}
                selectedCategory={selectedCategory}
              />
            ) : (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
              />
            )
          case TICKETS.CALLBACK_REQUEST:
            return isPhone ? (
              <TicketStep
                name="stepper_schedule"
                ticketItems={TICKET_SCHEDULES}
                setSelectedItem={setSelectedSchedule}
                selectedItem={selectedSchedule}
                selectedCategory={selectedCategory}
              />
            ) : (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
              />
            )
          default:
            return null;
        }
      case 4:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
          case TICKETS.CALLBACK_REQUEST:
            return (
              <StepDescription
                selectedCategory={selectedCategory}
                description={description}
                setDescription={setDescription}
                files={files}
                setFiles={setFiles}
              />
            )
          default:
            return null;
        }
      default:
        return null;
    }
  }

  const mapTitles = (title: TicketStepperTitle, index: number): TicketStepperTitle => {
    const stepIndex = index + 1;
    if (step <= stepIndex) {
      return title;
    }
    switch (stepIndex) {
      case 1:
        return { ...title, subtitle: intl.formatMessage({ id: selectedCategory }) }
      case 2:
        switch (selectedCategory) {
          case TICKETS.LEAK:
          case TICKETS.BLOCKED_PIPES:
          case TICKETS.BLACKOUT:
          case TICKETS.MALFUNCTION_IN_COMMON_AREAS:
            return { ...title, subtitle: intl.formatMessage({ id: selectedLocalization }) }
          case TICKETS.HOT_WATER_FAILURE:
          case TICKETS.INSECT_OR_PEST:
            return { ...title, subtitle: intl.formatMessage({ id: selectedType }) }
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return { ...title, subtitle: intl.formatMessage({ id: selectedRelationship }) }
          case TICKETS.CALLBACK_REQUEST:
            return { ...title, subtitle: intl.formatMessage({ id: selectedContact }) }
        }
        break;
      case 3:
        switch (selectedCategory) {
          case TICKETS.LEAK:
            return { ...title, subtitle: intl.formatMessage({ id: selectedImportance }) }
          case TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION:
            return { ...title, subtitle: isChild ? intl.formatMessage({ id: selectedSituation }) : undefined }
          case TICKETS.CALLBACK_REQUEST:
            return { ...title, subtitle: isPhone ? intl.formatMessage({ id: selectedSchedule }) : undefined }
        }
        break;
    }
    return title;
  }

  const titles = STEPPER_TITLES
    .filter((title: TicketStepperTitle): boolean => {
      return ticketService.filterStepper(title, ticket.name ? selectedCategory : undefined) &&
        (isChild || title.title !== "stepper_situation") &&
        (isPhone || title.title !== "stepper_schedule")
    })
    .map(mapTitles)

  return {
    step,
    ticket,
    titles,
    onSubmit,
    isDisabled,
    loading,
    getStepperContent,
    setStep,
  };
}
