import { fetchWrapper } from "../utils/fetchWrapper";
import { OFFER_APPLY_ROUTE } from "../constants/ApiRoutes";
import { OfferApplication } from "interfaces/offers/OffersInterfaces";

const applicationApply = (values: OfferApplication): Promise<void> => {
    const formData = new FormData()
    formData.append("lastName", values.lastName);
    formData.append("firstName", values.firstName);
    formData.append("email", values.email);
    formData.append("phoneNum", values.phoneNum);
    formData.append("offerTitle", values.offerTitle);
    formData.append("description", values.description);
    formData.append("files", values.CV)
    if (values.letter) {
        formData.append("files", values.letter)
    }

    return fetchWrapper.postFormData<void>(OFFER_APPLY_ROUTE, formData)
}

export const applicationService = {
    applicationApply
}