import React, { FunctionComponent } from "react"

// Styles : styles/atoms/badge

// Reactstrap
import { Badge as LibBadge, BadgeProps as LibBadgeProps } from "reactstrap"

type BadgeProps = LibBadgeProps & {
  href?: string
  icon?: boolean
}

const Badge: FunctionComponent<BadgeProps> = ({
  children,
  icon,
  className = "",
  ...rest
}) => {
  const BadgeClassName =
    (icon ? " BadgeIcon" : "") +
    (className ? " " + className : "") 
  return (
    <LibBadge className={`Badge ${BadgeClassName}`} {...rest}>
      {children}
    </LibBadge>
  )
}

export default Badge
