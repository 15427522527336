export const EDITORIAL_MOVING_STYLE = `
  @media print {

      @page {
        size: landscape;
      }

      .Button {
        display: none!important;
        overflow: hidden !important;
        height: 0 !important;
        padding: 0 !important;
        border: 0 !important;
      }
    }

    @media all {
      .page-break {
        display: none;
      }
    }

    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  `
