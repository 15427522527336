import { fetchWrapper } from "../utils/fetchWrapper";
import { Water } from "../interfaces/water/WaterInterfaces";
import { WATER_ROUTE } from "../constants/ApiRoutes";
import { WATER_TYPE } from "../constants/water/WaterConstants";
import { colorService } from "./ColorService";
import { PRIMARY_COLORS } from "constants/colors/Colors";

const findByCurrentUserByContract = (contractId: string): Promise<Water[]> => {
  return fetchWrapper.get<Water[]>(WATER_ROUTE + `?contractId=${contractId}`);
}

const getColor = (type: string, count: number): string => {
  const multiplier = count - 1;
  const waterType = WATER_TYPE[type];
  if (waterType) {
    const rgb = waterType.color;
    const r = waterType.colorShading[PRIMARY_COLORS.RED](rgb[PRIMARY_COLORS.RED], multiplier);
    const g = waterType.colorShading[PRIMARY_COLORS.GREEN](rgb[PRIMARY_COLORS.GREEN], multiplier);
    const b = waterType.colorShading[PRIMARY_COLORS.BLUE](rgb[PRIMARY_COLORS.BLUE], multiplier);
    return colorService.computeRGB(r, g, b);
  }
  return "#000000";
}

const mapWatersToMap = (waters: Water[]): Map<string, Water[]> => {
  return waters.reduce((acc: Map<string, Water[]>, cur: Water) => {
    const list: Water[] = acc.get(cur.date) || [];
    list.push(cur);
    acc.set(cur.date, list);
    return acc;
  }, new Map())
}

export const waterService = {
  findByCurrentUserByContract,
  getColor,
  mapWatersToMap
}