import React, { FunctionComponent, ReactElement } from "react";
import Button from "./Button";
import { useIntl } from "react-intl";

interface PressDocumentProps {
  url: string;
  name: string;
}

const PressDocument: FunctionComponent<PressDocumentProps> = ({ url, name }): ReactElement => {
  const intl = useIntl();

  return (
    <Button
      className="mx-1"
      href={url}
      title={intl.formatMessage({ id: name })}
      target="_blank"
      color="primary"
    />
  )
}

export default PressDocument;
