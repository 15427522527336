import React, { FunctionComponent, ReactElement, useCallback, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import Section from "../../../components/atoms/Section";
import TicketStepper from "../../../components/molecules/TicketStepper";
import { FormattedMessage } from "react-intl";
import Button from "../../../components/atoms/Button";
import BasicModal from "../../../components/molecules/BasicModal";
import { history } from "../../../constants/History";
import { TICKETS_PATH } from "../../../constants/routes/RoutePaths";
import { authService } from "../../../services/AuthService";
import { TicketUser } from "../../../interfaces/tickets/TicketsInterfaces";
import { UseTicket, useTicket } from "../../../hooks/useTicket";
import { formatPhoneNumber } from "utils/utilFunctions";
import { useContracts } from "../../../hooks/useContracts";

const CreateTicketView: FunctionComponent<RouteProps> = (): ReactElement => {
  const { selectedContract, contractsDropdown } = useContracts();
  const user = authService.getLoggedInUser().user;

  const getUserInfo = (): TicketUser => {
    return {
      contractId: selectedContract,
      resident_reference: parseInt(user.tenantNumber),
      phone: formatPhoneNumber(user.phone),
      email: user.email,
    }
  }

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    step,
    ticket,
    titles,
    loading,
    getStepperContent,
    setStep,
    isDisabled,
    onSubmit,
  }: UseTicket = useTicket(getUserInfo, () => setModalOpen(true))

  const onModalClose = useCallback(() => {
    setModalOpen(false)
    history.push(TICKETS_PATH)
  }, [])

  return (
    <Layout dashboard>
      <>
        <Section className="mb-5">
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <div>
              <h2 className="h6 text-uppercase mb-0">
                <FormattedMessage id="your_ticket" />
              </h2>
              <p>{ticket.name}</p>
            </div>
            {contractsDropdown}
          </div>
        </Section>

        <Section>
          <div className="d-flex flex-column flex-xl-row mb-5">
            <TicketStepper step={step} titles={titles} setStep={setStep} />
            <div className="ticket-stepper-content">
              {getStepperContent()}
            </div>
          </div>
          <div className="d-flex justify-content-end flex-column flex-md-row">
            {step > 1 && (
              <Button
                disabled={loading}
                onClick={() => setStep((state: number) => state - 1)}
                size="lg"
                color="primary"
                className="text-uppercase mb-3 mb-md-0 mr-md-3"
              >
                <FormattedMessage id="stepper_previous" />
              </Button>
            )}
            <Button
              disabled={isDisabled() || loading}
              onClick={onSubmit}
              size="lg"
              color="primary"
              className="text-uppercase"
            >
              <FormattedMessage id={step === 4 ? "stepper_validate" : "stepper_next_step"} />
            </Button>
          </div>
        </Section>
        <BasicModal
          open={modalOpen}
          text="stepper_modal_text"
          button="stepper_modal_button"
          onClose={onModalClose}
        />
      </>
    </Layout>
  )
}

export default CreateTicketView;
