import React, { ReactElement, useContext, useEffect } from "react";
import { Contract } from "../interfaces/contracts/ContractsInterfaces";
import { tenantService } from "../services/TenantService";
import { FormGroup } from "reactstrap";
import Input from "../components/atoms/Input";
import { FormattedMessage } from "react-intl";
import Label from "../components/atoms/Label";
import { ContextContract } from "../contexts/contextContract";
import { ComplexUrlKey } from "interfaces/complex/ComplexInterfaces";
import { complexService } from "services/ComplexService";

interface UseContracts {
  selectedContract?: string;
  contracts: Contract[];
  contractsDropdown: ReactElement;
}

export const useContracts = (): UseContracts => {
  const { selectedContractId, setSelectedContractId, contracts, setContracts, setSelectedContractComplex } = useContext(ContextContract);
  // const [selectedComplex, setSelectedComplex] = useState<Complex>(null);
  // const setSelected
  useEffect(() => {
    if (contracts.length === 0) {
      void tenantService.getContracts()
        .then((result: Contract[]) => {
          const contractIds = result.map((contract: Contract) => contract.contract_number);
          setContracts(result)
          if (result.length > 0) {
            setSelectedContractId(contractIds[0]);
            void complexService.findComplexUrlKeyByContractNumber(contractIds[0])
              .then((result: ComplexUrlKey) => {
                setSelectedContractComplex(result.complex);
            })
          }
        });
    }

}, [contracts.length]);

const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedContractId(e.target.value);
    void complexService.findComplexUrlKeyByContractNumber(e.target.value)
    .then((result: ComplexUrlKey) => {
      setSelectedContractComplex(result.complex);
    })
  }

  const display = (): ReactElement => {
    return (
      <FormGroup>
        {contracts.length > 1 && (
          <>
            <Label for="contract">
              <FormattedMessage id="my_contracts" />
            </Label>
            <Input
              type="select"
              name="contract"
              value={selectedContractId}
              onChange={onChange}
            >
              {contracts.map((contract: Contract) => (
                <option key={contract.contract_number}>
                  {contract.contract_number}
                </option>
              ))}
            </Input>
          </>
        )}
      </FormGroup>
    )
  }

  return {
    selectedContract: selectedContractId,
    contracts: contracts,
    contractsDropdown: display()
  }
}
