import React, { FunctionComponent, ReactElement } from "react";
import { formatDateToStandardShortWithTime } from "../../utils/dateUtils";
import BasicModal from "./BasicModal";
import { Ticket } from "../../interfaces/tickets/TicketsInterfaces";

interface TicketModalProps {
  selectedTicket?: Ticket;
  open: boolean;
  onClose: () => void;
}

const TicketModal: FunctionComponent<TicketModalProps> = ({
  selectedTicket,
  open,
  onClose,
}): ReactElement => {
  return (
    <BasicModal
      open={open}
      body={(
        <>
          <h4 className="h4">
            {selectedTicket?.name}
          </h4>
          <em>{selectedTicket && formatDateToStandardShortWithTime(selectedTicket?.created_at)}</em>
          <p className="my-3">
            {selectedTicket?.description?.split("\n").map((item: string, index: number) => (
              <span key={`description_${index}`}>
                {item}<br />
              </span>
            ))}
          </p>
        </>
      )}
      onClose={onClose}
      button="close"
    />
  );
}

export default TicketModal;