import { LatLngBoundsExpression } from "leaflet";
import { useMap } from "react-leaflet";
import { FunctionComponent, useEffect } from "react";
import { MarkerInterface } from "../../interfaces/map/MapInterfaces";
import { MARKER_SELECT_ZOOM } from "../../constants/map/MapConstants";

interface MapCoreProps {
  bounds: LatLngBoundsExpression | undefined;
  autoCenter: boolean;
  setAutoCenter?: (autoCenter: boolean) => void | undefined;
  onMapClick?: () => void;
  center?: MarkerInterface;
}

const MapCore: FunctionComponent<MapCoreProps> = ({
  center,
  bounds,
  autoCenter,
  setAutoCenter,
  onMapClick,
}) => {
  const map = useMap()

  onMapClick && map.on("click", onMapClick)

  useEffect(() => {
    if (bounds && autoCenter) {
      map.fitBounds(bounds);
      setAutoCenter && setAutoCenter(false);
    } else if (center) {
      map.flyTo(center.position, MARKER_SELECT_ZOOM, { easeLinearity: 0.2 })
    }
  }, [bounds])

  return null;
}

export default MapCore;