import React, { FunctionComponent, useState } from "react"

// Styles : styles/atoms/alert

// Reactstrap
import { Alert as LibAlert, AlertProps as LibAlertProps} from "reactstrap"

type AlertProps = LibAlertProps & {
  dismiss: boolean
  color?: string
}

const Alert: FunctionComponent<AlertProps> = ({
  children,
  className = "",
  dismiss = false,
  color = "secondary",
  ...rest
}) => {

  const [visible, setVisible] = useState<boolean>(true);

  const onDismiss = () => {
    if (dismiss) {
      setVisible(false)
    }
  }
  return (
    <LibAlert
      className={`Alert ${className}`}
      color={color}
      {...rest}
      isOpen={visible}
      onClick={() => {
        onDismiss()
      }}
    >
      {children}
    </LibAlert>
  )
}

export default Alert
