import { MarkerInterface } from "interfaces/map/MapInterfaces";
import { DivIcon, Marker } from "leaflet";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { Marker as ReactMarker, Popup } from "react-leaflet";
import MapComplexCard from "./MapComplexCard";

interface MapMarkerProps {
  marker: MarkerInterface;
  getIcon?: (marker: MarkerInterface) => DivIcon;
}

const MapMarker: FunctionComponent<MapMarkerProps> = ({
  marker,
  getIcon,
}) => {
  const markerRef = useRef<Marker>();

  const removeSelectedClassName = () => {
    markerRef?.current?.getElement()?.classList.remove("selected")
  }

  const addSelectedClassName = () => {
    markerRef.current.getElement()?.classList.add("selected");
  }

  useEffect(() => {
    markerRef.current.on("popupclose", removeSelectedClassName)
    markerRef.current.on("mousedown", addSelectedClassName)
  }, [markerRef.current])

  return (
    <ReactMarker
      ref={markerRef}
      position={marker.position}
      icon={getIcon(marker)}
    >
      <Popup>
        <MapComplexCard complex={marker.complex} />
      </Popup>
    </ReactMarker>
  )
}

export default MapMarker