import React, { FunctionComponent, ReactElement, useState } from "react";
import { RouteProps, useParams } from "react-router";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import { useQuery } from "@apollo/client";
import { OffersDetailQuery } from "../interfaces/offers/OffersInterfaces";
import { OFFER_DETAIL_QUERY } from "../constants/queries/OfferQueries";
import Button from "../components/atoms/Button";
import ReactMarkdownWithHtml from "react-markdown/with-html"
import gfm from "remark-gfm"
import { useIntl } from "react-intl";
import Icon from "../components/atoms/Icon";
import { EmailShareButton, LinkedinShareButton } from "react-share";
import Nav from "components/atoms/Nav";
import ListArticles from "components/molecules/ListArticles";
import Heading from "components/molecules/Heading";
import { NavItem } from "reactstrap";
import { handleApolloError } from "../hooks/handleApolloError";
import BasicModal from "components/molecules/BasicModal";
import ApplicationForm from "components/molecules/ApplicationForm";

interface RouteParams {
  id: string;
}

const OfferDetailView: FunctionComponent<RouteProps> = (): ReactElement | null => {
  const intl = useIntl();
  const { id } = useParams<RouteParams>();
  const [applicationModal, setApplicationModal] = useState<boolean>(false);

  const { data, error } = useQuery<OffersDetailQuery>(OFFER_DETAIL_QUERY, {
    variables: { urlKey: id },
    errorPolicy: "all"
  });
  handleApolloError("offer_detail_query", error);
  const offer = data?.offers[0];

  return (
    <Layout className="offer-detail">
      {offer && (
        <>
          <BasicModal
            open={applicationModal}
            body={(
              <ApplicationForm
                offerTitle={offer.title}
                modalTitle={offer?.title}
                closeModal={() => setApplicationModal(false)}
              />
            )}
            onClose={() => setApplicationModal(!applicationModal)}
          />
          <Section>
            <Nav className="NavSocial">
              <NavItem>
                <EmailShareButton url={window.location.href}>
                  <Button outline pill icon color="dark"><Icon name="Mail" /></Button>
                </EmailShareButton>
              </NavItem>
              <NavItem>
                <LinkedinShareButton url={window.location.href}>
                  <Button outline pill icon color="dark"><Icon name="LinkedIn" /></Button>
                </LinkedinShareButton>
              </NavItem>
            </Nav>

            <Heading h1 mainTitle={offer.title} upTitle={offer.city} tags={offer.tags}>
              <Button
                color="primary"
                title="offer_detail_apply"
                onClick={() => setApplicationModal(!applicationModal)}
              />
            </Heading>

            <ReactMarkdownWithHtml
              allowDangerousHtml
              plugins={[gfm]}
              className="description mb-5 mt-4 pb-5"
            >
              {offer.description}
            </ReactMarkdownWithHtml>
          </Section>

          {offer.articles.length > 0 &&
          <>
              <Section oblique="up" bgColor="light">
                  <Heading mainTitle={intl.formatMessage({ id: "offer_detail_job_card" })} />
                  <ListArticles articles={offer?.articles || []} />
              </Section>
          </>
          }
        </>
      )}
    </Layout>
  )
}

export default OfferDetailView
