import React, { FunctionComponent, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Col, FormFeedback, FormGroup, Label } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { StepProps } from "interfaces/formstepper/FormStepperInterfaces";
import { useIntl } from "react-intl";
import TownSuggester from "../molecules/TownSuggester";
import { COMMUNITY_LIST } from "../../constants/common/CommonConstants";

export const FormCommunity: FunctionComponent<StepProps> = ({ formData, setFormData, nextStep }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    community: Yup
      .mixed()
      .required(intl.formatMessage({ id: "error_message_community_required" }))
      .oneOf(COMMUNITY_LIST.map((community: string) => community), intl.formatMessage({ id: "error_message_community_wrong_city" }))
  });

  const [value, setValue] = useState<string>(formData.community);
  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setFormData(values)
        nextStep();
      }}
    >
      {({
        setFieldValue,
        touched,
        errors,
      }) => (
        (<Form >
          <FormGroup>
            <Row grid gutters="sm" justifyContent="center">
              <Col xs="auto">
                <Label className="text-center" >{intl.formatMessage({ id: "form_question_where_to_live" })}</Label>
                <FormGroup >
                  <TownSuggester
                    currentValue={value}
                    onChange={setValue}
                    onSuggestionsFetchRequested={ (value: string) => setFieldValue("community", value)}
                    onSuggestionSelected={ (suggestionValue: string) => setFieldValue("community", suggestionValue) }
                    placeholderLabel="form_question_where_to_live_placeholder"
                  />
                  {(touched.community && !!errors.community) && <FormFeedback className="d-block text-center">{errors.community}</FormFeedback>}
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
            <Button type="button" disabled color="link" className="align-self-center">
              <Icon name="ChevronLeft" />
              <span className="Text">{intl.formatMessage({ id: "form_stepper_button_prev" })}</span>
            </Button>
            <Button
              type="submit"
              color="primary"
            >
              {intl.formatMessage({ id: "form_stepper_button_next" })}
            </Button>
          </FormGroup>
        </Form>)
      )}
    </Formik>
  );
};
