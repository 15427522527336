import { fetchWrapper } from "../utils/fetchWrapper";
import { TICKET_ADD_DOCUMENT_ROUTE, TICKET_EMAIL_ROUTE, TICKET_ROUTE } from "../constants/ApiRoutes";
import { Ticket, TicketItemsKeys, TicketStepperTitle } from "../interfaces/tickets/TicketsInterfaces";
import { Params } from "../interfaces/api/ApiInterfaces";
import { DateTime } from "luxon";

const sort = (a: Ticket, b: Ticket): number => {
  const dateA = DateTime.fromISO(a.last_event_at);
  const dateB = DateTime.fromISO(b.last_event_at);
  return dateA < dateB ? 1 : -1;
}

const sortIntervention = (a: Ticket, b: Ticket): number => {
  const dateA = DateTime.fromISO(a.intervention.intervention_date);
  const dateB = DateTime.fromISO(b.intervention.intervention_date);
  return dateA < dateB ? -1 : 1;
}

const findAllByTenantNumber = (tenantNumber: string): Promise<Ticket[]> => {
  return fetchWrapper.get<Ticket[]>(TICKET_ROUTE + `?tenantNumber=${tenantNumber}`)
    .then((result: Ticket[]) => {
      return result.sort(sort)
    })
}

const findAppointmentsByTenantNumber = (tenantNumber: string): Promise<Ticket[]> => {
  return fetchWrapper.get<Ticket[]>(TICKET_ROUTE + `?tenantNumber=${tenantNumber}&status=4`)
    .then((result: Ticket[]) => {
      return result.sort(sort)
    })
}

const findById = (id: number): Promise<Ticket> => {
  return fetchWrapper.get<Ticket>(TICKET_ROUTE + `/${id.toString()}`)
}

const create = (ticket: Ticket): Promise<Ticket> => {
  return fetchWrapper.post<Ticket>(TICKET_ROUTE, { ticket } as unknown as Params)
}

const sendEmail = (ticket: Ticket, files: File[]): Promise<void> => {
  const formData = new FormData()
  formData.append("ticket", JSON.stringify(ticket));
  files.forEach((file: File) => {
    formData.append("files", file);
  });
  return fetchWrapper.postFormData<void>(TICKET_EMAIL_ROUTE, formData);
}

const addDocuments = (ticketId: string, tenantNumber: number, files: File[]): Promise<void> => {
  const formData = new FormData()
  files.forEach((file: File) => formData.append("files", file))
  return fetchWrapper.postFormData<void>(
    TICKET_ADD_DOCUMENT_ROUTE.replace(":ticket_id", ticketId) + `?tenantNumber=${tenantNumber}`,
    formData,
  )
}

const filterStepper = (stepperTitle: TicketStepperTitle, selectedCategory?: TicketItemsKeys): boolean => {
  if (stepperTitle.categories) {
    return stepperTitle.categories.includes(selectedCategory)
  }
  return true;
}

export const ticketService = {
  findAllByTenantNumber,
  findAppointmentsByTenantNumber,
  create,
  addDocuments,
  filterStepper,
  findById,
  sort,
  sortIntervention,
  sendEmail,
}
