/**
 * C'est à cause d'eux qu'on fait comme ça : https://eway.io/api-v3
 */
export const callEwayPayment = (selectedContract: string,
                                email: string,
                                paymentAmount: string,
                                paymentLink: string): void => {
    const data = {
        contrat: selectedContract,
        email: email,
        mnt_: paymentAmount
    }
    const form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = paymentLink;
    for (const name in data) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = data[name];
        form.appendChild(input);
    }
    form.submit();
}