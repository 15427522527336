import React, { FunctionComponent } from "react"

// Styles : styles/atoms/Label

// Reactstrap
import { Label as LibLabel, LabelProps as LibLabelProps } from "reactstrap"

type LabelProps = LibLabelProps & {
  button?: boolean
  noBorder?: boolean
  bsSize?: "sm" | "md" | "lg"

}

const Label: FunctionComponent<LabelProps> = ({
  children,
  button,
  noBorder,
  bsSize,
  className = "",
  ...rest
}) => {
  const LabelClassName =
    (button ? " LabelButton" : "") +
    (noBorder ? " NoBorder" : "") +
    (bsSize ? " Label--" + bsSize : "") +
    (className ? " " + className : "") 
  return (
    <LibLabel className={`Label ${LabelClassName}`} {...rest}>
      {children}
    </LibLabel>
  )
}

export default Label
