import React, { FunctionComponent, ReactElement } from "react";
import Section from "../atoms/Section";
import Heading from "./Heading";
import Row from "../atoms/Row";
import { Property } from "../../interfaces/properties/PropertiesInterfaces";
import { Col } from "reactstrap";
import CardProperty from "./CardProperty";
import { useIntl } from "react-intl";

interface HighlightPropertiesProps {
  properties: Property[];
}

const HighlightProperties: FunctionComponent<HighlightPropertiesProps> = ({
  properties
}): ReactElement | null => {
  const intl = useIntl();

  if (properties.length === 0) {
    return null;
  }
  return (
    <Section bgColor="light">
      <Heading mainTitle={intl.formatMessage({ id: "housing_property_highlight" })} center />
      <Row>
        {properties.map((property: Property) => (
          <Col key={property.id} xs="12" md="4">
            <CardProperty property={property} />
          </Col>
        ))}
      </Row>
    </Section>
  );
}

export default HighlightProperties;