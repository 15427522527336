import React, { FunctionComponent } from "react";

// Styles : styles/molecules/ButtonPhone

import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";

type ButtonPhoneProps = {
  number: string;
  onClick: () => void;
}

const ButtonPhone: FunctionComponent<ButtonPhoneProps> = ({
  number,
  onClick
}) => {
    return (
    <Button onClick={onClick} className="ButtonPhone" color="" outline={false}>
      <Icon name="Phone"/>
      <span className="Text">{number}</span>
    </Button>
  )
}

export default ButtonPhone;
