import { fetchWrapper } from "../utils/fetchWrapper";
import { User } from "../interfaces/users/UsersInterfaces";
import { USER_ROUTE } from "../constants/ApiRoutes";
import { authService } from "./AuthService";
import { Media } from "../interfaces/common/CommonInterfaces";
import { imageService } from "./ImageService";

const update = (updatedUser: User) : Promise<User> => {
  return fetchWrapper
    .put<User>(`${USER_ROUTE}/${updatedUser.id}`, {
      id: updatedUser.id,
      email: updatedUser.email,
      phone: updatedUser.phone,
    })
    .then((result: User) => {
      void authService.updateLoggedInUser(result)
      return result;
    })
}

const switchErConsent = (updatedUser: User) : Promise<User> => {
  return fetchWrapper
    .put<User>(`${USER_ROUTE}/${updatedUser.id}`, {
      id: updatedUser.id,
      er_consent: updatedUser.er_consent
    })
    .then((result: User) => {
      void authService.updateLoggedInUser(result)
      return result;
    })
}

const uploadProfilePicture = (file: File, user: User) : Promise<Media[]> => {
  return imageService.uploadImage(file, "user", user.id.toString(), "image", "users-permissions")
    .then((result: Media[]) => {
      void authService.updateLoggedInUser({ ...user, image: result[0]})
      return result;
    })
}

export const userService = {
  update,
  switchErConsent,
  uploadProfilePicture
}