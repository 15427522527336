import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback } from "react";
import Form from "../../../components/atoms/Form";
import { FormGroup, Label } from "reactstrap";
import Input from "../../../components/atoms/Input";
import { FormattedMessage, useIntl } from "react-intl";
import Container from "../../../components/atoms/Container";
import FileCard from "../../../components/atoms/FileCard";
import ReactHtmlParser from "react-html-parser";

interface StepDescriptionProps {
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  selectedCategory: string;
  setBilled?: Dispatch<SetStateAction<boolean>>;
  billed?: boolean;
  descriptionType?: number;
}

const StepDescription: FunctionComponent<StepDescriptionProps> = ({
  selectedCategory,
  description,
  setDescription,
  setFiles,
  files,
  setBilled,
  billed,
  descriptionType,
}): ReactElement => {
  const intl = useIntl();

  const onFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const array = [];
    for (const file of e.target.files) {
      array.push(file);
    }
    setFiles((state: File[]) => state.concat(array))
  }, [])

  const onFileClick = useCallback((index: number) => () => {
    setFiles((state: File[]) => {
      const stateCopy = state.concat();
      stateCopy.splice(index, 1);
      return stateCopy;
    })
  }, [])

  return (
    <Container className="h-100 d-flex flex-column justify-content-center">
      <Form>
        <h6 className="h6 mb-4">
          <FormattedMessage id="describe_your_ticket" />
        </h6>
        <FormGroup>
          <Input
            rows={7}
            type="textarea"
            name="description"
            id="description"
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            placeholder={intl.formatMessage({ id: `your_comments_${selectedCategory}${descriptionType ? `_${descriptionType}` : ""}` })}
          />
        </FormGroup>
        <FormGroup>
          <Input className="d-none" type="file" name="files" id="files" multiple onChange={onFileUpload} />
          <Label className="text-primary text-underline cursor-pointer mb-0" htmlFor="files">
            <FormattedMessage id="add_documents" />
          </Label>
          <p className="d-block small">(PDF, JPG, Word, etc.)</p>
          <div className="d-flex flex-wrap">
            {files.map((file: File, index: number) => (
              <FileCard key={`file_${index}`} file={file} onClick={onFileClick(index)} />
            ))}
          </div>
        </FormGroup>
        {setBilled && (
          <FormGroup check disabled>
            <Label check>
              <Input
                type="checkbox"
                name="billed"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBilled(e.target.checked)}
                className="mr-3"
                defaultChecked={billed}
              />
              {ReactHtmlParser(intl.formatMessage({ id: "billing" }))}
            </Label>
          </FormGroup>
        )}
      </Form>
    </Container>
  );
}

export default StepDescription;