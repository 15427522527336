import Row from "components/atoms/Row";
import React, { FunctionComponent, ReactElement } from "react";
import { Col } from "reactstrap";
import { Offer } from "../../interfaces/offers/OffersInterfaces";
import CardOffer from "./CardOffer";
import NoResult from "./NoResult";

interface ListOffersProps {
  offers: Offer[]
}

const ListOffers: FunctionComponent<ListOffersProps> = ({ offers }): ReactElement => {
  const filteredOffers = offers.filter((offer: Offer): boolean => !!offer);
  return (
    <>
      {filteredOffers.length === 0 && <NoResult />}

      <Row grid>
        {filteredOffers.map((offer: Offer, index: number) => {
          const even = index % 2 === 0;
          return (
            <Col xs="12" md="4" key={offer.id}>
              <CardOffer offer={offer} className={even ? " even" : " odd"} />
            </Col>
            
          )
        })}
      </Row>
    </>
  )
}

export default ListOffers;