export const LOGO_LRYE = "/logo.svg"
export const LOGO_LLI = "/logo_lli.svg"
export const LOGO_SERVICE = "/service.svg"
export const LOGO_PODCAST = "/podcast.svg"
export const LOGO_PRESS = "/press.svg"
export const LOGO_USER = "/user.svg"
export const LOCATION_ICON_MAP = "/loc.svg"
export const LOCATION_ICON_LRYE_MAP = "/logo_lrye.svg"
export const COMPLEX_ICON = "/residence.svg"
export const COMMERCE_ICON = "/picto-commerce.svg"
export const LOGEMENT_ICON = "/picto-logement.svg"
export const MAISON_ICON = "/picto-maison.svg"
export const PARKING_ICON = "/picto-parking.svg"

export const RENT_DIRECT_DEBIT_PDF = "/pdf/Document_prelevement_SEPA.pdf"
export const RENT_SHOP_DIRECT_DEBIT_PDF = "/pdf/Document_prelevement_commerce_SEPA.pdf"
export const NOTICE_YETI_PDF = "/pdf/DC-101_YETI_Notice-Utilisation.pdf"
export const FOUNDATION_PDF = "/pdf/Fondation.pdf"
export const RAPPORT_RSE_PDF = "/pdf/LRYE_RSE 2024_A4_V12WEB.pdf"

export const COMPLEX_DEFAULT_PHOTO = "/residence.svg"
export const HOUSING_DEFAULT_PHOTO = "/housings.jpeg"
export const JOIN_US_DEFAULT_PHOTO = "/join_us.png"
export const HOME_DEFAULT_PHOTO = "/home.jpeg";

export const APPLE_DOWNLOAD_BACKGROUND = "/apple_download_background.jpg"
export const GOOGLE_PLAY_DOWNLOAD_BACKGROUND = "/google_play_download_background.jpg"

export const TENANT_SEPA = {
  name: "Formulaire locataire",
  url: RENT_DIRECT_DEBIT_PDF,
}

export const SHOP_SEPA = {
  name: "Formulaire commerce",
  url: RENT_SHOP_DIRECT_DEBIT_PDF,
}

export const FOUNDATION = {
  name: "Dépliant de la fondation",
  url: FOUNDATION_PDF,
}

export const NOTICE_YETI = {
  name: "Téléchargez la notice d'utilisation du logement connecté",
  url: NOTICE_YETI_PDF,
}

export const RAPPORT_RSE = {
  name: "Rapport RSE",
  url: RAPPORT_RSE_PDF,
}


export const PLACEHOLDERS = {
  _40x40: "/placeholders/40x40.png",
  _200x200: "/placeholders/200x200.png",
  _450x600: "/placeholders/450x600.png",
  _600x400: "/placeholders/600x400.png",
  _600x450: "/placeholders/600x450.png",
  _1200x600: "/placeholders/1200x600.png",
  _1600x900: "/placeholders/1600x900.png",
  _1920x600: "/placeholders/1920x600.png",
}
