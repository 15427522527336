import { gql } from "@apollo/client";

export const OFFERS_QUERY = gql`
   query Query(
    $tagIds: [String]
    )
    {
      offers(
        where: {
          tags: {id: $tagIds}
        }
        sort : "title"
      ) {
          id
          title
          description
          city
          start
          urlKey
          tags{id name color}
          url
        }
    } 
`

export const OFFER_DETAIL_QUERY = gql`
    query Query($urlKey: String)
    {
      offers(where: {
        urlKey: $urlKey
        })
      {
          id
          title
          description
          city
          tags{id name color}
          articles{id title chapeau photo{url} created_at readingTime urlKey}
          url
        }
    } 
`
export const OFFER_TAGS_CATEGORIES_QUERY = gql`
query OfferTagCategories {
  offerTagCategories {
    label
  }
}`

