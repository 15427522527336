import { SUBMIT_CONTACT_FORM_ROUTE, SUBMIT_PROPERTY_CONTACT_FORM_ROUTE } from "constants/ApiRoutes"
import { MailContactRequest, MailPropertyContactRequest } from "interfaces/mail/MailInterfaces"
import { fetchWrapper } from "utils/fetchWrapper"

const submitPropertyContactForm = (mailRequest: MailPropertyContactRequest) : Promise<void> => {
  return fetchWrapper.post<void>(SUBMIT_PROPERTY_CONTACT_FORM_ROUTE, {
    "user": mailRequest.user,
    "propertyId": mailRequest.propertyId,
    "to": mailRequest.to,
    "from": mailRequest.from,
    "path": mailRequest.path,
  })
}

const submitContactForm = (request: MailContactRequest, agencyMail: string): Promise<void> => {
  const formData = new FormData()
  formData.append("lastName", request.lastName);
  formData.append("firstName", request.firstName);
  formData.append("email", request.email);
  formData.append("agency", agencyMail);
  formData.append("message", request.message);
  formData.append("file", request.file);
  return fetchWrapper.postFormData<void>(SUBMIT_CONTACT_FORM_ROUTE, formData);
}

export const contactFormService = {
  submitPropertyContactForm,
  submitContactForm,
}
