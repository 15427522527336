import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback } from "react";
import { TicketCard } from "../../../interfaces/tickets/TicketsInterfaces";
import Container from "../../../components/atoms/Container";
import Row from "../../../components/atoms/Row";
import { Col } from "reactstrap";
import CardTicketContact from "../../../components/molecules/CardTicketContact";
import { TICKET_CONTACTS } from "../../../constants/tickets/TicketsConstants";
import { FormattedMessage } from "react-intl";

interface StepContactProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  selectedContact: string | undefined;
  setSelectedContact: Dispatch<SetStateAction<string | undefined>>;
  phone: string;
}

const StepContact: FunctionComponent<StepContactProps> = ({
  email,
  setEmail,
  selectedContact,
  setSelectedContact,
  phone
}): ReactElement => {

  const onCardClick = useCallback((contact: TicketCard) => () => {
    setSelectedContact(selectedContact === contact.name ? undefined : contact.name)
  }, [selectedContact]);

  const enhancedContacts = TICKET_CONTACTS.callback_request.map((contact: TicketCard) => {
    const isPhone = contact.name === "by_phone";
    return {
      ...contact,
      value: isPhone ? phone : email,
    }
  })
  return (
    <Container className="h-100 d-flex flex-column justify-content-center">
      <div>
        <h6 className="h6 mb-4">
          <FormattedMessage id="recontact" />
        </h6>
        <Row flexFit>
          {enhancedContacts.map((contact: TicketCard) => (
            <Col key={contact.name} xl={6}>
              <CardTicketContact
                selected={selectedContact === contact.name}
                onClick={onCardClick(contact)}
                contact={contact}
                onChange={setEmail}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default StepContact;