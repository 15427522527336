import { stringify } from "query-string";

export const getRouteWithId = (route: string, id: string): string => {
  return route.replace(":id", id)
}

export const buildQueryParam = (name: string, value: string | number | boolean | undefined): string => {
  if (value === undefined || value === "") {
    return "";
  }
  return `${name}=${value.toString()}&`;
}
export const stringifyQuery = <T>(query: T): string => stringify(query, { skipEmptyString: true, skipNull: true })
