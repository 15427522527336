import { MarkerInterface } from "interfaces/map/MapInterfaces";
import { DivIcon } from "leaflet";
import React, { FunctionComponent } from "react";
import { Marker as ReactMarker } from "react-leaflet";

interface MapMarkerProps {
  marker: MarkerInterface;
  getIcon?: (marker: MarkerInterface) => DivIcon;
  onMarkerClick?: (id: string) => void | undefined;
}
const MapMarker: FunctionComponent<MapMarkerProps> = ({
  marker,
  getIcon,
  onMarkerClick,
}) => {
  return (
    <ReactMarker
      position={marker.position}
      icon={getIcon(marker)}
      eventHandlers={{
        mousedown: () => onMarkerClick && onMarkerClick(marker.id),
      }}
    />
  )
}

export default MapMarker