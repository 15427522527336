import React, { FunctionComponent, ReactElement, useCallback, useState } from "react";
import { FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import Label from "components/atoms/Label";

interface FormValues extends FormikValues {
  value: string;
}

interface EditableInputProps {
  name: string;
  label: string;
  value: string;
  onSubmit: (name: string, value: string) => Promise<void>;
  validation?: Yup.StringSchema;
}

const EditableInput: FunctionComponent<EditableInputProps> = ({
  name,
  label,
  value,
  onSubmit,
  validation,
}): ReactElement => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toggleIsEdit = useCallback(() => {
    setIsEdit((state: boolean) => !state);
  }, [])

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleIsEdit();
  }, [])

  const onLibSubmit = useCallback((
    { value }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    onSubmit(name, value)
      .then(() => {
        toggleIsEdit();
      })
      .finally(() => setSubmitting(false))
  }, [])

  return (
    <Formik
      validationSchema={Yup.object({
        value: validation,
      })}
      onSubmit={onLibSubmit}
      initialValues={{ value }}>
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormGroup>
            <Label>
              <FormattedMessage id={label} />
              {isEdit ? (
                <Button type="submit" size="sm" color="link" className="py-0">
                  <FormattedMessage id="save" />
                </Button>
              ) : (
                <Button size="sm" color="link" className="py-0" onClick={onClick}>
                  <FormattedMessage id="edit" />
                </Button>
              )}
            </Label>
            <Input
              name="value"
              value={values.value}
              readOnly={!isEdit}
              onChange={handleChange}
              invalid={touched.value && !!errors.value}
              errorMessage={errors.value}
            />
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}

export default EditableInput;