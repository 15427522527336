import React, { FunctionComponent, ReactElement } from "react";
import { Water, WaterGraphLegendInterface } from "../../interfaces/water/WaterInterfaces";
import { useIntl } from "react-intl";
import { WATER_LOCATION_LABELS, WATER_TYPE } from "../../constants/water/WaterConstants";

interface WaterGraphLegendProps {
  waterDatas: Water[];
  props: WaterGraphLegendInterface;
}

const WaterGraphLegend: FunctionComponent<WaterGraphLegendProps> = ({
  waterDatas,
  props
}): ReactElement => {
  const intl = useIntl();

  const { payload } = props;
  return (
    <ul className="recharts-default-legend text-center">
      {payload.map((entry, index) => {
        const waterData = waterDatas.find((waterData: Water) => waterData.meterNumber === entry.value);
        
        let waterLocation = WATER_LOCATION_LABELS.UNK;
        if (Object.keys(WATER_LOCATION_LABELS).includes(waterData.location)) {
          waterLocation = WATER_LOCATION_LABELS[waterData.location]
        }

        const text = [
          intl.formatMessage({ id:  waterLocation}),
          intl.formatMessage({ id: WATER_TYPE[waterData.type]?.label })
        ].join(" - ");
        return (
          <li key={`item-${index}`} className="recharts-legend-item legend-item-0 d-inline-block mr-4">
            <svg
              className="recharts-surface mr-2"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
            >
              <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
            </svg>
            <span className="recharts-legend-item-text" style={{ color: entry.color }}>
              {text}
            </span>
          </li>
        )
      })}
    </ul>
  );
}

export default WaterGraphLegend;