import React, { FunctionComponent, useEffect, useState } from "react";

import { getSimpleMarker, MAP_DETAIL_ZOOM } from "../constants/map/MapConstants"

import {CardBody, CardHeader, CardText, CardTitle, Col} from "reactstrap";

import Row from "components/atoms/Row";
import Section from "components/atoms/Section";
import Layout from "components/layout/Layout";
import Map from "components/atoms/Map";
import MapBasic from "../components/molecules/MapBasic";
import Heading from "components/molecules/Heading";
import Figure from "components/atoms/Figure";
import Img from "components/atoms/Img";
import Card from "components/atoms/Card";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useParams } from "react-router";
import { AgencyDetailQuery } from "interfaces/agencies/AgenciesInterfaces";
import { AGENCY_DETAIL_QUERY } from "constants/queries/AgencyQueries";
import { agencyService } from "services/AgencyService";
import { MarkerInterface } from "interfaces/map/MapInterfaces";
import CardArticle from "components/molecules/CardArticle";
import { useIntl } from "react-intl";
import { handleApolloError } from "../hooks/handleApolloError";
import { scrollTop } from "../utils/utilFunctions";
import { PLACEHOLDERS } from "../assets/Assets";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { AGENCIES_PATH } from "../constants/routes/RoutePaths";
import Breadcrumb from "../components/molecules/Breadcrumb";
import ContactForm from "../components/molecules/ContactForm";
import {imageService} from "../services/ImageService";

interface RouteParams {
  agencyKey: string;
}

const AgencyDetailView: FunctionComponent = () => {
  const intl = useIntl()
  const { agencyKey } = useParams<RouteParams>()
  const {
    data: agencyData,
    error: agencyError
  } = useQuery<AgencyDetailQuery>(AGENCY_DETAIL_QUERY, { variables: { urlKey: agencyKey } })
  handleApolloError("agency_query", agencyError);

  const [autoCenter, setAutoCenter] = useState<boolean>(true);
  const agency = _.first(agencyData?.agencies);
  const markers = agencyService.mapAgencyToMarkers(agency).filter((marker: MarkerInterface) => !!marker)

  useEffect(() => {
    scrollTop();
  }, [])

  return (
    <Layout>
      {agency && (
        <>
          <Section>
            <Breadcrumb
              firstText={intl.formatMessage({ id: "our_agencies" })}
              secondText={agency.name}
              backLink={AGENCIES_PATH}
            />
          </Section>
          <Section>
            <Heading mainTitle={agency.name} />
            <Figure className="mb-5" cap ratio="3-2">
              <Img src={agency.photo?.url || PLACEHOLDERS._600x400} alt="" />
            </Figure>
          </Section>
          <Section>
            <Card rounded>
              <Row grid>
                <Col xs="12" md="4" style={{ backgroundColor: "#383838" }}>
                  <Section className="ComplexCardAddress">
                    <h3 className="mb-5">{agency.name}</h3>
                    <p className="mb-0">{agency.address}</p>
                    <p className="mb-0">{agency.zip_code}</p>
                    <p className="mb-5">{agency.city}</p>
                    <p>{agency.phone}</p>
                  </Section>
                </Col>
                <Col xs="12" md="8">
                  <Map>
                    <MapBasic
                      zoom={MAP_DETAIL_ZOOM}
                      latitude={agency.latitude}
                      longitude={agency.longitude}
                      markers={markers}
                      getIcon={getSimpleMarker}
                      setAutoCenter={setAutoCenter}
                      autoCenter={autoCenter}
                      hasCluster={false}
                    />
                  </Map>
                </Col>
              </Row>
            </Card>
          </Section>
          { (agency.open_hours && agency.open_hours.length || agency.director) && (
            <Section>
              <Row>
                { agency.open_hours && agency.open_hours.length > 0 && (
                  <Col key={agency.open_hours} xs="12" sm="4">
                      <ReactMarkdownWithHtml
                          allowDangerousHtml
                          plugins={[gfm]}
                          className="description"
                      >
                        {agency.open_hours}
                      </ReactMarkdownWithHtml>
                  </Col>
                )}
                { agency.director && (
                  <Col key={agency.director.title} xs="12" sm="8">
                    <Card noBorder className="Member">
                      <Row>
                        <Col xs="12" sm="5" md="4" xl="3">
                          <Figure className="justify-content-center justify-content-lg-end">
                            <Img
                                className="Rounded--lg"
                                src={imageService.getImageUrlByFormat(agency.director.photo) || PLACEHOLDERS._450x600}
                            />
                          </Figure>
                        </Col>
                        <Col xs="12" sm="7" md="8" xl="9">
                          <CardHeader>
                            <CardTitle className="h4 text-primary">
                              {agency.director.title}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <CardText className="h5">
                              {agency.director.subtitle}
                            </CardText>
                            <CardText className="mt-5">
                              {agency.director.description}
                            </CardText>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
            </Section>
          )}
          {agency.email && (
            <Section>
              <Heading mainTitle="Formulaire de contact" />
              <ContactForm className="my-5" email={agency.email} />
            </Section>
          )}
          {
            agency.articles && agency.articles.length > 0 && (
              <Section>
                <Heading mainTitle={intl.formatMessage({ id: "featured_articles" })} />
                <Row>
                  {agency.articles.map((article, index) => (
                    <Col key={index} xs="12" md="4">
                      <CardArticle article={article} />
                    </Col>
                  ))}
                </Row>
              </Section>
            )
          }
        </>
      )}
    </Layout>
  )
}

export default AgencyDetailView
