import React, { FunctionComponent, ReactElement, useRef } from "react";
import { EditorialPageQueryInterface } from "../../interfaces/footer/FooterInterfaces";
import { useQuery } from "@apollo/client";
import { EDITORIAL_PAGE_QUERY } from "../../constants/queries/FooterQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import gfm from "remark-gfm";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import EditorialMedia from "./EditorialMedia";
import EditorialStepper from "./EditorialStepper";
import { editorialService } from "../../services/EditorialService";
import { footerService } from "../../services/FooterService";
import { Media } from "../../interfaces/common/CommonInterfaces";
import MaintenanceStepperContainer from "./MaintenanceStepperContainer";
import Button from "../atoms/Button";
import ReactToPrint from "react-to-print";
import { EDITORIAL_MOVING_STYLE } from "../../constants/print/PrintConstants";
import { useIntl } from "react-intl";

interface EditorialContentProps {
  pageUrlKey: string;
}

const EditorialContent: FunctionComponent<EditorialContentProps> = ({ pageUrlKey }): ReactElement | null => {
  const intl = useIntl();
  const printRef = useRef();
  const {
    data: editorialPageData,
    previousData: editorialPreviousData,
    error: editorialPageQueryError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: pageUrlKey } })
  handleApolloError("editorial_page_query", editorialPageQueryError);
  const editorialPage = editorialPageData?.editorialPages[0] || editorialPreviousData?.editorialPages[0];

  if (!editorialPage) {
    return null;
  }

  const hasPrint = editorialPage.urlKey === "je-demenage-je-n-oublie-rien";

  return (
    <div ref={printRef} id="EditorialContent" className="flex-grow-1 px-0 px-md-5">
      <h1 className="h1 mb-5 d-flex justify-content-between flex-column flex-sm-row">
        {editorialPage.title}
        {hasPrint && (
          <ReactToPrint
            pageStyle={EDITORIAL_MOVING_STYLE}
            content={() => printRef.current}
            trigger={() => <Button className="h-100" title={intl.formatMessage({ id: "print" })} color="primary" />}
          />
        )}
      </h1>
      <ReactMarkdownWithHtml
        allowDangerousHtml
        plugins={[gfm]}
        className="description"
      >
        {editorialService.handleCustomComponent(editorialPage.content)}
      </ReactMarkdownWithHtml>
      {editorialPage.stepper.length > 0 &&
      <EditorialStepper steps={editorialPage.stepper.slice().sort(footerService.sortSteps)} />}
      {editorialPage.maintenanceStepper?.length > 0 &&
      <MaintenanceStepperContainer steppers={editorialPage.maintenanceStepper} />}
      {editorialPage.file?.map((file: Media) => (
        <EditorialMedia key={file.url} media={file} className="mt-md-0" />
      ))}
    </div>
  );
}

export default EditorialContent;
