import React, { FunctionComponent } from "react"
import { ErrorMessages, WsError } from "../../interfaces/error/ErrorsInterfaces";
import { FormattedMessage } from "react-intl";
import { formatStrapiError } from "../../utils/utilFunctions";

const getTitle = (wsError: WsError): string => {
  switch (wsError.statusCode) {
    case 400:
    case 401:
    case 403:
    case 404:
    case 500:
    case 504:
      return `error_${wsError.statusCode}`
    default:
      return wsError.error;
  }
}

interface ToastWsErrorProps {
  wsError: WsError,
}

const ToastWsError: FunctionComponent<ToastWsErrorProps> = ({
  wsError,
}) => {
  const title = getTitle(wsError)
  const descriptions = wsError.data && wsError.data.map((data: ErrorMessages) => formatStrapiError(data.messages[0].id));
  const messages = wsError.data && wsError.data.map((data: ErrorMessages) => data.messages[0].message);

  return (
    <div>
      {title && (
        <div>
          <FormattedMessage id={title} defaultMessage={wsError.error} />
        </div>
      )}
      {descriptions?.map((description: string, index: number) => (
        <div key={`error_${description}_${index}`}>
          <FormattedMessage
            id={description}
            values={{ value: wsError.message.toString() }}
            defaultMessage={messages[index] || ""}
          />
        </div>
      ))}
    </div>
  )
}

export default ToastWsError
