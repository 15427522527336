import React, { FunctionComponent } from "react"

// Styles : styles/layout/menu

type MenuProps = {
  children: JSX.Element
  className?: string
}

const Menu: FunctionComponent<MenuProps> = ({
  children,
  className
}) => {
  return (
    <div className={`Menu ${className}`}>
      <div className="MenuContainer">
        {children}
      </div>
    </div>
  )
}

export default Menu
