import React, { FunctionComponent, ReactElement } from "react";
import { Button, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import Card from "../atoms/Card";
import Row from "../atoms/Row";
import { Survey } from "../../interfaces/surveys/SurveysInterfaces";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDateToStandardShort } from "../../utils/dateUtils";

interface SurveyCardProps {
  survey: Survey;
}

const SurveyCard: FunctionComponent<SurveyCardProps> = ({ survey }): ReactElement => {
  const intl = useIntl();

  return (
    <Card rounded>
      <Row>
        <Col xs="12">
          <CardHeader>
            <CardTitle className="h3" tag="h3">
              <FormattedMessage id="survey" />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <CardText className="lead">
              <FormattedMessage id="survey_from" />
              {formatDateToStandardShort(survey.startDate)}
              <FormattedMessage id="survey_to" />
              {formatDateToStandardShort(survey.endDate)}
            </CardText>
          </CardBody>
          <CardFooter className="justify-content-end">
            <Button
              tag="a"
              href={survey.url}
              target="_blank"
              title={intl.formatMessage({ id: "survey_answer" })}
              color="primary"
            >
              <FormattedMessage id="survey_answer" />
            </Button>
          </CardFooter>
        </Col>
      </Row>
    </Card>
  );
}

export default SurveyCard;
