import React, { FunctionComponent } from "react"

// Styles : styles/layout/header

type HeaderProps = {
  children: JSX.Element
  className?: string
  stickyTop?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  children,
  className,
  stickyTop,
}) => {
  const HeaderClassName =
    (className ? className : "") +
    (stickyTop && " sticky-top")
  return (
    <header className={`Header ${HeaderClassName}`}>
      {children}
    </header>
  )
}

export default Header
