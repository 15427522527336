import React, { FunctionComponent } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Col, CustomInput, FormGroup, Label } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { StepProps } from "interfaces/formstepper/FormStepperInterfaces";
import { useIntl } from "react-intl";
import { ANSWER } from "constants/common/CommonConstants";

const validationSchema = Yup.object({
  status: Yup.string().required(" ")
});

export const FormStatus: FunctionComponent<StepProps> = ({ formData, setFormData, nextStep, prevStep }) => {
  const intl = useIntl();
  const handlePrevClick = () => prevStep();
  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setFormData(values)
        if (values.status === ANSWER.INCOUPLE) {
          nextStep();
        } else {
          nextStep(3);
        }
      }}
    >
      {({
        values,
        setFieldValue,
        touched,
        errors,
      }) => (
        (<Form >
          <FormGroup className="text-center">
            <Label>{intl.formatMessage({ id: "form_question_status" })}</Label>
            <Row grid gutters="sm" justifyContent="center">
              <Col xs="6" md="4">
                <CustomInput
                  invalid={touched.status && !!errors.status}
                  type="radio"
                  name="status"
                  id={ANSWER.SINGLE}
                  label={intl.formatMessage({ id: "form_answer_status_single" })}
                  value={ANSWER.SINGLE}
                  checked={values.status === ANSWER.SINGLE}
                  onChange={() => setFieldValue("status", ANSWER.SINGLE)}
                />
              </Col>
              <Col xs="6" md="4">
                <CustomInput
                  invalid={touched.status && !!errors.status}
                  type="radio"
                  name="status"
                  id={ANSWER.INCOUPLE}
                  label={intl.formatMessage({ id: "form_answer_status_In_Couple" })}
                  value={ANSWER.INCOUPLE}
                  checked={values.status === ANSWER.INCOUPLE}
                  onChange={() => setFieldValue("status", ANSWER.INCOUPLE)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
            <Button
              onClick={handlePrevClick}
              type="button"
              color="link"
              className="align-self-center"
            >
              <Icon name="ChevronLeft" />
              <span className="Text">{intl.formatMessage({ id: "form_stepper_button_prev" })}</span>
            </Button>
            <Button
              type="submit"
              color="primary"
            >
              {intl.formatMessage({ id: "form_stepper_button_next" })}
            </Button>
          </FormGroup>
        </Form>)
      )}
    </Formik>
  );
};