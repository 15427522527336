import React, { FunctionComponent } from "react"
import { ToastContainer } from "react-toastify"

const Toast: FunctionComponent = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default Toast
