import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import React, { FunctionComponent, ReactElement } from "react";
import BasicModal from "./BasicModal";
import * as Yup from "yup"
import { FormattedMessage, useIntl } from "react-intl";
import { FormGroup } from "reactstrap";
import Label from "../atoms/Label";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { PropertyDetails } from "../../interfaces/properties/PropertiesInterfaces";
import { toastService } from "../../services/ToastService";
import { propertyService } from "../../services/PropertyService";

interface FormValues extends FormikValues {
  from: string;
  to: string;
  subject: string;
  message: string;
}

interface ShareModalProps {
  open: boolean;
  onClose: () => void;
  property?: PropertyDetails;
}

const ShareModal: FunctionComponent<ShareModalProps> = ({
  open,
  onClose,
  property
}): ReactElement => {
  const intl = useIntl()

  const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const path = window.location.href;
    void propertyService.share(values.from, values.to, values.subject, { path, message: values.message, property })
      .then(() => {
        toastService.success(intl.formatMessage({ id: "share_success" }))
        onClose();
      })
      .finally(() => setSubmitting(false))
  }

  const validationSchema = Yup.object({
    from: Yup
      .string()
      .email(intl.formatMessage({ id: "invalid_email" }))
      .required(intl.formatMessage({ id: "mandatory_field" })),
    to: Yup
      .string()
      .email(intl.formatMessage({ id: "invalid_email" }))
      .required(intl.formatMessage({ id: "mandatory_field" })),
    subject: Yup
      .string()
      .required(intl.formatMessage({ id: "mandatory_field" })),
    message: Yup
      .string()
      .required(intl.formatMessage({ id: "mandatory_field" })),
  })

  const displayIfNotNull = (value: string | number | null): string => {
    if (!value) {
      return "";
    }
    return ` ${value.toString()}`
  }

  const defaultSubject = property ?
    property.complex.label +
    displayIfNotNull(property.number_of_room) +
    displayIfNotNull(property.surface) +
    displayIfNotNull(property.complex.city)
    : ""

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      backdrop="static"
      header="share_property"
      body={
        <>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              from: "",
              to: "",
              subject: defaultSubject,
              message: intl.formatMessage({ id: "share_message_default" }),
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit} className="text-left">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="share_from" />
                  </Label>
                  <Input
                    value={values.from}
                    name="from"
                    onChange={handleChange}
                    invalid={touched.from && !!errors.from}
                    errorMessage={errors.from}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="share_to" />
                  </Label>
                  <Input
                    value={values.to}
                    name="to"
                    onChange={handleChange}
                    invalid={touched.to && !!errors.to}
                    errorMessage={errors.to}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="share_subject" />
                  </Label>
                  <Input
                    value={values.subject}
                    name="subject"
                    onChange={handleChange}
                    invalid={touched.subject && !!errors.subject}
                    errorMessage={errors.subject}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <FormattedMessage id="share_message" />
                  </Label>
                  <Input
                    type="textarea"
                    value={values.message}
                    name="message"
                    onChange={handleChange}
                    invalid={touched.message && !!errors.message}
                    errorMessage={errors.message}
                  />
                </FormGroup>
                <div className="d-flex justify-content-center mt-5">
                  <Button type="submit" title="send" color="primary" className="m-1" disabled={isSubmitting} />
                  <Button onClick={onClose} title="cancel" color="tertiary" className="m-1" />
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}

export default ShareModal;