import React, { FunctionComponent, ReactElement } from "react";

// Styles : styles/molecules/backButton

import Section from "components/atoms/Section";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { history } from "../../constants/History";

interface BackButtonProps {
  text: string;
  path?: string;
}

const BackButton: FunctionComponent<BackButtonProps> = ({text, path = ""}): ReactElement => {
  return (
    <Section onClick={() => path ? history.push(path) : history.goBack()} className="BackButton">
      <Button tag="a" color="link">
        <Icon name="ChevronLeft"/>
        <span className="Text">{text}</span>
      </Button>
    </Section>
  )
}

export default BackButton;