import React, { FunctionComponent } from "react"

// Styles : styles/components/table

// Reactstrap
import { Table as LibTable, TableProps } from "reactstrap"

const Table: FunctionComponent<TableProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibTable className={`Table ${className}`} {...rest}>
      {children}
    </LibTable>
  )
}

export default Table
