import Layout from "components/layout/Layout";
import React, { FunctionComponent } from "react";
import { Redirect, useLocation } from "react-router";
import { parse, ParsedQuery } from "query-string";
import { HOME_PATH, LOGIN_REGISTER_PATH } from "constants/routes/RoutePaths";
import { useIntl } from "react-intl"
import { Formik, Form, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup"
import { authService } from "services/AuthService";
import { FormGroup } from "reactstrap";
import { history } from "../constants/History"
import Section from "components/atoms/Section";
import Heading from "components/molecules/Heading";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { regExValidPwd } from "../constants/regex/RegexConstants";

interface FormValues extends FormikValues {
  newPassword: string,
  confirmPassword: string
}

const ResetPassword: FunctionComponent = () => {
  const location = useLocation();
  const parsedQuery: ParsedQuery<string> = parse(location.search)

  const intl = useIntl()

  const loginSchema = Yup.object({
    newPassword: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })).matches(
      regExValidPwd, " "),
    confirmPassword: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })).oneOf([Yup.ref("newPassword")], intl.formatMessage({ id: "error_matching" })).matches(
      regExValidPwd,
      intl.formatMessage({ id: "invalid_password" }))
  })

  const onSubmitLogin = (
    { newPassword, confirmPassword }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    authService
      .resetPassword(newPassword, confirmPassword, parsedQuery.code.toString())
      .then(() => {
        history.push(LOGIN_REGISTER_PATH+"?password-reset=true")
      })
      .finally(() => setSubmitting(false))
  }

  return (parsedQuery && parsedQuery.code) ? (
    <Layout>
      <Section>
        <Heading upTitle={intl.formatMessage({ id: "reset_password" })} />
        <div className="container" >
          <Formik
            validationSchema={loginSchema}
            onSubmit={onSubmitLogin}
            initialValues={{ newPassword: "", confirmPassword: "" }}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              values,
              errors
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    type="password"
                    name="newPassword"
                    placeholder={intl.formatMessage({ id: "new_password" })}
                    value={values.newPassword}
                    onChange={handleChange}
                    invalid={touched.newPassword && !!errors.newPassword}
                    errorMessage={errors.newPassword}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    name="confirmPassword"
                    placeholder={intl.formatMessage({ id: "confirm_password" })}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    invalid={touched.confirmPassword && !!errors.confirmPassword}
                    errorMessage={errors.confirmPassword}
                  />
                </FormGroup>
                <div className="d-flex flex-column align-items-center mb-5">
                  <Button size="xs" color="info" type="submit">{intl.formatMessage({ id: "confirmation" })}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Section>
    </Layout>
  ) : (
      <Redirect to={HOME_PATH} />
    )
}

export default ResetPassword