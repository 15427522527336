import { gql } from "@apollo/client";

export const FAQ_TAG_QUERY = gql `
query FaqTag
{
  faqTags {
    id
    label
  }
}`

export const FAQ_QUERY = gql `
query FaqItem(
  $query: String
  $tagId: String
){
  faqItems(where: {
    faqTag: {
      id: $tagId
    }
    _or: [
      { question_contains: $query }
      { answer_contains: $query }
    ]
  }){
    id
    question
    answer
    faqTag {
      id
      label
    }
  }
}`