import React, { FunctionComponent } from "react";
import { Formik, Form, FormikValues } from "formik";
import * as Yup from "yup";
import { Col, FormGroup, Label } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { StepProps } from "interfaces/formstepper/FormStepperInterfaces";
import Input from "components/atoms/Input";
import { useIntl } from "react-intl";
import { ANSWER } from "constants/common/CommonConstants";

export const FormNbDependents: FunctionComponent<StepProps> = ({ formData, setFormData, nextStep, prevStep }) => {
  const intl = useIntl();
  const validationSchema = Yup.object({
    nbOfDependents: Yup
      .number()
      .integer(intl.formatMessage({ id: "error_msg_number_no_decimal" }))
      .min(0, intl.formatMessage({ id: "error_msg_positive_number_or_0" }))
      .required(intl.formatMessage({ id: "mandatory_field" }))
  });
  const handlePrevClick = () => prevStep();

  const onClick = (values: FormikValues, setValues: (values: FormikValues) => void) => {
    setValues({ ...values, nbOfDependents: values.nbOfDependents === 0 ? "" : values.nbOfDependents })
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        values = values.status === ANSWER.SINGLE ? {...values, youngCouple: ANSWER.NO } : values;
        setFormData(values)
        nextStep();
      }}
    >
      {({
        values,
        handleChange,
        touched,
        errors,
        setValues,
      }) => (
        <Form >
          <FormGroup className="text-center">
            <Label>{intl.formatMessage({ id: "form_question_num_of_dependents" })}</Label>
            <Row grid gutters="sm" justifyContent="center">
              <Col xs="6" md="4">
                <Input
                  onClick={() => onClick(values, setValues)}
                  type="number"
                  invalid={touched.nbOfDependents && !!errors.nbOfDependents}
                  errorMessage={errors.nbOfDependents}
                  name="nbOfDependents"
                  placeholder={intl.formatMessage({ id: "form_question_num_of_dependents_placeholder" })}
                  value={values.nbOfDependents}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
            <Button
              className="align-self-center"
              type="button"
              color="link"
              onClick={handlePrevClick}
            >
              <Icon name="ChevronLeft" />
              <span className="Text">{intl.formatMessage({ id: "form_stepper_button_prev" })}</span>
            </Button>
            <Button
              type="submit"
              color="primary"
            >
              {intl.formatMessage({ id: "form_stepper_button_next" })}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};
