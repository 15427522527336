import Button from "components/atoms/Button";
import { LLI_INFO_PAGE_LINK } from "constants/links/ExternalLinks";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { history } from "../../constants/History";
import { PROPERTIES_MAP_PATH } from "../../constants/routes/RoutePaths";

interface TestEligibilityIntermediateProps {
  outOfZone: boolean;
}

export const TestEligibilityIntermediate: FunctionComponent<TestEligibilityIntermediateProps> = ({ outOfZone }) => {
  return (
    <>
      <p>
        <FormattedMessage id="form_result_eligible_message" />
        <strong><FormattedMessage id="form_result_eligible_type_intermediate" /></strong>
        {outOfZone && <FormattedMessage id="form_result_eligible_no_housing" />}
        <br />
        <FormattedMessage id="form_result_eligible_type_intermediate_info" />
        <a rel="noopener noreferrer" target="_blank" href={LLI_INFO_PAGE_LINK}>
          <FormattedMessage id="form_result_eligible_type_intermediate_link_text" />
        </a>
      </p>
      <Button className="mb-4" onClick={() => history.push(PROPERTIES_MAP_PATH)} color="primary">
        <FormattedMessage id="form_result_link_discover_more" />
      </Button>
    </>
  )
}
