import { gql } from "@apollo/client";

export const DISCOVER_QUERIES = gql`query Discover 
  {
    discoverUs {
      id
      quoteTitle
      quoteDescription
      quote
      quoteAuthor
      quotePhoto { url, formats }
      kpi { title, description, icon { url, formats } }
      identityTitle
      identities { title, description, icon { url, formats } }
      presidency { title, subtitle, description, photo { url, formats } }
      supervisory_board { title, subtitle, description, photo { url, formats } }
      board { title, subtitle, description, photo { url, formats } }
      partnerTitle
      partners { url, formats }
      services { title, description, url, icon { url, formats } }
      sections { title, description }
    }
  }
  `
