import { ApolloClient, InMemoryCache, NormalizedCacheObject, HttpLink } from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";

export const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
    const httpLink = new HttpLink({ uri: "/graphql" })
    const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

    return new ApolloClient({
        cache: new InMemoryCache(),
        link: persistedQueriesLink.concat(httpLink),
    });
};
