import React, { FunctionComponent, ReactElement } from "react";
import { DiscoverSection } from "../../interfaces/discover/DiscoverPageInterfaces";
import Section from "../atoms/Section";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Heading from "./Heading";

interface DiscoverSectionProps {
  children?: ReactElement;
  section?: DiscoverSection;
  bgColor?: string;
  titlePrimary?: boolean;
  oblique?: "down"| "up";
  obliquePosition?: "default" | "down"| "up";
  className?: string;
}

const DiscoverSectionSection: FunctionComponent<DiscoverSectionProps> = ({
  children,
  section,
  bgColor,
  titlePrimary,
  oblique,
  obliquePosition,
  className = "",
}): ReactElement => {
  return (
    <Section bgColor={bgColor} oblique={oblique} obliquePosition={obliquePosition} className={className}>
      {section && (
        <>
          <Heading mainTitle={section.title} primary={titlePrimary} uppercase />
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {section.description}
          </ReactMarkdownWithHtml>
        </>
      )}
      {children}
    </Section>
  )
}

export default DiscoverSectionSection;
