import Button from "components/atoms/Button";
import { Category } from "interfaces/articles/ArticlesCategoriesInterfaces";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import { LOGO_PODCAST, LOGO_PRESS, LOGO_SERVICE } from "assets/Assets";
import { FormGroup, InputGroupAddon } from "reactstrap";
import Img from "components/atoms/Img";
import Figure from "components/atoms/Figure";
import Input from "components/atoms/Input";
import Label from "components/atoms/Label";
import InputGroup from "components/atoms/InputGroup";
import Icon from "components/atoms/Icon";
import Form from "components/atoms/Form";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";

interface ArticleCategoriesFilterProps {
  categories: Category[];
  selectedCategories: string[];
  keywordSearch: string;
  setKeywordSearch?: Dispatch<SetStateAction<string>>;
  handleCategoryClick: (id: string) => void;
  handlePodcastClick: () => void;
  handlePressClick: () => void;
  showPodcasts: boolean;
  showPress: boolean;
}

const ArticleCategoriesFilter: FunctionComponent<ArticleCategoriesFilterProps> = ({
  categories,
  selectedCategories = [],
  keywordSearch,
  setKeywordSearch,
  handlePodcastClick,
  handlePressClick,
  handleCategoryClick,
  showPodcasts,
  showPress
}) => {
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      search: keywordSearch,
    },
    onSubmit: values => {
      setKeywordSearch(values.search)
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup className="mb-4">
          <Label for="search">{intl.formatMessage({ id: "search" })}</Label>
          <InputGroup>
            <Input
              type="search"
              name="search"
              id="search"
              placeholder={intl.formatMessage({ id: "search" })}
              bsSize="lg"
              onChange={formik.handleChange}
              value={formik.values.search}
            />
            <InputGroupAddon addonType="append">
              <Button size="lg" color="primary" type="submit" icon rounded>
                <Icon name="Search" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
      <label className="Label">
        <FormattedMessage id="articles_click" />
      </label>
      <div
        className="d-flex justify-content-lg-between justify-content-center align-items-center flex-column flex-lg-row">
        <div className="d-flex justify-content-lg-start justify-content-center flex-wrap">
          {categories.map((item, index) => (
            <Button
              key={index}
              className="ArticleCategoryButton"
              vertical
              active={selectedCategories.includes(String(item.id))}
              onClick={() => handleCategoryClick(String(item.id))}
              size="lg"
              outline
              color="info"
            >
              <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                <Img className="h-auto p-4" src={item.icon?.url || LOGO_SERVICE} />
              </Figure>
              <span className="Text">{item.name}</span>
            </Button>
          ))}
          <Button
            className="ArticleCategoryButton"
            vertical
            active={showPodcasts}
            onClick={handlePodcastClick}
            size="lg"
            outline
            color="info"
          >
            <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
              <Img className="h-auto p-4" src={LOGO_PODCAST} />
            </Figure>
            <span className="Text ">{intl.formatMessage({ id: "podcasts_category" })}</span>
          </Button>
        </div>

        <Button
          className="ArticleCategoryButton"
          vertical
          active={showPress}
          onClick={handlePressClick}
          size="lg"
          outline
          color="info"
        >
          <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
            <Img className="h-auto p-4" src={LOGO_PRESS} />
          </Figure>
          <span className="Text ">{intl.formatMessage({ id: "press_category" })}</span>
        </Button>
      </div>

    </>
  )
}

export default ArticleCategoriesFilter
