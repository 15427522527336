import React, { FunctionComponent, ReactElement } from "react";
import Figure from "../atoms/Figure";
import Img from "../atoms/Img";
import { Media } from "../../interfaces/common/CommonInterfaces";
import { imageService } from "../../services/ImageService";
import Button from "../atoms/Button";

interface EditorialMediaProps {
  media: Media;
  className?: string;
}

const EditorialMedia: FunctionComponent<EditorialMediaProps> = ({
  media,
  className = ""
}): ReactElement | null => {
  if (media.mime.includes("image")) {
    return (
      <Figure className={`my-5 justify-content-center ${className}`}>
        <Img className="Rounded--lg" src={imageService.getImageUrlByFormat(media)} />
      </Figure>
    )
  } else if (media.mime === "application/pdf") {
    return (
      <Button tag="a" href={media.url} title={media.name} target="_blank" color="primary" download />
    )
  }
  return null;
}

export default EditorialMedia;
