import React, { FunctionComponent, ReactElement } from "react";
import { formatNumber } from "../../utils/utilFunctions";

interface FileCardProps {
  file: File;
  onClick?: () => void;
}

const FileCard: FunctionComponent<FileCardProps> = ({ file, onClick }): ReactElement => {
  return (
    <div className={`FileCard my-1 ${onClick ? "Hover" : ""}`} onClick={onClick ? onClick : undefined}>
      <div className="name">{file.name}</div>
      <div className="size">{formatNumber(file.size / 1000)} ko</div>
    </div>
  );
}

export default FileCard;
