import {
  APPLE_DOWNLOAD_BACKGROUND,
  FOUNDATION,
  GOOGLE_PLAY_DOWNLOAD_BACKGROUND,
  NOTICE_YETI,
  RAPPORT_RSE,
  SHOP_SEPA,
  TENANT_SEPA
} from "../assets/Assets";

const getDownloadAnchor = (name: string, url: string): string => {
  return `<a className="Button btn btn-primary my-4" href="${url}" title={name} target="_blank" color="primary">${name}</a>`;
}

const getSmallButtonWithLink = (name: string, url: string): string => {
  return `<a className="Button btn btn-primary my-4" href="${url}" title={name} color="primary">${name}</a>`;
}

const getAppDownloadBlock = (): string => {
  return `<div>
            <a className="Button" href="https://qr.ogga.fr/APP-101" target="_blank">
              <Figure className="p-3">
                <img src="${APPLE_DOWNLOAD_BACKGROUND}" />
              </Figure>
            </a>
            <a className="Button" href="https://qr.ogga.fr/APP-102" target="_blank">
              <Figure className="p-3">
                <img src="${GOOGLE_PLAY_DOWNLOAD_BACKGROUND}" />
              </Figure>
            </a>
          </div>
          `;
}

const handleFileDownload = (content: string): string => {
  return content
    .replace(/<FICHIER_LOCATAIRE_SEPA>/, getDownloadAnchor(TENANT_SEPA.name, TENANT_SEPA.url))
    .replace(/<FICHIER_COMMERCE_SEPA>/, getDownloadAnchor(SHOP_SEPA.name, SHOP_SEPA.url))
    .replace(/<FICHIER_FONDATION>/, getDownloadAnchor(FOUNDATION.name, FOUNDATION.url))
    .replace(/<NOTICE_YETI>/, getDownloadAnchor(NOTICE_YETI.name, NOTICE_YETI.url))
    .replace(/<RAPPORT_RSE>/, getDownloadAnchor(RAPPORT_RSE.name, RAPPORT_RSE.url))
}

const handleAppsButtons = (content: string): string => {
  return content.replace(/<APPS_BUTTONS>/, getAppDownloadBlock())
}

const getModalButton = (name: string): string => {
  return `<button id="MODAL_BUTTON" type="button" class="Button btn btn-primary">${name}</button>`;
}

const handleModalButton = (content: string): string => {
  return content.replace(/<BOUTON_MODAL_CANDIDATURE>/, getModalButton("Candidature spontanée"));
}

const isExternal = (url: string): boolean => {
  return !url.startsWith("/");
}

const getBigButton = (label: string, url: string, more?: string): string => {
  const isExternalLink = isExternal(url)
  return `<div class="my-5 col-12 col-md-6 col-xl-4">
             <a target=${isExternalLink ? "_blank" : "_self"} roundedsize="lg" href="${url}" class="Card no-zoom Rounded--lg card">
                  <div class="card-header">
                      <h3 class="h3 card-title">${label}</h3>
                  </div>
                  <div class="card-footer">
                      <button class="Button More btn btn-link">
                          <span class="Icon ">
                              <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 150 150" xml:space="preserve">
                                  <style>
                                      .bar {transform: scaleX(0); transform-origin: center;}
                                      .circle {transform: scale(1); transform-origin: center;}
                                  </style>
                                  <g>
                                      <circle class="circle" cx="15" cy="75" r="11"></circle>
                                      <circle class="circle" cx="75" cy="75" r="11"></circle>
                                      <circle class="circle" cx="135" cy="75" r="11"></circle>
                                  </g>
                                  <path class="bar" d="M146,75c0-5.7-4.4-10.4-10-10.9V64H15C8.9,64,4,68.9,4,75s4.9,11,11,11h121v-0.1C141.6,85.4,146,80.7,146,75z"></path>
                              </svg>
                          </span>
                           <span class="Text">${more ? more : "En savoir plus"}</span>
                       </button>
                  </div>
              </a>
          </div>`;
}

const handleBigButton = (content: string): string => {
  return content
    .replace(/<BOUTON_TEST_ELIGIBILITE>/, getBigButton("Testez votre éligibilité !", "/logements?eligibility=true"))
    .replace(/<BOUTON_NOS_LOGEMENTS>/, getBigButton("Découvrez toute notre offre de logements !", "/logements"))
    .replace(/<BOUTON_PSLA>/, getBigButton("Le PSLA", "/editorial/l-accession-sociale/le-pret-social-location-accession-ou-psla"))
    .replace(/<BOUTON_BRS>/, getBigButton("Le BRS", "/editorial/l-accession-sociale/le-bail-reel-solidaire-ou-brs"))
    .replace(/<BOUTON_APILOGIS>/, getBigButton("APILOGIS", "https://apilogis.fr/"))
    .replace(/<BOUTON_ACCESSION_SOCIALE>/, getBigButton("Accession sociale", "/editorial/l-accession-sociale/apilogis-accession-sociale"))
    .replace(/<BOUTON_POLITIQUE_ATTRIBUTION>/, getBigButton("Notre politique d'attribution", "/editorial/le-logement-a-loyer-modere/politique-attribution"))
}

const handleSmallButtons = (content: string): string => {
  return content
    .replace(/<BOUTON_AGENCES>/, getSmallButtonWithLink("Nos agences", "/nos-agences"))
}

const handleRow = (content: string): string => {
  return content
    .replace(/<LIGNE>/, "<div class='grid justify-content-center row'>")
    .replace(/<FIN_LIGNE>/, "</div>")
}

const handleCustomComponent = (content: string): string => {
  const fileTagsReplaced = handleFileDownload(content)
  const modalTagsReplaced = handleModalButton(fileTagsReplaced)
  const bigButtonTagsReplaced = handleBigButton(modalTagsReplaced);
  const smallButtonTagsReplaced = handleSmallButtons(bigButtonTagsReplaced)
  const rowReplaced = handleRow(smallButtonTagsReplaced)
  return handleAppsButtons(rowReplaced)
}

export const editorialService = {
  handleFileDownload,
  handleCustomComponent,
  getBigButton
}
