import React, { FormEvent, FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { RouteProps } from "react-router"
import Layout from "../components/layout/Layout";
import Map from "components/atoms/Map";
import MapBasic from "../components/molecules/MapBasic";
import { getPriceMarker, MARKER_SELECT_ZOOM } from "../constants/map/MapConstants"
import Card from "../components/atoms/Card";
import Section from "../components/atoms/Section";
import { Col } from "reactstrap";
import Button from "../components/atoms/Button";
import Form from "../components/atoms/Form";
import { Property, PropertyFilters, PropertyFiltersKey } from "../interfaces/properties/PropertiesInterfaces";
import { propertyService } from "../services/PropertyService";
import { formatNumber } from "../utils/utilFunctions";
import PropertyItem from "../components/molecules/PropertyItem";
import Pagination from "../components/molecules/Pagination";
import Badge from "../components/atoms/Badge";
import { FormattedMessage, useIntl } from "react-intl";
import AdvancedFilters from "../components/molecules/AdvancedFilters";
import { usePropertySearch } from "../hooks/usePropertySearch";
import { useDebouncedInput } from "../hooks/useDebouncedInput";
import Row from "components/atoms/Row";
import { PROPERTY_CATEGORIES_FILTER } from "../constants/properties/PropertiesConstants";
import { useQueryParams } from "../hooks/useQueryParams";
import TownSuggester from "../components/molecules/TownSuggester";

const AdvancedFilteringView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const resultListRef = useRef<HTMLDivElement>();

  // Map and selected property management
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>();
  const [selectedBounds, setSelectedBounds] = useState<DOMRect>();
  const [autoCenter, setAutoCenter] = useState<boolean>(true);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(false);

  //Init filters from search query params
  const {
    query: paramQuery,
    category,
    numberOfRoom,
    project,
    maxRent,
    page: paramPage,
  } = useQueryParams(["query", "category", "numberOfRoom", "project", "maxRent", "page"]);
  const initialFilters: PropertyFilters = {
    category: category,
    numberOfRoom: numberOfRoom ? numberOfRoom.split(",").map((item: string) => parseInt(item)) : [],
    project: project,
    maxRent: parseInt(maxRent),
  }
  const initialQuery = paramQuery || ""

  // Search management
  const searchCallback = useCallback(() => {
    setAutoCenter(true);
    resultListRef.current?.scrollTo({ top: 0, behavior: "smooth" })
    setSelectedPropertyId(undefined)
  }, [])
  const {
    searchProperties,
    onQueryChange,
    properties,
    page,
    setPage,
    filters,
    onFilterChanged,
  } = usePropertySearch(searchCallback, initialFilters, initialQuery, parseInt(paramPage) || 1);
  const { query, onChange } = useDebouncedInput(onQueryChange, initialQuery);

  // Page and map animation and selection management
  useEffect(() => {
    if (selectedBounds && resultListRef.current) {
      const bounds = resultListRef.current.getBoundingClientRect();
      if (selectedBounds.top < bounds.top) {
        resultListRef.current.scrollTo({
          top: resultListRef.current.scrollTop + selectedBounds.top - bounds.top,
          behavior: "smooth"
        })
      } else if (selectedBounds.bottom > bounds.bottom) {
        resultListRef.current.scrollTo({
          top: resultListRef.current.scrollTop + selectedBounds.top + selectedBounds.height - bounds.top - bounds.height,
          behavior: "smooth"
        })
      }
      setSelectedBounds(undefined)
    }
  }, [selectedBounds])

  const onPropertyClick = useCallback((id: string) => {
    setSelectedPropertyId((state: string) => state === id ? undefined : id)
  }, [])

  // On property item in the result list click
  const onPropertyItemClick = useCallback((id: string) => {
    onPropertyClick(id)
  }, [])

  const onMapClick = useCallback(() => {
    setSelectedPropertyId(undefined);
  }, [])

  const toggleAdvancedFilters = useCallback(() => {
    setShowAdvancedFilters((state: boolean) => !state)
  }, [])

  const displayBadge = useCallback((key: PropertyFiltersKey, value = filters[key]): string | ReactElement => {
    if (!value) {
      return;
    }
    switch (key) {
      case "project":
        return <FormattedMessage id={value as string} />
      case "category":
        return <FormattedMessage id={PROPERTY_CATEGORIES_FILTER[value as string]} />
      case "numberOfPerson":
      case "numberOfRoom":
        return intl.formatMessage({ id: key }, { count: value }) +
          (value === 4 ? ` ${intl.formatMessage({ id: "or_more" })}` : "")
      case "maxRent":
        return `${formatNumber(parseInt(value as string))} €`
      default:
        return;
    }
  }, [intl, filters])

  const markers = properties.map((property: Property) => propertyService.mapPropertyToMarker(property, selectedPropertyId));
  const resultCount = `${searchProperties.count > 0 ? `${formatNumber(searchProperties.count)} ` : ""}${intl.formatMessage(
    { id: "result" },
    { count: searchProperties.count }
  )}`
  return (
    <Layout className="propertiesMapView">
      <Section>
        <Row grid>
          <Col xs="12" md="6">
            <div className="search-container position-relative">
              <AdvancedFilters
                show={showAdvancedFilters}
                onBack={toggleAdvancedFilters}
                onQueryChange={onChange}
                searchInput={query}
                resultCount={resultCount}
                filters={filters}
                onFilterChanged={onFilterChanged}
              />
              <Form onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}>
                <TownSuggester
                  currentValue={query}
                  onChange={onChange}
                  placeholderLabel={"where"}
                  inputType={"search"}
                  inputName={"search"}
                  inputId={"search"}
                  inputBsSize={"lg"}
                  inputNoBorder={false}
                />
                <div
                  className="filters d-flex justify-content-between flex-column flex-sm-row flex-md-column flex-lg-row">
                  <div
                    className="align-self-center d-flex justify-content-center justify-content-sm-start justify-content-md-center justify-content-lg-start flex-wrap">
                    {Object.keys(filters).map((key: PropertyFiltersKey) => {
                      if (Array.isArray(filters[key])) {
                        return (
                          <>
                            {(filters[key] as number[]).sort((a: number, b: number) => a - b).map((filter: number) => (
                              <Badge
                                key={key}
                                onClick={() => onFilterChanged(key, filter)}
                                pill
                                color="info"
                                className="mr-2 my-1 cursor-pointer"
                              >
                                {displayBadge(key, filter)}
                              </Badge>
                            ))}
                          </>
                        )
                      }
                      return (
                        <Badge
                          key={key}
                          onClick={() => onFilterChanged(key, "")}
                          pill
                          color="info"
                          className="mr-2 my-1 cursor-pointer"
                        >
                          {displayBadge(key)}
                        </Badge>
                      )
                    })}
                  </div>
                  <Button onClick={toggleAdvancedFilters} color="link" className="d-flex flex-shrink-0 py-0">
                    <FormattedMessage id="advanced_search" />
                  </Button>
                </div>
              </Form>

              <div className="results">
                <div className="header d-flex justify-content-between mr-3 mb-2">
                  <div className="mb-2 mb-lg-0">
                    <h5 className="h5 d-inline mr-3 mb-0">{resultCount}</h5>
                  </div>
                  {/* TODO: Ajouter la page dans l'url, le bouton retour à la liste retourne à la bonne page, idem pour les autres filtres */}
                  <Pagination total={searchProperties.totalPages} current={page} setPage={setPage} showOffset={1} />
                </div>
                <div ref={resultListRef} className="result-list">
                  {properties.map((property: Property, index: number) => (
                    <div key={property.id}>
                      <PropertyItem
                        onClick={onPropertyItemClick}
                        property={property}
                        selected={selectedPropertyId === property.id}
                        setSelectedBounds={setSelectedBounds}
                      />
                      {index !== properties.length - 1 && (
                        <div className="separator" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="map-container">
              <Card rounded>
                <Map mapHeight="lg">
                  <MapBasic
                    setAutoCenter={setAutoCenter}
                    autoCenter={autoCenter}
                    markers={markers}
                    getIcon={getPriceMarker}
                    onMarkerClick={onPropertyClick}
                    onMapClick={onMapClick}
                    maxClusterRadius={(zoom) => zoom < MARKER_SELECT_ZOOM ? 65 : 0.1}
                    flyOnClick
                  />
                </Map>
              </Card>
            </div>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}

export default AdvancedFilteringView
