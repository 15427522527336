import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Row from "../atoms/Row";
import CardDocument from "./CardDocument";
import Card from "../atoms/Card";
import { DocumentInterface } from "../../interfaces/documents/DocumentsInterfaces";
import Loader from "../atoms/Loader";

interface DocumentContainerProps {
  title: string;
  documents: DocumentInterface[];
  contractId: string;
  loading?: boolean;
}

const DocumentContainer: FunctionComponent<DocumentContainerProps> = ({
  title,
  documents,
  contractId,
  loading,
}): ReactElement => {
  return (
    <Card rounded>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id={title} />
        </CardTitle>
      </CardHeader>
      <CardBody className="d-flex justify-content-center">
        {loading && <Loader />}
        {!loading && (documents.length > 0 ? (
          <Row grid>
            {documents.map((document: DocumentInterface) => (
              <Col key={document.name} xs="6" md="3">
                <CardDocument document={document} contractId={contractId} />
              </Col>
            ))}
          </Row>
        ) : (
          <em className="my-5">
            <FormattedMessage id="no_document_available" />
          </em>
        ))}
      </CardBody>
    </Card>
  )
}

export default DocumentContainer;
