import React, { FunctionComponent, ReactElement } from "react";
import { Step } from "../../interfaces/footer/FooterInterfaces";
import EditorialStep from "./EditorialStep";

interface EditorialStepperProps {
  steps: Step[];
}

const EditorialStepper: FunctionComponent<EditorialStepperProps> = ({ steps }): ReactElement | null => {
  return (
    <div className="EditorialStepper mb-5">
      {steps.map((step: Step, index: number) => (
        <EditorialStep key={`step_${step.id}`} number={index + 1} step={step} />
      ))}
    </div>
  );
}

export default EditorialStepper;
