import React, { FunctionComponent, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import Card from "../atoms/Card";
import { CardHeader, CardText, CardTitle } from "reactstrap";

interface MaintenanceChargeCardProps {
  isLrye: boolean;
  onClick: () => void;
}

const MaintenanceChargeCard: FunctionComponent<MaintenanceChargeCardProps> = ({
  isLrye,
  onClick,
}): ReactElement => {
  return (
    <Card className="MaintenanceChargeCard cursor-pointer" noShadow rounded onClick={onClick}>
      <CardHeader>
        <CardText>
          <FormattedMessage id="in_charge_of" />
        </CardText>
        <CardTitle>
          <strong>
            <FormattedMessage id={isLrye ? "of_lrye" : "of_tenant"} />
          </strong>
        </CardTitle>
      </CardHeader>
    </Card>
  )
}

export default MaintenanceChargeCard;
