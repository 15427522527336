import { gql } from "@apollo/client";

export const ARTICLES_GET_QUERY = gql`
query Articles(
  $selectedCategories: [String]
  $keywordSearch: String
){
  articles(
    where:{
      categories: {id: $selectedCategories}
      highlight: false
      _or:[
        {title_contains: $keywordSearch},
        {description_contains: $keywordSearch}
      ]
    }
  ){
      id
      title
      chapeau
      urlKey
      created_at
      readingTime
      photo {
        formats
        url
      }
      description
      highlight
      youtubeId
      updated_at
      order
    }
  }`

export const ARTICLES_HIGHLIGHTS_QUERY = gql`
query Articles {
  articles(where: { highlight: true }) 
  {
    id
    title
    chapeau
    urlKey
    created_at
    readingTime
    photo {
      formats
      url
    }
    highlight
    youtubeId
    updated_at
    order
  }
}`

export const ARTICLE_DETAIL_QUERY = gql`
    query Articles($urlKey: String)
    {
      articles(where: { urlKey: $urlKey })
      {
        id,
        title,
        chapeau,
        description,
        urlKey,
        created_at,
        readingTime,
        youtubeId,
        photo {
          formats
          url
        }
      }
    } 
`
