import { Survey } from "../interfaces/surveys/SurveysInterfaces";
import { fetchWrapper } from "../utils/fetchWrapper";
import { SURVEY_ROUTE } from "../constants/ApiRoutes";

const getByContract = (contractId: string): Promise<Survey[]> => {
  return fetchWrapper.get<Survey[]>(SURVEY_ROUTE + `?contractId=${contractId}`);
}

export const surveyService = {
  getByContract,
}