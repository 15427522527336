import { SEARCH_QUERY_DELAY, SEARCH_QUERY_MIN } from "../constants/properties/PropertiesConstants";
import { useCallback, useState } from "react";

interface UseDebouncedInput {
  query: string;
  onChange: (value: string) => void;
}

export const useDebouncedInput = (callback: (value: string) => void, initialValue = ""): UseDebouncedInput => {
  const [query, setQuery] = useState<string>(initialValue);
  const [timeoutValue, setTimeoutValue] = useState<number>();

  const onChange = useCallback((value: string) => {
    setQuery(value);
    window.clearTimeout(timeoutValue);
    setTimeoutValue(window.setTimeout(() => {
      const trimmedValue = value.trim();
      if (trimmedValue === "" || trimmedValue.length >= SEARCH_QUERY_MIN) {
        callback(trimmedValue)
      }
    }, SEARCH_QUERY_DELAY));
  }, [callback])

  return {
    query,
    onChange
  }
}