export const API_ROUTE = "/api"

export const AUTHENTICATION_ROUTE = API_ROUTE + "/auth/local/"
export const REGISTER_ROUTE = API_ROUTE + "/auth/local/register"
export const FORGOT_PASSWORD_ROUTE = API_ROUTE + "/auth/forgot-password"
export const RESET_PASSWORD_ROUTE = API_ROUTE + "/auth/reset-password"
export const CHANGE_PASSWORD_ROUTE = API_ROUTE + "/auth/change-password"

export const SUBMIT_ELIGIBILITY_ROUTE = API_ROUTE + "/eligibility"
export const SUBMIT_CONTACT_FORM_ROUTE = API_ROUTE + "/contact-form"
export const SUBMIT_PROPERTY_CONTACT_FORM_ROUTE = SUBMIT_CONTACT_FORM_ROUTE + "/property"

export const UPLOAD_ROUTE = API_ROUTE + "/upload"

export const TENANT_ADD_INSURANCE = API_ROUTE + "/tenants/addInsurance"
export const TICKET_ROUTE = API_ROUTE + "/tickets"
export const TICKET_EMAIL_ROUTE = TICKET_ROUTE + "/email"
export const TICKET_ADD_DOCUMENT_ROUTE = TICKET_ROUTE + "/:ticket_id/documents"

export const PROPERTY_ROUTE = API_ROUTE + "/properties"
export const PROPERTY_SHARE_ROUTE = PROPERTY_ROUTE + "/share"

export const COMPLEX_ROUTE = API_ROUTE + "/complexes"
export const COMPLEX_CONTRACT_ROUTE = COMPLEX_ROUTE + "/contract";
export const COMPLEX_WITH_PROPERTIES_ROUTE = COMPLEX_ROUTE + "/:id/properties";

export const USER_ROUTE = API_ROUTE + "/users"

export const OFFER_APPLY_ROUTE = API_ROUTE + "/offers/apply"
export const TENANT_ROUTE = API_ROUTE + "/tenant"
export const TENANT_CONTRACTS_ROUTE = TENANT_ROUTE + "/contracts"
export const OPINION_ROUTE = API_ROUTE + "/opinions"

export const WATER_ROUTE = API_ROUTE + "/water"

export const GENERAL_ALERTS_ROUTE = API_ROUTE + "/alerts"
export const COMPLEX_ALERTS_ROUTE = API_ROUTE + "/alerts/complex"

export const RENT_ROUTE = API_ROUTE + "/rents"
export const PAYMENT_LINK_ROUTE = RENT_ROUTE + "/payment"
export const BALANCE_ROUTE = RENT_ROUTE + "/balance";

export const CARETAKER_ROUTE = API_ROUTE + "/caretaker";

export const DOCUMENT_ROUTE = API_ROUTE + "/documents";
export const DOCUMENT_DOWNLOAD_ROUTE = DOCUMENT_ROUTE + "/download";

export const SURVEY_ROUTE = API_ROUTE + "/surveys";

export const BLOCS_CONTACT_ROUTE = API_ROUTE + "/blocs-contact";

export const BLOCS_PAIEMENT_ROUTE = API_ROUTE + "/blocs-paiement";