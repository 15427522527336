import React, { FunctionComponent, ReactElement, useCallback, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import Row from "components/atoms/Row";
import { authService } from "../../services/AuthService";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { WsError } from "../../interfaces/error/ErrorsInterfaces";
import { formatStrapiError } from "../../utils/utilFunctions";
import { toastService } from "../../services/ToastService";
import { regExValidPwd } from "../../constants/regex/RegexConstants";

interface FormValues extends FormikValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordProps {
  userId: string;
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = ({ userId }): ReactElement => {
  const intl = useIntl();

  const [editPassword, setEditPassword] = useState<boolean>(false)

  const toggleEditPassword = useCallback(() => {
    setEditPassword((state: boolean) => !state)
  }, [])

  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleEditPassword()
  }, [toggleEditPassword])

  const onSubmit = useCallback((
    { currentPassword, newPassword, confirmPassword }: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) => {
    void authService.changePassword(userId, currentPassword, newPassword, confirmPassword)
      .then(() => {
        toastService.success(intl.formatMessage({ id: "success_personal_information_password_updated" }))
        toggleEditPassword();
      })
      .catch((e: WsError) => {
        if (Array.isArray(e.message)) {
          const currentPasswordInvalidError = "Auth.form.error.password.invalid";
          if (e.message[0].messages[0].id === currentPasswordInvalidError) {
            setErrors({ currentPassword: intl.formatMessage({ id: formatStrapiError(currentPasswordInvalidError) }) })
          }
        }
      })
      .finally(() => setSubmitting(false))
  }, [])

  const registerSchema = Yup.object({
    currentPassword: Yup
      .string()
      .required(intl.formatMessage({ id: "mandatory_field" })),
    newPassword: Yup
      .string()
      .required(intl.formatMessage({ id: "mandatory_field" }))
      .matches(regExValidPwd, intl.formatMessage({ id: "invalid_password" })),
    confirmPassword: Yup
      .string()
      .required(intl.formatMessage({ id: "mandatory_field" }))
      .when("newPassword", (newPassword: string, schema: Yup.StringSchema) => {
        return schema.test({
          test: confirmPassword => confirmPassword === newPassword,
          message: intl.formatMessage({ id: "Auth_form_error_confirm" })
        })
      })
  })

  return (
    <Formik
      validationSchema={registerSchema}
      onSubmit={onSubmit}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="mt-5">
          <Row grid>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                {editPassword ? (
                  <Button type="submit" outline color="dark" block>
                    <FormattedMessage id="personal_information_save_password" />
                  </Button>
                ) : (
                  <Button type="button" outline color="dark" block onClick={onClick}>
                    <FormattedMessage id="personal_information_edit_password" />
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Row>
          {editPassword && (
            <Row grid>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="personal_information_current_password" />
                  </Label>
                  <Input
                    name="currentPassword"
                    type="password"
                    onChange={handleChange}
                    value={values.currentPassword}
                    invalid={touched.currentPassword && !!errors.currentPassword}
                    errorMessage={errors.currentPassword}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="personal_information_new_password" />
                  </Label>
                  <Input
                    name="newPassword"
                    type="password"
                    onChange={handleChange}
                    value={values.newPassword}
                    invalid={touched.newPassword && !!errors.newPassword}
                    errorMessage={errors.newPassword}
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="personal_information_confirm_password" />
                  </Label>
                  <Input
                    name="confirmPassword"
                    type="password"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    invalid={touched.confirmPassword && !!errors.confirmPassword}
                    errorMessage={errors.confirmPassword}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ChangePassword;