import React, {FunctionComponent, useEffect, useState} from "react"
import { RouteProps } from "react-router"
import Layout from "../components/layout/Layout";
import Section from "components/atoms/Section";
import { FormattedMessage, useIntl } from "react-intl";
import { LINKEDIN_PAGE_LINK } from "../constants/links/ExternalLinks";
import Button from "../components/atoms/Button";
import { useQuery } from "@apollo/client";
import { OFFERS_QUERY } from "../constants/queries/OfferQueries";
import { OffersQuery } from "../interfaces/offers/OffersInterfaces";
import ListOffers from "../components/molecules/ListOffers";
import TagFilters from "../components/molecules/TagFilters";
import { TagsQuery } from "../interfaces/tags/TagsInterfaces";
import { TAGS_QUERY } from "../constants/queries/TagQueries";
import Row from "components/atoms/Row";
import { CardBody, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import { handleApolloError } from "../hooks/handleApolloError";
import Card from "../components/atoms/Card";
import BasicModal from "../components/molecules/BasicModal";
import ApplicationForm from "../components/molecules/ApplicationForm"
import { EDITORIAL_PAGE_QUERY } from "../constants/queries/FooterQueries";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { EditorialPageQueryInterface } from "../interfaces/footer/FooterInterfaces";
import Banner from "../components/atoms/Banner";
import { JOIN_US_DEFAULT_PHOTO } from "../assets/Assets";
import {scrollTop} from "../utils/utilFunctions";


const JoinUsView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const [tagIds, setTagIds] = useState<string[]>([]);
  const [applicationModal, setApplicationModal] = useState<boolean>(false);

  useEffect(() => {
    scrollTop();
  }, []);

  const {
    data: offersData,
    previousData: offersPreviousData,
    error: offerError
  } = useQuery<OffersQuery>(OFFERS_QUERY, { variables: { tagIds }, errorPolicy: "all" });
  handleApolloError("offer_query", offerError);

  const {
    data: editorialData,
    error: editorialError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: "nous-rejoindre" } });
  handleApolloError("editorial_page_query", editorialError);

  const { data: tagsData, error: tagError } = useQuery<TagsQuery>(TAGS_QUERY);
  handleApolloError("tag_query", tagError);

  return (
    <Layout>
      <>
        <BasicModal
          open={applicationModal}
          body={(
            <ApplicationForm
              modalTitle={intl.formatMessage({ id: "simple_application" })}
              offerTitle=""
              closeModal={() => setApplicationModal(false)}
            />
          )}
          onClose={() => setApplicationModal(!applicationModal)}
        />

        <Banner src={JOIN_US_DEFAULT_PHOTO} alt="" insideContent />

        <Section>
          <div className="pb-5">
            {editorialData?.editorialPages[0] && (
              <ReactMarkdownWithHtml
                allowDangerousHtml
                plugins={[gfm]}
                className="description"
              >
                {editorialData.editorialPages[0].content}
              </ReactMarkdownWithHtml>
            )}
          </div>
          <TagFilters
            setSelectedTagIds={setTagIds}
            selectedTagIds={tagIds}
            tags={tagsData?.tags || []}
          />
          <Row justifyContent="end" className="mb-5">
            <Col xs="12" md="auto">
              <Button
                color="primary"
                title="offers_button_application"
                onClick={() => setApplicationModal(!applicationModal)}
              />
            </Col>
          </Row>
          <ListOffers offers={offersData?.offers || offersPreviousData?.offers || []} />
        </Section>

        <Section>
          <Card className="JoinUsCard text-center" color="info">
            <CardHeader>
              <CardTitle>
                <FormattedMessage id="join_us_card_title" />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <CardText className="JoinUsFindOffer">
                <FormattedMessage id="join_us_offers_find" />
              </CardText>
              <CardText className="JoinUsExternal">
                <FormattedMessage id="join_us_offers_external" />
                <a
                  className="text-underline"
                  href={LINKEDIN_PAGE_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Linkedin
                </a>
              </CardText>
            </CardBody>
          </Card>
        </Section>
      </>
    </Layout>
  )
}

export default JoinUsView
