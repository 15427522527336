import React, { FunctionComponent, ReactElement } from "react";
import Figure from "../atoms/Figure";
import { CardBody, CardText } from "reactstrap";
import Card from "../atoms/Card";
import { TicketCard } from "../../interfaces/tickets/TicketsInterfaces";
import { FormattedMessage } from "react-intl";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Icon from "../atoms/Icon";

interface CardTicketCategoryProps {
  category: TicketCard;
  selected: boolean;
  onClick: () => void;
}

const CardTicketCategory: FunctionComponent<CardTicketCategoryProps> = ({ category, selected, onClick }): ReactElement => {
  return (
    <Card onClick={onClick} className={`card-ticket-category ${selected ? "selected" : ""}`} rounded>
      <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
        <Icon name={category.icon} />
      </Figure>
      <CardBody>
        <CardText className="lead">
          <FormattedMessage id={category.name} />
        </CardText>
      </CardBody>
    </Card>
  );
}

export default CardTicketCategory;