import React, { FunctionComponent, useEffect } from "react"
import { RouteProps } from "react-router"
import { useQuery } from "@apollo/client"
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../components/atoms/Section";
import {AgenciesQueryInterface, OurAgenciesQueryInterface} from "../interfaces/ourAgencies/OurAgenciesInterfaces";
import {AGENCIES_QUERY, OUR_AGENCIES_PAGE_QUERY} from "../constants/queries/AgencyQueries";
import {Agency} from "../interfaces/agencies/AgenciesInterfaces";
import AgencyCard from "../components/molecules/AgencyCard";
import Row from "../components/atoms/Row";
import { Col } from "reactstrap";
import { scrollTop } from "../utils/utilFunctions";

const OurAgenciesView: FunctionComponent<RouteProps> = () => {

  const { data: ourAgenciesData, error: ourAgenciesQueryError } = useQuery<OurAgenciesQueryInterface>(OUR_AGENCIES_PAGE_QUERY)
  handleApolloError("our_agencies_query", ourAgenciesQueryError);

  const { data: agenciesData, error: agenciesQueryError } = useQuery<AgenciesQueryInterface>(AGENCIES_QUERY)
  handleApolloError("agencies_query", agenciesQueryError);

  const ourAgenciesPage = ourAgenciesData?.ourAgency;
  const agencies = agenciesData?.agencies;

  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Layout>
      <Section>
        {
          ourAgenciesPage && (
            <>
              <h1 className="h1 mb-5">{ourAgenciesPage.title}</h1>
              <ReactMarkdownWithHtml
                allowDangerousHtml
                plugins={[gfm]}
                className="description"
              >
                {ourAgenciesPage.description}
              </ReactMarkdownWithHtml>
              {
                agencies && (
                  <Section>
                    <Row grid justifyContent="center">
                      {agencies.map((agency: Agency) => (
                        <Col key={agency.id} xs="6" md="3">
                          <AgencyCard agency={agency} />
                        </Col>
                      ))}
                    </Row>
                  </Section>
                )
              }
            </>
          )
        }
      </Section>
    </Layout>
  )
}

export default OurAgenciesView
