import React, { FunctionComponent } from "react"

// Styles : styles/atoms/grid

// Reactstrap
import { Container as LibContainer, ContainerProps} from "reactstrap"

const Container: FunctionComponent<ContainerProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibContainer className={`Container ${className}`} {...rest}>
      {children}
    </LibContainer>
  )
}

export default Container
