import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router";
import Layout from "../components/layout/Layout"
import { Col, FormGroup } from "reactstrap";
import { useIntl } from "react-intl";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { authService } from "../services/AuthService";
import Section from "components/atoms/Section";
import FormLogin from "../components/molecules/FormLogin"
import FormCreateAccount from "../components/molecules/FormCreateAccount"
import Row from "components/atoms/Row";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { history } from "../constants/History";
import { DASHBOARD_PATH } from "../constants/routes/RoutePaths";
import { scrollTop } from "../utils/utilFunctions";
import BasicModal from "../components/molecules/BasicModal";
import { toastService } from "../services/ToastService";
import { useQueryParams } from "../hooks/useQueryParams";

interface ForgotPassFormValues {
  emailForgotPassword: string
}

const LLILoginRegisterView: FunctionComponent<RouteProps> = () => {
  
  const { confirmed } = useQueryParams(["confirmed"]);
  const intl = useIntl();
  const [modalPass, setModalPass] = useState(false)
  const toggleModalPass = () => setModalPass(!modalPass)
  const user = authService.getLoggedInUser();
  
  useEffect(() => {
    scrollTop();
  }, [])
  
  useEffect(() => {
    if (confirmed === "true") {
      toastService.success(intl.formatMessage({ id: "create_account_confirmation_success" }))
    }
  }, [confirmed])
  
  useEffect(() => {
    if (user && user.jwt) {
      history.push(DASHBOARD_PATH)
    }
  }, [user])
  
  const onSubmitForgotPassword = (
    { emailForgotPassword }: ForgotPassFormValues,
    { setSubmitting }: FormikHelpers<ForgotPassFormValues>): void => {
    authService.forgottenPassword(emailForgotPassword)
      .then(() => {
        setModalPass(false)
        toastService.success(intl.formatMessage({ id: "forgot_password_modal_success" }))
      })
      .finally(() => setSubmitting(false))
  }
  
  const forgotPasswordSchema = Yup.object({
    emailForgotPassword: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" }))
  })
  
  return (
    <Layout >
      <>
        <Section bgAlt>
          <Row justifyContent="between" >
            <Col xs={12} lg={5}>
              <FormLogin toggleModalPass={toggleModalPass} />
            </Col>
            <Col xs={12} lg={5}>
              <FormCreateAccount />
            </Col>
          </Row>
        </Section>
        <BasicModal open={modalPass} onClose={toggleModalPass} header="forgot_password_modal_title" body={
          <>
            <div className="text-dark-grey mb-4">
              {intl.formatMessage({ id: "forgot_password_modal_text" })}
            </div>
            <Formik
              validationSchema={forgotPasswordSchema}
              onSubmit={onSubmitForgotPassword}
              initialValues={{ emailForgotPassword: "" }}
            >
              {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      name="emailForgotPassword"
                      placeholder={intl.formatMessage({ id: "login_email" })}
                      value={values.emailForgotPassword}
                      onChange={handleChange}
                      invalid={touched.emailForgotPassword && !!errors.emailForgotPassword}
                      errorMessage={errors.emailForgotPassword}
                    />
                  </FormGroup>
                  <Button color="primary" type="submit">
                    {intl.formatMessage({ id: "forgot_password_modal_btn" })}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        } />
      </>
    </Layout>
  )
}

export default LLILoginRegisterView
