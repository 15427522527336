import React, { FunctionComponent, useState } from "react";
import { Step, StepItem } from "../../interfaces/footer/FooterInterfaces";
import Card from "../atoms/Card";
import { CardBody, CardHeader, CardTitle, Collapse } from "reactstrap";
import Icon from "../atoms/Icon";

interface EditorialStepProps {
  number: number;
  step: Step;
}

const EditorialStep: FunctionComponent<EditorialStepProps> = ({ number, step }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [checked, setChecked] = useState<string[]>([]);

  const toggle = () => setIsOpen((state: boolean) => !state)

  const onItemClick = (id: string) => {
    if (checked.includes(id)) {
      setChecked((state: string[]) => state.filter((item: string) => item !== id));
    } else {
      setChecked((state: string[]) => [...state, id]);
    }
  }

  return (
    <Card className="EditorialStep rounded-lg">
      <CardHeader onClick={toggle} className="cursor-pointer">
        <CardTitle className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="Number">{number}</span>
            <span className="Title">{step.title}</span>
          </div>
          <Icon className="mx-0 my-auto" name={isOpen ? "Minus" : "Plus"} />
        </CardTitle>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <hr className="Hr" />
          {step.stepItems.map((stepItem: StepItem) => (
            <div onClick={() => onItemClick(stepItem.id)} className="EditorialStepItem" key={`stepItem_${stepItem.id}`}>
              <div className={`Check ${checked.includes(stepItem.id) ? "success" : ""}`}>
                <Icon name="Success" />
              </div>
              <span className={`Text ${checked.includes(stepItem.id) ? "success" : ""}`}>
                {stepItem.label}
              </span>
            </div>
          ))}
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default EditorialStep;
