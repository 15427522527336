import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router"

import Layout from "components/layout/Layout";
import { ComplexWithProperties } from "../interfaces/complex/ComplexInterfaces";
import { MarkerInterface } from "../interfaces/map/MapInterfaces";
import { complexService } from "../services/ComplexService";
import { scrollTop } from "../utils/utilFunctions";
import MapBasic from "../components/molecules/MapBasic";
import { getSimpleMarker } from "../constants/map/MapConstants";
import Card from "../components/atoms/Card";
import Map from "../components/atoms/Map";
import Section from "../components/atoms/Section";
import { useIntl } from "react-intl";


const OurHousingView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();

  const [complexes, setComplexes] = useState<ComplexWithProperties[]>([]);
  const [markers, setMarkers] = useState<MarkerInterface[]>([]);
  const [selectedComplexId, setSelectedComplexId] = useState<string>(undefined);

  useEffect(() => {
    void complexService.findAll()
      .then((result: ComplexWithProperties[]) => {
        setComplexes(result);
      })
    scrollTop();
  }, []);

  useEffect(() => {
    setMarkers(complexes.map((complex: ComplexWithProperties) => {
      return complexService.mapComplexWithPropertiesToMarkers(complex, selectedComplexId)
    }));
  }, [complexes, selectedComplexId])

  const onMapClick = () => {
    setSelectedComplexId(undefined);
  }

  return (
    <Layout>
      <Section>
        <div>
          <h1 className="h1 mb-5">{intl.formatMessage({ id: "our_housing_stock_title" })}</h1>
          <Card rounded>
            <Map>
              <div className="text-center">
                <MapBasic
                  getIcon={getSimpleMarker}
                  markers={markers}
                  onMapClick={onMapClick}
                  hasPopup
                  size="xl"
                />
              </div>
            </Map>
          </Card>
        </div>
      </Section>
    </Layout>
  )
}

export default OurHousingView
