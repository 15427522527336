import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import Row from "components/atoms/Row";
import { CardBody, Col, FormGroup } from "reactstrap";
import Figure from "components/atoms/Figure";
import Img from "components/atoms/Img";
import Input from "components/atoms/Input";
import { FormattedMessage, useIntl } from "react-intl";
import { authService } from "../../services/AuthService";
import EditableInput from "../../components/molecules/EditableInput";
import { userService } from "../../services/UserService";
import { User } from "../../interfaces/users/UsersInterfaces";
import { toastService } from "../../services/ToastService";
import ChangePassword from "../../components/molecules/ChangePassword";
import * as Yup from "yup";
import { phoneRegExp } from "../../constants/regex/RegexConstants";
import { Tenant } from "../../interfaces/tenants/TenantsInterfaces";
import { tenantService } from "../../services/TenantService";
import Card from "components/atoms/Card";
import Label from "components/atoms/Label";
import { scrollTop } from "../../utils/utilFunctions";
import { useContracts } from "../../hooks/useContracts";
import { Contract } from "../../interfaces/contracts/ContractsInterfaces";
import { formatDateToStandardShort } from "../../utils/dateUtils";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";

const PersonalInformationView: FunctionComponent<RouteProps> = ({}) => {
  const intl = useIntl();
  const { selectedContract, contractsDropdown, contracts } = useContracts();
  const [user, setUser] = useState<User>()
  const [tenant, setTenant] = useState<Tenant>();

  const getLocalUser = () => {
    setUser(authService.getLoggedInUser()?.user);
  }

  useEffect(() => {
    getLocalUser();
    void tenantService.findByCurrentUser()
      .then((result: Tenant) => setTenant(result));
    scrollTop();
  }, [])

  const onSubmit = useCallback((name: string, value: string) => {
    return userService.update({ ...user, [name]: value } as User)
      .then(() => {
        toastService.success(intl.formatMessage({ id: `success_personal_information_${name}_updated` }))
      })
  }, [user])

  const onUploadFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    void userService.uploadProfilePicture(e.target.files[0], user)
      .then(() => {
        getLocalUser();
      })
  }, [user]);

  const currentContract = contracts.find((contract: Contract) => contract.contract_number === selectedContract);

  return (
    <Layout dashboard>
      {user && tenant && (
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2">
              <FormattedMessage id="personal_information_title" />
            </h2>
            {contractsDropdown}
          </div>
          <Card rounded>
            <CardBody>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <h5 className="pb-5">
                    <FormattedMessage id="personal_information_identity_sub_title" />
                  </h5>
                </Col>
                <Col xs="12" md="4" lg="4" className="align-items-center">
                  <Label htmlFor="file-input" className="cursor-pointer text-underline" size="sm" color="link">
                    <FormattedMessage id="edit" />
                  </Label>
                  <Figure ratio="1-1" circle>
                    <Img src={user.image?.url || "/user.svg"} alt="" />
                  </Figure>
                  <Input
                    className="d-none"
                    id="file-input"
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    onChange={onUploadFile}
                  />
                </Col>
                <Col xs="12" md="8" lg="8" className="float-right">
                  <ChangePassword userId={user.id.toString()} />
                </Col>
              </Row>
              <Row grid>
                <Col xs="12" md="6" lg="4">
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="personal_information_lastname" />
                    </Label>
                    <Input value={tenant.lastName || ""} readOnly />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="personal_information_firstname" />
                    </Label>
                    <Input value={tenant.firstName || ""} readOnly />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                  <EditableInput
                    validation={Yup
                      .string()
                      .email(intl.formatMessage({ id: "invalid_email" }))
                      .required(intl.formatMessage({ id: "mandatory_field" }))
                    }
                    name="email"
                    label="personal_information_email"
                    value={user.email}
                    onSubmit={onSubmit}
                  />
                </Col>
                <Col xs="12" md="6" lg="4">
                  <EditableInput
                    validation={Yup
                      .string()
                      .matches(phoneRegExp, intl.formatMessage({ id: "invalid_phone" }))
                    }
                    name="phone"
                    label="personal_information_phone"
                    value={user.phone?.replaceAll(" ", "")?.replaceAll(".", "")}
                    onSubmit={onSubmit}
                  />
                </Col>
                <Col xs="12" md="6" lg="4">
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="personal_information_tenant_number" />
                    </Label>
                    <Input value={user.tenantNumber || ""} readOnly />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="personal_information_entry_date" />
                    </Label>
                    <Input value={currentContract ? (formatDateToStandardShort(currentContract?.entry_date)!=="Invalid DateTime" ? formatDateToStandardShort(currentContract?.entry_date) : "Non renseignée" ) : ""} readOnly />
                  </FormGroup>
                </Col>
              </Row>
                <Row className="pt-5">
                <Col xs="12" md="6" lg="6">
                  <h5 className="pt-5">
                    <FormattedMessage id="personal_information_options_sub_title" />
                  </h5>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="personal_information_er_consent" />
                    </Label>
                    <BootstrapSwitchButton
                      width={70}
                      height={20}
                      checked={user.er_consent}
                      onlabel={intl.formatMessage({ id: "contact_form_is_tenant_yes" })}
                      offlabel={intl.formatMessage({ id: "contact_form_is_tenant_no" })}
                      onChange={(checked: boolean) => {
                        const newObject = { ...user, er_consent : checked } as User
                        void userService.switchErConsent(newObject)
                          .then(() => {
                            toastService.success(intl.formatMessage({ id: "personal_information_er_"+ checked.toString()}))
                          })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Section>
      )}
    </Layout>
  );
}

export default PersonalInformationView;
