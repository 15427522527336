import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Figure from "components/atoms/Figure";
import List from "components/atoms/List";
import Row from "components/atoms/Row";
import React, { FunctionComponent, useEffect } from "react";
import { CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col } from "reactstrap";
import { Property } from "../../interfaces/properties/PropertiesInterfaces";
import { FormattedMessage } from "react-intl";
import { formatPriceTTC ,scrollTop} from "../../utils/utilFunctions";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import { PROPERTY_DETAIL_PATH } from "constants/routes/RoutePaths";
import { history } from "constants/History";
import { PLACEHOLDERS } from "../../assets/Assets";
import { imageService } from "../../services/ImageService";

interface CardPropertyProps {
  property: Property;
}

const CardProperty: FunctionComponent<CardPropertyProps> = ({
  property,
}) => {

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    history.push(PROPERTY_DETAIL_PATH.replace(":id", property.urlKey));
  }
  useEffect(() => {
    scrollTop();
  }, [])

  return (
    <Card tag="a" rounded noFade>
      <Figure ratio={FIGURE_RATIOS.THREE_TWO}>
        <CardImg src={imageService.getImageUrlByFormat(property.photo) || PLACEHOLDERS._600x400} />
      </Figure>
      <CardHeader>
        <CardTitle className="h3" tag="h3">{property.complex.label}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText className="lead">{property.complex.city}</CardText>
        <List inline className="justify-content-between">
          {property.number_of_bedroom !== null && (
            <li>
              <strong>
                <FormattedMessage
                  id="properties_map_bedroom"
                  values={{ count: property.number_of_bedroom }}
                />
              </strong>
            </li>
          )}
          {property.surface !== null && (
            <li><strong>{property.surface} m2</strong></li>
          )}
          {property.number_of_bathroom !== null && (
            <li>
              <strong>
                <FormattedMessage
                  id="properties_map_bathroom"
                  values={{ count: property.number_of_bathroom }}
                />
              </strong>
            </li>
          )}
        </List>
      </CardBody>
      <CardFooter>
        <Row grid>
          <Col xs="8" md="6">
            <Button color="primary" block onClick={onButtonClick}>
              <FormattedMessage id="discover" />
            </Button>
          </Col>
          <Col xs="4" md="6">
            {property.price !== null && (
              <strong className="Price text-right">{formatPriceTTC(property.price, property.fees)} <small>€ TTC</small></strong>
            )}
          </Col>
        </Row>
      </CardFooter>
    </Card>

  )
}

export default CardProperty
