import React, { FunctionComponent } from "react"

// Styles : styles/atoms/inputGroup

// Reactstrap
import { InputGroup as LibInputGroup, InputGroupProps as LibInputGroupProps } from "reactstrap"

type InputGroupProps = LibInputGroupProps & {
  href?: string
}

const InputGroup: FunctionComponent<InputGroupProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibInputGroup className={`InputGroup ${className}`} {...rest}>
      {children}
    </LibInputGroup>
  )
}

export default InputGroup
