import React, { FunctionComponent, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { authService } from "../../services/AuthService"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import * as Yup from "yup"
import { Col, CustomInput, FormGroup, Label, ModalBody, ModalHeader } from "reactstrap";
import Popover from "../atoms/Popover";
import { regExValidPwd } from "../../constants/regex/RegexConstants";
import Modal from "../atoms/Modal";

interface FormCreateAccountProps {
  className?: string;
}

interface FormValues extends FormikValues {
  lastName: string;
  firstName: string;
  contractNumber: string;
  email: string;
  password: string;
  conditions: boolean;
  water: boolean;
}

const FormCreateAccount: FunctionComponent<FormCreateAccountProps> = ({
                                                                        className = ""
                                                                      }) => {
  const intl = useIntl()
  const registerSchema = Yup.object({
    lastName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    firstName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    email: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    confirmEmail: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })).oneOf([Yup.ref("email"), null], intl.formatMessage({ id: "invalid_confirm_email" })),
    password: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })).matches(
      regExValidPwd,
      intl.formatMessage({ id: "invalid_password" })),
    confirmPassword: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })).oneOf([Yup.ref("password"), null], intl.formatMessage({ id: "invalid_confirm_password" })),
    contractNumber: Yup.number().required(intl.formatMessage({ id: "mandatory_field" })),
    conditions: Yup.boolean().required(" ").oneOf([true], " ")
  })
  
  const [popoverOpen, setPopoverOpen] = useState(false);
  
  const toggle = () => setPopoverOpen((state: boolean) => !state);
  
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  
  const toggleModalInfo = () => setModalInfoOpen(!modalInfoOpen);
  
  const onSubmit = (
    { lastName, firstName, email, password, contractNumber, conditions, water }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    authService
      .register({
        lastName,
        firstName,
        contractNumber,
        email,
        password,
        conditions,
        water
      })
      .then(toggleModalInfo)
      .finally(() => setSubmitting(false))
  }
  
  return (
    <div className={className}>
      <h3 className="mb-3 text-uppercase">{intl.formatMessage({ id: "create_account" })}</h3>
      <Formik
        validationSchema={registerSchema}
        onSubmit={onSubmit}
        initialValues={{
          lastName: "",
          firstName: "",
          contractNumber: "",
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          conditions: false,
          water: false,
        }}
      >
        {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                name="lastName"
                placeholder={intl.formatMessage({ id: "create_account_last_name" })}
                value={values.lastName}
                onChange={handleChange}
                invalid={touched.lastName && !!errors.lastName}
                errorMessage={errors.lastName}
              />
            </FormGroup>
            <FormGroup>
              <Input
                name="firstName"
                placeholder={intl.formatMessage({ id: "create_account_first_name" })}
                value={values.firstName}
                onChange={handleChange}
                invalid={touched.firstName && !!errors.firstName}
                errorMessage={errors.firstName}
              />
            </FormGroup>
            <FormGroup row>
              <Label for="contractNumber" xs="auto">L/</Label>
              <Col xs="auto" className="d-flex">
                <div className="flex-grow-1">
                  <Input
                    name="contractNumber"
                    placeholder={intl.formatMessage({ id: "create_account_contract_number" })}
                    label={intl.formatMessage({ id: "create_account_contract_number" })}
                    value={values.contractNumber}
                    onChange={handleChange}
                    invalid={touched.contractNumber && !!errors.contractNumber}
                    errorMessage={errors.contractNumber}
                  />
                </div>
                <Button
                  id="popInfo"
                  type="button"
                  outline
                  size="xs"
                  color="dark"
                  icon
                  pill
                  className="PopoverTrigger"
                >
                  ?
                </Button>
              </Col>
              <Popover isOpen={popoverOpen} toggle={toggle} placement="top">
                <FormattedMessage id="create_account_contract_number_popover" />
              </Popover>
            </FormGroup>
            <FormGroup>
              <Input
                name="email"
                placeholder={intl.formatMessage({ id: "create_account_email" })}
                value={values.email}
                onChange={handleChange}
                invalid={touched.email && !!errors.email}
                errorMessage={errors.email}
              />
            </FormGroup>
            <FormGroup>
              <Input
                name="confirmEmail"
                placeholder={intl.formatMessage({ id: "create_account_confirm_email" })}
                value={values.confirmEmail}
                onChange={handleChange}
                invalid={touched.confirmEmail && !!errors.confirmEmail}
                errorMessage={errors.confirmEmail}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="password"
                placeholder={intl.formatMessage({ id: "create_account_password" })}
                value={values.password}
                onChange={handleChange}
                invalid={touched.password && !!errors.password}
                errorMessage={errors.password}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="confirmPassword"
                placeholder={intl.formatMessage({ id: "create_account_confirm_password" })}
                value={values.confirmPassword}
                onChange={handleChange}
                invalid={touched.confirmPassword && !!errors.confirmPassword}
                errorMessage={errors.confirmPassword}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                id="conditions"
                checked={values.conditions}
                invalid={touched.conditions && !!errors.conditions}
                onChange={handleChange}
                type="checkbox"
                name="conditions"
                label={intl.formatMessage({ id: "create_account_conditions" })}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                id="water"
                checked={values.water}
                invalid={touched.water && !!errors.water}
                onChange={handleChange}
                type="checkbox"
                name="water"
                label={intl.formatMessage({ id: "create_account_water" })}
              />
            </FormGroup>
            <div className="d-flex flex-column align-items-center mt-5">
              <Button
                disabled={isSubmitting}
                type="submit"
                color="info"
                size="lg"
              >
                {intl.formatMessage({ id: "create_account_btn" })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal className="create-account-modale" isOpen={modalInfoOpen} toggle={toggleModalInfo}>
        <ModalHeader toggle={toggleModalInfo}>
          {intl.formatMessage({ id: "create_account_btn_modal" })}
        </ModalHeader>
        <ModalBody >
          {intl.formatMessage({ id: "create_account_confirmation_email_sent" })}
        </ModalBody>
      </Modal>
    </div>
  )
}
export default FormCreateAccount
