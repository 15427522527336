import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CardText, CardTitle } from "reactstrap";
import { ticketService } from "../../../services/TicketService";
import { Ticket } from "../../../interfaces/tickets/TicketsInterfaces";
import { formatDateToStandardShortWithTime } from "../../../utils/dateUtils";
import { FormattedMessage } from "react-intl";
import TicketModal from "../TicketModal";
import { TICKET_STATUS } from "../../../constants/tickets/TicketsConstants";
import { history } from "../../../constants/History";
import { TICKETS_PATH } from "../../../constants/routes/RoutePaths";
import { authService } from "../../../services/AuthService";

interface DbTicketProps {
  children?: ReactElement;
}

const DbTicket: FunctionComponent<DbTicketProps> = ({}): ReactElement => {

  const [tickets, setTickets] = useState<Ticket[]>([])
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [open, setOpen] = useState<boolean>(false);

  const user = authService.getLoggedInUser().user;

  useEffect(() => {
    void ticketService.findAllByTenantNumber(user.tenantNumber)
      .then((result: Ticket[]) => {
        setTickets(result.filter((ticket: Ticket) => ticket.badge.status_id !== TICKET_STATUS.CLOSED.id))
      })
  }, [user.id])

  const toggleOpen = () => {
    setOpen((state: boolean) => !state);
  }

  const onTicketClick = (e: React.MouseEvent<HTMLAnchorElement>, ticketId: number) => {
    e.preventDefault();
    e.stopPropagation();
    void ticketService.findById(ticketId)
      .then((result: Ticket) => {
        setSelectedTicket(result);
        toggleOpen();
      })
  }

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push(TICKETS_PATH);
  }

  const ticket = tickets[0]

  return (
    <div className="cursor-pointer" onClick={onCardClick}>
      <CardTitle>
        <FormattedMessage id="db_current_tickets" values={{ count: tickets.length }} />
      </CardTitle>
      {ticket && (
        <>
          <div className="card-content">
            <CardText>
              {ticket.name}
            </CardText>
            <CardText className="link">
              <a onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onTicketClick(e, ticket.id)} href="" title="">
                {ticket.badge.label}
              </a>
            </CardText>
            <CardText className="deadline">
              <small className="date">
                {formatDateToStandardShortWithTime(ticket.created_at)}
              </small>
            </CardText>
          </div>
          <TicketModal
            selectedTicket={selectedTicket}
            open={open}
            onClose={toggleOpen}
          />
        </>
      )}
    </div>
  );
}

export default DbTicket;