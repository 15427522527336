import React, { FunctionComponent } from "react"

// Styles : styles/atoms/banner
// Components
import Container from "./Container"
import Figure from "./Figure"
import Img from "./Img"

type BannerProps = {
  children?: JSX.Element
  className?: string
  src: string
  alt: string
  insideContent?: boolean
  centered?: boolean
}

const Banner: FunctionComponent<BannerProps> = ({
  children,
  className="",
  src,
  alt,
  insideContent,
  centered,
}) => {
  const BannerClassName =
      (insideContent ? " InsideContent" : "") +
      (centered ? " Centered" : "") +
      (className ? " " + className : "")
  return (
    <section className={`Banner ${BannerClassName}`}>
      <React.Fragment>
        <Figure>
          <Img src={src} alt={alt}/>
        </Figure>
        <Container>
          {children}
        </Container>
      </React.Fragment>
    </section>
  )
}

export default Banner
