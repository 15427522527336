import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback } from "react";
import { TicketCard } from "../../../interfaces/tickets/TicketsInterfaces";
import { TICKET_CATEGORIES } from "../../../constants/tickets/TicketsConstants";
import CardTicketCategory from "../../../components/molecules/CardTicketCategory";
import Container from "../../../components/atoms/Container";

interface StepCategoryProps {
  selectedCategory: string | undefined;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
}

const StepCategory: FunctionComponent<StepCategoryProps> = ({
  selectedCategory,
  setSelectedCategory
}): ReactElement => {

  const onCardClick = useCallback((category: TicketCard) => () => {
    setSelectedCategory(selectedCategory === category.name ? undefined : category.name)
  }, [selectedCategory]);

  return (
    <Container>
      <div className="d-flex flex-wrap justify-content-center justify-content-xl-start">
        {TICKET_CATEGORIES.all.map((category: TicketCard) => (
          <CardTicketCategory
            key={category.name}
            category={category}
            selected={selectedCategory === category.name}
            onClick={onCardClick(category)}
          />
        ))}
      </div>
    </Container>
  );
}

export default StepCategory;