export const HOME_PATH = "/"
export const COMPONENTS_PATH = "/components"
export const LOGIN_REGISTER_PATH = "/connexion"
export const LLI_LOGIN_REGISTER_PATH = "/connexion-lli"
export const PROPERTIES_PATH = "/logements"
export const PROPERTIES_MAP_PATH = PROPERTIES_PATH + "/recherche"
export const PROPERTY_DETAIL_PATH = "/logements/:id"
export const ARTICLE_BY_ID_PATH = "/articles/:id"
export const NEWS_PATH = "/actualites"
export const JOIN_US_PATH = "/nous-rejoindre"
export const OFFER_DETAIL_PATH = "/offres/:id"
export const RESET_PASSWORD = "/reinitialiser"
export const COMPLEX_DETAIL_PATH = "/residences/:id"

export const COMPONENTS_DASHBOARD_PATH = "/components-dashboard"
export const EDITORIAL_PATH = "/editorial/:groupUrlKey/:pageUrlKey"
export const FAQ_PATH = "/faq"
export const DISCOVER_PATH = "/nous-decouvrir"
export const OUR_HOUSING_STOCK_PATH = "/notre-parc-immobilier"
export const CONTACT_US_PATH = "/nous-contacter"

export const AGENCIES_PATH = "/nos-agences"
export const AGENCY_DETAIL_PATH = AGENCIES_PATH + "/:agencyKey"

export const CONNECTED_HOUSINGS_PATH = "/nos-logements-connectes"
export const CONNECTED_HOUSINGS_SPECIFIC_PATH = CONNECTED_HOUSINGS_PATH + "/:connectedHousingUrlKey"

export const OUR_COMMITMENTS_PATH = "/nos-engagements"
export const OUR_COMMITMENTS_SPECIFIC_PATH = OUR_COMMITMENTS_PATH + "/:commitmentUrlKey"

const TENANT_PATH = "/espace-locataire"
export const DASHBOARD_PATH = TENANT_PATH + "/tableau-de-bord"
export const PERSONAL_INFORMATION_PATH = TENANT_PATH + "/informations-personnelles"
export const RENT_PATH = TENANT_PATH + "/loyer"
export const PAYEMENT_RENT_PATH = RENT_PATH + "/paiement"
export const DOCUMENTS_PATH = TENANT_PATH + "/documents"
export const TICKETS_PATH = TENANT_PATH + "/demandes"
export const HOUSING_PATH = TENANT_PATH + "/logement"
export const SURVEYS_PATH = TENANT_PATH + "/enquetes"
export const COMPLEX_PATH = TENANT_PATH + "/residence"

export const CREATE_TICKET_PATH = TICKETS_PATH + "/creation"

export const PRIVACY_POLICY_PATH = "/politique-de-confidentialite"
export const LEGAL_MENTIONS_PATH = "/mentions-legales"

export const TENANT_MENU_PATHS = [
  {
    label: "dashboard_page",
    path: DASHBOARD_PATH,
    icon: "Dashboard",
  },
  {
    label: "personal_information_page",
    path: PERSONAL_INFORMATION_PATH,
    icon: "User",
  },
  {
    label: "my_rent_page",
    path: RENT_PATH,
    icon: "Bill",
  },
  {
    label: "my_documents_page",
    path: DOCUMENTS_PATH,
    icon: "Documents",
  },
  {
    label: "my_tickets_page",
    path: TICKETS_PATH,
    icon: "Requests",
  },
  {
    label: "my_housing_page",
    path: HOUSING_PATH,
    icon: "MyHome",
  },
  {
    label: "surveys_page",
    path: SURVEYS_PATH,
    icon: "Surveys",
  },
  {
    label: "your_complex_page",
    path: COMPLEX_PATH,
    icon: "Residence",
  },
]
