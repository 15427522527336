import Dropdown from "components/atoms/Dropdown"
import React, { FunctionComponent } from "react"
import { DropdownMenu, DropdownToggle} from "reactstrap"

// Styles : styles/molecules/DropdownSelect

type DropdownSelectProps = {
  bsSize?: "sm" | "lg"
  className?: string
  value?: string
  noBorder?: boolean
  disabled?: boolean
  valid?: boolean
  invalid?: boolean
}

const DropdownSelect: FunctionComponent<DropdownSelectProps> = ({
  bsSize,
  children,
  value,
  noBorder,
  className,
  disabled,
  valid,
  invalid,
  ...rest
}) => {
  const DropdownSelectClassName =
    (className ? " " + className : "") 

  const DropdownSelectToggleClassName =
    (bsSize ? " form-control-" + bsSize : "") +
    (noBorder ? " NoBorder" : "") + 
    (disabled ? " disabled" : "") +
    (valid ? " is-valid" : "") + 
    (invalid ? " is-invalid" : "") 

  return (
    <Dropdown className={`DropdownSelect ${DropdownSelectClassName}`} {...rest}>
      <DropdownToggle className={`DropdownSelectToggle ${DropdownSelectToggleClassName}`} size={bsSize} noBorder={noBorder} caret>
        <span>{value}</span>
      </DropdownToggle>
      <DropdownMenu className="DropdownSelectMenu">
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropdownSelect
