import React, { FunctionComponent, useState, Suspense, useContext } from "react"
import FooterDashboard from "./FooterDashboard"
import HeaderEditorial from "./HeaderEditorial"
import MenuDashboard from "./MenuDashboard"
import { useBreakpoints } from "utils/breakpoints"
import MenuEditorial from "./MenuEditorial"
import PhoneModal from "../molecules/PhoneModal";
import { ContextContract } from "../../contexts/contextContract";

const FooterEditorial = React.lazy(() => import("./FooterEditorial"));

interface LayoutProps {
  children: string | JSX.Element;
  dashboard?: boolean;
  className?: string;
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  dashboard,
  className = ""
}) => {
  const breakpoints = useBreakpoints();

  const [phoneModalOpen, setPhoneModalOpen] = useState<boolean>(false);

  const togglePhoneModalOpen = () => setPhoneModalOpen((state: boolean) => !state);

  const { selectedContractComplex } = useContext(ContextContract);
 

  return (
    <>
      {dashboard ? (
        <>
          <HeaderEditorial togglePhoneModalOpen={togglePhoneModalOpen} hasDashboard={true} complex={selectedContractComplex} />
          <MenuDashboard togglePhoneModalOpen={togglePhoneModalOpen} />
        </>
      ) : (
        breakpoints.isMdOrLess ? (
          <>
            <HeaderEditorial togglePhoneModalOpen={togglePhoneModalOpen} hasDashboard={false}/>
            <MenuEditorial togglePhoneModalOpen={togglePhoneModalOpen} />
          </>
        ) : <HeaderEditorial togglePhoneModalOpen={togglePhoneModalOpen} hasDashboard={false}/>
      )}

      <main className={`Main ${dashboard ? " Dashboard" : ""}`}>
        <div className={`app-content ${className}`}>
          {children}
        </div>
      </main>

      {dashboard ? (
        <FooterDashboard/>
      ) : (
        <Suspense fallback={<div>Chargement...</div>}>
          <FooterEditorial togglePhoneModalOpen={togglePhoneModalOpen} />
        </Suspense>
      )}

      <PhoneModal open={phoneModalOpen} onClose={togglePhoneModalOpen} />
    </>
  )
}

export default Layout
