import React, { FunctionComponent, ReactElement } from "react";

// Styles : styles/molecules/menuUser
import Nav from "components/atoms/Nav";
import { DASHBOARD_PATH, LOGIN_REGISTER_PATH } from "../../constants/routes/RoutePaths";
import { NavItem } from "reactstrap";
import Button from "components/atoms/Button";
import Img from "components/atoms/Img";
import { authService } from "services/AuthService";
import { useIntl } from "react-intl";
import { history } from "../../constants/History";
import Figure from "../atoms/Figure";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";

const MenuUser: FunctionComponent = (): ReactElement => {

  const intl = useIntl();

  const user = authService.getLoggedInUser();

  const onUserClick = () => {
    history.push(user ? DASHBOARD_PATH : LOGIN_REGISTER_PATH)
  };

  return (
    <Nav className="MenuUser">
      <NavItem onClick={onUserClick}>
        <span className="UserText">
          {user ? `${intl.formatMessage({ id: "hello" })} ${user.user.username} !` : intl.formatMessage({ id: "login" })}
        </span>
        <Button color={"link"} pill icon className="UserImage">
          <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
            <Img src={user?.user.image?.url || "/user.svg"} alt="" />
          </Figure>
        </Button>
      </NavItem>
    </Nav>
  )
}

export default MenuUser;
