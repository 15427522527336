import React, { FunctionComponent, ReactElement } from "react";
import WaterGraphComponent from "../WaterGraphComponent";
import { Rent } from "../../../interfaces/rents/RentsInterfaces";
import DbLastRentCard from "./DbLastRentCard";
import DbPaymentMean from "./DbPaymentMean";
import { DocumentInterface } from "../../../interfaces/documents/DocumentsInterfaces";

interface DbComplexNewsProps {
  selectedContract: string;
  lastRent?: Rent;
  receipt?: DocumentInterface;
}

const DbSecondRow: FunctionComponent<DbComplexNewsProps> = ({
  selectedContract,
  lastRent,
  receipt,
}): ReactElement => {
  return (
    <div className="DashboardGrid">
      <div className="One">
        <WaterGraphComponent selectedContract={selectedContract} />
      </div>
      <div className="Two">
        <DbLastRentCard rent={lastRent} receipt={receipt} />
      </div>
      <DbPaymentMean selectedContract={selectedContract} />
    </div>
  );
}

export default DbSecondRow;