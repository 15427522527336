import React, { FunctionComponent, ReactElement } from "react";
import { DiscoverInterface } from "../../interfaces/discover/DiscoverPageInterfaces";
import Section from "../atoms/Section";
import Quote from "../atoms/Quote";
import Figure from "../atoms/Figure";
import Img from "../atoms/Img";
import { imageService } from "../../services/ImageService";
import Row from "../atoms/Row";
import { Col } from "reactstrap";
import Heading from "./Heading";
import { PLACEHOLDERS } from "../../assets/Assets";

interface DiscoverQuoteSectionProps {
  discover: DiscoverInterface;
}

const DiscoverQuoteSection: FunctionComponent<DiscoverQuoteSectionProps> = ({
  discover
}): ReactElement => {
  return (
    <Section oblique="down" bgColor="light">
      <Quote size="xl" marginDisabledDown="lg" />
      <Heading mainTitle={discover.quoteTitle} h1 className="QuoteTitle mb-5 pl-xl-0 ml-xl-0 pl-5 ml-3" />
      <p>
        {discover.quoteDescription}
      </p>
      <Row className="QuoteRow align-items-center" grid gutters="xl">
        <Col xs="12" md="6" lg="5">
          <Figure className="justify-content-center justify-content-lg-end">
            <Img className="Rounded--lg" src={imageService.getImageUrlByFormat(discover.quotePhoto) || PLACEHOLDERS._450x600} />
          </Figure>
        </Col>
        <Col xs="12" md="8" lg="5" className="QuoteContainer pt-5 mt-5">
          <Quote size="md" />
          <p>
            <em>
              {discover.quote}
            </em>
          </p>
          <strong className="text-primary">
            {discover.quoteAuthor}
          </strong>
        </Col>
      </Row>
    </Section>
  )
}

export default DiscoverQuoteSection;
