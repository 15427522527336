import React, { FunctionComponent } from "react"

// Styles : styles/layout/menu
import Menu from "./Menu"
import Nav from "components/atoms/Nav"
import SideMenuLink from "./MenuDashboardLink";
import { useBreakpoints } from "utils/breakpoints";
import { TENANT_MENU_PATHS } from "../../constants/routes/RoutePaths";
import MenuNav from "components/molecules/MenuNav";
import MenuSocial from "components/molecules/MenuSocial";
import { authService } from "../../services/AuthService";

interface MenuDashboardProps {
  togglePhoneModalOpen: () => void;
}

const MenuDashboard: FunctionComponent<MenuDashboardProps> = ({ togglePhoneModalOpen }) => {
  const breakpoints = useBreakpoints()

  const onDisconnect = () => {
    authService.logout();
  }

  return (
    <Menu className="MenuDashboard">
      <>
        {breakpoints.isMdOrLess? (
          <div className="MenuContainerEditorial">
            <MenuNav/>
            <MenuSocial onPhoneClick={togglePhoneModalOpen} />
          </div>
        ) : undefined}

        <div className="MenuContainerDashboard">
          <Nav vertical>
            {TENANT_MENU_PATHS.map((path) => (
              <SideMenuLink key={path.label} path={path.path} message={path.label} icon={path.icon} />
            ))}
            <SideMenuLink onClick={onDisconnect} message="disconnect" icon="User2" />
          </Nav>
        </div>
      </>
    </Menu>
  )
}

export default MenuDashboard
