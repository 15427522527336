import { gql } from "@apollo/client";

export const COMPLEX_PHOTO_QUERY = gql`
query Complexes($id: String) {
  complexes(where: { id: $id }) {
    photo {
      formats
      url
    }
  }
}`

export const COMPLEX_DETAIL_QUERY = gql`
    query complex($urlKey: String)
    {
      complexes(where:{ urlKey: $urlKey })
      {
        id
        company {
          short_code
          name
        }
        label
        type
        address
        zip_code
        city
        longitude
        latitude
        description
        photo {
          formats
          url
        }
      }
    } 
`
