import { fetchWrapper } from "../utils/fetchWrapper";
import { OPINION_ROUTE } from "../constants/ApiRoutes";

const sendOpinion = (lastName: string, firstName: string, email: string, subject: string, message: string): Promise<void> => {
  return fetchWrapper.post(OPINION_ROUTE,
    {
      lastName,
      firstName,
      email,
      subject,
      message,
    }
  )
}

export const opinionService = {
  sendOpinion,
}