import { TicketStepperTitle } from "../../interfaces/tickets/TicketsInterfaces";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "./Icon";

interface TicketStepperItemProps {
  title: TicketStepperTitle;
  validated: boolean;
  selected: boolean;
  onClick: () => void;
  step: number;
}

const TicketStepperItem: FunctionComponent<TicketStepperItemProps> = ({
  title,
  validated,
  selected,
  onClick,
  step
}) => {
  return (
    <div
      onClick={onClick}
      className={`ticket-stepper-item ${selected ? "selected" : ""} ${validated ? "validated" : ""}`}
    >
      <h3 className="h6 title mr-5">
        <FormattedMessage id={title.title} />
      </h3>
      {title.subtitle && (
        <p className="subtitle mr-5">
          {title.subtitle}
        </p>
      )}
      <div className="stepper-icon">{validated ? <Icon name="Success" /> : step}</div>
    </div>
  )
}

export default TicketStepperItem;