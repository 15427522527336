import React, { FunctionComponent, ReactElement } from "react";
import { ApolloError } from "@apollo/client";
import { FormattedMessage } from "react-intl";

interface ToastApolloProps {
  name: string;
  error: ApolloError;
}

const ToastApollo: FunctionComponent<ToastApolloProps> = ({
  name,
  error,
}): ReactElement => {
  return (
    <div>
      <div>
        <FormattedMessage id={name} />
      </div>
      <div>
        {error.message}
      </div>
    </div>
  )
}

export default ToastApollo;