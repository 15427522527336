import React, { FunctionComponent, ReactElement, useState } from "react";
import { Collapse } from "reactstrap";
import { Faq } from "../../interfaces/faq/FaqInterfaces";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";

interface FaqItemProps {
  faq: Faq;
}

const FaqItem: FunctionComponent<FaqItemProps> = ({ faq }): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = () => setIsOpen((state: boolean) => !state);

  return (
    <div className="p-5 border-bottom cursor-pointer" onClick={toggleIsOpen}>
      <div className="d-flex justify-content-between py-3">
        <h4 className="h4">{faq.question}</h4>
        <span className="h4">{isOpen ? "-" : "+"}</span>
      </div>
      <Collapse isOpen={isOpen}>
        <ReactMarkdownWithHtml
          allowDangerousHtml
          plugins={[gfm]}
          className="description"
        >
          {faq.answer}
        </ReactMarkdownWithHtml>
      </Collapse>
    </div>
  );
}

export default FaqItem;
