import React, {FunctionComponent, ReactElement, useEffect, useState} from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import {FormattedMessage, useIntl} from "react-intl";
import { useContracts } from "../../hooks/useContracts";
import { scrollTop } from "../../utils/utilFunctions";
import {CardHeader, CardTitle} from "reactstrap";
import Card from "../../components/atoms/Card";
import {Rent} from "../../interfaces/rents/RentsInterfaces";
import {rentService} from "../../services/RentService";
import {tenantService} from "../../services/TenantService";
import {Tenant} from "../../interfaces/tenants/TenantsInterfaces";
import Button from "../../components/atoms/Button";
import {usePaymentLink} from "../../hooks/usePaymentLink";
import Input from "../../components/atoms/Input";
import {priceRegExp} from "../../constants/regex/RegexConstants";
import {User} from "../../interfaces/users/UsersInterfaces";
import {authService} from "../../services/AuthService";
import {callEwayPayment} from "../../utils/callEwayPayment";

const MyPaymentRentView: FunctionComponent<RouteProps> = (): ReactElement => {
  const { selectedContract } = useContracts();
  const [error, setError] = useState<string>()
  const [tenant, setTenant] = useState<Tenant>();
  const [paymentAmount, setPaymentAmount] = useState<string>("");
  const { paymentLink } = usePaymentLink(selectedContract, paymentAmount);
  const intl = useIntl();
  const [user, setUser] = useState<User>()

  useEffect(() => {
    setUser(authService.getLoggedInUser()?.user);
    void tenantService.findByCurrentUser()
        .then((result: Tenant) => setTenant(result));
    scrollTop();
  }, [])


  useEffect(() => {
    if (selectedContract) {
      void rentService.getRents(selectedContract)
          .then((response: Rent[]) => {
            const dept = response[0]?.debit - response[0]?.credit || 0;
            
            if (dept > 0) {
              setPaymentAmount(`${dept.toFixed(2)}`)
            } else {
              setPaymentAmount("0")
            }
          });
    }
  }, [selectedContract])

  return (
    <Layout dashboard>
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2">
              <FormattedMessage id="my_payment_rent_page" />
            </h2>
          </div>
        </Section>
        <Section>
          <Card rounded>
            <CardHeader>
              { tenant &&
                  <>
                    <CardTitle>
                      <FormattedMessage id="rent_name_lastname" />
                    </CardTitle>
                    {tenant.firstName} {tenant.lastName}
                  </>
              }
            </CardHeader>
            <CardHeader>
              { tenant &&
                  <>
                    <CardTitle>
                      <FormattedMessage id="rent_my_quitance" />
                    </CardTitle>
                    <Input
                        name="firstName"
                        id="firstName"
                        type="number"
                        value={paymentAmount}
                        invalid={!!error}
                        errorMessage={error}
                        onChange={(event) => {
                          if (!event.target.value || priceRegExp.exec(event.target.value)) {
                            setPaymentAmount(event.target.value)
                          }
                          if (!event.target.value || parseFloat(event.target.value) > 3000) {
                            setError(intl.formatMessage({ id: "invalid_price_amount" }))
                          } else {
                            setError(null)
                          }
                        }}
                    />
                  </>
              }
            </CardHeader>
            <Button onClick={() => {
              setPaymentAmount(`${parseFloat(paymentAmount).toFixed(2)}`)
              callEwayPayment(selectedContract, user.email, `${parseFloat(paymentAmount).toFixed(2)}`, paymentLink)
            }} size="sm" color="primary">
              <FormattedMessage id="rent_submit_payment" />
            </Button>
          </Card>
        </Section>
      </>
    </Layout>
  );
}

export default MyPaymentRentView;
