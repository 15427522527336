import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { EditorialGroupQueryInterface, EditorialPage } from "../interfaces/footer/FooterInterfaces";
import { useQuery } from "@apollo/client";
import { EDITORIAL_GROUP_QUERY } from "../constants/queries/FooterQueries";
import { handleApolloError } from "../hooks/handleApolloError";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import EditorialMenu from "../components/layout/EditorialMenu";
import { useParams } from "react-router";
import EditorialContent from "../components/molecules/EditorialContent";
import { EDITORIAL_PATH, HOME_PATH } from "../constants/routes/RoutePaths";
import { scrollTop } from "../utils/utilFunctions";
import { history } from "../constants/History";
import { navService } from "../services/NavService";
import Breadcrumb from "../components/molecules/Breadcrumb";

interface RouteParams {
  groupUrlKey: string;
  pageUrlKey: string;
}

interface EditorialViewProps {
  children: ReactElement;
}

const EditorialView: FunctionComponent<EditorialViewProps> = ({}): ReactElement => {
  const { groupUrlKey, pageUrlKey } = useParams<RouteParams>();
  const {
    data: editorialGroupData,
    error: editorialGroupQueryError
  } = useQuery<EditorialGroupQueryInterface>(EDITORIAL_GROUP_QUERY, { variables: { urlKey: groupUrlKey } })
  handleApolloError("editorial_group_query", editorialGroupQueryError);

  const editorialGroup = editorialGroupData?.editorialGroups[0];
  const foundPage = editorialGroup && editorialGroup.editorialPages.find((editorialPage: EditorialPage) => editorialPage.urlKey === pageUrlKey);

  useEffect(() => {
    scrollTop();
  }, [pageUrlKey])

  useEffect(() => {
    if (editorialGroup && editorialGroup.editorialPages.length > 0) {
      if (!foundPage) {
        history.push(EDITORIAL_PATH
          .replace(":groupUrlKey", groupUrlKey)
          .replace(":pageUrlKey", editorialGroup.editorialPages[0].urlKey)
        );
      } else if (editorialGroup.url) {
        navService.redirect(editorialGroup.url, HOME_PATH);
      }
    }
  }, [editorialGroup, foundPage])

  return (
    <Layout className="EditorialView">
      <Section className="pt-0">
        {editorialGroup && (
          <div>
            <Breadcrumb id="EditorialBreadcrumb" firstText={editorialGroup.title} secondText={foundPage?.title} backLink={editorialGroup.editorialPages[0]?.urlKey} />
            <div className="d-flex flex-column flex-md-row">
              {editorialGroup.editorialPages.length > 1 && !editorialGroup.hideMenu && (
                <EditorialMenu groupUrlKey={groupUrlKey} editorialPages={editorialGroup.editorialPages} />
              )}
              {foundPage && <EditorialContent pageUrlKey={foundPage.urlKey} />}
            </div>
          </div>
        )}
      </Section>
    </Layout>
  );
}

export default EditorialView;
