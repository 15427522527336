import React, { FunctionComponent, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import Card from "components/atoms/Card";
import { CardBody, CardHeader, CardTitle, Col, FormGroup } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import WaterGraphComponent from "../../components/molecules/WaterGraphComponent";
import { scrollTop } from "../../utils/utilFunctions";
import { Formik } from "formik";
import Form from "components/atoms/Form";
import Input from "components/atoms/Input";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { tenantService } from "services/TenantService";
import { toastService } from "../../services/ToastService";
import { useContracts } from "../../hooks/useContracts";
import { useQuery } from "@apollo/client";
import { HomepageQueryInterface } from "../../interfaces/homepage/HomePageInterfaces";
import { PARTIAL_HOMEPAGE_QUERY } from "../../constants/queries/HomepageQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import YoutubeVideoContainer from "../../components/molecules/YoutubeVideoContainer";
import { PARTIAL_MY_HOME_QUERY } from "../../constants/queries/MyHomepageQueries";
import { MyHomepageQueryInterface } from "../../interfaces/myHome/MyHomePageInterfaces";

interface MyFormValues {
  insurance: File;
}

const MyHousingView: FunctionComponent<RouteProps> = ({}) => {
  const { selectedContract, contractsDropdown } = useContracts();

  const { data: homeData, error: homepageQueryError } = useQuery<HomepageQueryInterface>(PARTIAL_HOMEPAGE_QUERY)
  const { data: myHomeData } = useQuery<MyHomepageQueryInterface>(PARTIAL_MY_HOME_QUERY)
  handleApolloError("homepage_query", homepageQueryError);

  const intl = useIntl();
  const insuranceSchema = Yup.object({
    insurance: Yup.mixed().required(intl.formatMessage({ id: "mandatory_field" })),
  })

  useEffect(() => {
    scrollTop();
  }, [])

  const initialValues: MyFormValues = { insurance: undefined };
  const home = homeData?.home;
  const myHome = myHomeData?.myHome;
  return (
    <Layout dashboard>
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2"><FormattedMessage id="my_housing" /></h2>
            {contractsDropdown}
          </div>
          <WaterGraphComponent selectedContract={selectedContract} />
        </Section>

        {myHome && myHome.youtubeId && (
          <Section>
            <Card className="pb-4" rounded>
              <CardHeader>
                <CardTitle>
                  <FormattedMessage id="my_housing_tuto" />
                </CardTitle>
              </CardHeader>
              <CardBody>
                <YoutubeVideoContainer videoID={myHome.youtubeId} />
              </CardBody>
            </Card>
          </Section>
        )}

        {home && home.myHousingImage && (
            <Section>
              <Card className="pb-4" rounded>
                <CardBody>
                  <img className="Img Img--housing" src={home.myHousingImage.url} alt={home.myHousingImage.alternativeText}/>
                </CardBody>
              </Card>
            </Section>
        )}

        <Section>
          <Card rounded>
            <CardHeader>
              <CardTitle>
                <FormattedMessage id="my_housing_my_insurance" />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Formik
                validationSchema={insuranceSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  void tenantService.addInsurance(values.insurance, selectedContract)
                    .then(() => toastService.success(intl.formatMessage({ id: "insurance_email_success" })))
                }}
              >
                {({
                  handleSubmit,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form noValidate onSubmit={handleSubmit} className="m-2">
                    <Row>
                      <Col className="align-self-center">
                        <FormGroup className="mb-0">
                          <Input className="cursor-pointer btn btn-link input-file required" name="insurance" type="file"
                                 onChange={(event) => {
                                   setFieldValue("insurance", event.currentTarget.files[0])
                                 }}
                                 invalid={touched.insurance && !!errors.insurance}
                                 errorMessage={errors.insurance}
                          />
                          <div className="input-file-label"><span className="label"><FormattedMessage
                            id="my_housing_add_insurance" /></span></div>
                          <div className="input-file-name">{values.insurance?.name || ""}</div>
                        </FormGroup>
                      </Col>
                      <Col md="3" className="float-right">
                        <Button
                          className="submit-application"
                          color="primary"
                          type="submit"
                          size="xs"
                        >
                          {intl.formatMessage({ id: "send" })}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Section>

        {/*<Section>*/}
        {/*  <Card rounded>*/}
        {/*    <CardHeader>*/}
        {/*      <CardTitle>*/}
        {/*        Réglement intérieur*/}
        {/*      </CardTitle>*/}
        {/*    </CardHeader>*/}
        {/*    <CardBody>*/}
        {/*      <DefinitionList>*/}
        {/*        <>*/}
        {/*          <dt className="Toggle" onClick={toggle}><span className="Text">Lorem Ipsum</span> <Icon name="Plus" />*/}
        {/*          </dt>*/}
        {/*          <Collapse isOpen={isOpen}>*/}
        {/*            <dd>Dolor sit amet.</dd>*/}
        {/*          </Collapse>*/}

        {/*          <dt className="Toggle" onClick={toggle}><span className="Text">Lorem Ipsum</span> <Icon name="Plus" />*/}
        {/*          </dt>*/}
        {/*          <Collapse isOpen={isOpen}>*/}
        {/*            <dd>Dolor sit amet.</dd>*/}
        {/*          </Collapse>*/}
        {/*        </>*/}
        {/*      </DefinitionList>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Section>*/}
      </>
    </Layout>
  );
}

export default MyHousingView;
