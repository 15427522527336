import React, { FunctionComponent, ReactElement, useState } from "react";
import { CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { formatDateToStandardShort } from "../../../utils/dateUtils";
import Price from "../../atoms/Price";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Card from "../../atoms/Card";
import { Rent } from "../../../interfaces/rents/RentsInterfaces";
import { history } from "../../../constants/History";
import { DOCUMENTS_PATH } from "../../../constants/routes/RoutePaths";
import { DocumentInterface } from "../../../interfaces/documents/DocumentsInterfaces";
import { documentService } from "../../../services/DocumentService";
import { authService } from "../../../services/AuthService";

interface DbLastRentCardProps {
  rent?: Rent;
  receipt?: DocumentInterface;
}

const DbLastRentCard: FunctionComponent<DbLastRentCardProps> = ({ rent, receipt }): ReactElement => {
  const user = authService.getLoggedInUser().user;

  const [loading, setLoading] = useState<boolean>(false);

  const onClick = () => {
    history.push(DOCUMENTS_PATH);
  }

  const onDownload = () => {
    if (!loading) {
      setLoading(true);
      documentService.downloadFile(documentService.getUrl(rent.contract_number.toString(), receipt.name, user.tenantNumber))
      // TODO : Trouver un meilleur moyen de récupérer l'information lorsque le fichier a été téléchargé
      setTimeout(() => setLoading(false), 3000);
    }
  }

  return (
    <Card rounded>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="db_last_rent" />
        </CardTitle>
      </CardHeader>
      <CardBody>
        {rent ? (
          <div className="card-content">
            <CardText className="bill">
              <small className="date">
                <FormattedMessage id="db_rent_of" />
                {formatDateToStandardShort(rent.due_date)}
              </small>
            </CardText>
            <CardText className="amount">
              <Price price={rent.debit - rent.credit}>
                {receipt && (
                  <Button disabled={loading} color="link" onClick={onDownload}>
                    <Icon name="Download" size="2" />
                  </Button>
                )}
              </Price>
            </CardText>
          </div>
        ) : (
          <em>
            <FormattedMessage id="no_data" />
          </em>
        )}
      </CardBody>
      <CardFooter className="justify-content-end">
        <Button onClick={onClick} size="sm" color="primary">
          <FormattedMessage id="db_see_all" />
        </Button>
      </CardFooter>
    </Card>
  );
}

export default DbLastRentCard;
