import * as React from "react"

import { ReactComponent as IconChevronLeft } from "../../assets/icons/picto-chevron-left.svg"
import { ReactComponent as IconChevronRight } from "../../assets/icons/picto-chevron-right.svg"
import { ReactComponent as IconChevronUp } from "../../assets/icons/picto-chevron-up.svg"
import { ReactComponent as IconChevronDown } from "../../assets/icons/picto-chevron-down.svg"
import { ReactComponent as IconFavorite } from "../../assets/icons/picto-favorite.svg"
import { ReactComponent as IconFavoriteActive } from "../../assets/icons/picto-favorite-active.svg"
import { ReactComponent as IconSuccess } from "../../assets/icons/picto-success.svg"
import { ReactComponent as IconPhone } from "../../assets/icons/picto-phone.svg"
import { ReactComponent as IconShare } from "../../assets/icons/picto-share.svg"
import { ReactComponent as IconTwitter } from "../../assets/icons/picto-twitter.svg"
import { ReactComponent as IconLinkedIn } from "../../assets/icons/picto-linkedin.svg"
import { ReactComponent as IconYoutube } from "../../assets/icons/picto-youtube.svg"
import { ReactComponent as IconMore } from "../../assets/icons/picto-more.svg"
import { ReactComponent as IconPlay } from "../../assets/icons/picto-play.svg"
import { ReactComponent as IconSearch } from "../../assets/icons/picto-search.svg"
import { ReactComponent as IconLocation } from "../../assets/icons/picto-location.svg"
import { ReactComponent as IconDownload } from "../../assets/icons/picto-download.svg"
import { ReactComponent as IconFilePdf } from "../../assets/icons/picto-file-pdf.svg"
import { ReactComponent as IconPlus } from "../../assets/icons/picto-plus.svg"
import { ReactComponent as IconMinus } from "../../assets/icons/picto-minus.svg"
import { ReactComponent as IconMail } from "../../assets/icons/picto-mail.svg"
import { ReactComponent as IconUser } from "../../assets/icons/picto-user.svg"
import { ReactComponent as IconUser2 } from "../../assets/icons/picto-user-2.svg"
import { ReactComponent as IconMenu } from "../../assets/icons/picto-menu.svg"
import { ReactComponent as IconCanalisation } from "../../assets/icons/picto-canalisation.svg"
import { ReactComponent as IconCommunes } from "../../assets/icons/picto-communes.svg"
import { ReactComponent as IconElectricite } from "../../assets/icons/picto-electricite.svg"
import { ReactComponent as IconFuite } from "../../assets/icons/picto-fuite.svg"
import { ReactComponent as IconHPConnaitre } from "../../assets/icons/picto-HP-connaitre.svg"
import { ReactComponent as IconHPLocataire } from "../../assets/icons/picto-HP-locataire.svg"
import { ReactComponent as IconHPProprio } from "../../assets/icons/picto-HP-proprio.svg"
import { ReactComponent as IconInsecte } from "../../assets/icons/picto-insecte.svg"
import { ReactComponent as IconMutation } from "../../assets/icons/picto-mutation.svg"
import { ReactComponent as IconNouvelOccupant } from "../../assets/icons/picto-nouvel-occupant.svg"
import { ReactComponent as IconRappel } from "../../assets/icons/picto-rappel.svg"
import { ReactComponent as IconPanne } from "../../assets/icons/picto-panne.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icons/picto-arrow-right.svg"
import { ReactComponent as IconFacebook} from "../../assets/icons/picto-facebook.svg"
import { ReactComponent as IconBankCards} from "../../assets/icons/picto-bank-cards.svg"
import { ReactComponent as IconBill} from "../../assets/icons/picto-bill.svg"
import { ReactComponent as IconDashboard} from "../../assets/icons/picto-dashboard.svg"
import { ReactComponent as IconIncidentPower} from "../../assets/icons/picto-incident-power.svg"
import { ReactComponent as IconIncidentWater} from "../../assets/icons/picto-incident-water.svg"
import { ReactComponent as IconMyHome} from "../../assets/icons/picto-myhome.svg"
import { ReactComponent as IconRequests} from "../../assets/icons/picto-requests.svg"
import { ReactComponent as IconSurveys} from "../../assets/icons/picto-surveys.svg"
import { ReactComponent as IconResidence} from "../../assets/icons/picto-residence.svg"
import { ReactComponent as IconDocuments} from "../../assets/icons/picto-documents.svg"
import { ReactComponent as IconWave} from "../../assets/icons/picto-wave.svg"
import { ReactComponent as IconPrint} from "../../assets/icons/picto-print.svg"

interface IconMap {
  [key: string]: JSX.Element;
}

export const ICONS_MAP: IconMap = {
  ChevronLeft: <IconChevronLeft/>,
  Canalisation: <IconCanalisation/>,
  Communes: <IconCommunes/>,
  Electricite: <IconElectricite/>,
  Fuite: <IconFuite/>,
  HPConnaitre: <IconHPConnaitre/>,
  HPLocataire: <IconHPLocataire/>,
  HPProprio: <IconHPProprio/>,
  Insecte: <IconInsecte/>,
  Mutation: <IconMutation/>,
  NouvelOccupant: <IconNouvelOccupant/>,
  Panne: <IconPanne/>,
  Rappel: <IconRappel/>,
  ChevronRight: <IconChevronRight/>,
  ChevronUp: <IconChevronUp/>,
  ChevronDown: <IconChevronDown/>,
  Favorite: <IconFavorite/>,
  FavoriteActive: <IconFavoriteActive/>,
  Success: <IconSuccess/>,
  Phone: <IconPhone/>,
  Share: <IconShare/>,
  Twitter: <IconTwitter/>,
  LinkedIn: <IconLinkedIn/>,
  Youtube: <IconYoutube/>,
  More: <IconMore/>,
  Play: <IconPlay/>,
  Search: <IconSearch/>,
  Location: <IconLocation/>,
  Download: <IconDownload/>,
  FilePdf: <IconFilePdf/>,
  Plus: <IconPlus/>,
  Minus: <IconMinus/>,
  Mail: <IconMail/>,
  User: <IconUser/>,
  User2: <IconUser2/>,
  Menu: <IconMenu/>,
  ArrowRight: <IconArrowRight/>,
  Facebook: <IconFacebook/>,
  BankCards: <IconBankCards/>,
  Bill: <IconBill/>,
  Dashboard: <IconDashboard/>,
  IncidentPower: <IconIncidentPower/>,
  IncidentWater: <IconIncidentWater/>,
  MyHome: <IconMyHome/>,
  Requests: <IconRequests/>,
  Surveys: <IconSurveys/>,
  Residence: <IconResidence/>,
  Documents: <IconDocuments/>,
  Wave: <IconWave/>,
  Print: <IconPrint/>,
}
