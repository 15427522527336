import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardText, Col } from "reactstrap";
import Row from "../atoms/Row";
import Figure from "../atoms/Figure";
import Card from "../atoms/Card";
import {useIntl} from "react-intl";
import YoutubeVideoContainer from "./YoutubeVideoContainer";

interface CardDistrictProps {
  title: string;
  youtubeId: string;
  children: JSX.Element;
}

const CardDistrict: FunctionComponent<CardDistrictProps> = ({
  title,
  children,
  youtubeId,
}): ReactElement => {
  const intl = useIntl()
  return (
    <Card className="CardDistrict">
      <CardBody>
        <Row>
          <Col xs="12">
            <h4>{`${intl.formatMessage({ id: "district" })} : ${title}`}</h4>
          </Col>
          <Col xs="12" md="6">
            <CardText className="lead">
              {children}
            </CardText>
          </Col>
          {youtubeId && (
            <Col xs="12" md="6" className="mt-5">
              <Figure sizeAuto>
                <YoutubeVideoContainer videoID={youtubeId} />
              </Figure>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

export default CardDistrict;
