import React, { FunctionComponent } from "react"

// Styles : styles/components/navbar

// Reactstrap
import { Navbar as LibNavbar, NavbarProps } from "reactstrap"

const Navbar: FunctionComponent<NavbarProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibNavbar className={`Navbar ${className}`} {...rest}>
      {children}
    </LibNavbar>
  )
}

export default Navbar
