import React, { FunctionComponent, ReactElement } from "react";
import { PropertyDetails } from "../../interfaces/properties/PropertiesInterfaces";
import { PROPERTY_CATEGORIES_TRANSLATIONS } from "../../constants/properties/PropertiesConstants";
import { FormattedMessage } from "react-intl";

interface PropertyLabelProps {
  property: PropertyDetails
}

const PropertyLabel: FunctionComponent<PropertyLabelProps> = ({ property }): ReactElement => {
  const has_rooms = property?.number_of_room > 0;
  const has_surface = property?.surface > 0;
  return (
    <h1 className="h1 PropertyLabel">
      {property && PROPERTY_CATEGORIES_TRANSLATIONS[property?.category] && (
        <strong className="MainTitle">
          <FormattedMessage id={PROPERTY_CATEGORIES_TRANSLATIONS[property?.category]} />
        </strong>
      )}
      {has_rooms && <span> - {property?.number_of_room} <FormattedMessage id="property_room_info" /></span>}
      {has_surface && <span> - {property?.surface}m<sup>2</sup></span>}
    </h1>
  )
}

export default PropertyLabel;