import { fetchWrapper } from "../utils/fetchWrapper"
import { RegisterRequest, LoggedUser, User } from "../interfaces/users/UsersInterfaces"
import {
  AUTHENTICATION_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE
} from "../constants/ApiRoutes"

const getLoggedInUser = (): LoggedUser => {
  return JSON.parse(localStorage.getItem("user"))
}

const setLoggedInUser = (user: LoggedUser): void => {
  localStorage.setItem("user", JSON.stringify(user))
}

const updateLoggedInUser = (user: User): void => {
  setLoggedInUser({ ...getLoggedInUser(), user: user })
}

const login = (loginValue: string, passwordValue: string): Promise<LoggedUser> => {
  return fetchWrapper
    .post<LoggedUser>(AUTHENTICATION_ROUTE, {
      identifier: loginValue,
      password: passwordValue
    })
    .then((user: LoggedUser) => {
      setLoggedInUser(user)
      return user
    })
}

const logout = (): void => {
  localStorage.removeItem("user")
  location.reload()
}

const register = (registerRequest: RegisterRequest): Promise<LoggedUser> => {
  return fetchWrapper
    .post<LoggedUser>(REGISTER_ROUTE, {
      username: `${registerRequest.firstName.charAt(0)}${registerRequest.lastName}`,
      lastName: registerRequest.lastName,
      firstName: registerRequest.firstName,
      contractNumber: registerRequest.contractNumber,
      email: registerRequest.email,
      password: registerRequest.password,
      conditions: registerRequest.conditions,
      water: registerRequest.water,
    });
}

const forgottenPassword = (login: string): Promise<boolean | void> => {
  return fetchWrapper
    .post(FORGOT_PASSWORD_ROUTE, {
      email: login
    })
}

const resetPassword = (newPassword: string, confirmPassword: string, code: string): Promise<boolean | void> => {
  return fetchWrapper
    .post(RESET_PASSWORD_ROUTE, {
      code: code,
      password: newPassword,
      passwordConfirmation: confirmPassword
    })
}

const changePassword = (
  id: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
): Promise<LoggedUser> => {
  return fetchWrapper
    .post(CHANGE_PASSWORD_ROUTE, {
      id,
      currentPassword,
      newPassword,
      confirmPassword
    })
    .then((user: LoggedUser) => {
      setLoggedInUser(user)
      return user
    })
}

export const authService = {
  login,
  logout,
  register,
  getLoggedInUser,
  setLoggedInUser,
  updateLoggedInUser,
  forgottenPassword,
  resetPassword,
  changePassword,
}
