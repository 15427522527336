import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle } from "reactstrap";
import Figure from "../atoms/Figure";
import { imageService } from "../../services/ImageService";
import { LOGO_SERVICE } from "../../assets/Assets";
import Card from "../atoms/Card";
import { DiscoverService } from "../../interfaces/discover/DiscoverPageInterfaces";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { FormattedMessage } from "react-intl";
import { navService } from "../../services/NavService";

interface DiscoverCardProps {
  service: DiscoverService;
}

const DiscoverCard: FunctionComponent<DiscoverCardProps> = ({
  service
}): ReactElement => {
  const hasLink = !!service.url;

  const onClick = () => {
    if (hasLink) {
      navService.redirect(service.url);
    }
  }

  return (
    <Card
      rounded
      roundedSize="lg"
      imgSizeAuto
      noZoom
      tag={hasLink ? "a" : "div"}
      href=""
      onClick={onClick}
    >
      <CardHeader>
        <Figure sizeAuto className="justify-content-center">
          <CardImg src={imageService.getImageUrlByFormat(service.icon) || LOGO_SERVICE} />
        </Figure>
        <CardTitle className="h3" tag="h3">{service.title}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>{service.description}</CardText>
      </CardBody>
      {hasLink && (
        <CardFooter>
          <Button className="More" color="link">
            <Icon name="More" />
            <span className="Text">
              <FormattedMessage id="home_card_know_more" />
            </span>
          </Button>
        </CardFooter>
      )}
    </Card>
  )
}

export default DiscoverCard;
