import React, { FunctionComponent, ReactElement, useRef, useState } from "react";
import { MaintenanceStep, MaintenanceStepper } from "../../interfaces/footer/FooterInterfaces";
import MaintenanceStepperCard from "./MaintenanceStepperCard";
import Row from "../atoms/Row";
import { Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import MaintenanceStepCard from "./MaintenanceStepCard";
import MaintenanceChargeCard from "./MaintenanceChargeCard";
import { SCROLL_INTO_VIEW_OPTIONS } from "../../constants/common/CommonConstants";

interface MaintenanceStepperContainerProps {
  steppers: MaintenanceStepper[];
}

const MaintenanceStepperContainer: FunctionComponent<MaintenanceStepperContainerProps> = ({
  steppers
}): ReactElement => {
  const selectRoomRef = useRef<HTMLHeadingElement>(null)
  const chooseEquipmentRef = useRef<HTMLHeadingElement>(null)
  const inChargeOfRef = useRef<HTMLHeadingElement>(null)

  const [selectedStepper, setSelectedStepper] = useState<number>(steppers[0].id);
  const [selectedStep, setSelectedStep] = useState<number>(steppers[0].step[0].id);

  const onStepperClick = (id: number): void => {
    setSelectedStepper(id);
    setSelectedStep(steppers.find((stepper: MaintenanceStepper) => stepper.id === id).step[0].id);
    chooseEquipmentRef.current.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
  }

  const onStepClick = (id: number): void => {
    setSelectedStep(id);
    inChargeOfRef.current.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
  }

  const onChargeClick = () => {
    selectRoomRef.current.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
  }

  const steps = steppers.find((stepper: MaintenanceStepper) => stepper.id === selectedStepper).step;
  const isLrye = steps.find((step: MaintenanceStep) => step.id === selectedStep).lrye;

  return (
    <div className="MaintenanceStepperContainer">
      <h4 ref={selectRoomRef}>
        <FormattedMessage id="select_your_room" />
      </h4>
      <div className="StepperGrid">
        {steppers.map((stepper: MaintenanceStepper) => (
          <div key={`stepper_${stepper.id}`} className="StepperContainer">
            <MaintenanceStepperCard
              stepper={stepper}
              active={selectedStepper === stepper.id}
              onClick={onStepperClick}
            />
          </div>
        ))}
      </div>
      <h4 ref={chooseEquipmentRef}>
        <FormattedMessage id="choose_your_equipment" />
      </h4>
      <Row grid gutters="lg">
        {steps.map((step: MaintenanceStep) => (
          <Col key={`step_${step.id}`} xl="4" lg="6" md="12" sm="6" xs="12">
            <MaintenanceStepCard
              step={step}
              active={selectedStep === step.id}
              onClick={onStepClick}
            />
          </Col>
        ))}
      </Row>
      <h4 ref={inChargeOfRef}>
        <FormattedMessage id="in_charge_of" />
      </h4>
      <MaintenanceChargeCard isLrye={isLrye} onClick={onChargeClick} />
    </div>
  );
}

export default MaintenanceStepperContainer;
