import { gql } from "@apollo/client";

export const PROPERTIES_PHOTO_QUERY = gql`
   query Property($immowareIds: [String])
    {
      properties(
        where: {
          immoware_id: $immowareIds
        }
      ) {
        id,
        photo { 
          url
          formats
        }        
      }
    } 
`

export const PROPERTY_DETAIL_QUERY = gql`
query Properties($immowareId: String) {
  properties(
    where:{
      immoware_id: $immowareId
    }) 
    {
      id,
      immoware_id,
      number_of_bathroom,
      number_of_bedroom,
      has_parking,
      has_elevator,
      floor,
      surface,
      number_of_room,
      vacant,
      common,
      description,
      address_key,
      type,
      highlight,
      has_balcony,
      has_terrace,
      has_basement,
      has_storage,
      has_bike_parking,
      has_garden,
      fees,
      price,
      plan,
      category,
      selling_price,
      has_playground,
      situation,
      company_short_code,
      company_name,
      plans {
        name
        pdf {
          url
          formats
        }
      }
      agency {
        id
        phone
        email
      }
      photo {
        url
        formats
      },
      complex {
        immoware_id,
        label,
        type,
        address,
        city,
        zip_code
        longitude,
        latitude,
        district {
          name
          bus
          elementary_school
          middle_school
          high_school
          health
          youtube_video {
            youtubeId
          }
        },
        company {
          id
          short_code
          name
          description
        }
      }
    }
  }`
