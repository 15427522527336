import React, { FunctionComponent, ReactElement, useEffect, useRef } from "react";
import { Property } from "../../interfaces/properties/PropertiesInterfaces";
import Figure from "../atoms/Figure";
import Img from "../atoms/Img";
import { FormattedMessage, useIntl } from "react-intl";
import { formatPriceTTC } from "../../utils/utilFunctions";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Button from "../atoms/Button";
import { history } from "../../constants/History";
import { PROPERTY_DETAIL_PATH } from "../../constants/routes/RoutePaths";
import { imageService } from "../../services/ImageService";

interface PropertyItemProps {
  property: Property;
  onClick: (id: string) => void;
  selected?: boolean;
  setSelectedBounds?: (bounds: DOMRect) => void;
}

const PropertyItem: FunctionComponent<PropertyItemProps> = ({
  property,
  onClick,
  selected,
  setSelectedBounds
}): ReactElement => {
  const ref = useRef<HTMLDivElement>()
  const intl = useIntl()

  useEffect(() => {
    if (setSelectedBounds && selected) {
      setSelectedBounds(ref.current.getBoundingClientRect());
    }
  }, [selected])

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    history.push(PROPERTY_DETAIL_PATH.replace(":id", property.urlKey));
  }

  const complex = property.complex;
  const propertyInfos = []
  if (property.number_of_bedroom !== null && property.number_of_bedroom > 0) {
    propertyInfos.push(intl.formatMessage({ id: "properties_map_bedroom" }, { count: property.number_of_bedroom }))
  }
  if (property.surface !== null && property.surface > 0) {
    propertyInfos.push(`${property.surface}m2`)
  }
  if (property.number_of_bathroom !== null && property.number_of_bathroom > 0) {
    propertyInfos.push(intl.formatMessage({ id: "properties_map_bathroom" }, { count: property.number_of_bathroom }))
  }

  return (
    <div ref={ref} className={`PropertyItem ${selected ? "selected" : ""}`} onClick={() => onClick(property.id)}>
      <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
        <Img src={imageService.getImageUrlByFormat(property.photo) || imageService.getIconByCategory(property.category)}
             alt="" />
      </Figure>
      <div className="content">
        <h6 className="h6 type mb-0">{complex?.label}</h6>
        <div className="city mb-4">{complex?.city}</div>
        <div className="details">
          {propertyInfos.join(" - ")}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Button color="primary" onClick={onButtonClick}>
            <FormattedMessage id="see_property" />
          </Button>
          {property.price !== null && (
            <div className="price mr-3">
              {formatPriceTTC(property.price, property.fees)} € TTC
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyItem;
