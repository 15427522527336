import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import { Water } from "../../interfaces/water/WaterInterfaces";
import { FormattedMessage } from "react-intl";
import { authService } from "../../services/AuthService";
import WaterGraphChart from "./WaterGraphChart";
import { CardBody, CardHeader, CardText, CardTitle } from "reactstrap";
import Card from "../atoms/Card";
import { waterService } from "../../services/WaterService";
import Loader from "../atoms/Loader";
import Icon from "../atoms/Icon";

interface WaterGraphProps {
  selectedContract: string;
}

const WaterGraphComponent: FunctionComponent<WaterGraphProps> = ({ selectedContract }): ReactElement => {
  const user = authService.getLoggedInUser();

  const [waters, setWaters] = useState<Map<string, Water[]>>(new Map());
  const [loading, setLoading] = useState<boolean>(true);
  const [showUrl] = useState<boolean>(!!user.user.water);

  useEffect(() => {
    if (selectedContract) {
      setLoading(true);
      waterService.findByCurrentUserByContract(selectedContract)
        .then((result: Water[]) => {
          setWaters(waterService.mapWatersToMap(result));
        })
        .finally(() => setLoading(false))
    }
  }, [selectedContract]);

  const graphMemoized = useMemo<ReactElement>(() => (
    <WaterGraphChart waterMap={waters} showUrl={showUrl} />
  ), [waters, showUrl]);

  return (
    <Card className="WaterGraphCard" rounded>
      <CardHeader>
        <CardTitle tag="h2">
          <FormattedMessage id="water_consumption" />
        </CardTitle>
      </CardHeader>
      <CardBody className="justify-content-center">
        {loading && <Loader />}
        {!loading && (waters.size > 0 ? graphMemoized : (
          <CardText className="text-center my-5">
            <em>
              <FormattedMessage id="no_data" />
            </em>
          </CardText>
        ))}
      </CardBody>
      <Icon name="Wave" />
    </Card>
  );
}

export default WaterGraphComponent;
