import React, { FunctionComponent, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Col, CustomInput, FormGroup, Label } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { StepProps } from "interfaces/formstepper/FormStepperInterfaces";
import { useIntl } from "react-intl";
import Popover from "../atoms/Popover";
import { ANSWER } from "constants/common/CommonConstants";

const validationSchema = Yup.object({
  handicap: Yup.string().required(" ")
});

export const FormHandicap: FunctionComponent<StepProps> = ({ formData, setFormData, nextStep, prevStep }) => {
  const intl = useIntl();
  const handlePrevClick = () => formData.status === ANSWER.SINGLE ? prevStep(1) : prevStep();
  const [popoverInfoOpen, setPopoverInfo] = useState(false)
  const togglePopoverInfo = () => setPopoverInfo((state: boolean) => !state);
  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setFormData(values)
          nextStep();
        }}
      >
        {({
          touched,
          errors,
          values,
          setFieldValue
        }) => (
          <Form >
            <FormGroup className="text-center">
              <Label>{intl.formatMessage({ id: "form_question_handicap" })}</Label>
              <Button id="popInfo" type="button" outline size="xs" color="dark" icon pill>i</Button>
              <Row flexDirection="column" grid gutters="sm" justifyContent="center">
                <Col xs="6" md="4">
                  <CustomInput
                    invalid={touched.handicap && !!errors.handicap}
                    type="radio"
                    name="handicap"
                    id={ANSWER.YES}
                    label={intl.formatMessage({ id: "form_question_handicap_explained" })}
                    value={ANSWER.YES}
                    checked={values.handicap === ANSWER.YES}
                    onChange={() => setFieldValue("handicap", ANSWER.YES)}
                  />
                </Col>
                <Col xs="6" md="4">
                  <CustomInput
                    invalid={touched.handicap && !!errors.handicap}
                    type="radio"
                    name="handicap"
                    id={ANSWER.NO}
                    label={intl.formatMessage({ id: "general_form_answer_no" })}
                    value={ANSWER.NO}
                    checked={values.handicap === ANSWER.NO}
                    onChange={() => setFieldValue("handicap", ANSWER.NO)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
              <Button
                type="button"
                color="link"
                onClick={handlePrevClick}
                className="align-self-center"
              >
                <Icon name="ChevronLeft" />
                <span className="Text">{intl.formatMessage({ id: "form_stepper_button_prev" })}</span>
              </Button>
              <Button
                type="submit"
                color="primary"
              >
                {intl.formatMessage({ id: "form_stepper_button_next" })}
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <Popover isOpen={popoverInfoOpen} toggle={togglePopoverInfo}>
        {intl.formatMessage({ id: "form_info_handicap" })}
      </Popover>
    </>
  );
};