import { gql } from "@apollo/client";
export const CONNECTED_HOUSINGS_QUERY = gql
  `query ConnectedHousings {
    connectedHousing {
      title
      content
      categories {
        title
        description
        url
        icon {
          name
          url
          formats
        }
      }
    }
  }`
