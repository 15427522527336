import React, { FunctionComponent, ReactElement, useCallback } from "react";

// Styles : styles/molecules/cardOffer
import { Offer } from "../../interfaces/offers/OffersInterfaces";
import { useIntl } from "react-intl";
import { formatDateToLitteralMonthYear } from "../../utils/dateUtils";
import Button from "../atoms/Button";
import { history } from "../../constants/History"
import { OFFER_DETAIL_PATH } from "../../constants/routes/RoutePaths";
import { getRouteWithId } from "../../utils/routeUtils";
import TagLabel from "./TagLabel";
import Card from "components/atoms/Card";
import { CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap";

interface CardOfferProps {
  offer: Offer;
  className?: string;
}

const CardOffer: FunctionComponent<CardOfferProps> = ({
  offer,
  className = ""
}): ReactElement => {
  const intl = useIntl();

  const onClick = useCallback(() => {
    history.push(getRouteWithId(OFFER_DETAIL_PATH, offer.urlKey))
  }, [offer.id])

  return (
    <Card className={`CardOffer ${className}`} rounded>

      <CardHeader>
        <CardTitle className="h3" tag="h3">{offer.title}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>
          {
            offer.tags && offer.tags.map((tag) => (
              <TagLabel key={tag.id} tag={tag} />
            ))
          }
        </CardText>
        <CardText className="city">
          {offer.city}
        </CardText>
        {offer.start && (
          <CardText className="dateStart">
          {intl.formatMessage({ id: "offers_start_date" }, { date: formatDateToLitteralMonthYear(offer.start) })}
        </CardText>
        )}
      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        <Button
          size="sm"
          pill
          color="primary"
          title="offers_see_offer"
          onClick={onClick}
        >
        </Button>
      </CardFooter>
    </Card>
  )
}

export default CardOffer;
