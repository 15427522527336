import React, { FunctionComponent } from "react"

// Styles : styles/atoms/DefinitionList

type DefinitionListProps = {
  children?: JSX.Element
  className?: string
}

const DefinitionList: FunctionComponent<DefinitionListProps> = ({
  children,
  className=""
}) => {
  const DefinitionListClassName =
      (className ? " " + className : "")
  return (
    
    <dl className={`DefinitionList ${DefinitionListClassName}`}>
      {children}
    </dl>
  )
}

export default DefinitionList
