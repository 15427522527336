import React, { FunctionComponent, ReactElement } from "react";
import { navService } from "../../services/NavService";

interface BreadcrumbProps {
  id?: string;
  firstText: string;
  secondText: string;
  backLink?: string;
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  id,
  firstText,
  secondText,
  backLink
}): ReactElement => {

  const onClick = () => {
    if (backLink) {
      navService.redirect(backLink)
    }
  }

  return (
    <div id={id} className="mt-3 mt-md-0 small">
      <u className="cursor-pointer" onClick={onClick}>{firstText}</u>{" > "}<span>{secondText}</span>
    </div>
  )
}

export default Breadcrumb;
