import { LatLngExpression } from "leaflet";
import { ComplexWithProperties } from "../complex/ComplexInterfaces";

export enum MarkerType {
  property = "property",
  complex = "complex",
  agency = "agency",
  agency_city = "agency_city",
}

export interface MarkerInterface {
  id?: string;
  position: LatLngExpression;
  title?: string;
  selected?: boolean;
  complex?: ComplexWithProperties;
  type?: MarkerType;
}
