import { QueryResult } from "@apollo/client";
import { Media } from "interfaces/common/CommonInterfaces";
import { ComplexDetail } from "../complex/ComplexInterfaces";
import { Agency } from "../agencies/AgenciesInterfaces";

export enum PropertyCompany {
  OPI = "OPI",
  LLI = "LLI"
}

export enum PropertyTypologies {
  T1 = "t1",
  T2 = "t2",
  T3 = "t3",
  T4 = "t4",
  T5 = "t5",
}

export interface Property {
  id: string;
  urlKey: string;
  photo: Media;
  number_of_bathroom: number;
  number_of_bedroom: number;
  complex: ComplexDetail;
  surface: number;
  price: number;
  fees: number;
  category: string;
}

export interface PropertyDetails extends Property {
  immoware_id: string;
  has_parking: boolean;
  has_elevator: boolean;
  floor: string;
  number_of_room: number;
  vacant: boolean;
  common: boolean;
  description: string;
  type: string;
  highlight: boolean;
  has_balcony: boolean;
  has_terrace: boolean;
  has_basement: boolean;
  has_storage: boolean;
  has_bike_parking: boolean;
  has_garden: boolean;
  plan: string;
  category: string;
  selling_price: number;
  has_playground: boolean;
  situation: string;
  agency: Agency;
  urlKey: string;
  company_short_code: string;
  company_name: string;
  plans: PropertyPlan[]
}

export interface PropertyPlan {
  name: string;
  pdf: Media;
}

export interface SearchProperties {
  count: number;
  data: Property[];
  totalPages: number;
  page: number;
}

export interface PropertyPhoto {
  id: string;
  photo: Media[];
}

export interface PropertiesPhotoQuery extends QueryResult {
  properties: PropertyPhoto[]
}

export interface PropertyDetailsQuery extends QueryResult {
  properties: PropertyDetails[]
}

export type PropertyFiltersKey = keyof PropertyFilters;

export interface PropertyFilters {
  project?: string;
  category?: string;
  numberOfRoom: number[];
  numberOfPerson?: number;
  maxRent?: number;
  types?: string;
}
