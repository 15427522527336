import { Media } from "../interfaces/common/CommonInterfaces";
import { fetchWrapper } from "../utils/fetchWrapper";
import { UPLOAD_ROUTE } from "../constants/ApiRoutes";
import { ImageFormat } from "../constants/images/ImagesConstants";
import { CATEGORIES } from "../constants/properties/PropertiesConstants";
import { COMMERCE_ICON, LOGEMENT_ICON, MAISON_ICON, PARKING_ICON } from "../assets/Assets";

const uploadImage = (file: File, ref: string, refId: string, field: string, source?: string) : Promise<Media[]> => {
  const imageFormData = new FormData()
  imageFormData.append("files", file)
  imageFormData.append("ref", ref)
  imageFormData.append("refId", refId)
  imageFormData.append("field", field)
  if (source) {
    imageFormData.append("source", source)
  }
  return fetchWrapper.postFormData<Media[]>(UPLOAD_ROUTE, imageFormData)
}

const getImageUrlByFormat = (image: Media, format: ImageFormat = ImageFormat.SMALL): string => {
  if (!image) {
    return undefined
  }

  if (!image.formats || !image.formats[format]) {
    return image.url
  }

  return image.formats[format].url
}

const getIconByCategory = (category: string): string => {
  switch(category) {
    case CATEGORIES[0].value:
      return LOGEMENT_ICON;
    case CATEGORIES[1].value:
      return MAISON_ICON;
    case CATEGORIES[2].value:
      return COMMERCE_ICON;
    case CATEGORIES[3].value:
      return PARKING_ICON;
    default:
      return LOGEMENT_ICON;
  }
}

export const imageService = {
  uploadImage,
  getImageUrlByFormat,
  getIconByCategory,
}
