import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import { ticketService } from "../../services/TicketService";
import { Ticket } from "../../interfaces/tickets/TicketsInterfaces";
import { TICKET_STATUS } from "../../constants/tickets/TicketsConstants";
import { FormattedMessage } from "react-intl";
import { CREATE_TICKET_PATH } from "../../constants/routes/RoutePaths";
import { history } from "../../constants/History"
import OpinionForm from "../../components/molecules/OpinionForm";
import Card from "../../components/atoms/Card";
import { CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { scrollTop } from "../../utils/utilFunctions";
import TicketModal from "../../components/molecules/TicketModal";
import { authService } from "../../services/AuthService";
import TicketsListCard from "../../components/molecules/TicketsListCard";

const MyTicketsView: FunctionComponent<RouteProps> = (): ReactElement | null => {
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedTicketId, setSelectedTicketId] = useState<number>();
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();

  const user = authService.getLoggedInUser().user;

  useEffect(() => {
    scrollTop();
    setLoading(true)
    ticketService.findAllByTenantNumber(user.tenantNumber)
      .then((result: Ticket[]) => {
        setTickets(result)
      })
      .finally(() => setLoading(false))
  }, [user.tenantNumber])

  useEffect(() => {
    if (selectedTicketId) {
      void ticketService.findById(selectedTicketId)
        .then((result: Ticket) => {
          setSelectedTicket(result)
        })
    }
  }, [selectedTicketId])

  const onClose = () => {
    setSelectedTicketId(undefined);
  }

  const onClick = (id: number) => setSelectedTicketId(id);

  const openedTickets = tickets.filter((ticket: Ticket) => ticket.badge.status_id !== TICKET_STATUS.CLOSED.id)
  const closedTickets = tickets.filter((ticket: Ticket) => ticket.badge.status_id === TICKET_STATUS.CLOSED.id).slice(0, 10)

  return (
    <Layout dashboard>
      <>
        <Section>
          <Row>
            <Col>
              <h2 className="h2">
                <FormattedMessage id="tickets_follow_up" />
              </h2>
            </Col>
            <Col xs="auto">
              <Button outline color="dark" onClick={() => history.push(CREATE_TICKET_PATH)}>
                <Icon name="Plus" /> <span className="Text"><FormattedMessage id="ticket_request" /></span>
              </Button>
            </Col>
          </Row>
        </Section>

        <Section>
          <Row grid>
            <Col xs="12" md="6">
              <TicketsListCard name="tickets_opened" tickets={openedTickets} onClick={onClick} loading={loading} />
            </Col>
            <Col xs="12" md="6">
              <TicketsListCard name="tickets_closed" tickets={closedTickets} onClick={onClick} loading={loading} />
            </Col>
          </Row>
        </Section>

        <Section>
          <Card rounded>
            <CardHeader>
              <CardTitle>
                <FormattedMessage id="opinion_title" />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <OpinionForm />
            </CardBody>
          </Card>
        </Section>

        <TicketModal
          selectedTicket={selectedTicket}
          open={!!selectedTicketId}
          onClose={onClose}
        />
      </>
    </Layout>
  );
}

export default MyTicketsView;
