import React, { FunctionComponent } from "react"
import { RouteProps } from "react-router"
import { useQuery } from "@apollo/client"
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import { OUR_COMMITMENTS_QUERY } from "../constants/queries/OurCommitmentsQueries";
import { OurCommitmentsQueryInterface } from "../interfaces/ourCommitments/OurCommitmentsInterfaces";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../components/atoms/Section";
import { CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col } from "reactstrap";
import Card from "../components/atoms/Card";
import Figure from "../components/atoms/Figure";
import { imageService } from "../services/ImageService";
import { LOGO_SERVICE } from "../assets/Assets";
import { truncateText } from "../utils/utilFunctions";
import Button from "../components/atoms/Button";
import Icon from "../components/atoms/Icon";
import Row from "../components/atoms/Row";
import { useIntl } from "react-intl";
import { OUR_COMMITMENTS_PATH } from "../constants/routes/RoutePaths";
import { history } from "./../constants/History";

const OurCommitmentsView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const { data: ourCommitmentsData, error: ourCommitmentsQueryError } = useQuery<OurCommitmentsQueryInterface>(OUR_COMMITMENTS_QUERY)
  handleApolloError("our_commitments_query", ourCommitmentsQueryError);

  const ourCommitmentsPage = ourCommitmentsData?.ourCommitment;

  return (
    <Layout>
      <Section>
        {
          ourCommitmentsPage && (
            <>
              <h1 className="h1 mb-5">{ourCommitmentsPage.title}</h1>
              <ReactMarkdownWithHtml
                allowDangerousHtml
                plugins={[gfm]}
                className="description"
              >
                {ourCommitmentsPage.content}
              </ReactMarkdownWithHtml>
              <Row grid justifyContent="center" className="mt-3">
                {ourCommitmentsPage.categories?.map(category => (
                  <Col key={category.title} xs="12" md="3">
                    <Card
                      tag="a"
                      rounded
                      roundedSize="lg"
                      imgSizeAuto
                      noZoom
                      onClick={() => history.push(`${OUR_COMMITMENTS_PATH}${category.url}`)}
                    >
                      <CardHeader>
                        <Figure sizeAuto>
                          <CardImg src={imageService.getImageUrlByFormat(category.icon) || LOGO_SERVICE} />
                        </Figure>
                        <CardTitle className="h3" tag="h3">{category.title}</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <CardText>{truncateText(category.description, 100)}</CardText>
                      </CardBody>
                      <CardFooter>
                        <Button className="More" color="link"><Icon name="More" /> <span
                          className="Text">{ intl.formatMessage({ id: "home_card_know_more" }) }</span></Button>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )
        }
      </Section>
    </Layout>
  )
}

export default OurCommitmentsView
