import React, { FunctionComponent } from "react"

// Styles : styles/components/nav

import { Nav as LibNav, NavProps as LibNavProps, CSSModule } from "reactstrap"

type NavProps = LibNavProps & {
  tabs?: boolean
  pills?: boolean
  vertical?: boolean | string
  horizontal?: string
  justified?: boolean
  fill?: boolean
  navbar?: boolean
  card?: boolean
  className?: string
  cssModule?: CSSModule
  center?: boolean
  hasNet?: boolean
}

const Nav: FunctionComponent<NavProps> = ({
  children,
  className = "",
  center,
  hasNet,
  ...rest
}) => {
  const NavClassName =
    (hasNet ? " HasNet" : "") +
    (center ? " justify-content-center" : "") +
    (className ? " " + className : "")

  return (
    <LibNav className={`Nav ${NavClassName}`} {...rest}>
      {children}
    </LibNav>
  )
}

export default Nav
