/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */
reloadJob = (id) => {
    (tarteaucitron.job = tarteaucitron.job || []).push(id);
    let allowButtons = document.getElementsByClassName("tarteaucitronAllow");
    for (let buttonIndex = 0; buttonIndex < allowButtons.length; buttonIndex++) {
        tarteaucitron.addClickEventToElement(allowButtons[buttonIndex], function () {
            tarteaucitron.userInterface.respond(this, true);
        });
    }
}

module.exports = {
    reloadJob
}
