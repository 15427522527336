import React, { FunctionComponent, ReactElement } from "react";
import Card from "../atoms/Card";
import Row from "../atoms/Row";
import { CardBody, Col } from "reactstrap";
import Icon from "../atoms/Icon";
import Button from "../atoms/Button";
import { ComplexPhotoQuery, ComplexWithProperties } from "../../interfaces/complex/ComplexInterfaces";
import { COMPLEX_DETAIL_PATH } from "../../constants/routes/RoutePaths";
import { getRouteWithId } from "../../utils/routeUtils";
import { history } from "../../constants/History"
import Figure from "../atoms/Figure";
import Img from "../atoms/Img";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { COMPLEX_PHOTO_QUERY } from "../../constants/queries/ComplexQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import { COMPLEX_ICON } from "../../assets/Assets";
import { imageService } from "../../services/ImageService";
import ComplexCount from "./ComplexCount";

interface MapAgencyCardProps {
  complex: ComplexWithProperties;
}

const MapComplexCard: FunctionComponent<MapAgencyCardProps> = ({ complex }): ReactElement | null => {
  const intl = useIntl();

  const {
    data: dataMedia,
    error: dataError
  } = useQuery<ComplexPhotoQuery>(COMPLEX_PHOTO_QUERY, { variables: { id: complex.id } });
  handleApolloError("complex_photo_query", dataError);

  if (!complex) {
    return null;
  }
  return (
    <Card noBorder className="MapComplexCard">
      <Row grid gutters="none" flexDirection="row">
        <Col xs="8">
          <CardBody>
            <address>
              {complex.address}<br />
              <strong>{`${complex.city}, ${complex.zip_code}`}</strong>
            </address>
            <ComplexCount complex={complex} />
          </CardBody>
        </Col>
        <Col xs="4">
          <Figure>
              <Img src={imageService.getImageUrlByFormat(dataMedia?.complexes[0]?.photo[0]) || COMPLEX_ICON} alt="" />
          </Figure>
        </Col>
      </Row>
      <Button
        onClick={() => history.push(getRouteWithId(COMPLEX_DETAIL_PATH, complex.urlKey))}
        className="complexDiscover"
        color="primary"
      >
        <span className="Text">{intl.formatMessage({ id: "discover_agency_button" })}</span> <Icon
        name="ChevronRight" />
      </Button>
    </Card>
  );
}

export default MapComplexCard;
