import React, { FunctionComponent, ReactElement } from "react";
import { formatNumber } from "../../utils/utilFunctions";
import { CardText } from "reactstrap";

interface PriceProps {
  price: number;
  decimal?: number;
  currency?: string;
  children?: ReactElement;
  disabled?: boolean;
}

const Price: FunctionComponent<PriceProps> = ({
  price,
  decimal = 2,
  currency = "€",
  children,
  disabled,
}): ReactElement => {
  return (
    <CardText className={`amount ${disabled ? "text-muted" : ""}`}>
      <strong className="Price">
        {formatNumber(price, decimal)}
        {currency && (
          <small> {currency}</small>
        )}
      </strong>
      {children}
    </CardText>
  );
}

export default Price;