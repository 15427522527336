/**
 * File listing every translated label in French
 */

const FrenchMessages = {
  welcome: "Bienvenue sur le site des Résidences !",
  to_components: "Aller à la page des composants",
  no_result: "Aucun résultat",
  close: "Fermer",
  discover: "Découvrir",
  edit: "Modifier",
  save: "Sauvegarder",
  send: "Envoyer",
  cancel: "Annuler",
  print: "Imprimer",
  disconnect: "Déconnexion",
  // Login section
  login: "Connexion à votre espace locataire",
  login_description: "Votre espace locataire évolue ! Connectez-vous pour accéder à vos informations personnelles, vos quittances, vos documents...",
  login_email: "E-mail",
  login_password: "Mot de passe",
  login_forgot_password: "Mot de passe oublié ?",
  login_btn: "Se connecter",
  forgot_password_modal_title: "Mot de passe oublié",
  forgot_password_modal_text: "Veuillez saisir vos informations pour recevoir par Email votre mot de passe",
  forgot_password_modal_btn: "Valider",
  forgot_password_modal_success: "Un mail de réinitialisation vous a été envoyé",
  reset_password_success: "Votre mot de passe a été réinitialisé",
  create_account: "Créer votre espace locataire",
  create_account_last_name: "Nom",
  create_account_first_name: "Prénom",
  create_account_contract_number: "123456789",
  create_account_contract_number_popover: "Votre numéro de contrat est disponible sur toutes vos quittances. Merci d’indiquer uniquement le numéro et non le L/.",
  create_account_email: "E-mail",
  create_account_confirm_email: "Confirmation de l'e-mail",
  create_account_password: "Mot de passe",
  create_account_confirm_password: "Confirmation du mot de passe",
  create_account_conditions: "En soumettant ce formulaire, j’accepte que mes informations soient utilisées dans le cadre de ma demande et de la relation commerciale qui pourrait en découler.",
  create_account_water: "Les informations recueillies par Les résidences dans le cadre de la télérelève des compteurs d’eau font l’objet d’un traitement informatique. Si vous acceptez que vos données soient transmises au prestataire d'eau pour la mise à disposition sur votre espace personnel du portail Les résidences, merci de cocher la case ci-contre.",
  create_account_btn: "S'inscrire",
  create_account_btn_modal: "Création de compte",
  create_account_confirmation_email_sent: "Votre compte a bien été crée. Un mail de confirmation va vous être envoyé. Pensez à regarder dans vos courriers indésirables",
  create_account_confirmation_success: "Votre compte a été validé avec succès !",
  or: "ou",
  // Reset password
  invalid_password: "Le mot de passe doit contenir au moins 8 caractères (une majuscule,un chiffre et un caractère spécial)",
  reset_password: "Veuillez saisir votre nouveau mot de passe",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmation du mot de passe",
  error_matching: "Votre confirmation ne correspond pas à votre nouveau mot de passe",
  confirmation: "Confirmer",
  validation_forgotten_password: "Un mail vous a été envoyé avec un lien vous permettant de réinitiliser votre mot de passe",
  // Offers
  offers_button_application: "Candidature spontanée",
  offers_start_date: "Poste à pourvoir à partir de {date}",
  offers_see_offer: "Voir l'offre",
  // Offer detail
  offer_detail_apply: "Postuler",
  offer_detail_job_card: "Fiche métier",
  offer_detail_article_read_time: "{count, plural, =0 {Moins d'une minute de lecture} one {1 minute de lecture} other {# minutes de lecture}}",
  // Form errors
  mandatory_field: "Champ obligatoire",
  invalid_email: "Veuillez renseigner une adresse mail valide",
  invalid_confirm_email: "Les e-mails saisis ne sont pas identiques",
  invalid_phone: "Veuillez renseigner un numéro de téléphone valide",
  invalid_price_amount: "Veuillez renseigner un montant supérieur à 0€ et inférieur à 3000€",
  invalid_confirm_password: "Les mots de passe saisis ne sont pas identiques",
  //Navbar Links
  property_page: "Nos Logements",
  realization_page: "Nos Réalisations",
  suppliers_page: "Nos Fournisseurs",
  articles_page: "Nos Actualités",
  join_us_page: "Nous rejoindre",
  hello: "Bonjour",
  // Sidemenu links
  dashboard_page: "Tableau de bord",
  personal_information_page: "Mes informations personnelles",
  my_rent_page: "Ma quittance",
  my_payment_rent_page: "Récapitulatif de mon paiement",
  my_documents_page: "Mes documents",
  my_tickets_page: "Mes demandes",
  my_housing_page: "Mon logement",
  surveys_page: "Enquêtes",
  your_complex_page: "Ma résidence",
  // Join us
  join_us_human_resources: "Notre politique RH",
  join_us_1: "Forts de près de 600 collaborateurs, nous recherchons toutes sortes de profils, que ce soit en maîtrise d’ouvrage (des responsables d’opérations immobilières pour nos constructions ou nos réhabilitations, des responsables technique immobilier), en proximité (des responsables patrimoine, des conseillers clientèle, des conseillers sociaux ou des résid’Managers¹) ou en fonctions support (comptabilité, juridique, informatique, RH).",
  join_us_2: "Les principales qualités que nous recherchons : la motivation et la capacité à rejoindre une entreprise en croissance, tournée vers le social et l’innovation, engagée dans une démarche RSE² ambitieuse.",
  join_us_3: "N’hésitez pas à consulter nos offres d’emploi sur la plateforme Welcome To The Jungle et sur LinkedIn.",
  join_us_4: "Manager de proximité assurant le suivi, l’animation et la gestion de l’entretien des immeubles dont il a la charge.",
  join_us_5: "Responsabilité sociétale de l’entreprise",
  // Dashboard
  db_title: "Mon tableau de bord",
  db_your_appointments: "Vos rendez-vous",
  db_no_appointment: "Vous n'avez aucun rendez-vous planifié",
  db_rent_current_due_date: "Echéance en cours",
  db_rent_due_date: "Echéance",
  db_rent_paid: "Payé",
  db_rent_to_pay: "A payer",
  db_rent_invoices: "Accéder aux factures",
  db_current_tickets: "{count, plural, =0 {Aucune demande en cours} one {1 demande en cours} other {# demandes en cours}}",
  db_last_rent: "Votre dernière quittance",
  db_rent_of: "Quittance du ",
  db_see_all: "Voir tout",
  db_contacts: "Vos contacts privilégiés",
  db_caretaker: "Votre Résid'Manager",
  db_phone: "Téléphone",
  db_mobile: "Portable",
  db_email: "Email",
  db_address: "Adresse",
  db_address_default: "Service marketing et expérience client",
  // Housing page
  housing_property_highlight: "Logements à la une",
  // Personal information page
  personal_information_identity_sub_title: "Mon compte",
  personal_information_options_sub_title: "Mes options",
  personal_information_title: "Mes informations personnelles",
  personal_information_lastname: "Nom",
  personal_information_firstname: "Prénom",
  personal_information_email: "E-mail",
  personal_information_phone: "Téléphone",
  personal_information_tenant_number: "Numéro de locataire",
  personal_information_entry_date: "Date d'entrée dans le logement",
  personal_information_edit_password: "Modifier le mot de passe",
  personal_information_save_password: "Enregistrer le mot de passe",
  personal_information_current_password: "Mot de passe actuel",
  personal_information_new_password: "Nouveau mot de passe",
  personal_information_confirm_password: "Confirmer le mot de passe",
  personal_information_er_consent: "Quittances de loyer dématérialisées",
  personal_information_er_true: "Vous avez opté pour les quittances electroniques qui vous seront envoyées par email",
  personal_information_er_false: "Vous avez opté pour les quittances papiers qui vous seront envoyées par courrier",
  // My tickets page
  ticket_request: "Faire une demande",
  tickets_follow_up: "Suivi des demandes",
  tickets_opened: "{count, plural, =0 {Demandes en cours} one {1 demande en cours} other {# demandes en cours}}",
  tickets_closed: "Mes 10 dernières demandes",
  tickets_opened_none: "Aucune demande en cours",
  tickets_closed_none: "Aucun historique",
  tickets_title: "Titre",
  tickets_description: "Description",
  // Opinion form
  opinion_title: "Votre avis nous intéresse",
  opinion_form_firstname: "Prénom",
  opinion_form_lastname: "Nom",
  opinion_form_email: "E-mail",
  opinion_form_subject: "Sujet",
  opinion_form_message: "Message",
  opinion_form_submit: "Soumettre",
  opinion_success: "Votre avis a bien été soumis.",
  opinion_email_required: "L'adresse e-mail est obligatoire.",
  opinion_email_invalid: "Veuillez indiquer une adresse e-mail valide.",
  opinion_lastName_required: "Le nom est obligatoire.",
  opinion_firstName_required: "Le prénom est obligatoire.",
  opinion_message_required: "Le message est obligatoire.",
  // Create ticket
  your_ticket: "Votre demande",
  stepper_your_ticket: "Votre demande",
  stepper_importance: "Importance",
  stepper_description: "Description",
  stepper_contact: "Mode de contact privilégié",
  stepper_localization: "Localisation",
  stepper_type: "Type",
  stepper_relationship: "Lien de parenté",
  stepper_situation: "Situation",
  stepper_transfer: "Mutation",
  stepper_schedule: "Plage horaire souhaitée (sous réserve de disponibilité)",
  stepper_next_step: "étape suivante",
  stepper_previous: "Retour",
  stepper_validate: "Valider",
  stepper_modal_text: "Votre demande a bien été envoyée",
  stepper_modal_button: "Retour à la liste des demandes",
  // Step Category
  leak: "Fuite",
  blocked_pipes: "Canalisations bouchées",
  hot_water_failure: "Panne eau chaude / chauffage",
  insect_or_pest: "Insectes ou nuisibles",
  blackout: "Panne d'électricité",
  malfunction_in_common_areas: "Dysfonctionnement dans les parties communes",
  new_occupant_in_the_accommodation: "Nouvel occupant dans le logement",
  request_for_transfer: "Demande de mutation",
  callback_request: "Demande de rappel",
  // Step category titles
  leak_title: "Signaler une fuite dans mon logement",
  blocked_pipes_title: "Signaler un bouchon ou un refoulement",
  hot_water_failure_title: "Signaler une panne",
  insect_or_pest_title: "Signaler des insectes ou des nuisibles dans mon logement",
  blackout_title: "Signaler une panne électrique dans mon logement",
  malfunction_in_common_areas_title: "Signaler un dysfonctionnement dans les parties communes",
  new_occupant_in_the_accommodation_title: "Déclarer un nouvel occupant dans mon logement",
  request_for_transfer_title: "Demander une mutation",
  callback_request_title: "Être recontacté par un conseiller",
  // Step localization
  leak_localization_kitchen: "Cuisine",
  leak_localization_bathroom: "Salle d'eau / WC",
  leak_localization_other_room: "Autre pièce (salon, chambre, etc.)",
  blocked_pipes_localization_kitchen_sink: "Evier de cuisine",
  blocked_pipes_localization_bath: "Baignoire / Douche / Lavabo",
  blocked_pipes_localization_toilet: "Toilettes / WC",
  blackout_localization_one: "Un seul équipement (prise, interrupteur, etc.)",
  blackout_localization_one_room: "Les équipements d'une même pièce",
  blackout_localization_general: "Panne générale dans le logement",
  malfunction_in_common_areas_localization_electric: "Panne électrique",
  malfunction_in_common_areas_localization_elevator: "Panne d'ascenseur",
  malfunction_in_common_areas_localization_intercom: "Panne d'interphonie",
  malfunction_in_common_areas_localization_smoke_extraction: "Trappe de désenfumage ouverte",
  malfunction_in_common_areas_localization_main_door: "Dysfonctionnement de la porte du hall",
  malfunction_in_common_areas_localization_parking: "Dysfonctionnement des accès au parking",
  malfunction_in_common_areas_localization_squat: "Squat",
  // Step localization titles
  leak_localization_kitchen_title: "localisée dans la cuisine",
  leak_localization_bathroom_title: "localisée dans la salle d'eau / WC",
  leak_localization_other_room_title: "localisée dans une autre pièce (salon, chambre, etc.)",
  blocked_pipes_localization_kitchen_sink_title: "au niveau de l'évier de la cuisine",
  blocked_pipes_localization_bath_title: "au niveau de la baignoire / douche / lavabo",
  blocked_pipes_localization_toilet_title: "au niveau des toilettes / WC",
  malfunction_in_common_areas_localization_electric_title: ": Panne électrique",
  malfunction_in_common_areas_localization_elevator_title: ": Panne d'ascenseur",
  malfunction_in_common_areas_localization_intercom_title: ": Panne d'interphonie",
  malfunction_in_common_areas_localization_smoke_extraction_title: ": Trappe de désenfumage ouverte",
  malfunction_in_common_areas_localization_main_door_title: ": Dysfonctionnement de la porte du hall",
  malfunction_in_common_areas_localization_parking_title: ": Dysfonctionnement des accès au parking",
  malfunction_in_common_areas_localization_squat_title: ": Squat",
  // Step type
  hot_water_failure_type_hot_water_tank: "Chaudière ou ballon d'eau individuel",
  hot_water_failure_type_heater: "Chauffage ou eau chaude en collectif",
  insect_or_pest_type_cockroaches: "Cafards",
  insect_or_pest_type_bugs: "Punaises",
  insect_or_pest_type_rodents: "Rongeurs",
  insect_or_pest_type_pigeons: "Pigeons",
  // Step type titles
  hot_water_failure_type_hot_water_tank_title: "de la chaudière ou du ballon d'eau individuel",
  hot_water_failure_type_heater_title: "du chauffage ou de l'eau chaude en collectif",
  // Step importance
  drip: "Goutte à goutte",
  discontinuous_thread: "Filet discontinu",
  big_flow: "Gros débit",
  // Step relationship
  new_occupant_in_the_accommodation_relationship_partner: "Conjoint, concubin ou pacsé",
  new_occupant_in_the_accommodation_relationship_child: "Enfant",
  new_occupant_in_the_accommodation_relationship_sibling: "Parent, frère, soeur",
  new_occupant_in_the_accommodation_relationship_none: "Aucune parenté",
  // Step situation
  new_occupant_in_the_accommodation_situation_dependent: "À charge",
  new_occupant_in_the_accommodation_situation_custody: "Garde alternée",
  new_occupant_in_the_accommodation_situation_visiting: "Droit de visite",
  // Step transfer
  request_for_transfer_number_of_person_question: "Combien de personnes occuperont le logement ?",
  request_for_transfer_number_of_person: "{count, plural, =0 {Personne n'occupera le logement} one {1 personne occupera le logement} other {# personnes occuperont le logement}}",
  request_for_transfer_id_question: "Avez-vous créé une demande de logement social ?",
  request_for_transfer_id: "Numéro unique de demande",
  request_for_transfer_request: "Faire une demande de logement social",
  // Step description
  describe_your_ticket: "Décrivez votre demande",
  your_comments_leak: "Avez-vous pensé à fermer le robinet d'arrivée d'eau ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_blocked_pipes: "L’incident est-il survenu suite à une intervention de votre part ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_hot_water_failure: "Êtes-vous le seul concerné par ce dysfonctionnement ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_insect_or_pest: "Les insectes ou les nuisibles sont-ils présents dans un seule pièce ou dans tout le logement ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_blackout_1: "Avez-vous vérifié qu'aucun fusible de votre tableau électrique n'a disjoncté ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_blackout_2: "Êtes-vous le seul concerné ? Y a-t-il de la lumière dans les parties communes ou dans le quartier ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_malfunction_in_common_areas: "Avez-vous pensé à contacter votre gardien ou le service d'astreinte ?\nDonnez-nous plus de précisions sur le problème rencontré...",
  your_comments_new_occupant_in_the_accommodation: "Avez-vous une information particulière à nous transmettre concernant ce nouvel occupant ?\nPensez à nous transmettre tout document justifiant de la situation du nouvel occupant (acte de naissance, acte de mariage, jugement de divorce, etc.).",
  your_comments_request_for_transfer: "Donnez-nous quelques précisions sur votre demande (secteur souhaité, type de logement, étage bas/élevé, motif de la demande, etc.).",
  your_comments_callback_request: "Avez-vous vérifié que vos coordonnées dans l’onglet ‘Informations personnelles’ sont exactes ?\nDonnez-nous plus de précisions sur l’objet de votre demande...",
  add_documents: "+ Ajouter des documents",
  billing: "J'ai bien noté que les travaux sont susceptibles de m'être refacturés selon le diagnostic établi par le technicien.<br/>En cochant cette case, j'accepte d'être facturé au tarif en vigueur le cas échéant.",
  // Step contact
  contact_me: "Contactez-moi",
  recontact: "Comment souhaitez-vous être recontacté ?",
  by_phone: "Par téléphone",
  by_mail: "Par mail",
  callback_request_edit: "Modifier le mail",
  callback_request_save: "Valider le mail",
  // Step schedule
  callback_request_schedule_12: "12h00 - 14h00",
  callback_request_schedule_14: "14h00 - 16h00",
  callback_request_schedule_16: "16h00 - 18h00",
  // My housing
  my_housing: "Mon logement",
  contract_number: "Numéro de contrat",
  water_consumption: "Ma consommation d'eau",
  water_location_other: "Autres",
  water_location_office: "Bureau",
  water_location_cellar: "Cave",
  water_location_storeroom: "Cellier",
  water_location_bedroom: "Chambre",
  water_location_corridor: "Couloir",
  water_location_kitchen: "Cuisine",
  water_location_boxroom: "Débarras",
  water_location_entrance: "Entrée",
  water_location_outside: "Extérieur",
  water_location_garage: "Garage",
  water_location_gpa: "Gaine palière",
  water_location_lobby: "Halls d'entrées",
  water_location_landing: "Palier",
  water_location_closet: "Placard",
  water_location_bathroom: "Salle de bain",
  water_location_living_room: "Salle de séjour",
  water_location_basement: "Sous-sols",
  water_location_crawl_space: "Vides sanitaires",
  water_location_restroom: "WC",
  water_location_unknown: "Non renseigné",
  water_type_hot: "Eau chaude",
  water_type_cold: "Eau froide",
  water_more_informations: "Plus d'informations : ",
  my_housing_my_insurance: "Mon assurance",
  my_housing_add_insurance:"+ Ajouter l'assurance",
  no_data: "Aucune information disponible",
  my_housing_tuto: "Tutoriel pour l'entretien de mon logement",
  // My rent
  rent_empty: "Vous êtes à jour sur vos paiements",
  rent_debt_details: "Détails des dettes",
  rent_access_bills: "Accéder aux factures",
  rent_direct_debit: "Prélèvement automatique",
  rent_direct_debit_description: "Simplifiez-vous la vie, gagnez du temps et soyez certain de ne pas oublier votre loyer ! Choisissez le prélèvement automatique, un moyen de payer en toute sécurité. Imprimez et remplissez le document ci-dessous puis adressez-le à votre gardien ou à votre agence.",
  rent_direct_debit_file: "Télécharger le document",
  rent_online_payment: "Paiement en ligne",
  rent_online_payment_description: "Vous préférez régler en ligne à chaque fois ? C’est facile et rapide : munissez-vous de votre carte bancaire, cliquez sur le lien ci-dessous et laissez-vous guider.",
  rent_my_balance: "Mon solde",
  rent_my_balance_to_pay: "Ma dette à payer",
  rent_due_date: "Echéance",
  rent_name_lastname: "Nom et prénom du locataire",
  rent_my_quitance: "Ma quittance du mois",
  rent_submit_payment: "Procéder au paiement",
  rent_history: "Mes récapitulatifs",
  rent_debit: "Débit",
  rent_credit: "Crédit",
  rent_your_payment: "Votre paiement",
  rent_quitance: "Quittancement",
  rent_debts: "Mes dettes",
  my_contracts: "Mes contrats",
  // My documents
  my_receipts: "Mes quittances",
  my_contract: "Mon contrat",
  my_regularization_notices: "Mes avis de régularisation",
  no_document_available: "Aucun document disponible",
  // Surveys
  surveys_title: "Votre avis nous intéresse",
  no_survey: "Aucune enquête en cours. Pour plus d'informations, vous pouvez contacter le service marketing et expérience client au 09.69.370.370 de 08:00 à 18:00.",
  survey: "Enquête",
  survey_from: "Du ",
  survey_to: " au ",
  survey_answer: "Répondre",

  //Homepage Links
  to_all_articles_page: "Voir tous les articles",
  //Homepage Search
  search: "Rechercher",
  buy_tab: "Acheter",
  rent_tab: "Louer",
  location: "Localisation",
  properties_types: "Types de bien ?",
  typologie_types: "Typologie",
  service: "Service",
  where: "Où",
  //Homepage Text
  max_budget: "Budget max",
  advanced_search: "Recherche avancée",
  //Agency Page
  apartment_count: "{count, plural, =1 {1 appartement} other {# appartements}}",
  house_count: "{count, plural, =1 {1 maison} other {# maisons}}",
  parking_count: "{count, plural, =1 {1 parking} other {# parkings}}",
  room_multiple: "De {min} à {max} pièces",
  room_simple: "{count, plural, =1 {1 pièce} other {# pièces}}",
  discover_agency_button: "Je découvre",
  //Articles links
  reading_time: " minutes de lecture",
  search_by_keyword: "Recherche par mot clé",
  // Join us
  join_us_card_title: "Nos offres d'emploi",
  join_us_article: "L'article",
  join_us_offers_find: "Retrouvez toutes nos offres d'emploi et postulez en ligne !",
  join_us_offers_external: "Retrouvez également toutes nos offres d'emploi sur ",
  //Articles page
  articles_click: "Cliquer pour découvrir nos informations",
  articles_page_maintitle: "Notre Actualité",
  articles_page_subTitle: "Nos derniers articles",
  podcasts_page_maintitle:"Nos Podcasts",
  podcasts_page_subTitle:"Nos derniers podcasts",
  featured_articles: "Articles à la une",
  article_podcast_tag: "Podcast",
  podcasts_category:"Podcasts",
  press_category: "Espace Presse",
  // Press
  press_media: "Documents à disposition",
  press_logo: "Logo blanc",
  press_charte: "LRYE charte 2020",
  press_logo_cmjn: "LRYE logo CMJN",
  press_patrimoine: "La carte de notre parc immobilier",
  press_plaquette: "Notre entreprise en un clin d’œil",
  // Article detail
  article_detail_back: "Retour à la liste d'articles",
  //Agency page
  our_agencies: "Nos agences",
  //Discover us page
  our_presidency: "Notre présidence",
  our_supervisory_board: "Notre conseil de surveillance",
  our_board: "Notre directoire",
  // Conatct form : Property detail
  contact_form_title: "Demande d'informations",
  contact_form_number_of_residents: "Nombre de personnes dans le foyer",
  contact_form_monthly_income: "Ressources nettes mensuelles",
  contact_form_previous_tax_income: "Revenu fiscal de référence N-2",
  contact_form_is_tenant: "Déjà locataire chez nous",
  contact_form_is_tenant_yes: "Oui",
  contact_form_is_tenant_no: "Non",
  contact_form_professional_situation: "Situation professionnelle",
  contact_form_professional_situation_working: "En activité",
  contact_form_professional_situation_jobless: "Sans emploi",
  contact_form_professional_situation_undefined: "Non renseignée",
  contact_form_unique_number: "NUR",
  contact_form_submit: "Contacter",
  contact_form_phone_number: "Téléphone",
  contact_form_success: "Mail de contact envoyé avec succès.",
  contact_form_error: "Echec d'envoi, une erreur s'est produite !",
  // Property detail
  share_property: "Partager le logement",
  share_from: "E-mail de l'expéditeur",
  share_to: "E-mail du destinataire",
  share_subject: "Objet",
  share_message: "Message",
  share_message_default: "Voici une annonce qui pourrait t'intéresser.",
  share_success: "Mail envoyé avec succès.",
  plan: "Plans du logement",
  download_plan: "Télécharger le plan",
  // properties
  see_map: "Visualiser sur la carte<br />notre parc de logement",
  // Properties map
  properties_map_bedroom: "{count, plural, =0 {} one {1 chambre} other {# chambres}}",
  properties_map_bathroom: "{count, plural, =0 {} one {1 salle de bain} other {# salles de bain}}",
  result: "{count, plural, =0 {Aucun résultat} one {résultat} other {résultats}}",
  back: "Retour",
  see_property: "Voir les détails",
  buy: "Acheter",
  rent: "Louer",
  apartment: "Appartement",
  house: "Maison",
  shop: "Commerce",
  parking: "Parking",
  numberOfRoom: "{count, plural, =0 {} one {1 pièce} other {# pièces}}",
  numberOfPerson: "{count, plural, =0 {} one {1 personne} other {# personnes}}",
  or_more: "ou plus",
  project_question: "Quel est votre projet ?",
  type_question: "Quel type de bien ?",
  room_question: "Nombre de pièces ?",
  person_question: "Nombre de personnes dans le foyer ?",
  max_rent_question: "Quel est votre loyer max ?",
  max_budget_question: "Quel est votre budget max ?",
  data_not_stored: "* Les données saisies à cet endroit ne sont pas stockées",
  conditions: "Tous nos logements sont conditionnés à des plafonds de ressource<br />(salaires, allocations, etc...)",
  maxRent: "Loyer Max",
  maxBudget: "Budget Max",
  //Footer
  my_tenant_space: "Mon espace locataire",
  copyright: "© 2021 Copyright - Les Résidences Yvelines Essonne",
  privacy_policy: "Politique de confidentialité",
  legal_mentions: "Mentions légales",
  //Eligibility Form
  form_question_where_to_live: "Dans quelle ville souhaitez-vous habiter ?",
  form_question_where_to_live_placeholder: "Tapez le nom de ville",
  form_question_status: "Vous vivez : Célibataire ou En couple",
  form_question_handicap: "Vous êtes dans une situation particulière ?",
  form_question_handicap_explained: "Oui : en situation de handicap ou dans un foyer accueillant une personne handicapée.",
  form_question_num_of_dependents: "Vous avez des personnes à charge, combien ?",
  form_question_num_of_dependents_placeholder: "Nombre de personne à charge",
  form_question_income: "Votre revenu fiscal de référence ?",
  form_question_income_placeholder: "Votre revenu",
  form_stepper_button_next: "Etape suivante",
  form_stepper_button_prev: "Retour",
  form_stepper_button_submit: "Terminer le test",
  error_msg_positive_number: "Ce champ doit être supérieur ou égal à 0",
  error_msg_positive_number_or_0: "Ce champ doit être supérieur ou égal à 0",
  error_msg_number_no_decimal: "Ce champ doit être un entier",
  form_answer_status_single: "Célibataire",
  form_answer_status_In_Couple: "En couple",
  general_form_answer_yes: "Oui",
  general_form_answer_no: "Non",
  form_result_eligible_message: "Vous êtes éligible à un logement ",
  form_result_eligible_message_fail: "Vous n'êtes ni éligible à un logement Social ni Intermédiaire ",
  form_result_eligible_type_social: "Social* ",
  form_result_eligible_type_social_info: "(à loyer modéré)",
  form_result_eligible_type_intermediate_info: "plus d'information sur ",
  form_result_eligible_type_intermediate: "Intermédiaire ",
  form_result_eligible_no_housing: "cependant, aucune logement Intermédiaire n'est disponible dans la ville sélectionnée ",
  form_question_youngCouple: "Jeune couple ?",
  form_info_handicap: "En situation de handicap ou dans un foyer accueillant une personne handicapée",
  form_info_youngCouple: "Couple marié, pacsé ou concubin dont la somme des âges révolus est inférieure ou égale à 55 ans et sans personne à charge.",
  form_info_income: "La sommes de tous les revenus fiscaux de référence de toutes les personnes occupant le logement ( voir sur votre avis d'imposition).",
  form_result_link_discover_more: "Découvrez notre offre de logement",
  form_result_success_benefits_link: "www.demande-logement-social.gouv.fr",
  form_result_success_benefits_link_text: "Pour en bénéficier penser à remplir votre dossier en ligne sur :",
  form_result_success_benefits_link_info: "Sous réserve de validation de votre dossier dans l'une de nos agences.",
  form_result_eligible_type_intermediate_link_text: "le logement intermédiaire",
  error_message_community_required: "Vous devez choisir une ville",
  error_message_community_wrong_city: "Cette ville n'appartient pas au patrimoine LRYE",
  // FAQ
  faq: "FAQ",
  faq_placeholder: "Posez votre question ...",
  faq_frequent_topic: "Thèmes fréquents",

  // Strapi error
  Auth_form_error_blocked: "Le site est actuellement indisponible.Merci de réessayer ultérieurement",
  Auth_form_error_code_provide: "Le code est incorrect.",
  Auth_form_error_confirmed: "L'e-mail de votre compte n'est pas confirmé.",
  Auth_form_error_email_invalid: "Cette e-mail n'est pas valide.",
  Auth_form_error_email_provide: "Votre identifiant est manquant.",
  Auth_form_error_email_taken: "Cette adresse email n'est pas disponible.",
  Auth_form_error_invalid: "Votre identifiant ou mot de passe est incorrect.",
  Auth_form_error_params_provide: "Les informations sont incorrectes.",
  Auth_form_error_password_format: "Votre mot de passe ne peut pas contenir trois fois le symbole `$`.",
  Auth_form_error_password_local: "Ce compte n'a pas de mot de passe.",
  Auth_form_error_password_matching: "Les mots de passe ne sont pas identique.",
  Auth_form_error_password_provide: "Votre mot de passe est manquant.",
  Auth_form_error_password_invalid: "Votre mot de passe actuel est incorrecte.",
  Auth_form_error_ratelimit: "Trop de tentatives, veuillez réessayer dans une minute.",
  Auth_form_error_user_not_exist: "Cette e-mail n'existe pas.",
  Auth_form_error_username_taken: "Ce nom est déjà utilisé.",
  Auth_form_error_confirm: "Le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas.",
  Auth_form_error_lastName_provide: "Votre nom est manquant.",
  Auth_form_error_firstName_provide: "Votre prénom est manquant.",
  Auth_form_error_contractNumber_provide: "Votre numéro de contrat est manquant.",
  Auth_form_error_conditions_provide: "Veuillez accepter les conditions.",
  Auth_form_error_contract_notFound: "Ces informations de contrat ne sont pas correctes.",
  Auth_form_error_tenantNumber_taken: "Ces informations correspondent déjà à un compte locataire existant.",
  Property_not_found: "La propriété ayant {value} comme identifiant est introuvable",
  error_400: "Mauvaise requête",
  error_401: "requête interdite",
  error_403: "Requête non autorisée",
  error_404: "Ressource introuvable",
  error_500: "Erreur interne au serveur",
  error_504: "Aucune réponse reçue du serveur",

  // Apollo error
  offer_query: "Erreur lors de la récupération des offres",
  tag_query: "Erreur lors de la récupération des tags",
  tag_category_query: "Erreur lors de la récupération des catégories de tags offres",
  homepage_query: "Erreur lors de la récupération de la page `Home`",
  complex_detail_query: "Erreur lors de la récupération du détail de la résidence",
  property_detail_query: "Erreur lors de la récupération du détail du logement",
  properties_photo_query: "Erreur lors de la récupération des photos des logements",
  offer_detail_query: "Erreur lors de la récupération du détail de l'offre",
  article_detail_query: "Erreur lors de la récupération du détail de l'article",
  articles_query: "Erreur lors de la récupération des articles",
  articles_highlight_query: "Erreur lors de la récupération des articles mis en avant",
  categories_query: "Erreur lors de la récupération des catégories",
  article_highlight_query: "Erreur lors de la récupération des articles à la une",
  footer_query: "Erreur lors de la récupération des données du footer",
  editorial_group_query: "Erreur lors de la récupération du groupe éditorial",
  editorial_page_query: "Erreur lors de la récupération de la page éditorial",
  faq_tag_query: "Erreur lors de la récupération des tags FAQ",
  faq_query: "Erreur lors de la récupération de la FAQ",
  agency_query: "Erreur lors de la récupération du détail de l'agence",
  podcasts_query: "Erreur lors de la récupération des podcasts",
  discover_query: "Erreur lors de la récupération de la page 'Nous découvrir'",
  our_commitments_query: "Erreur lors de la récupération de la page 'Nos engagements'",
  connected_housings_query: "Erreur lors de la récupération de la page 'Nos logements connectés'",
  our_agencies_query: "Erreur lors de la récupération de la page 'Nos agences'",
  agencies_query: "Erreur lors de la récupération des agences",
  privacy_policy_query: "Erreur lors de la récupération de la politique de confidentialité",
  legal_mentions_query: "Erreur lors de la récupération des mentions légales",

  // Success message
  success_personal_information_email_updated: "Votre adresse e-mail a été mise à jour",
  success_personal_information_phone_updated: "Votre numéro de téléphone a été mis à jour",
  success_personal_information_password_updated: "Votre mot de passe a été mis à jour",
  //Property Form : Modal Informations
  property_form_modal_title: "Création de votre numéro unique",
  property_form_modal_description: "Si vous disposez déja de ce numéro, merci de nous le renseigner il facilitera le traitement de votre dossier.",

  //Property Details
  property_photos_info: "Photos non contractuelles",
  property_location_badge:"Location",
  property_no_fees:"Hors charges",
  property_with_fees: "Charges comprises",
  property_call_center:"Centre d'appel ",
  property_info_form:"Demande d'information",
  property_info_district:"Informations sur le quartier",
  property_number_of_bedroom:"Chambres",
  property_number_of_bathroom:"Salles de bain",
  property_surface:"Surface",
  property_has_parking:"Parking",
  property_has_elevator:"Ascenseur",
  property_ext_space:"Espace extérieur",
  property_district:"Quartier",
  property_floor: "Étage",
  property_type_house:"Maison",
  property_type_terrain:"Terrain",
  property_type_garage:"Garage",
  property_type_desk:"Bureau",
  property_type_locals:"Locaux Collectif",
  property_type_lodge:"Loge",
  property_type_collective:"Logement Collectif",
  property_room_info:"Pièces",
  property_has_balcon:"Balcon",
  property_has_terrace:"Terrasse",
  property_has_basement:"Cave",
  property_has_storage:"Rangements",
  property_has_bike_parking:"Local Vélos",
  property_has_garden:"Jardin",
  property_has_playground:"Aire Jeux",
  //Go back buttons
  property_back_button:"Retour aux résultats",

  // Our properties
  properties_top_heading: "Comment pouvons-nous vous aider dans votre projet ?",
  properties_search_info: "Tous nos logements sont conditionnés à des critères de ressources",
  properties_eligibility_heading: "Logement social - Testez votre éligibilité",
  //Types de biens
  property_type_L: "Appartement",
  property_type_M: "Maison",
  property_type_C: "Commerce",
  property_type_G: "Parking",
  property_type_T: "Terrain",
  property_type_O: "Loge",
  property_type_X: "Locaux",
  property_type_A: "Emplacement Ant",
  property_type_D: "Locaux associatifs divers",
  property_type_E: "Emplacement publicitaire",
  property_type_P: "Logement pro",
  property_type_N: "Terrasse",
  property_type_F: "Foyer",
  property_type_R: "Residence",
  property_type_B: "Bureau",
  property_type_H: "Local Technique",
  property_type_Z: "Refacturation charges",
  property_type_Y: "Emplacement locaux de chantier",
  //Home filters
  home_type_filter:"Type de bien",
  home_typology_filter:"Typologie",
  home_localisation_filter:"Localisation",
  home_number_of_rooms_filter: "Nombre de pièces",
  //Offers Application Form
  simple_application:"Candidature Simplifiée",
  application_description: "Description",
  application_add_cv : "+ Ajouter un CV",
  application_add_motivational_letter : "+ Ajouter une lettre de motivation",
  application_error_email:"Veuillez remplir les champs obligatoires",
  application_success: "Candidature envoyée avec succès",
  //Attestation d'Assurance
  insurance_email_success:"Votre attestation est bien été envoyée à votre agence",
  // Our housing stock view
  our_housing_stock_title: "Notre parc immobilier",
  // Nous contacter
  your_request_concerns: "Votre demande concerne",
  communication_press: "Communication / Presse",
  marketing_and_ux: "Service marketing et expérience client",
  attached_file: "Pièce jointe",
  add_attached_file: "+ Ajouter une pièce jointe",
  contact_form_error_email: "Veuillez remplir les champs obligatoires",
  spontaneous_application: "Candidature spontanée",

  home_card_know_more: "En savoir plus",
  phone_tenant_center_title: "Le centre d'appels locataires",
  phone_tenant_center: "Une question sur votre logement ? Votre dossier locataire ? Contactez le centre de relations locataire du lundi au vendredi, de 8h à 18h au <strong>09 69 370 370</strong> (service gratuit + prix d'appel).",
  phone_on_call_title: "L'assistance Les Résidences",
  phone_on_call: "Pour toute urgence, en dehors des horaires d'ouverture des loges de vos gardiens, contactez l'assistance au <strong class='flex-nowrap d-inline-block'>0 820 89 10 10</strong>.<br/><br/>" +
    "(0,12 € la minute depuis un poste ﬁxe - Depuis un portable : tarif selon opérateur)<br/><br/>" +
    "<strong>Semaine</strong> : de 18h à 8h<br/>" +
    "<strong>Week-end</strong> : du vendredi 18h au lundi 8h<br/>" +
    "<strong>Jours fériés</strong> : de la veille 18h au lendemain 8h",
  select_your_room: "Sélectionnez votre pièce",
  choose_your_equipment: "Choisissez votre équipement",
  in_charge_of: "À charge",
  of_lrye: "Des Résidences Yvelines Essonne",
  of_tenant: "Du locataire",

  // districts
  district: "Quartier",
  district_proximity: "Proximité",
  district_education: "Education",
  district_transport: "Transport",
  district_elementary_school: "Ecole primaire",
  district_middle_school: "Collège",
  district_high_school: "Lycée",
  district_health: "Santé",
  district_bus: "Bus",
  district_number_of_elementary_school: "{count, plural, =0 {} one {1 école primaire} other {# écoles primaires}}",
  district_number_of_middle_school: "{count, plural, =0 {} one {1 collège} other {# collèges}}",
  district_number_of_high_school: "{count, plural, =0 {} one {1 lycée} other {# lycées}}",
}

export default FrenchMessages
