import { gql } from "@apollo/client";
export const OUR_COMMITMENTS_QUERY = gql
  `query OurCommitments {
    ourCommitment {
      title
      content
      categories {
        title
        description
        url
        icon {
          name
          url
          formats
        }
      }
    }
  }`
