import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardHeader, CardTitle } from "reactstrap";
import Card from "../../atoms/Card";
import { FormattedMessage } from "react-intl";
import Icon from "../../atoms/Icon";
import { RENT_DIRECT_DEBIT_PDF } from "../../../assets/Assets";
import { documentService } from "../../../services/DocumentService";
import {history} from "../../../constants/History";
import {PAYEMENT_RENT_PATH} from "../../../constants/routes/RoutePaths";

interface DbPaymentMeanProps {
  selectedContract?: string;
}

const DbPaymentMean: FunctionComponent<DbPaymentMeanProps> = (): ReactElement => {
  const onSepaClick = () => {
    documentService.downloadFile(RENT_DIRECT_DEBIT_PDF);
  }

  const onOnlineClick = () => {
    history.push(PAYEMENT_RENT_PATH)
  }

  return (
    <>
      <div className="Three cursor-pointer" onClick={onSepaClick}>
        <Card rounded tag="a">
          <CardHeader>
            <CardTitle className="text-center">
              <FormattedMessage id="rent_direct_debit" />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="card-content">
              <Icon name="Bill" size="4" />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="Four cursor-pointer" onClick={onOnlineClick}>
        <Card rounded tag="a">
          <CardHeader>
            <CardTitle className="text-center">
              <FormattedMessage id="rent_online_payment" />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="card-content">
              <Icon name="BankCards" size="4" />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default DbPaymentMean;
