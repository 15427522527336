import React, { FunctionComponent } from "react";
import { PropertyPlan } from "../../interfaces/properties/PropertiesInterfaces";
import Card from "../atoms/Card";
import { CardBody, CardHeader, CardTitle } from "reactstrap";
import Button from "../atoms/Button";
import { imageService } from "../../services/ImageService";
import { useIntl } from "react-intl";

interface PlanProps {
  plan: PropertyPlan;
}

const Plan: FunctionComponent<PlanProps> = ({ plan }) => {
  const intl = useIntl();

  return (
    <Card className="p-3">
      <CardHeader>
        <CardTitle>{plan.name}</CardTitle>
      </CardHeader>
      <CardBody>
        <Button
          className="mx-1"
          href={imageService.getImageUrlByFormat(plan.pdf)}
          title={intl.formatMessage({ id: "download_plan" })}
          target="_blank"
          color="primary"
        />
      </CardBody>
    </Card>
  )
}

export default Plan;
