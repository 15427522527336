import React, { FunctionComponent } from "react"

// Styles : styles/atoms/figure

import Icon from "./Icon"

type FigureProps = {
  children?: JSX.Element
  className?: string
  icon?: string
  ratio?: string
  cap?: boolean
  sizeAuto?: boolean
  circle?: boolean
  occupation?: "50" | "60" | "70"  | "80" | "90"
  fill?: boolean;
}

const Figure: FunctionComponent<FigureProps> = ({
  children,
  className="",
  icon,
  ratio,
  cap,
  sizeAuto,
  circle,
  occupation,
  fill
}) => {
  const FigureClassName =
      (ratio ? " ratio--" + ratio : "") +
      (sizeAuto ? " auto" : "") +
      (cap ? " cap" : "") +
      (circle ? " circle" : "") +
      (occupation ? " occupation--" + occupation : "") +
      (fill ? " fill" : "") +
      (className ? " " + className : "")
  return (

    <figure className={`Figure ${FigureClassName}`}>
      {children}

      {icon &&
        <Icon name={icon}/>
      }
    </figure>
  )
}

export default Figure
