import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardHeader, CardTitle } from "reactstrap";
import { FormattedMessage } from "react-intl";
import List from "../atoms/List";
import { Ticket } from "../../interfaces/tickets/TicketsInterfaces";
import TicketComponent from "./TicketComponent";
import Card from "../atoms/Card";
import Loader from "../atoms/Loader";

interface TicketsListCardProps {
  name: string;
  tickets: Ticket[];
  onClick: (id: number) => void;
  loading?: boolean;
}

const TicketsListCard: FunctionComponent<TicketsListCardProps> = ({
  name,
  tickets,
  onClick,
  loading,
}): ReactElement => {
  return (
    <Card rounded>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id={name} values={{ count: tickets.length }} />
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <Loader />}
        {!loading && (tickets.length > 0 ? (
          <List className="pl-4">
            {tickets.map((ticket: Ticket) => (
              <TicketComponent
                key={ticket.id}
                ticket={ticket}
                onClick={() => onClick(ticket.id)}
              />
            ))}
          </List>
        ) : (
          <em className="d-flex justify-content-center">
            <FormattedMessage id={`${name}_none`} />
          </em>
        ))}
      </CardBody>
    </Card>
  );
}

export default TicketsListCard;
