import React, { FunctionComponent, ReactElement } from "react";
import { PropertyDetails } from "../../interfaces/properties/PropertiesInterfaces";
import { EXT_SPACES, EXT_SPACES_FIELDS } from "../../constants/properties/PropertiesConstants";
import { useIntl } from "react-intl";
import { Col } from "reactstrap";
import Label from "components/atoms/Label";

interface ListExtSpaceProps {
  property: PropertyDetails
}

const ListExtSpace: FunctionComponent<ListExtSpaceProps> = ({ property }): ReactElement => {
  const intl = useIntl();
  const elements = [];
  Object.values(EXT_SPACES_FIELDS).forEach((enumEntry) => {
    if (property && property[enumEntry]) {
      elements.push(intl.formatMessage({ id: EXT_SPACES[enumEntry].labelKey }))
    }
  })
  return elements.length !== 0 && (
    <Col xs="12" sm="12" md="6">
      <Label>{intl.formatMessage({ id: "property_ext_space" })}</Label>
      <strong>
        {elements.join(", ")}
      </strong>
    </Col>
  );
}

export default ListExtSpace;