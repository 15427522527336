import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import Row from "components/atoms/Row";
import { Col } from "reactstrap";
import { scrollTop } from "../../utils/utilFunctions";
import { useContracts } from "../../hooks/useContracts";
import { surveyService } from "../../services/SurveyService";
import { Survey } from "../../interfaces/surveys/SurveysInterfaces";
import { FormattedMessage } from "react-intl";
import Loader from "../../components/atoms/Loader";
import SurveyCard from "../../components/molecules/SurveyCard";

const SurveysView: FunctionComponent<RouteProps> = () => {
  const { selectedContract, contractsDropdown } = useContracts();

  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (selectedContract) {
      setLoading(true);
      surveyService.getByContract(selectedContract)
        .then((response: Survey[]) => {
          setSurveys(response);
        })
        .finally(() => setLoading(false));
    }
  }, [selectedContract])

  return (
    <Layout dashboard>
      <Section>
        <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <h2 className="h2">
            <FormattedMessage id="surveys_title" />
          </h2>
          {contractsDropdown}
        </div>
        {loading && <Loader />}
        {!loading && surveys.length === 0 && (
          <p className="text-center my-5 py-5">
            <em>
              <FormattedMessage id="no_survey" />
            </em>
          </p>
        )}
        <Row grid>
          {surveys.map((survey: Survey) => (
            <Col key={survey.url} xs="12">
              <SurveyCard survey={survey} />
            </Col>
          ))}
        </Row>
      </Section>
    </Layout>
  );
}

export default SurveysView;
