import React, { FunctionComponent, ReactElement } from "react";
import { CardText, CardTitle } from "reactstrap";
import Badge from "../../atoms/Badge";
import { FormattedMessage, useIntl } from "react-intl";
import {DOCUMENTS_PATH, PAYEMENT_RENT_PATH, RENT_PATH} from "../../../constants/routes/RoutePaths";
import { history } from "../../../constants/History";
import Price from "../../atoms/Price";

interface DbCurrentRentProps {
  debit: number;
  paid: boolean;
  dueDate: string;
  loading: boolean;
  selectedContract?: string;
}

const DbCurrentRent: FunctionComponent<DbCurrentRentProps> = ({
  debit,
  paid,
  dueDate,
  loading
}): ReactElement => {
  const intl = useIntl();

  const onBillClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(DOCUMENTS_PATH);
  };
  
  const onPaymentClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(PAYEMENT_RENT_PATH)
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push(RENT_PATH);
  }
  
  return (
    <div onClick={onClick} className="cursor-pointer">
      <CardTitle>
        <FormattedMessage id="db_rent_current_due_date" />
      </CardTitle>
      <div className="card-content">
        <CardText className="deadline">
          <small className="date">
            <FormattedMessage id="db_rent_due_date" /> {dueDate}
          </small>
        </CardText>
        <Price price={debit} disabled={loading}>
          <Badge color={paid ? "success" : "danger"} pill onClick={!paid && onPaymentClick}>
            <FormattedMessage id={paid ? "db_rent_paid" : "db_rent_to_pay"} />
          </Badge>
        </Price>
        <CardText className="link">
          <a
            onClick={onBillClick}
            href=""
            title={intl.formatMessage({ id: "db_rent_invoices" })}
          >
            <FormattedMessage id="db_rent_invoices" />
          </a>
        </CardText>
      </div>
    </div>
  );
}

export default DbCurrentRent;
