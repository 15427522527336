import { SearchProperties } from "../../interfaces/properties/PropertiesInterfaces";
import { FilterItem } from "../../interfaces/form/FormsInterfaces";

export const INITIAL_SEARCH_PROPERTIES: SearchProperties = {
  totalPages: 1,
  count: 0,
  data: [],
  page: 1
};

export const SEARCH_QUERY_DELAY = 500;
export const SEARCH_QUERY_MIN = 3;
export const SEARCH_LIMIT = 10;
export const HIGHLIGHT_LIMIT = 3;

export const PROJECTS: FilterItem[] = [
  {
    label: "buy",
    value: "buy",
  },
  {
    label: "rent",
    value: "rent",
  }
]

export const CATEGORIES: FilterItem[] = [
  {
    label: "property_type_L",
    value: "L",
  },
  {
    label: "property_type_M",
    value: "M",
  },
  {
    label: "property_type_C",
    value: "C",
  },
  {
    label: "property_type_G",
    value: "G",
  }
]

export const NUMBER_OF_ROOM: FilterItem[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4+",
    value: 4,
  }
]

export const NUMBER_OF_PERSON: FilterItem[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4+",
    value: 4,
  }
]

export enum PROPERTY_CATEGORIES_TRANSLATIONS {
  M = "property_type_M",
  T = "property_type_T",
  G = "property_type_G",
  B = "property_type_B",
  X = "property_type_X",
  L = "property_type_L",
  O = "property_type_O",
  C = "property_type_C"
}
export enum PROPERTY_CATEGORIES_FILTER {
  L = "property_type_L",
  M = "property_type_M",
  G = "property_type_G",
  C = "property_type_C",
}

export enum EXT_SPACES_FIELDS {
  BALCONY = "has_balcony",
  TERRACE = "has_terrace",
  BASEMENT = "has_basement",
  BIKE_PARKING = "has_bike_parking",
  GARDEN = "has_playground",
  PLAYGROUND = "has_playground"
}

interface ExtSpaceType {
  [key: string]: {
    labelKey: string
  }
}

export const EXT_SPACES : ExtSpaceType = {
  [EXT_SPACES_FIELDS.BALCONY]:{
    labelKey:"property_has_balcon"
  },
  [EXT_SPACES_FIELDS.BASEMENT]:{
    labelKey:"property_has_basement"
  },
  [EXT_SPACES_FIELDS.TERRACE]:{
    labelKey:"property_has_terrace"
  },
  [EXT_SPACES_FIELDS.BIKE_PARKING]:{
    labelKey:"property_has_bike_parking"
  },
  [EXT_SPACES_FIELDS.GARDEN]:{
    labelKey:"property_has_garden"
  },
  [EXT_SPACES_FIELDS.PLAYGROUND]:{
    labelKey:"property_has_playground"
  }
}
