import { useCallback, useEffect, useState } from "react";
import {
  PropertiesPhotoQuery,
  Property,
  PropertyFilters,
  PropertyFiltersKey,
  SearchProperties
} from "../interfaces/properties/PropertiesInterfaces";
import { INITIAL_SEARCH_PROPERTIES, SEARCH_LIMIT } from "../constants/properties/PropertiesConstants";
import { useQuery } from "@apollo/client";
import { PROPERTIES_PHOTO_QUERY } from "../constants/queries/PropertyQueries";
import { propertyService } from "../services/PropertyService";
import { handleApolloError } from "./handleApolloError";
import { history } from "../constants/History";
import { stringifyQuery } from "../utils/routeUtils";

interface UsePropertySearch {
  searchProperties: SearchProperties;
  properties: Property[];
  onQueryChange: (value: string) => void;
  page: number;
  setPage: (page: number) => void;
  filters: PropertyFilters;
  onFilterChanged: (name: string, value: string | number | undefined) => void;
  initialQuery?: string
}

export const usePropertySearch = (searchCallback: () => void, initialFilters: PropertyFilters, initialQuery: string, initialPage: number): UsePropertySearch => {

  // Search results
  const [searchProperties, setSearchProperties] = useState<SearchProperties>(INITIAL_SEARCH_PROPERTIES)
  const [page, setPage] = useState<number>(initialPage);
  const [triggerSearch, setTriggerSearch] = useState<boolean>(true);

  // Filters
  // Query search
  const [query, setQuery] = useState<string>(initialQuery);
  const [filters, setFilters] = useState<PropertyFilters>(initialFilters);

  ///////////////////////
  // SEARCH MANAGEMENT //
  ///////////////////////
  // The search function
  const search = useCallback(() => {
    setTriggerSearch(false);
    void propertyService.search(query, page, SEARCH_LIMIT, filters)
      .then((result: SearchProperties) => {
        setSearchProperties(result)
        searchCallback();
      })
  }, [query, page, filters])

  // The search trigger
  useEffect(() => {
    if (triggerSearch) {
      search();
    }
  }, [triggerSearch])


  ////////////////////////
  // FILTERS MANAGEMENT //
  ////////////////////////
  // Page changed
  const onPageChange = (value: number) => {
    setPage(value);
    setTriggerSearch(true);
  }

  // Query changed
  const onQueryChange = (value: string) => {
    setQuery(value)
    setPage(1)
    setTriggerSearch(true);
  }

  // Filters changed
  const onFilterChanged = (name: PropertyFiltersKey, value: string | number | undefined) => {
    setFilters((state: PropertyFilters) => {
      const oldValue = state[name];
      return {
        ...state,
        [name]: Array.isArray(oldValue) ?
          (
            oldValue.includes(value as number) ?
              oldValue.filter((item: number) => item !== value) :
              [...oldValue, value]
          )
          : state[name] === value ? undefined : value,
      }
    })
    setPage(1)
    setTriggerSearch(true)
  }

  ////////////////////////////////////
  // QUERY SEARCH PARAMS MANAGEMENT //
  ////////////////////////////////////
  const updateSearchParams = (): void => {
    const search = {
      query,
      page,
      ...filters,
      maxRent: filters.maxRent ? filters.maxRent : undefined,
      numberOfRoom: filters.numberOfRoom.join(",")
    }
    history.push({
      search: `?${stringifyQuery(search)}`
    })
  }

  useEffect(() => {
    updateSearchParams();
  }, [filters, query, page])


  ///////////////////////
  // PHOTOS MANAGEMENT //
  ///////////////////////
  // GraphQL call to get photos
  const { data: propertiesPhotoData, error } = useQuery<PropertiesPhotoQuery>(
    PROPERTIES_PHOTO_QUERY,
    {
      variables: { ids: searchProperties.data.map((property: Property) => property.id.toString()) },
      skip: searchProperties.data.length === 0,
      errorPolicy: "all"
    }
  );
  handleApolloError("properties_photo_query", error);

  // The mapper to attach photo to property
  const mapPropertyWithPhoto = useCallback((property: Property): Property => {
    if (propertiesPhotoData) {
      return propertyService.mapPropertyWithPhoto(property, propertiesPhotoData.properties)
    }
    return property;
  }, [propertiesPhotoData])

  return {
    searchProperties,
    properties: searchProperties.data.map(mapPropertyWithPhoto),
    onQueryChange,
    page,
    setPage: onPageChange,
    filters,
    onFilterChanged,
  }
}
