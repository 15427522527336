import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback, useState } from "react";
import { TicketCard } from "../../interfaces/tickets/TicketsInterfaces";
import { CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap";
import Card from "../atoms/Card";
import { FormattedMessage } from "react-intl";
import Figure from "../atoms/Figure";
import Input from "../atoms/Input";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";

interface CardTicketContactProps {
  contact: TicketCard;
  onChange: Dispatch<SetStateAction<string>>;
  onClick: () => void;
  selected: boolean;
}

const CardTicketContact: FunctionComponent<CardTicketContactProps> = ({
  contact,
  onChange,
  onClick,
  selected,
}): ReactElement => {
  const [editMail, setEditMail] = useState<boolean>(false)

  const onEditClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setEditMail((state: boolean) => !state)
  }, [])

  const isPhone = contact.name === "by_phone";
  const content = (isPhone || !editMail) ? (
    <CardText className="m-auto">
      {contact.value}
    </CardText>
  ) : (
    <Input
      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      value={contact.value}
    />
  );
  return (
    <Card onClick={onClick} className={`card-ticket-contact ${selected ? "selected" : ""}`}>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id={contact.name} />
        </CardTitle>
      </CardHeader>
      {contact.description && (
        <CardBody>
          <CardText>
            {contact.description}
          </CardText>
        </CardBody>
      )}
      <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
        <Icon name={contact.icon} />
      </Figure>
      <CardFooter>
        {content}
        {!isPhone && (
          <Button
            className="mt-3"
            color="primary"
            title={editMail ? "callback_request_save" : "callback_request_edit"}
            onClick={onEditClick}
          />
        )}
      </CardFooter>
    </Card>
  );
}

export default CardTicketContact;