import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import Form from "../atoms/Form";
import { Col, FormGroup } from "reactstrap";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { authService } from "../../services/AuthService";
import { Tenant } from "../../interfaces/tenants/TenantsInterfaces";
import { tenantService } from "../../services/TenantService";
import Row from "../atoms/Row";
import Label from "../atoms/Label";
import { opinionService } from "../../services/OpinionService";
import { toastService } from "../../services/ToastService";
import Loader from "../atoms/Loader";

interface FormValues extends FormikValues {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}

const OpinionForm: FunctionComponent = ({}): ReactElement | null => {
  const intl = useIntl();
  const validationSchema = Yup.object({
    lastName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    firstName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    email: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    message: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
  });

  const [tenant, setTenant] = useState<Tenant>();

  useEffect(() => {
    void tenantService.findByCurrentUser()
      .then((result: Tenant) => setTenant(result));
  }, [])

  const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    void opinionService.sendOpinion(values.lastName, values.firstName, values.email, values.subject, values.message)
      .then(() => {
        toastService.success(intl.formatMessage({ id: "opinion_success" }));
      })
      .finally(() => setSubmitting(false));
  }

  const user = authService.getLoggedInUser().user;

  if (!tenant) {
    return <Loader />;
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        firstName: tenant.firstName,
        lastName: tenant.lastName,
        email: user.email,
        subject: "",
        message: "",
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="m-2">
          <FormGroup row>
            <Col xs={12} sm={4} className="col-form-label">
              <Label className="required" for="firstName">
                <FormattedMessage id="opinion_form_firstname" />
                <span>*</span>
              </Label>
            </Col>
            <Col>
              <Input
                name="firstName"
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                invalid={touched.firstName && !!errors.firstName}
                errorMessage={errors.firstName}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={12} sm={4} className="col-form-label">
              <Label className="required" aria-required={true} for="lastName">
                <FormattedMessage id="opinion_form_lastname" />
                <span>*</span>
              </Label>
            </Col>
            <Col>
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                invalid={touched.lastName && !!errors.lastName}
                errorMessage={errors.lastName}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={12} sm={4} className="col-form-label">
              <Label className="required" for="email">
                <FormattedMessage id="opinion_form_email" />
                <span>*</span>
              </Label>
            </Col>
            <Col>
              <Input
                name="email"
                value={values.email}
                onChange={handleChange}
                invalid={touched.email && !!errors.email}
                errorMessage={errors.email}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={12} sm={4} className="col-form-label">
              <Label for="subject">
                <FormattedMessage id="opinion_form_subject" />
              </Label>
            </Col>
            <Col>
              <Input
                name="subject"
                value={values.subject}
                onChange={handleChange}
                invalid={touched.subject && !!errors.subject}
                errorMessage={errors.subject}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={12} sm={4} className="col-form-label">
              <Label className="required" for="message">
                <FormattedMessage id="opinion_form_message" />
                <span>*</span>
              </Label>
            </Col>
            <Col>
              <Input
                rows={3}
                type="textarea"
                name="message"
                value={values.message}
                onChange={handleChange}
                invalid={touched.message && !!errors.message}
                errorMessage={errors.message}
              />
            </Col>
          </FormGroup>
          <Row className="justify-content-end">
            <Col className="mt-5" xs={12} sm={6} md={4}>
              <Button
                className="w-100"
                color="primary"
                type="submit"
                size="lg"
                disabled={isSubmitting}
              >
                <FormattedMessage id="opinion_form_submit" />
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default OpinionForm;
