import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { RouteProps } from "react-router"

import Section from "components/atoms/Section"
import Layout from "components/layout/Layout";

import { useQuery } from "@apollo/client";
import { ArticlesQuery } from "../interfaces/articles/ArticlesInterfaces"
import { ARTICLES_GET_QUERY, ARTICLES_HIGHLIGHTS_QUERY } from "../constants/queries/ArticleQueries"
import ListArticles from "components/molecules/ListArticles";
import ArticleCategoriesFilter from "components/molecules/ArticleCategoriesFilter";
import { CATEGORIES_QUERY } from "constants/queries/CategoryQueries";
import { CategoriesQuery } from "interfaces/articles/ArticlesCategoriesInterfaces";
import Heading from "components/molecules/Heading";
import { useIntl } from "react-intl";
import { handleApolloError } from "../hooks/handleApolloError";
import { scrollTop } from "../utils/utilFunctions";
import Podcasts from "../components/molecules/Podcasts";
import { useQueryParams } from "../hooks/useQueryParams";
import Press from "../components/molecules/Press";

const NewsView: FunctionComponent<RouteProps> = () => {
  const { category } = useQueryParams(["category"])

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [showPodcasts, setShowPodcasts] = useState<boolean>(category === "podcast");
  const [showPress, setShowPress] = useState<boolean>(category === "media");
  const [keywordSearch, setKeywordSearch] = useState<string>();

  const categoryRef = useRef<string>(category);

  const {
    data: articlesData,
    previousData: articlesPreviousData,
    error: articleError
  } = useQuery<ArticlesQuery>(ARTICLES_GET_QUERY, { variables: { selectedCategories, keywordSearch } });
  const {
    data: highlightsData,
    previousData: highlightsPreviousData,
    error: highlightsError
  } = useQuery<ArticlesQuery>(ARTICLES_HIGHLIGHTS_QUERY, { variables: { selectedCategories, keywordSearch } });
  const {
    data: categoriesData,
    loading: categoryLoading,
    error: categoryError
  } = useQuery<CategoriesQuery>(CATEGORIES_QUERY)
  handleApolloError("articles_query", articleError);
  handleApolloError("article_highlight_query", highlightsError);
  handleApolloError("categories_query", categoryError);

  const intl = useIntl();

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (categoryRef.current !== category) {
      window.location.reload();
    }
  }, [category])

  const handleCategoryClick = (id: string) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter(category => category !== id))
    } else {
      setShowPodcasts(false)
      setShowPress(false)
      setSelectedCategories(selectedCategories.concat(id))
    }
  }

  const handlePodcastClick = () => {
    setShowPodcasts(!showPodcasts)
    setShowPress(false)
    setSelectedCategories([])
  }

  const handlePressClick = () => {
    setShowPress(!showPress)
    setShowPodcasts(false)
    setSelectedCategories([])
  }

  return (
    <Layout>
      <>
        {!categoryLoading && (
          <Section bgAlt>
            <ArticleCategoriesFilter
              keywordSearch={keywordSearch}
              setKeywordSearch={setKeywordSearch}
              categories={categoriesData?.categories || []}
              selectedCategories={selectedCategories}
              handleCategoryClick={handleCategoryClick}
              handlePodcastClick={handlePodcastClick}
              handlePressClick={handlePressClick}
              showPodcasts={showPodcasts}
              showPress={showPress}
            />
          </Section>
        )}
        {showPodcasts && <Podcasts />}
        {showPress && <Press />}
        {!showPodcasts && !showPress && (
          <Section>
            <Heading
              upTitle={intl.formatMessage({ id: "articles_page_maintitle" })}
              mainTitle={intl.formatMessage({ id: "articles_page_subTitle" })}
            />
            <ListArticles
              articles={articlesData?.articles || articlesPreviousData?.articles || []}
              highlightArticles={highlightsData?.articles || highlightsPreviousData?.articles || []}
            />
          </Section>
        )}
      </>
    </Layout>
  )
}

export default NewsView
