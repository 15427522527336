import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import { scrollTop } from "../../utils/utilFunctions";
import { FormattedMessage } from "react-intl";
import { documentService } from "../../services/DocumentService";
import { useContracts } from "../../hooks/useContracts";
import { DOCUMENT_NATURE, DocumentInterface } from "../../interfaces/documents/DocumentsInterfaces";
import DocumentContainer from "../../components/molecules/DocumentContainer";

const MyDocumentsView: FunctionComponent<RouteProps> = () => {
  const { selectedContract, contractsDropdown } = useContracts();

  const [documents, setDocuments] = useState<DocumentInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (selectedContract) {
      setLoading(true);
      documentService.findAllByContract(selectedContract)
        .then((result: DocumentInterface[]) => {
          setDocuments(result);
        })
        .finally(() => setLoading(false))
    }
  }, [selectedContract])

  const quittances = documents.filter((document: DocumentInterface) => document.nature === DOCUMENT_NATURE.QUITTANCE).sort(documentService.sort).slice(0, 6);
  const charges = documents.filter((document: DocumentInterface) => document.nature === DOCUMENT_NATURE.CHARGES).sort(documentService.sort);

  return (
    <Layout dashboard>
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2">
              <FormattedMessage id="my_documents_page" />
            </h2>
            {contractsDropdown}
          </div>
          <DocumentContainer title="my_receipts" documents={quittances} contractId={selectedContract} loading={loading} />
        </Section>

        {/* TODO : Afficher lorsqu'on récupérera les documents du contrat */}
        {/*<Section>*/}
        {/*  <DocumentContainer title="my_contract" documents={[]} contractId={selectedContract} loading={loading} />*/}
        {/*</Section>*/}

        <Section>
          <DocumentContainer title="my_regularization_notices" documents={charges} contractId={selectedContract} loading={loading} />
        </Section>
      </>
    </Layout>
  );
}

export default MyDocumentsView;
