import React, { FunctionComponent } from "react"
import { Route, RouteProps } from "react-router-dom"
import { authService } from "../../services/AuthService";
import { history } from "../../constants/History";
import { LOGIN_REGISTER_PATH } from "../../constants/routes/RoutePaths";
import { Redirect } from "react-router";

const PrivateRoute: FunctionComponent<RouteProps> = ({
  component,
  ...rest
}) => {
  const user = authService.getLoggedInUser()
  const isLoggedIn = user && user.jwt
  if (!isLoggedIn) {
    history.push(LOGIN_REGISTER_PATH)
  }

  return (isLoggedIn) ? (
    <Route
      {...rest}
      component={component}
    />
  ) : (
    <Redirect to={LOGIN_REGISTER_PATH} />
  )
}

export default PrivateRoute
