import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { reloadJob } from "../../lib/tarteaucitron"

export interface YoutubeVideoContainerInterface {
  videoID: string;
  width?: string;
  height?: string;
  theme?: "light" | "dark";
  controls?: "0" | "1";
  showinfo?: "0" | "1";
  rel?: "0" | "1";
}

const YoutubeVideoContainer: FunctionComponent<YoutubeVideoContainerInterface> = ({
  videoID,
  width = "640",
  height = "360",
  theme = "light",
  controls = "1",
  showinfo = "1",
  rel = "1"
}): ReactElement => {

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    reloadJob("youtube")
  }, [])

  const playerProps = { videoID, width, height, theme, controls, showinfo, rel }
  return (
    <div className="YoutubeVideoContainer video">
      <div className="youtube_player" {...playerProps} />
    </div>
  )
}

export default YoutubeVideoContainer;
