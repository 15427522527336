/**
 * File listing every translated label in English
 */

const EnglishMessages = {
  welcome: "Welcome on les Résidences website!",
  to_components: "Go to components page",
  no_result: "No result",
  close: "Close",
  discover: "Discover",
  edit: "Edit",
  save: "Save",
  send: "Send",
  cancel: "Cancel",
  print: "Print",
  disconnect: "Disconnect",
  // Login section
  login: "Login",
  login_email: "E-mail",
  login_password: "Password",
  login_forgot_password: "Forgot your password?",
  login_btn: "Login",
  forgot_password_modal_title: "Forgot your password",
  forgot_password_modal_text: "Enter your informations to receive your password by email",
  forgot_password_modal_btn: "Validate",
  forgot_password_modal_success: "A reset email has been sent to you",
  reset_password_success: "Your password was reset successfully",
  create_account: "Create your tenant space",
  create_account_confirm_email: "Email confirmation",
  create_account_last_name: "Last Name",
  create_account_first_name: "First name",
  create_account_contract_number: "123456789",
  create_account_contract_number_popover: "Your contract number is available on all your receipts. Please indicate only the number and not the L/.",
  create_account_email: "E-mail",
  create_account_password: "Password",
  create_account_conditions: "By submitting this form, I agree that my information will be used in the context of my request and the commercial relationship that may result.",
  create_account_water: "The information collected by Les résidences in the context of the remote reading of water meters is subject to computer processing. If you accept that your data is transmitted to the water supplier for the provision of your personal space on the portal Les résidences, please check the box opposite.",
  create_account_btn: "Sign in",
  create_account_btn_modal: "Account creation",
  create_account_confirmation_email_sent: "Your account has been created. A confirmation email will be sent to you. Remember to look in your junk mail",
  create_account_confirmation_success: "Your account has been successfully validated!",
  or: "or",
  invalid_confirm_email: "The e-mails entered are not identical",
  create_account_confirm_password: "Password confirmation",
  invalid_confirm_password: "The passwords entered are not identical",
  // Reset password
  invalid_password: "Password must contain at least 8 Characters (One Uppercase, One Number and one special case Character)",
  reset_password: "Please enter your new password",
  new_password: "New password",
  confirm_password: "Password confirmation",
  error_matching: "Your confirmation does not match with your new password",
  confirmation: "Confirmation",
  validation_forgotten_password: "Consult your mail account we have send a re-initialisation link",
  // Offers
  offers_button_application: "Spontaneous application",
  offers_start_date: "Job start from {date}",
  offers_see_offer: "See offer",
  // Offer detail
  offer_detail_apply: "Apply",
  offer_detail_job_card: "Job card",
  offer_detail_article_read_time: "{count, plural, =0 {Less than a minute read} one {1 minute read} other {# minute read}}",
  // Form errors
  mandatory_field: "Mandatory field",
  invalid_email: "Please enter a valid email address",
  invalid_phone: "Please enter a valid phone number",
  invalid_price_amount: "Please submit amount less than 3000€",
  //Navbar Links
  property_page: "Our Properties",
  realization_page: "Our Realizations",
  suppliers_page: "Our Suppliers",
  articles_page: "Our News",
  join_us_page: "Join us",
  hello: "Hello",
  // Sidemenu links
  my_payment_rent_page: "Summary of my payment",
  rent_name_lastname: "Name and firstname of tenant",
  rent_submit_payment: "Pay",
  dashboard_page: "Dashboard",
  personal_information_page: "Personal informations",
  my_rent_page: "My receipt",
  my_documents_page: "My documents",
  my_tickets_page: "My requests",
  my_housing_page: "My housing",
  surveys_page: "Surveys",
  your_complex_page: "My complex",
  // Join us
  join_us_human_resources: "Our HR policy",
  join_us_1: "With nearly 600 employees, we are looking for all kinds of profiles, whether in project management (real estate operations managers for our constructions or renovations, real estate technical managers), in proximity (asset managers, customer advisors, social advisors or resid'Managers¹) or in support functions (accounting, legal, IT, HR).",
  join_us_2: "The main qualities we are looking for: motivation and the ability to join a growing company, focused on social issues and innovation, committed to an ambitious CSR² approach.",
  join_us_3: "Do not hesitate to consult our job offers on the Welcome To The Jungle platform and on LinkedIn.",
  join_us_4: "Local manager ensuring the follow-up, the animation and the management of the maintenance of the buildings for which he is responsible.",
  join_us_5: "Corporate Social Responsibility",
  // Dashboard
  db_title: "My dashboard",
  db_your_appointments: "Your appointments",
  db_no_appointment: "You have no scheduled appointments",
  db_rent_current_due_date: "Current term",
  db_rent_due_date: "Due date",
  db_rent_paid: "Paid",
  db_rent_to_pay: "To pay",
  db_rent_invoices: "Access to invoices",
  db_current_tickets: "{count, plural, =0 {No ticket in progress} one {1 ticket in progress} other {# tickets in progress}}",
  db_last_rent: "Your last receipt",
  db_rent_of: "Receipt of ",
  db_see_all: "See all",
  db_contacts: "Your privileged contacts",
  db_caretaker: "Your Résid'Manager",
  db_phone: "Phone",
  db_mobile: "Mobile",
  db_email: "Email",
  db_address: "Address",
  db_address_default: "Marketing service and client experience",
  // Housing page
  housing_property_highlight: "Housing in the spotlight",
  // Personal information page
  personal_information_title: "My personal information",
  personal_information_identity_sub_title: "My account",
  personal_information_options_sub_title: "My options",
  personal_information_lastname: "Lastname",
  personal_information_firstname: "Firstname",
  personal_information_email: "Email",
  personal_information_phone: "Phone number",
  personal_information_tenant_number: "Tenant number",
  personal_information_entry_date: "Date of entry into the accommodation",
  personal_information_edit_password: "Edit password",
  personal_information_save_password: "Save password",
  personal_information_current_password: "Current password",
  personal_information_new_password: "New password",
  personal_information_confirm_password: "Confirm password",
  personal_information_er_consent: "I want to have dematerialized rent receipts",
  personal_information_er_true: "You have opted for electronic receipts which will be sent to you by email",
  personal_information_er_false: "You have opted for paper receipts which will be sent to you by postal mail",
  // My tickets page
  ticket_request: "Open a new ticket",
  tickets_follow_up: "Tickets follow-up",
  tickets_opened: "{count, plural, =0 {Pending tickets} one {1 pending ticket} other {# pending tickets}}",
  tickets_closed: "My 10 last tickets",
  tickets_opened_none: "No pending ticket",
  tickets_closed_none: "No history",
  tickets_title: "Title",
  tickets_description: "Description",
  // Opinion form
  opinion_title: "Your opinion is important to us",
  opinion_form_firstname: "First name",
  opinion_form_lastname: "Last name",
  opinion_form_email: "Email",
  opinion_form_subject: "Subject",
  opinion_form_message: "Message",
  opinion_form_submit: "Submit",
  opinion_success: "Your opinion has been properly submitted.",
  opinion_email_required: "The e-mail address is mandatory.",
  opinion_email_invalid: "Please provide a valid email address.",
  opinion_lastName_required: "Last name is mandatory.",
  opinion_firstName_required: "First name is mandatory.",
  opinion_message_required: "The message is mandatory.",
  // Create ticket
  your_ticket: "Your ticket",
  stepper_your_ticket: "Your ticket",
  stepper_importance: "Importance",
  stepper_description: "Description",
  stepper_contact: "Preferred contact mode",
  stepper_localization: "Localization",
  stepper_type: "Type",
  stepper_relationship: "Relationship",
  stepper_situation: "Situation",
  stepper_transfer: "Transfer",
  stepper_schedule: "Desired time slot (subject to availability)",
  stepper_next_step: "Next step",
  stepper_previous: "Back",
  stepper_validate: "Validate",
  stepper_modal_text: "Your ticket has been sent",
  stepper_modal_button: "Back to the ticket list",
  // Step Category
  leak: "Leak",
  blocked_pipes: "Blocked pipes",
  hot_water_failure: "Hot water failure",
  insect_or_pest: "Insect or pest",
  blackout: "Blackout",
  malfunction_in_common_areas: "Malfunction in common areas",
  new_occupant_in_the_accommodation: "New occupant in the accommodation",
  request_for_transfer: "Request for transfer",
  callback_request: "Callback request",
  // Step category titles
  leak_title: "Report a leak in my accommodation",
  blocked_pipes_title: "Report a blockage or backflow",
  hot_water_failure_title: "Report",
  insect_or_pest_title: "Report insects or pests in my accommodation",
  blackout_title: "Report a power failure in my accommodation",
  malfunction_in_common_areas_title: "Report a malfunction in the common areas",
  new_occupant_in_the_accommodation_title: "Declare a new occupant in my accommodation",
  request_for_transfer_title: "Request a transfer",
  callback_request_title: "Be contacted by an advisor",
  // Step localization
  leak_localization_kitchen: "Kitchen",
  leak_localization_bathroom: "Bathroom / WC",
  leak_localization_other_room: "Other room (living room, bedroom, etc.)",
  blocked_pipes_localization_kitchen_sink: "Kitchen sink",
  blocked_pipes_localization_bath: "Bathtub / Shower / Washbasin",
  blocked_pipes_localization_toilet: "Toilet / WC",
  blackout_localization_one: "A single device (socket, switch, etc.)",
  blackout_localization_one_room: "Equipment in the same room",
  blackout_localization_general: "General breakdown in the accommodation",
  malfunction_in_common_areas_localization_electric: "Electrical failure",
  malfunction_in_common_areas_localization_elevator: "Elevator failure",
  malfunction_in_common_areas_localization_intercom: "Intercom failure",
  malfunction_in_common_areas_localization_smoke_extraction: "Smoke vent open",
  malfunction_in_common_areas_localization_main_door: "Lobby door malfunction",
  malfunction_in_common_areas_localization_parking: "Malfunction of access to the car park",
  malfunction_in_common_areas_localization_squat: "Squat",
  // Step localization titles
  leak_localization_kitchen_title: "localized in the kitchen",
  leak_localization_bathroom_title: "localized in the bathroom / WC",
  leak_localization_other_room_title: "localized in another room (living room, bedroom, etc.)",
  blocked_pipes_localization_kitchen_sink_title: "at the kitchen sink",
  blocked_pipes_localization_bath_title: "at the bathtub / shower / washbasin",
  blocked_pipes_localization_toilet_title: "at toilet / WC",
  malfunction_in_common_areas_localization_electric_title: ": Electrical failure",
  malfunction_in_common_areas_localization_elevator_title: ": Elevator failure",
  malfunction_in_common_areas_localization_intercom_title: ": Intercom failure",
  malfunction_in_common_areas_localization_smoke_extraction_title: ": Smoke vent open",
  malfunction_in_common_areas_localization_main_door_title: ": Lobby door malfunction",
  malfunction_in_common_areas_localization_parking_title: ": Malfunction of access to the car park",
  malfunction_in_common_areas_localization_squat_title: ": Squat",
  // Step type
  hot_water_failure_type_hot_water_tank: "Individual boiler or water tank",
  hot_water_failure_type_heater: "Collective heating or hot water",
  insect_or_pest_type_cockroaches: "Cockroaches",
  insect_or_pest_type_bugs: "Bugs",
  insect_or_pest_type_rodents: "Rodents",
  insect_or_pest_type_pigeons: "Pigeons",
  // Step type titles
  hot_water_failure_type_hot_water_tank_title: "an individual boiler or water tank failure",
  hot_water_failure_type_heater_title: "a collective heating or hot water failure",
  // Step importance
  drip: "Drip",
  discontinuous_thread: "Discontinuous thread",
  big_flow: "Big flow",
  // Step relationship
  new_occupant_in_the_accommodation_relationship_partner: "Spouse, partner or civil partnership",
  new_occupant_in_the_accommodation_relationship_child: "Child",
  new_occupant_in_the_accommodation_relationship_sibling: "Parent, brother, sister",
  new_occupant_in_the_accommodation_relationship_none: "No relationship",
  // Step situation
  new_occupant_in_the_accommodation_situation_dependent: "Dependent",
  new_occupant_in_the_accommodation_situation_custody: "Alternating custody",
  new_occupant_in_the_accommodation_situation_visiting: "Access rights",
  // Step transfer
  request_for_transfer_number_of_person_question: "How many people will occupy the accommodation?",
  request_for_transfer_number_of_person: "{count, plural, =0 {No one will occupy the accommodation} one {1 person will occupy the accommodation} other {# people will occupy the accommodation}}",
  request_for_transfer_id_question: "Have you created a demand for social housing?",
  request_for_transfer_id: "Unique application number",
  request_for_transfer_request: "Apply for social housing",
  // Step description
  describe_your_ticket: "Describe your ticket",
  your_comments_leak: "Have you remembered to turn off the water supply tap?\nGive us more details on the problem encountered...",
  your_comments_blocked_pipes: "Did the incident occur as a result of an intervention on your part?\nGive us more details on the problem encountered...",
  your_comments_hot_water_failure: "Are you the only one affected by this malfunction?\nGive us more details on the problem encountered...",
  your_comments_insect_or_pest: "Are insects or pests present in one room or throughout the dwelling?\nGive us more details on the problem encountered...",
  your_comments_blackout_1: "Have you checked that no fuse in your electrical panel has blown?\nGive us more details on the problem encountered...",
  your_comments_blackout_2: "Are you the only one concerned? Is there light in the common areas or in the neighborhood?\nGive us more details on the problem encountered...",
  your_comments_malfunction_in_common_areas: "Have you thought about contacting your caretaker or the on-call service?\nGive us more details on the problem encountered...",
  your_comments_new_occupant_in_the_accommodation: "Do you have any specific information to send us concerning this new occupant?\nRemember to send us any document justifying the situation of the new occupant (birth certificate, marriage certificate, divorce decree, etc.).",
  your_comments_request_for_transfer: "Give us some details about your request (desired sector, type of accommodation, low / high floor, reason for the request, etc.).",
  your_comments_callback_request: "Have you checked that your contact details in the \"Personal Information\" tab are correct?\nTell us more about the subject of your request...",
  add_documents: "+ Add files",
  billing: "I have noted that the work is likely to be re-invoiced to me according to the diagnosis made by the technician.<br/>By checking this box, I agree to be billed at the current rate, if applicable.",
  // Step contact
  contact_me: "Contact me",
  recontact: "How would you like to be contacted ?",
  by_phone: "By phone",
  by_mail: "By mail",
  callback_request_edit: "Modify the email",
  callback_request_save: "Validate the email",
  // Step schedule
  callback_request_schedule_12: "12:00 p.m. - 2:00 p.m.",
  callback_request_schedule_14: "2:00 p.m. - 4:00 p.m.",
  callback_request_schedule_16: "4:00 p.m. - 6:00 p.m.",
  // My housing
  my_housing: "My housing",
  contract_number: "Contract number",
  water_consumption: "My water consumption",
  water_location_other: "Others",
  water_location_office: "Office",
  water_location_cellar: "Cellar",
  water_location_storeroom: "Storeroom",
  water_location_bedroom: "Bedroom",
  water_location_corridor: "Corridor",
  water_location_kitchen: "Kitchen",
  water_location_boxroom: "Boxroom",
  water_location_entrance: "Entrance",
  water_location_outside: "Outside",
  water_location_garage: "Garage",
  water_location_gpa: "Landing shaft",
  water_location_lobby: "Lobby",
  water_location_landing: "Landing",
  water_location_closet: "Closet",
  water_location_bathroom: "Bathroom",
  water_location_living_room: "Living room",
  water_location_basement: "Basement",
  water_location_crawl_space: "Crawl space",
  water_location_restroom: "WC",
  water_location_unknown: "Unknown",
  water_type_hot: "Hot water",
  water_type_cold: "Cold water",
  water_more_informations: "More details : ",
  my_housing_my_insurance: "My insurance",
  my_housing_add_insurance:"+ Add the insurance",
  no_data: "No data available",
  my_housing_tuto: "Tutorial about my housing maintenance",
  // My rent
  rent_empty: "You are up to date on your payments",
  rent_debt_details: "Debts details",
  rent_access_bills: "Access to invoices",
  rent_direct_debit: "Direct debit",
  rent_direct_debit_description: "Simplify your life, save time and be sure not to forget your rent! Choose direct debit, a secure way to pay. Print and fill out the document below and send it to your caretaker or agency.",
  rent_direct_debit_file: "Download the document",
  rent_online_payment: "Online payment",
  rent_online_payment_description: "You prefer to pay online every time? It's quick and easy: bring your credit card, click on the link below and let us guide you.",
  rent_my_balance: "My balance",
  rent_my_balance_to_pay: "My debt to pay",
  rent_due_date: "Due date",
  rent_my_quitance: "My receipt of the month",
  rent_history: "My summaries",
  rent_debit: "Debit",
  rent_credit: "Credit",
  rent_your_payment: "Your payment",
  rent_quitance: "Rent",
  rent_debts: "My debts",
  my_contracts: "My contracts",
  // My documents
  my_receipts: "My receipts",
  my_contract: "My contracts",
  my_regularization_notices: "My regularization notices",
  no_document_available: "No document available",
  // Surveys
  surveys_title: "Your opinion is important to us",
  no_survey: "No survey in progress",
  survey: "Survey",
  survey_from: "From ",
  survey_to: " to ",
  survey_answer: "Answer",

  //Homepage Links
  to_all_articles_page: " See all articles",
  //Homepage Search
  search: "Search",
  buy_tab: "Buy",
  rent_tab: "Rent",
  location: "Location",
  properties_types: "Property type",
  typologie_types: "Typologie",
  service: "Service",
  where: "Where",
  //Homepage Text
  max_budget: "Maximum budget",
  advanced_search: "Advanced search",
  //Agency Page
  apartment_count: "{count, plural, =1 {1 apartment} other {# apartments}}",
  house_count: "{count, plural, =1 {1 house} other {# houses}}",
  parking_count: "{count, plural, =1 {1 parking} other {# parkings}}",
  room_multiple: "From {min} to {max} rooms",
  room_simple: "{count, plural, =1 {1 room} other {# rooms}}",
  discover_agency_button: "Discover",
  //Articles links
  reading_time: " min read",
  search_by_keyword: "Search by keyword",
  // Join us
  join_us_card_title: "Our job offers",
  join_us_article: "The article",
  join_us_offers_find: "Find all of ours job offers and apply online !",
  join_us_offers_external: "You can also find all our job offers on ",
  //Articles page
  articles_click: "Click to discover our information",
  articles_page_maintitle: "Our News",
  articles_page_subTitle: "Our latest news",
  podcasts_page_maintitle:"Our Podcasts",
  podcasts_page_subTitle:"Our latest podcasts",
  featured_articles: "Featured articles",
  article_podcast_tag: "Podcast",
  podcasts_category: "Podcasts",
  press_category: "Press area",
  // Press
  press_media: "Documents available",
  press_logo: "White logo",
  press_charte: "LRYE chart 2020",
  press_logo_cmjn: "LRYE logo CMJN",
  press_patrimoine: "Map of our real estate",
  press_plaquette: "Our company at a glance",
  // Article detail
  article_detail_back: "Back to the articles list",
  //Agency page
  our_agencies: "Our agencies",
  //Discover us page
  our_presidency: "Our presidency",
  our_supervisory_board: "Our supervisory board",
  our_board: "Our board of directors",
  // Conatct form : property detail
  contact_form_title: "Request Informations",
  contact_form_number_of_residents: "Number of residents in the property",
  contact_form_monthly_income: "Monthly net income",
  contact_form_previous_tax_income: "year -2 tax income",
  contact_form_is_tenant: "Already tenant",
  contact_form_is_tenant_yes: "Yes",
  contact_form_is_tenant_no: "No",
  contact_form_professional_situation: "Professional situation",
  contact_form_professional_situation_working: "Working",
  contact_form_professional_situation_jobless: "Jobless",
  contact_form_professional_situation_undefined: "Undefined",
  contact_form_unique_number: "Unique Number",
  contact_form_submit: "Submit",
  contact_form_phone_number: "Phone Number",
  contact_form_success: "Contact email sent successfully.",
  contact_form_error: "Failed to send, an error has occurred!",
  // Property detail
  share_property: "Share property",
  share_from: "Sender email",
  share_to: "Receiver email",
  share_subject: "Subject",
  share_message: "Message",
  share_message_default: "Here's an ad you might be interested in.",
  share_success: "Mail sent successfully.",
  plan: "Housing plans",
  download_plan: "Download the plan",
  // properties
  see_map: "See on the map<br />our housing stock",
  // Properties map
  properties_map_bedroom: "{count, plural, =0 {} one {1 bedroom} other {# bedrooms}}",
  properties_map_bathroom: "{count, plural, =0 {} one {1 bathroom} other {# bathrooms}}",
  result: "{count, plural, =0 {No result} one {result} other {results}}",
  back: "Back",
  see_property: "See details",
  buy: "Buy",
  rent: "Rent",
  apartment: "Apartment",
  house: "House",
  shop: "Shop",
  parking: "Parking",
  numberOfRoom: "{count, plural, =0 {} one {1 room} other {# rooms}}",
  numberOfPerson: "{count, plural, =0 {} one {1 person} other {# persons}}",
  or_more: "or more",
  project_question: "What is your project ?",
  type_question: "What kind of property ?",
  room_question: "Number of room ?",
  person_question: "Number of people in the household ?",
  max_rent_question: "What is your max rent ?",
  max_budget_question: "What is your max budget ?",
  data_not_stored: "* Data entered here is not stored",
  conditions: "All our accommodations are subject to resource ceilings<br />(salaries, allowances, etc ...)",
  maxRent: "Max rent",
  maxBudget: "Max budget",
  //Footer
  my_tenant_space: "My tenant space",
  copyright: "© 2021 Copyright - Les Résidences Yvelines Essonne",
  privacy_policy: "Privacy policy",
  legal_mentions: "Legal mentions",
  //Eligibility Form
  form_question_where_to_live: "In which city would you like to live?",
  form_question_where_to_live_placeholder: "Type the name of the city",
  form_question_status: "Are you single or in a couple ?",
  form_question_handicap: "Do you have special needs ?",
  form_question_handicap_explained: "Yes : in a disabled situation or in a home for a disabled person",
  form_question_num_of_dependents: "How many dependents do you have ?",
  form_question_num_of_dependents_placeholder: "Number of dependents",
  form_question_income: "How much is your income ?",
  form_question_income_placeholder: "Income",
  form_stepper_button_next: "Next step",
  form_stepper_button_prev: "Previous",
  form_stepper_button_submit: "Submit Test",
  error_msg_positive_number: "This field must be greater or equal than 0",
  error_msg_positive_number_or_0: "This field must be greater than or equal to 0",
  error_msg_number_no_decimal: "This field must be an integer",
  form_answer_status_single: "Single",
  form_answer_status_In_Couple: "In a couple",
  general_form_answer_yes: "Yes",
  general_form_answer_no: "No",
  form_result_eligible_message: "You are eligible for ",
  form_result_eligible_message_fail: "You are not eligible.",
  form_result_eligible_type_social: "Social* housing ",
  form_result_eligible_type_social_info: "(low rent)",
  form_result_eligible_type_intermediate_info: "more information on intermediate housing",
  form_result_eligible_type_intermediate: "Intermediate",
  form_result_eligible_no_housing: "however, no Intermediate housing is available in the selected city ",
  form_question_youngCouple: "Young couple ?",
  form_info_handicap: "With a disability or in a home with a disabled person",
  form_info_youngCouple: "Married couple, civil partnership or cohabiting partner whose total age is less than or equal to 55 years old and without dependents.",
  form_info_income: "The sum of all the reference tax income of all the people occupying the property (see your tax notice).",
  form_result_link_discover_more: "Discover our housing offers",
  form_result_success_benefits_link: "www.demande-logement-social.gouv.fr",
  form_result_success_benefits_link_text: "To benefit from it, remember to complete your file online on:",
  form_result_success_benefits_link_info: "Your file is subject to validation in one of our agencies.",
  form_result_eligible_type_intermediate_link_text: "intermediate housing",
  error_message_community_required: "You must choose a city",
  error_message_community_wrong_city: "This city does not belong to LRYE's estate",
  // FAQ
  faq: "FAQ",
  faq_placeholder: "Ask your question...",
  faq_frequent_topic: "Frequent topic",

  // Strapi error
  Auth_form_error_blocked: "The site is currently unavailable. Please try again later.",
  Auth_form_error_code_provide: "Incorrect code provided.",
  Auth_form_error_confirmed: "Your account email is not confirmed.",
  Auth_form_error_email_invalid: "This email is invalid.",
  Auth_form_error_email_provide: "Please provide your username or your email.",
  Auth_form_error_email_taken: "Email is already taken.",
  Auth_form_error_invalid: "Identifier or password invalid.",
  Auth_form_error_params_provide: "Incorrect params provided.",
  Auth_form_error_password_format: "Your password cannot contain the symbol `$` more than three times.",
  Auth_form_error_password_local: "This user never set a local password, please login via the provider used during account creation.",
  Auth_form_error_password_matching: "Passwords do not match.",
  Auth_form_error_password_provide: "Please provide your password.",
  Auth_form_error_password_invalid: "Current password invalid.",
  Auth_form_error_ratelimit: "Too many attempts, please try again in a minute.",
  Auth_form_error_user_not_exist: "This email does not exist.",
  Auth_form_error_username_taken: "Username is already taken.",
  Auth_form_error_confirm: "The new password and the password confirmation do not match.",
  Auth_form_error_lastName_provide: "Please provide your last name.",
  Auth_form_error_firstName_provide: "Please provide your first name.",
  Auth_form_error_contractNumber_provide: "Please provide your contract number.",
  Auth_form_error_conditions_provide: "Please accept the conditions.",
  Auth_form_error_contract_notFound: "Contract information is not correct.",
  Auth_form_error_tenantNumber_taken: "This information already corresponds to an existing tenant account.",
  Property_not_found: "Can't find property with id {value}",
  error_400: "Bad request",
  error_401: "Forbidden",
  error_403: "Unauthorized",
  error_404: "Not found",
  error_500: "Internal server error",
  error_504: "Gateway timeout",

  // Apollo error
  offer_query: "Error during offers fetch",
  tag_query: "Error during tags fetch",
  tag_category_query: "Error during offer tags categories fetch",
  homepage_query: "Error during `Home` page fetch",
  complex_detail_query: "Error during complex detail fetch",
  property_detail_query: "Error during property detail fetch",
  properties_photo_query: "Error during properties photos fetch",
  offer_detail_query: "Error during offer detail fetch",
  article_detail_query: "Error during article detail fetch",
  articles_query: "Error during articles fetch",
  articles_highlight_query: "Error during highlight articles fetch",
  categories_query: "Error during categories fetch",
  article_highlight_query: "Error during highlighted articles fetch",
  footer_query: "Error during footer's data fetch",
  editorial_group_query: "Error during editorial group fetch",
  editorial_page_query: "Error during editorial page fetch",
  faq_tag_query: "Error during FAQ tags fetch",
  faq_query: "Error during FAQ fetch",
  agency_query: "Error during agency detail fetch",
  podcasts_query: "Error during podcasts fetch",
  discover_query: "Error during 'Discover us' page fetch",
  our_commitments_query: "Error during 'Our commitments' page fetch",
  connected_housings_query: "Error during 'Our connected housings' page fetch",
  our_agencies_query: "Error during 'Our agencies' page fetch",
  agencies_query: "Error during agencies page fetch",
  privacy_policy_query: "Error during privacy policy fetch",
  legal_mentions_query: "Error during legal mentions fetch",

  // Success message
  success_personal_information_email_updated: "Your email address has been updated",
  success_personal_information_phone_updated: "Your phone number has been updated",
  success_personal_information_password_updated: "Your password has been updated",
  //Property Form : Modal Informations
  property_form_modal_title: "Creation of your unique number",
  property_form_modal_description: "If you already have this number, please let us know it will facilitate the processing of your file.",

  //Property Details
  property_photos_info: "Non-contractual photos",
  property_location_badge: "Location",
  property_no_fees: "No fees",
  property_with_fees: "All charges included",
  property_call_center: "Call center",
  property_info_form: "Information Form",
  property_info_district: "District Informations",
  property_number_of_bedroom: "Rooms",
  property_number_of_bathroom: "Bathrooms",
  property_surface: "Surface",
  property_has_parking: "Parking",
  property_has_elevator: "Elevator",
  property_ext_space: "Exterior Space",
  property_district: "District",
  property_floor: "Floor",
  property_type_house: "House",
  property_type_terrain: "Terrain",
  property_type_garage: "Garage",
  property_type_desk: "Office",
  property_type_locals: "Collective premises",
  property_type_lodge: "Lodge",
  property_type_collective: "Collective Housing",
  property_room_info: "Rooms",
  property_has_balcon: "Balcony",
  property_has_terrace: "Terrace",
  property_has_basement: "Basement",
  property_has_storage: "Storages",
  property_has_bike_parking: "Bike room",
  property_has_garden: "Garden",
  property_has_playground: "Playground",
  //Go back buttons
  property_back_button: "Return to results",

  // Our properties
  properties_top_heading: "How can we help you with your project ?",
  properties_search_info: "All of our properties are under income criteria",
  properties_eligibility_heading: "Social housing - Test your eligibility",
  // Types of properties
  property_type_L: "Appartment",
  property_type_M: "House",
  property_type_C: "Trade",
  property_type_G: "Parking",
  property_type_T: "Land",
  property_type_O: "Lodge",
  property_type_X: "Collective residential premises",
  property_type_A: "Antenna location",
  property_type_D: "Various associative premises",
  property_type_E: "Advertising space",
  property_type_P: "Professional accommodation",
  property_type_N: "Terrace",
  property_type_F: "Home",
  property_type_R: "Residence",
  property_type_B: "Office",
  property_type_H: "Technical room",
  property_type_Z: "Rebilling",
  property_type_Y: "Site location",
  //Home filters
  home_type_filter: "Property type",
  home_typology_filter: "Typology",
  home_localisation_filter: "Localization",
  home_number_of_rooms_filter: "Number of rooms",
  //Offers Application Form
  simple_application: "Simple Application",
  application_description: "Description",
  application_add_cv : "+ Add a CV",
  application_add_motivational_letter : "+ Add a motivational letter",
  application_error_email:"Please complete all required fields",
  application_success: "Application sent successfully",
  //Attestation d'Assurance
  insurance_email_success:"Your insurance is sent to your agency",
  // Our housing stock view
  our_housing_stock_title: "Our housing stock",
  // Nous contacter
  your_request_concerns: "Your request concerns",
  communication_press: "Communication / Press",
  marketing_and_ux: "Marketing and Customer Experience Service",
  attached_file: "Attached file",
  add_attached_file: "+ Add an attachment",
  contact_form_error_email: "Please complete all required fields",
  spontaneous_application: "Spontaneous application",

  home_card_know_more: "Learn more",
  phone_tenant_center_title: "The tenant call center",
  phone_tenant_center: "A question about your accommodation? Your tenant file? Contact the Tenant Relations Center from Monday to Friday, from 8am to 6pm on  <strong>09 69 370 370</strong> (free service + call charge).",
  phone_on_call_title: "Assistance Les Résidences",
  phone_on_call: "For any emergency, outside the opening hours of your guards' lodges, contact assistance on <strong class='flex-nowrap d-inline-block'>0 820 89 10 10</strong>.<br/><br/>" +
    "(0.12€ per minute from a fixed line - From a cell phone: rate according to operator)<br/><br/>" +
    "<strong>Week</strong>: from 6pm to 8am<br/>" +
    "<strong>Weekend</strong>: from Friday 6pm to Monday 8am<br/>" +
    "<strong>Public holidays</strong>: from 6pm the day before to 8am the day after",
  select_your_room: "Select your room",
  choose_your_equipment: "Choose your equipment",
  in_charge_of: "In charge of",
  of_lrye: "The Résidences Yvelines Essonne",
  of_tenant: "The tenant",

  // districts
  district: "District",
  district_proximity: "Proximity",
  district_education: "Education",
  district_transport: "Transport",
  district_elementary_school: "Educational school",
  district_middle_school: "High school",
  district_high_school: "High school",
  district_health: "Health",
  district_bus: "Bus",
  district_number_of_elementary_school: "{count, plural, =0 {} one {1 elementary school} other {# elementary schools}}",
  district_number_of_middle_school: "{count, plural, =0 {} one {1 high school} other {# high schools}}",
  district_number_of_high_school: "{count, plural, =0 {} one {1 college} other {# colleges}}",

}

export default EnglishMessages
