import React, { FunctionComponent, ReactElement } from "react";

// Styles : styles/molecules/menuSocial

import Nav from "components/atoms/Nav";
import { NavItem } from "reactstrap";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import ButtonPhone from "./ButtonPhone";
import { LINKEDIN_PAGE_LINK, YOUTUBE_CHANNEL_LINK } from "../../constants/links/ExternalLinks";

interface MenuSocialProps {
  onPhoneClick: () => void;
}

const MenuSocial: FunctionComponent<MenuSocialProps> = ({ onPhoneClick }): ReactElement => {
  return (
    <Nav className="MenuSocial">
      <NavItem>
        <Button tag="a" color="dark" href={LINKEDIN_PAGE_LINK} target="_blank" outline pill icon><Icon name="LinkedIn" /></Button>
      </NavItem>
      <NavItem>
        <Button tag="a" color="dark" href={YOUTUBE_CHANNEL_LINK} target="_blank" outline pill icon><Icon name="Youtube" /></Button>
      </NavItem>
      <NavItem>
        <ButtonPhone number="09 69 370 370" onClick={onPhoneClick} />
      </NavItem>
    </Nav>
  )
}

export default MenuSocial;
