import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CardBody, CardFooter, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import Row from "components/atoms/Row";
import Card from "components/atoms/Card";
import { rentService } from "../../services/RentService";
import {PaymentLinkResponse, Rent} from "../../interfaces/rents/RentsInterfaces";
import {BlocPaiementInterface} from "../../interfaces/blocPaiement/BlocPaiementInterface";
import {blocsPaiementService} from "../../services/BlocsPaiementService";
import {callEwayPayment} from "../../utils/callEwayPayment";
import {User} from "../../interfaces/users/UsersInterfaces";
import {authService} from "../../services/AuthService";

interface PaymentSectionProps {
  selectedContract: string;
}

const PaymentSection: FunctionComponent<PaymentSectionProps> = ({ selectedContract }): ReactElement => {
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [paymentLinkLoading, setPaymentLinkLoading] = useState<boolean>(false);
  const [blocsPaiement, setBlocsPaiement] = useState<BlocPaiementInterface[]>();
  const [user, setUser] = useState<User>()
  const [paymentAmount, setPaymentAmount] = useState<string>("");

  useEffect(() => {
    setUser(authService.getLoggedInUser()?.user);
    void blocsPaiementService.findAll()
        .then((result: BlocPaiementInterface[]) => {
          setBlocsPaiement(result);
        })
    void rentService.getRents(selectedContract)
        .then((response: Rent[]) => {
          const dept = response[0]?.debit - response[0]?.credit || 0;
          
          if (dept > 0) {
            setPaymentAmount(`${dept.toFixed(2)}`)
          } else {
            setPaymentAmount("0")
          }
        });
  }, [])


  useEffect(() => {
    if (selectedContract) {
      setPaymentLinkLoading(true);
      void rentService.getPaymentLink(selectedContract)
        .then((response: PaymentLinkResponse) => {
          setPaymentLink(response.link)
        }).finally(() => {
          setPaymentLinkLoading(false);
        });
    }
  }, [selectedContract])

  return (
    <Row grid>
      {blocsPaiement && blocsPaiement.map((blocPaiement: BlocPaiementInterface, key: number) =>
        <Col xs="12" md="6" key={key}>
          <Card rounded>
            <CardHeader>
              <CardTitle>
                {blocPaiement.titre}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <CardText>{blocPaiement.corps}</CardText>
            </CardBody>
            <CardFooter>
              {blocPaiement.fichier ?
                  <a href={blocPaiement.fichier.url} download className="Button btn btn-primary">{blocPaiement.CTA}</a>
              :
                  <a
                      onClick={() => {
                        setPaymentAmount(`${parseFloat(paymentAmount).toFixed(2)}`)
                        callEwayPayment(selectedContract, user.email, `${parseFloat(paymentAmount).toFixed(2)}`, paymentLink)
                      }}
                      className={"Button btn btn-primary" + (paymentLinkLoading ? " disabled" : "")}
                  >
                    {blocPaiement.CTA}
                  </a>
              }
            </CardFooter>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default PaymentSection;
