import React, { FunctionComponent } from "react"

// Styles : styles/atoms/button

// Reactstrap
import { Button as LibButton, ButtonProps as LibButtonProps } from "reactstrap"
import { useIntl } from "react-intl";

type ButtonProps = LibButtonProps & {
  pill?: boolean;
  icon?: boolean;
  title?: string;
  selected?: boolean;
  size?: "lg" | "sm" | "xs";
  hasShadow?: boolean;
  vertical?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  className = "",
  pill,
  icon,
  title,
  selected,
  hasShadow,
  vertical,
  ...rest
}) => {
  const intl = useIntl();

  const ButtonClassName =
    (pill ? " ButtonPill" : "") +
    (icon ? " ButtonIcon" : "") +
    (selected ? " selected" : "") +
    (hasShadow ? " HasShadow" : "") +
    (vertical ? " flex-column" : "") +
    (className ? " " + className : "")
  return (
    <LibButton
      className={`Button ${ButtonClassName}`}
      {...rest}
    >
      {title && intl.formatMessage({ id: title })}
      {children}
    </LibButton>
  )
}
export default Button
