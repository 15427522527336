import React, { FunctionComponent, ReactElement } from "react";
import { FilterItem } from "../../interfaces/form/FormsInterfaces";
import { FormGroup } from "reactstrap";
import Label from "../atoms/Label";
import Input from "../atoms/Input";

interface ChooseContainerProps {
  name: string;
  items: FilterItem[];
  onClick: (name: string, value: string | number | undefined) => void;
  selected: (string | number)[];
  disabled?: boolean;
}

const ChooseContainer: FunctionComponent<ChooseContainerProps> = ({
  name,
  items,
  onClick,
  selected,
  disabled= false
}): ReactElement => {
  return (
    <div className="d-flex">
      {items.map((item: FilterItem, index: number) => (
        <FormGroup className="mr-3" key={`${name}_${item.label}`}>
          <Label check button bsSize="lg">
            <Input
              type="checkbox"
              name={`${name}${index}`}
              bsSize="lg"
              onClick={() => onClick(name, item.value)}
              checked={selected.includes(item.value)}
              disabled={disabled}
            />
            <span>{item.label}</span>
          </Label>
        </FormGroup>
        ))}
    </div>
  );
}

export default ChooseContainer;
