import React, { FunctionComponent } from "react"

// Styles : styles/atoms/input

import {  FormFeedback, Input as LibInput, InputProps as LibInputProps } from "reactstrap"

type InputProps = LibInputProps & {
  type?: string;
  placeholder?: string | JSX.Element;
  label?: string | JSX.Element;
  readOnly?: boolean;
  autoComplete?: string;
  labelClassName?: string;
  autoFocus?: boolean;
  list?: string;
  row?: boolean;
  button?: boolean;
  rows?: number;
  multiple?: boolean;
  id?: string;
  className?: string;
  noBorder?: boolean;
}

const Input: FunctionComponent<InputProps> = ({
  name,
  value,
  type = "text",
  placeholder = "",
  disabled = false,
  onChange,
  invalid,
  errorMessage,
  readOnly = false,
  autoComplete = "true",
  autoFocus = false,
  list,
  rows,
  multiple,
  id,
  className,
  button,
  noBorder,
  ...rest
}) => {

  const InputClassName =
    (noBorder ? " NoBorder" : "") +
    (button ? " InputButton" : "") +
    (className ? " " + className : "") 
  return (
    <>
      <LibInput
        id={id}
        type={type}
        name={name}
        placeholder={placeholder.toString()}
        value={value}
        disabled={disabled}
        onChange={onChange}
        invalid={invalid}
        readOnly={readOnly}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        list={list}
        rows={rows}
        multiple={multiple}
        className={`Input ${InputClassName}`}
        {...rest}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </>
  )
}

export default Input
