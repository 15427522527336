import React, { FunctionComponent, useEffect, useState } from "react"
import { RouteProps, useParams } from "react-router"
import { useQuery } from "@apollo/client"
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../components/atoms/Section";
import { EDITORIAL_GROUP_QUERY, EDITORIAL_PAGE_QUERY } from "../constants/queries/FooterQueries";
import { scrollTop } from "../utils/utilFunctions";
import { EditorialGroupQueryInterface, EditorialPageQueryInterface } from "../interfaces/footer/FooterInterfaces";
import { OUR_COMMITMENTS_PATH } from "../constants/routes/RoutePaths";
import Breadcrumb from "../components/molecules/Breadcrumb";
import { editorialService } from "../services/EditorialService";
import BasicModal from "../components/molecules/BasicModal";
import ApplicationForm from "../components/molecules/ApplicationForm";
import { useIntl } from "react-intl";

interface RouteParams {
  commitmentUrlKey: string;
}

const OurCommitmentsSpecificView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const { commitmentUrlKey } = useParams<RouteParams>()

  const [modal, setModal] = useState<boolean>(false);

  const {
    data: editorialData,
    error: editorialError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: commitmentUrlKey } });
  handleApolloError("editorial_page_query", editorialError);
  const editorialPage = editorialData?.editorialPages[0];

  const {
    data: editorialGroupData,
    error: editorialGroupError
  } = useQuery<EditorialGroupQueryInterface>(EDITORIAL_GROUP_QUERY, { variables: { urlKey: OUR_COMMITMENTS_PATH.split("/")[1] } });
  handleApolloError("editorial_group_query", editorialGroupError);
  const editorialGroup = editorialGroupData?.editorialGroups[0];

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    const modalButton = document.getElementById("MODAL_BUTTON");
    if (modalButton) {
      modalButton.onclick = () => {
        setModal(true);
      }
    }
  })

  return (
    <Layout>
      <>
        <BasicModal
          open={modal}
          body={(
            <ApplicationForm
              modalTitle={intl.formatMessage({ id: "simple_application" })}
              offerTitle={intl.formatMessage({ id: "spontaneous_application" })}
              closeModal={() => setModal(false)}
            />
          )}
          onClose={() => setModal((state: boolean) => !state)}
        />

        <Section>
          {editorialPage && (
            <>
              {
                editorialGroup && (
                  <Breadcrumb firstText={editorialGroup.title} secondText={editorialPage.title}
                              backLink={OUR_COMMITMENTS_PATH} />
                )
              }
              <div className="p-5">
                <h1 className="h1 mb-5">{editorialPage.title}</h1>
                <ReactMarkdownWithHtml
                  allowDangerousHtml
                  plugins={[gfm]}
                  className="description"
                >
                  {editorialService.handleCustomComponent(editorialPage.content)}
                </ReactMarkdownWithHtml>
              </div>
            </>
          )}
        </Section>
      </>
    </Layout>
  )
}

export default OurCommitmentsSpecificView
