import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback } from "react";
import { getPages } from "../../services/PaginationService";
import { PageItem } from "../../interfaces/pagination/PaginationInterfaces";

interface PaginationProps {
  current: number;
  total: number;
  showOffset?: number;
  setPage: Dispatch<SetStateAction<number>>
}

const Pagination: FunctionComponent<PaginationProps> = ({
  current,
  total,
  showOffset = 1,
  setPage,
}): ReactElement => {

  const onClick = useCallback((page: PageItem) => {
    page.value && setPage(page.value)
  }, [])

  return (
    <div className="Pagination">
      {getPages(current, total, showOffset).map((page: PageItem, index: number) => (
        <span
          onClick={() => onClick(page)}
          className={`PaginationItem px-2 ${current === page.value ? "selected" : ""}`}
          key={`page_${index}`}
        >
          {page.label}
        </span>
      ))}
    </div>
  );
}

export default Pagination;