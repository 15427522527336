import Badge from "components/atoms/Badge";
import React, { FunctionComponent, ReactElement } from "react";
import { Tag } from "../../interfaces/tags/TagsInterfaces";
import {TagColors} from "../../constants/colors/Colors";

interface TagProps {
  tag: Tag;
  className?: string;
}

const TagLabel: FunctionComponent<TagProps> = ({ tag, className= "" }): ReactElement => {
  return tag ? (
    <Badge pill color={TagColors[tag.color]?.cssColorClass} className={`TagLabel ${className}`}>{tag.name}</Badge>
  ) : null
}

export default TagLabel;
