import React, { FunctionComponent } from "react"
import Carousel from "components/atoms/Carousel";
import { Media } from "interfaces/common/CommonInterfaces";
import { SwiperSlide } from "swiper/react";
import { CardImg, ModalBody } from "reactstrap";
import Figure from "components/atoms/Figure";
import { FIGURE_RATIOS } from "constants/common/CommonConstants";
import { imageService } from "../../services/ImageService";
import Modal from "../atoms/Modal";
import { ImageFormat } from "../../constants/images/ImagesConstants";

interface ModalCarouselProps {
  photos?: Media[],
  open: boolean;
  onClose: () => void;
  category?: string;
}

const ModalCarousel: FunctionComponent<ModalCarouselProps> = ({
  photos = [],
  open,
  onClose,
  category
}) => {
  const initialPhotos: Media[] = Array(4).fill({ url: imageService.getIconByCategory(category) })

  const mappedPhotos = photos.length !== 0 ? photos : initialPhotos

  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      size="lg"
    >
      <ModalBody>
        <Carousel>
          {mappedPhotos.map((photo, index) => (
            <SwiperSlide key={index}>
              <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                <CardImg src={imageService.getImageUrlByFormat(photo, ImageFormat.LARGE)} alt="" />
              </Figure>
            </SwiperSlide>
          ))}
        </Carousel>
      </ModalBody>
    </Modal>
  )
};

export default ModalCarousel;
