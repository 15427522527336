import React, { FunctionComponent } from "react";
import { TicketStepperTitle } from "../../interfaces/tickets/TicketsInterfaces";
import TicketStepperItem from "../atoms/TicketStepperItem";
import { FormattedMessage } from "react-intl";

interface TicketStepperProps {
  step: number;
  setStep: (step: number) => void;
  titles: TicketStepperTitle[];
}

const TicketStepper: FunctionComponent<TicketStepperProps> = ({
  step,
  titles,
  setStep
}) => {
  const selectedTitle = titles[step - 1];
  return (
    <>
      <div className="ticket-stepper mx-auto">
        {titles.map((title: TicketStepperTitle, index: number) => {
          const stepIndex = index + 1;
          return (
            <TicketStepperItem
              key={title.title}
              title={title}
              onClick={() => stepIndex < step && setStep(stepIndex)}
              validated={stepIndex < step}
              selected={stepIndex === step}
              step={stepIndex}
            />
          )
        })}
      </div>
      <div className="ticket-stepper-mobile text-center">
        <div className="title">
          {selectedTitle && <FormattedMessage id={selectedTitle.title} />}
        </div>
        {selectedTitle?.subtitle && (
          <div className="subtitle">
            <FormattedMessage id={selectedTitle.subtitle} defaultMessage={selectedTitle.subtitle} />
          </div>
        )}
      </div>
    </>
  )
}

export default TicketStepper;