import { WaterTypeInterface } from "../../interfaces/water/WaterInterfaces";
import { colorService } from "../../services/ColorService";
import { PRIMARY_COLORS } from "../colors/Colors";

export enum WATER_TYPE_ENUM {
  COLD = "F",
  HOT = "C",
}

export const WATER_LOCATION_LABELS = {
  AUT: "water_location_other",
  BUR: "water_location_office",
  CAV: "water_location_cellar",
  CEL: "water_location_storeroom",
  CHA: "water_location_bedroom",
  COU: "water_location_corridor",
  CUI: "water_location_kitchen",
  DEB: "water_location_boxroom",
  ENT: "water_location_entrance",
  EXT: "water_location_outside",
  GAR: "water_location_garage",
  GPA: "water_location_gpa",
  HAL: "water_location_lobby",
  PAL: "water_location_landing",
  PLA: "water_location_closet",
  SDB: "water_location_bathroom",
  SDJ: "water_location_living_room",
  SSL: "water_location_basement",
  VIS: "water_location_crawl_space",
  WC: "water_location_restroom",
  UNK: "water_location_unknown",
}

export const WATER_TYPE: WaterTypeInterface = {
  [WATER_TYPE_ENUM.HOT]: {
    label: "water_type_hot",
    color: {
      [PRIMARY_COLORS.RED]: "FF",
      [PRIMARY_COLORS.GREEN]: "7F",
      [PRIMARY_COLORS.BLUE]: "50",
    },
    colorShading: {
      [PRIMARY_COLORS.RED]: (color: string, multiplier: number) => Math.max(colorService.toDecimal(color) - multiplier * 40, 0),
      [PRIMARY_COLORS.GREEN]: (color: string, multiplier: number) => Math.max(colorService.toDecimal(color) - multiplier * 50, 0),
      [PRIMARY_COLORS.BLUE]: (color: string, multiplier: number) => Math.max(colorService.toDecimal(color) - multiplier * 10, 0),
    }
  },
  [WATER_TYPE_ENUM.COLD]: {
    label: "water_type_cold",
    color: {
      [PRIMARY_COLORS.RED]: "87",
      [PRIMARY_COLORS.GREEN]: "CE",
      [PRIMARY_COLORS.BLUE]: "EB",
    },
    colorShading: {
      [PRIMARY_COLORS.RED]: (color: string, multiplier: number) => Math.max(colorService.toDecimal(color) - multiplier * 20, 0),
      [PRIMARY_COLORS.GREEN]: (color: string, multiplier: number) => Math.max(colorService.toDecimal(color) - multiplier * 60, 0),
      [PRIMARY_COLORS.BLUE]: (color: string, multiplier: number) => Math.min(colorService.toDecimal(color) + multiplier * 10, 255),
    }
  },
}

export const WATER_SUPPLIERS = {
  "4": "OCEA SMART BUILDING",
  "1": "PROXISERVE"
}