import { TicketCard, TicketItems, Tickets, TicketStepperTitle } from "../../interfaces/tickets/TicketsInterfaces";

export const TICKET_STATUS = {
  CLOSED: {
    id: 7
  },
}

export const TICKETS = {
  LEAK: "leak",
  BLOCKED_PIPES: "blocked_pipes",
  HOT_WATER_FAILURE: "hot_water_failure",
  INSECT_OR_PEST: "insect_or_pest",
  BLACKOUT: "blackout",
  MALFUNCTION_IN_COMMON_AREAS: "malfunction_in_common_areas",
  NEW_OCCUPANT_IN_THE_ACCOMMODATION: "new_occupant_in_the_accommodation",
  REQUEST_FOR_TRANSFER: "request_for_transfer",
  CALLBACK_REQUEST: "callback_request"
} as Tickets;

export const STEPPER_TITLES = [
  {
    title: "stepper_your_ticket",
  },
  {
    title: "stepper_localization",
    categories: [TICKETS.LEAK, TICKETS.BLOCKED_PIPES, TICKETS.BLACKOUT, TICKETS.MALFUNCTION_IN_COMMON_AREAS]
  },
  {
    title: "stepper_importance",
    categories: [TICKETS.LEAK]
  },
  {
    title: "stepper_contact",
    categories: [TICKETS.CALLBACK_REQUEST]
  },
  {
    title: "stepper_schedule",
    categories: [TICKETS.CALLBACK_REQUEST]
  },
  {
    title: "stepper_transfer",
    categories: [TICKETS.REQUEST_FOR_TRANSFER]
  },
  {
    title: "stepper_relationship",
    categories: [TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION]
  },
  {
    title: "stepper_situation",
    categories: [TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION]
  },
  {
    title: "stepper_type",
    categories: [TICKETS.HOT_WATER_FAILURE, TICKETS.INSECT_OR_PEST]
  },
  {
    title: "stepper_description",
  },
] as TicketStepperTitle[];

export const TICKET_CATEGORIES_ALL = [
  {
    icon: "Fuite",
    name: TICKETS.LEAK,
  },
  {
    icon: "Canalisation",
    name: TICKETS.BLOCKED_PIPES,
  },
  {
    icon: "Panne",
    name: TICKETS.HOT_WATER_FAILURE,
  },
  {
    icon: "Insecte",
    name: TICKETS.INSECT_OR_PEST,
  },
  {
    icon: "Electricite",
    name: TICKETS.BLACKOUT,
  },
  {
    icon: "Communes",
    name: TICKETS.MALFUNCTION_IN_COMMON_AREAS,
  },
  {
    icon: "NouvelOccupant",
    name: TICKETS.NEW_OCCUPANT_IN_THE_ACCOMMODATION,
  },
  {
    icon: "Mutation",
    name: TICKETS.REQUEST_FOR_TRANSFER,
  },
  {
    icon: "Rappel",
    name: TICKETS.CALLBACK_REQUEST,
  }
] as TicketCard[]

export const TICKET_CATEGORIES = {
  all: TICKET_CATEGORIES_ALL,
} as TicketItems

export const TICKET_IMPORTANCES = {
  leak: [
    {
      name: "drip",
    },
    {
      name: "discontinuous_thread",
    },
    {
      name: "big_flow",
    }
  ]
}

export const TICKET_CONTACTS = {
  callback_request: [
    {
      icon: "Phone",
      name: "by_phone",
    },
    {
      icon: "Mail",
      name: "by_mail",
    }
  ]
}

export const TICKET_LOCALIZATIONS = {
  leak: [
    {
      name: "leak_localization_kitchen"
    },
    {
      name: "leak_localization_bathroom"
    },
    {
      name: "leak_localization_other_room"
    }
  ],
  blocked_pipes: [
    {
      name: "blocked_pipes_localization_kitchen_sink"
    },
    {
      name: "blocked_pipes_localization_bath"
    },
    {
      name: "blocked_pipes_localization_toilet"
    }
  ],
  blackout: [
    {
      name: "blackout_localization_one"
    },
    {
      name: "blackout_localization_one_room"
    },
    {
      name: "blackout_localization_general"
    }
  ],
  malfunction_in_common_areas: [
    {
      name: "malfunction_in_common_areas_localization_electric"
    },
    {
      name: "malfunction_in_common_areas_localization_elevator"
    },
    {
      name: "malfunction_in_common_areas_localization_intercom"
    },
    {
      name: "malfunction_in_common_areas_localization_smoke_extraction"
    },
    {
      name: "malfunction_in_common_areas_localization_main_door"
    },
    {
      name: "malfunction_in_common_areas_localization_parking"
    },
    {
      name: "malfunction_in_common_areas_localization_squat"
    },
  ],
} as TicketItems;

export const TICKET_TYPES = {
  hot_water_failure: [
    {
      name: "hot_water_failure_type_hot_water_tank"
    },
    {
      name: "hot_water_failure_type_heater"
    }
  ],
  insect_or_pest: [
    {
      name: "insect_or_pest_type_cockroaches",
      icon: "Youtube"
    },
    {
      name: "insect_or_pest_type_bugs",
      icon: "Youtube"
    },
    {
      name: "insect_or_pest_type_rodents",
      icon: "Youtube"
    },
    {
      name: "insect_or_pest_type_pigeons",
      icon: "Youtube"
    },
  ]
} as TicketItems;

export const TICKET_RELATIONSHIPS = {
  new_occupant_in_the_accommodation: [
    {
      name: "new_occupant_in_the_accommodation_relationship_partner"
    },
    {
      name: "new_occupant_in_the_accommodation_relationship_child"
    },
    {
      name: "new_occupant_in_the_accommodation_relationship_sibling"
    },
    {
      name: "new_occupant_in_the_accommodation_relationship_none"
    },
  ]
} as TicketItems;

export const TICKET_SITUATIONS = {
  new_occupant_in_the_accommodation: [
    {
      name: "new_occupant_in_the_accommodation_situation_dependent"
    },
    {
      name: "new_occupant_in_the_accommodation_situation_custody"
    },
    {
      name: "new_occupant_in_the_accommodation_situation_visiting"
    },
  ]
} as TicketItems;

export const TICKET_SCHEDULES = {
  callback_request: [
    {
      name: "callback_request_schedule_12"
    },
    {
      name: "callback_request_schedule_14"
    },
    {
      name: "callback_request_schedule_16"
    }
  ]
} as TicketItems;