import Section from "components/atoms/Section";
import Layout from "components/layout/Layout";
import React, { FunctionComponent } from "react";
import { RouteProps } from "react-router-dom";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { ARTICLE_DETAIL_QUERY } from "../constants/queries/ArticleQueries";
import { ArticlesQuery } from "../interfaces/articles/ArticlesInterfaces";
import TagLabel from "../components/molecules/TagLabel";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { podcastUtils } from "../utils/podcastUtils";
import { AUSHA_PLAYER_ID } from "../constants/links/ExternalLinks";
import { Col } from "reactstrap";
import Figure from "components/atoms/Figure";
import Img from "components/atoms/Img";
import Row from "components/atoms/Row";
import { handleApolloError } from "../hooks/handleApolloError";
import YoutubeVideoContainer from "../components/molecules/YoutubeVideoContainer";
import BackButton from "../components/molecules/BackButton";
import { useIntl } from "react-intl";

interface RouteParams {
  id: string;
}

const ArticleDetailView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const { id } = useParams<RouteParams>()

  const { data, error } = useQuery<ArticlesQuery>(ARTICLE_DETAIL_QUERY, {
    variables: { urlKey: id },
    errorPolicy: "all"
  });
  handleApolloError("article_detail_query", error);
  const article = data?.articles[0];
  if (!article) {
    return null;
  }

  article.photo;

  return (
    <Layout className="article-detail">
      <Section>
        <BackButton text={intl.formatMessage({ id: "back" })} />

        {article.youtubeId ? (
          <Figure sizeAuto fill className="justify-content-center">
            <YoutubeVideoContainer videoID={article.youtubeId} />
          </Figure>
        ) : (
          <></>          
        )}

        <Row>
          <Col xs="12" md="4">
            <h1 className="h1">{article.title}</h1>
          </Col>
          
          <Col xs="12" md="8">
            <Figure className="d-block mb-5" cap>
              <>
                <TagLabel className="d-block mb-3" tag={article.tag} />
                {article.photo && <Img src={article.photo.url} alt="" />}
              </>
            </Figure>
            <p className="lead text-justify">{article.chapeau}</p>
            {article.podcast && (
              <iframe
                className="podcast"
                frameBorder="0"
                loading="lazy"
                id={AUSHA_PLAYER_ID}
                src={podcastUtils.getSourceFromIframe(article.podcast)}
              />
            )}
            <ReactMarkdownWithHtml
              allowDangerousHtml
              plugins={[gfm]}
              className="description mw-100 text-justify"
            >
              {article.description}
            </ReactMarkdownWithHtml>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}

export default ArticleDetailView;
