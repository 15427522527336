import { Agency, AgencyCoordinate } from "interfaces/agencies/AgenciesInterfaces";
import { MarkerInterface, MarkerType } from "interfaces/map/MapInterfaces";
import { LatLng } from "leaflet";

const mapAgencyToMarkers = (agency: Agency): MarkerInterface[] => {
  if (!agency || agency.longitude === null || agency.latitude === null) {
    return []
  }
  return [{
    id: agency.id,
    position: new LatLng(agency.latitude, agency.longitude),
    title: agency.name,
    type: MarkerType.agency
  },
    ...(agency?.coordinates.map((coordinate: AgencyCoordinate): MarkerInterface => ({
      position: new LatLng(coordinate.latitude, coordinate.longitude),
      type: MarkerType.agency_city,
    })) || [])
  ]
}

export const agencyService = {
  mapAgencyToMarkers
}
