import { FormCommunity } from "components/formstepper/FormCommunity";
import { FormCouple } from "components/formstepper/FormCouple";
import { FormHandicap } from "components/formstepper/FormHandicap";
import { FormIncome } from "components/formstepper/FormIncome";
import { FormNbDependents } from "components/formstepper/FormNbDependents";
import { FormStatus } from "components/formstepper/FormStatus";
import FormEligibilityResult from "components/formstepper/FormTestResult";
import { FormStepperValues, SubmitEligibilityResponse } from "interfaces/formstepper/FormStepperInterfaces";
import React, { FunctionComponent, useState } from "react";
import { eligibilityService } from "services/EligibilityService";


const EligibilityStepper: FunctionComponent = () => {

  const [step, setStep] = useState(0);
  const [eligibilityResult, setEligibility] = useState<SubmitEligibilityResponse>();
  const [formData, setFormData] = useState<FormStepperValues>({
    community: "",
    status: undefined,
    handicap: undefined,
    nbOfDependents: 0,
    income: undefined,
    youngCouple: undefined
  });
  const setFormValues = (values) => setFormData(values)
  const nextStep = (step?: number) => { step ? setStep(step) : setStep(prev => prev + 1) };
  const prevStep = (step?: number) => { step ? setStep(step) : setStep(prev => prev - 1) };

  const submitForm = async (values) => {
    setFormData(values)
    const result = await eligibilityService.submitEligibilityForm(values)
    setEligibility(result);
    nextStep();
  }
  switch (step) {
    case 0:
      return (
        <FormCommunity formData={formData} setFormData={setFormValues}
          nextStep={nextStep} />
      )
    case 1:
      return (
        <FormStatus formData={formData} setFormData={setFormValues}
          nextStep={nextStep} prevStep={prevStep} />
      )
      case 2:
        return (
          <FormCouple formData={formData} setFormData={setFormValues}
            nextStep={nextStep} prevStep={prevStep} />
        )
    case 3:
      return (
        <FormHandicap formData={formData} setFormData={setFormValues}
          nextStep={nextStep} prevStep={prevStep} />
      )
    case 4:
      return (
        <FormNbDependents formData={formData} setFormData={setFormValues}
          nextStep={nextStep} prevStep={prevStep} />
      )
    case 5:
      return (
        <FormIncome formData={formData} prevStep={prevStep} submitForm={submitForm} />
      )
    case 6:
      return (
        <FormEligibilityResult eligibilityResult={eligibilityResult} />
      )
    default:
      return (
        <FormStatus formData={formData} setFormData={setFormValues}
          nextStep={nextStep} prevStep={prevStep} />
      )
  }

}

export default EligibilityStepper