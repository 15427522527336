import React, { FunctionComponent } from "react"

// Styles : styles/atoms/img

type ImgProps = {
  src: string
  alt?: string
  className?: string
}

const Img: FunctionComponent<ImgProps> = ({
  src,
  alt,
  className = "",
}) => {
  return (
    <img
      className={`Img ${className}`}
      src={src}
      alt={alt}
    />
  )
}

export default Img
