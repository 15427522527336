import React, { FunctionComponent, useEffect, useState } from "react";

import { CardBody, CardHeader, CardImg, CardTitle, Col, Label, NavItem } from "reactstrap";

import Card from "components/atoms/Card";
import Figure from "components/atoms/Figure";
import Row from "components/atoms/Row";
import Section from "components/atoms/Section";
import Layout from "components/layout/Layout";
import BackButton from "components/molecules/BackButton";
import Map from "components/atoms/Map";
import MapBasic from "../components/molecules/MapBasic";
import Overlay from "components/atoms/Overlay";
import Nav from "components/atoms/Nav";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import Badge from "components/atoms/Badge";
import PropertyContactForm from "components/molecules/PropertyContactForm";
import { FIGURE_RATIOS, FORMATTED_ANSWERS } from "../constants/common/CommonConstants";
import { PROPERTIES_PHOTO_QUERY } from "constants/queries/PropertyQueries";
import { PropertiesPhotoQuery, PropertyDetailsQuery, PropertyPlan } from "interfaces/properties/PropertiesInterfaces";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import _ from "lodash";
import { propertyService } from "services/PropertyService";
import { getPriceMarker } from "../constants/map/MapConstants"
import { MarkerInterface } from "interfaces/map/MapInterfaces";
import { handleApolloError } from "../hooks/handleApolloError";
import ShareModal from "../components/molecules/ShareModal";
import { PROPERTY_DETAIL_QUERY } from "../constants/queries/PropertyQueries";
import ModalCarousel from "components/molecules/ModalCarousel";
import { FormattedMessage, useIntl } from "react-intl";
import PropertyLabel from "../components/molecules/PropertyLabel";
import ListExtSpace from "../components/molecules/ListExtSpace";
import { formatNumber, formatPriceTTC, scrollTop } from "utils/utilFunctions";
import { imageService } from "../services/ImageService";
import { ImageFormat } from "../constants/images/ImagesConstants";
import CardDistrict from "../components/molecules/CardDistrict";
import Plan from "components/molecules/Plan";
import { isStringAValidNumber } from "../utils/numberUtils";

const HousingDetailView: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const {
    data: dataPhoto,
    error: photoError
  } = useQuery<PropertiesPhotoQuery>(PROPERTIES_PHOTO_QUERY, { variables: { immowareIds: [id] } })
  const {
    data: propertyData,
    error: propertyDetailError
  } = useQuery<PropertyDetailsQuery>(PROPERTY_DETAIL_QUERY, { variables: { immowareId: id } })
  handleApolloError("properties_photo_query", photoError);
  handleApolloError("property_detail_query", propertyDetailError);
  const photos = dataPhoto?.properties[0] ? _.flatten(dataPhoto?.properties[0].photo) : []

  const [autoCenter, setAutoCenter] = useState<boolean>(true);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [modalCarouselOpen, setModalCarouselOpen] = useState<boolean>(false);

  const property = _.first(propertyData?.properties)
  const toggleShareModal = () => {
    setShareModalOpen((state: boolean) => !state)
  }
  const toggleModalCarousel = () => {
    setModalCarouselOpen((state: boolean) => !state)
  }
  useEffect(() => {
    scrollTop();
  }, [])
  const markers = property ? [propertyService.mapPropertyToMarker(property)].filter((marker: MarkerInterface) => !!marker) : [];
  const onePhoto = photos.length <= 1;
  const numberOfsmallPhotos = photos.length > 1 ? Math.min(photos.length - 1, 3) : 3;
  return (
    <Layout>
      <React.Fragment>
        <ShareModal open={shareModalOpen} onClose={toggleShareModal} property={property} />

        <ModalCarousel
          open={modalCarouselOpen}
          onClose={toggleModalCarousel}
          photos={photos}
          category={property?.category}
        />

        <BackButton text={intl.formatMessage({ id: "property_back_button" })} />

        <Section className="mt-3">
          <Row gutters="lg">
            <Col xs="12" md="8">
              <Row grid gutters="lg" flexDirection="column">
                <Col xs="auto">
                  <div className="Mosaic">
                    <Row grid gutters="md" flexDirection="row-reverse">
                      <Col xs="12" md={onePhoto ? 12 : 9}>
                        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                        <Card tag="a" onClick={photos.length != 0 ? toggleModalCarousel : () => {}} title="" noZoom noBorder rounded roundedRadius="sm">
                          <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                            {property && (
                              <CardImg
                                src={imageService.getImageUrlByFormat(photos[0], ImageFormat.MEDIUM) || imageService.getIconByCategory(property.category)}
                                alt=""
                              />
                            )}
                          </Figure>
                        </Card>
                      </Col>
                      {!onePhoto && (
                        <Col xs="12" md="3">
                          <Row grid gutters="md">
                            {Array(numberOfsmallPhotos).fill("").map((value, index: number) => (
                              <Col key={index} xs="3" md="12">
                                <Card
                                  tag="a"
                                  onClick={toggleModalCarousel}
                                  title=""
                                  noZoom
                                  noBorder
                                  rounded
                                  roundedRadius="sm"
                                >
                                  <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                                    <>
                                      {property && (
                                        <CardImg
                                          src={imageService.getImageUrlByFormat(photos[index + 1]) || imageService.getIconByCategory(property?.category)}
                                          alt=""
                                        />
                                      )}
                                      {photos.length > 4 && index === 2 && (
                                        <Overlay>
                                          <big className="font-size-h3">{`+ ${photos.length - 4}`}</big>
                                        </Overlay>
                                      )}
                                    </>
                                  </Figure>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      )}
                      <Col className="Info" xs="12" md="12">
                        <span>
                          <i>
                            {intl.formatMessage({ id: "property_photos_info" })}
                          </i>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs="auto">
                  <PropertyLabel property={property} />
                  <p
                    className="HousingLocation">{`${property?.complex.zip_code || ""} ${property?.complex.city || ""}`}</p>
                  <p className="HousingDescription"><Badge>Location</Badge></p>
                </Col>
                <Col xs="auto">
                  <Card rounded className="CardHousingProperties">
                    <CardBody>
                      <Row grid gutters="xl">
                        <Col xs="6" sm="4" md="3">
                          <Label>{intl.formatMessage({ id: "property_number_of_bedroom" })}</Label>
                          <strong>{property?.number_of_bedroom}</strong>
                        </Col>
                        <Col xs="6" sm="4" md="3">
                          <Label>{intl.formatMessage({ id: "property_number_of_bathroom" })}</Label>
                          <strong>{property?.number_of_bathroom}</strong>
                        </Col>
                        <Col xs="6" sm="4" md="3">
                          <Label>{intl.formatMessage({ id: "property_surface" })}</Label>
                          <strong>{property?.surface} m<sup>2</sup></strong>
                        </Col>
                        <Col xs="6" sm="4" md="3">
                          <Label>{intl.formatMessage({ id: "property_has_parking" })}</Label>
                          <strong>{property?.has_parking ? intl.formatMessage({ id: FORMATTED_ANSWERS.YES }) : intl.formatMessage({ id: FORMATTED_ANSWERS.NO })}</strong>
                        </Col>
                        <Col xs="6" sm="4" md="3">
                          <Label>{intl.formatMessage({ id: "property_has_elevator" })}</Label>
                          <strong>{property?.has_elevator ? intl.formatMessage({ id: FORMATTED_ANSWERS.YES }) : intl.formatMessage({ id: FORMATTED_ANSWERS.NO })}</strong>
                        </Col>
                        {property?.floor && (
                          <Col xs="6" sm="4" md="3">
                            <Label>{intl.formatMessage({ id: "property_floor" })}</Label>
                            <strong>{property?.floor}</strong>
                          </Col>
                        )}
                        <ListExtSpace property={property} />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="4">
              <Row grid gutters="lg" flexDirection="column">
                <Col xs="auto">
                  <Card rounded>
                    <Map>
                      <MapBasic
                        markers={markers}
                        getIcon={getPriceMarker}
                        setAutoCenter={setAutoCenter}
                        autoCenter={autoCenter}
                      />
                    </Map>
                    <CardBody>
                      <Row grid gutter="sm" alignItems="center" justifyContent="between" flexWrap="nowrap" flexFit
                           className="HousingPrice">
                        <Col xs="12">
                          <strong className="Price">
                            {formatNumber(property?.price, 2)} € <small><FormattedMessage
                            id="property_no_fees" /></small>
                          </strong>
                          <em className="PriceNet">
                            {formatPriceTTC(property?.price, property?.fees)} € <FormattedMessage
                            id="property_with_fees" />
                          </em>
                        </Col>
                        <Col xs="auto">
                          <Nav className="FooterSocial>">
                            <NavItem>
                              <Button onClick={toggleShareModal} tag="a" color="dark" outline pill icon>
                                <Icon name="Share" />
                              </Button>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>

                      {
                        property && property.agency && property.agency.phone && (
                          <p className="CallCenter">
                            <Badge color="primary" pill icon><Icon name="Phone"></Icon></Badge><span
                            className="Text">{intl.formatMessage({ id: "property_call_center" })}<strong>{property.agency.phone}</strong></span>
                          </p>
                        )
                      }
                    </CardBody>
                  </Card>
                </Col>
                {property?.agency?.email && (
                  <Col xs="auto">
                    <Card rounded>
                      <CardHeader>
                        <CardTitle>
                          {intl.formatMessage({ id: "property_info_form" })}
                        </CardTitle>
                      </CardHeader>
                      {property.complex?.company?.short_code? // récupérer la société depuis property.complex.company.short_code
                        <CardBody>
                          <PropertyContactForm propertyCompany={property.complex.company.short_code} propertyId={property?.id} to={property?.agency.email} />
                        </CardBody>
                      :property.company_short_code? // Sinon récupérer la société depuis property.company_short_code
                        <CardBody>
                          <PropertyContactForm propertyCompany={property.company_short_code} propertyId={property?.id} to={property?.agency.email} />
                        </CardBody>
                        : // Sinon la société par défaut est LRYE i.e "OPI"
                        <CardBody>
                            <PropertyContactForm propertyCompany="OPI" propertyId={property?.id} to={property?.agency.email} />
                        </CardBody> 
                      }                      
                      {/* {property.complex?.company?.short_code === "OPI" && ( 
                      <CardBody>
                        <PropertyContactForm propertyCompany="LLS" propertyId={property?.id} to={property?.agency.email} />
                      </CardBody>)
                      }                      */}
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {
            property && property.complex && property.complex.district && (
              <Row gutters="lg" className="mt-5">
                <Col>
                  <CardDistrict
                    title={property.complex.district.name}
                    youtubeId={property.complex.district.youtube_video?.youtubeId || undefined}
                  >
                    <>
                      {
                        property.complex.district.bus && (
                          <Section>
                            <h5><FormattedMessage id="district_transport" /></h5>
                            <ul>
                              <li>
                                {property.complex.district.bus}
                              </li>
                            </ul>
                          </Section>
                        )
                      }
                      {
                        (property.complex.district.elementary_school || property.complex.district.middle_school || property.complex.district.high_school) && (
                          <Section>
                            <h5><FormattedMessage id="district_education" /></h5>
                            <ul>
                              {
                                property.complex.district.elementary_school && property.complex.district.elementary_school !== "0" && (
                                  <li>
                                    {isStringAValidNumber(property.complex.district.elementary_school) ?
                                      (
                                        <FormattedMessage
                                          id="district_number_of_elementary_school"
                                          values={{ count: property.complex.district.elementary_school }}
                                        />
                                      ) :
                                      property.complex.district.elementary_school
                                    }
                                  </li>
                                )
                              }
                              {
                                property.complex.district.middle_school && property.complex.district.middle_school !== "0" && (
                                  <li>
                                    {isStringAValidNumber(property.complex.district.middle_school) ?
                                      (
                                        <FormattedMessage
                                          id="district_number_of_middle_school"
                                          values={{ count: property.complex.district.middle_school }}
                                        />
                                      ) :
                                      property.complex.district.middle_school
                                    }
                                  </li>
                                )
                              }
                              {
                                property.complex.district.high_school && property.complex.district.high_school !== "0" && (
                                  <li>
                                    {isStringAValidNumber(property.complex.district.high_school) ?
                                      (
                                        <FormattedMessage
                                          id="district_number_of_high_school"
                                          values={{ count: property.complex.district.high_school }}
                                        />
                                      ) :
                                      property.complex.district.high_school
                                    }
                                  </li>
                                )
                              }
                            </ul>
                          </Section>
                        )
                      }
                      {
                        property.complex.district.health && (
                          <Section>
                            <h5><FormattedMessage id="district_proximity" /></h5>
                            <ul>
                              <li>
                                {property.complex.district.health}
                              </li>
                            </ul>
                          </Section>
                        )
                      }
                    </>
                  </CardDistrict>
                </Col>
              </Row>
            )
          }
        </Section>

        {property?.plan && (
          <Section>
            {property.plans.length > 0 && (
              <h2>
                <FormattedMessage id="plan" />
              </h2>
            )}
            <div className="d-flex flex-wrap">
              {property.plans.map((plan: PropertyPlan) => (
                <Plan key={plan.name} plan={plan} />
              ))}
            </div>
          </Section>
        )}

      </React.Fragment>

    </Layout>
  )
}

export default HousingDetailView
