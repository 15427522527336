import { Water } from "../interfaces/water/WaterInterfaces";
import { WaterGraph } from "../interfaces/graph/GraphInterfaces";
import { formatDateToStandardShort } from "../utils/dateUtils";

const mapWaterMapToGraph = (waterMap: Map<string, Water[]>): WaterGraph[] => {
  const waterGraphs = [];
  for (const [date, waterList] of waterMap) {
    const waterGraph: WaterGraph = {
      date: formatDateToStandardShort(date),
    };
    waterList.forEach((water: Water) => {
      waterGraph[water.meterNumber] = water.value;
      waterGraph[`${water.meterNumber}_data`] = water;
    })
    waterGraphs.push(waterGraph);
  }
  return waterGraphs;
}

export const graphService = {
  mapWaterMapToGraph,
}