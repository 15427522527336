import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import Form from "../atoms/Form";
import { Col, DropdownItem, FormGroup, Label } from "reactstrap";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { Formik, FormikHelpers } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { AgenciesQueryInterface } from "../../interfaces/ourAgencies/OurAgenciesInterfaces";
import { AGENCIES_QUERY } from "../../constants/queries/AgencyQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import { Agency } from "../../interfaces/agencies/AgenciesInterfaces";
import * as Yup from "yup";
import FileCard from "../atoms/FileCard";
import { contactFormService } from "../../services/ContactFormService";
import { toastService } from "../../services/ToastService";
import { MailContactRequest } from "../../interfaces/mail/MailInterfaces";
import { COMMUNICATION_PRESS, MARKETING_AND_UX } from "../../constants/contact-form/ContactFormConstants";
import DropdownSelect from "./DropdownSelect";

interface ContactFormValues {
  lastName: string;
  firstName: string;
  email: string;
  agency: string;
  message: string;
  file?: File;
}

interface ContactFormProps {
  className?: string;
  email?: string;
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  className = "",
  email,
}): ReactElement => {
  const intl = useIntl();
  const validationSchema = Yup.object({
    lastName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    firstName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    email: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    message: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
  });

  const defaultAgency: Agency = {
    email: COMMUNICATION_PRESS,
    name: intl.formatMessage({ id: "communication_press" })
  }

  const marketingUxAgency: Agency = {
    email: MARKETING_AND_UX,
    name: intl.formatMessage({ id: "marketing_and_ux" })
  }

  const [file, setFile] = useState<File>();
  const [agency, setAgency] = useState<Agency>(marketingUxAgency);

  const { data: agenciesData, error: agenciesQueryError } = useQuery<AgenciesQueryInterface>(AGENCIES_QUERY)
  handleApolloError("agencies_query", agenciesQueryError);

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files[0])
  }

  const onSubmit = (
    values: ContactFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
  ) => {
    const data: MailContactRequest = {
      ...values,
      file,
    }
    contactFormService.submitContactForm(data, agency.email)
      .then(() => {
        toastService.success(intl.formatMessage({ id: "contact_form_success" }))
        resetForm();
        setFile(undefined);
      })
      .finally(() => setSubmitting(false))
  }

  const agencies = agenciesData?.agencies;

  useEffect(() => {
    setAgency(marketingUxAgency)
  }, [])

  return (
    <div className={className}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          lastName: "",
          firstName: "",
          email: "",
          agency: email,
          message: "",
          file: undefined,
        }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormGroup row>
              <Col xs={12} sm={4} className="col-form-label">
                <Label className="required" for="lastName">
                  <FormattedMessage id="create_account_last_name" /><span>*</span>
                </Label>
              </Col>
              <Col className="m-0">
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  invalid={touched.lastName && !!errors.lastName}
                  errorMessage={errors.lastName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} sm={4} className="col-form-label">
                <Label className="required" for="firstName">
                  <FormattedMessage id="create_account_first_name" /><span>*</span>
                </Label>
              </Col>
              <Col className="m-0">
                <Input
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  invalid={touched.firstName && !!errors.firstName}
                  errorMessage={errors.firstName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} sm={4} className="col-form-label">
                <Label className="required" for="email">
                  <FormattedMessage id="create_account_email" /><span>*</span>
                </Label>
              </Col>
              <Col className="m-0">
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  invalid={touched.email && !!errors.email}
                  errorMessage={errors.email}
                />
              </Col>
            </FormGroup>
            {!email && (
              <FormGroup row>
                <Col xs={12} sm={4} className="col-form-label">
                  <Label className="required" for="agency">
                    <FormattedMessage id="your_request_concerns" /><span>*</span>
                  </Label>
                </Col>
                {agencies && (
                  <Col className="m-0">
                    <DropdownSelect
                      bsSize="lg"
                      value={agency?.name}
                    >
                      <DropdownItem
                        value={intl.formatMessage({ id: "marketing_and_ux" })}
                        onClick={() => setAgency(marketingUxAgency)}>
                        <FormattedMessage id="marketing_and_ux" />
                      </DropdownItem>
                      <DropdownItem
                        value={intl.formatMessage({ id: "communication_press" })}
                        onClick={() => setAgency(defaultAgency)}>
                        <FormattedMessage id="communication_press" />
                      </DropdownItem>
                      {agencies.filter((agency: Agency) => !!agency.email).map((agency: Agency) => (
                        <DropdownItem
                          key={agency.name}
                          value={agency.name}
                          onClick={() => setAgency(agency)}>
                          {agency.name}
                        </DropdownItem>
                      ))}
                    </DropdownSelect>
                  </Col>

                )}
              </FormGroup>
            )}
            <FormGroup row>
              <Col xs={12} sm={4} className="col-form-label">
                <Label className="required" for="message">
                  <FormattedMessage id="share_message" /><span>*</span>
                </Label>
              </Col>
              <Col className="m-0">
                <Input
                  name="message"
                  type="textarea"
                  value={values.message}
                  onChange={handleChange}
                  invalid={touched.message && !!errors.message}
                  errorMessage={errors.message}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} sm={4} className="col-form-label align-self-start">
                <Label for="file">
                  <FormattedMessage id="attached_file" />
                </Label>
              </Col>
              <Col className="m-0">
                <Input
                  id="file"
                  className="d-none"
                  type="file"
                  name="file"
                  multiple={false}
                  onChange={onFileUpload}
                />
                <Label className="text-primary text-underline cursor-pointer mb-0" htmlFor="file">
                  <FormattedMessage id="add_attached_file" />
                </Label>
                <div className="d-flex flex-wrap">
                  {file && <FileCard file={file} />}
                </div>
              </Col>
            </FormGroup>
            <div className="d-flex justify-content-center py-3">
              <Button
                color="primary"
                type="submit"
                size="lg"
                disabled={isSubmitting}
              >
                <FormattedMessage id="contact_form_submit" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm;
