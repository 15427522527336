import React, { FunctionComponent, useState } from "react";
import AutoSuggest from "react-autosuggest";
import { normalizeSearchStr } from "../../utils/utilFunctions";
import { useIntl } from "react-intl";
import Input from "../atoms/Input";
import { COMMUNITY_LIST } from "../../constants/common/CommonConstants";

interface TownSuggesterProps {
  currentValue: string;
  onSuggestionsFetchRequested?: (value: string) => void;
  onSuggestionSelected?: (value: string) => void;
  onChange: (value: string) => void;
  placeholderLabel: string;
  inputBsSize?: "lg" | "sm";
  inputNoBorder?: boolean;
  inputId?: string;
  inputName?: string;
  inputType?: string
}

const TownSuggester: FunctionComponent<TownSuggesterProps> = ({
  currentValue,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  onChange,
  placeholderLabel,
  inputBsSize,
  inputNoBorder,
  inputId,
  inputName,
  inputType = "text"
}) => {
  const intl = useIntl();
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const getSuggestions = (value: string): string[] => {
    const inputValue = normalizeSearchStr(value).trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : COMMUNITY_LIST.map((community: string) => community)
      .filter((label: string) =>
        normalizeSearchStr(label).toLowerCase().startsWith(inputValue)
      );
  }

  const renderCustomInput = (inputProps) => {
    return (
      <Input
        name={inputName}
        id={inputId}
        placeholder={intl.formatMessage({ id: placeholderLabel })}
        bsSize={inputBsSize}
        noBorder={inputNoBorder}
        {...inputProps}
      />
    )
  }

  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionsFetchRequested={({ value }) => {
        if (onSuggestionsFetchRequested) onSuggestionsFetchRequested(value)
        setSuggestions(getSuggestions(value));
      }}
      onSuggestionSelected={(_, { suggestionValue }) => {
        if (onSuggestionSelected) onSuggestionSelected(suggestionValue)
      }}
      renderInputComponent={renderCustomInput}
      getSuggestionValue={suggestion => suggestion}
      renderSuggestion={suggestion => <div className="city-suggestion">{suggestion}</div>}
      inputProps={{
        value: currentValue,
        onChange: (_, { newValue }) => {
          onChange(newValue);
        },
        inputType: inputType
      }}
      highlightFirstSuggestion={true}
    />
  )
}

export default TownSuggester;
