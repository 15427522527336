import { gql } from "@apollo/client";
export const HOMEPAGE_QUERIES = gql`query Homepage
  {
  home {
        id
        titleService
        subtitleService
        services {
          id
          title: titre
          description
          url
          icon {
            name
            url
          }
        }
        titleResidence
        subtitleResidence
        titleAgency
        subtitleAgency
        agenciesYvelines {
          id
          name
          urlKey
          photo {
            url
            formats
          }
        }
        agenciesEssonne {
          id
          name
          urlKey
          photo {
            url
            formats
          }
        }
        titleArticle
        subtitleArticle
        articles {
          id
          title
          chapeau
          created_at
          readingTime
          urlKey
          photo {
            url
            formats
          }
        } 
        titleHelp
        subtitleHelp
        textHelp    
        youtubeId
    }
  }`

export const PARTIAL_HOMEPAGE_QUERY = gql`query Homepage
  {
    home {
      services {
        id
        title: titre
        description
        url
        displayHousingsPage
        icon {
          name
          url
        }
      }
      titleHelp
      subtitleHelp
      textHelp    
      youtubeId
      myHousingImage {
         url
         alternativeText
      }
    }
  }`
