import React, { FunctionComponent, ReactElement, useState } from "react";
import Figure from "../atoms/Figure";
import { CardBody, CardImg, CardText, CardTitle } from "reactstrap";
import Overlay from "../atoms/Overlay";
import Icon from "../atoms/Icon";
import Card from "../atoms/Card";
import { DocumentInterface } from "../../interfaces/documents/DocumentsInterfaces";
import { formatDateToStandardShort } from "../../utils/dateUtils";
import { documentService } from "../../services/DocumentService";
import { authService } from "../../services/AuthService";

interface CardDocumentProps {
  document: DocumentInterface;
  contractId: string;
}

const CardDocument: FunctionComponent<CardDocumentProps> = ({
  document,
  contractId,
}): ReactElement => {

  const user = authService.getLoggedInUser().user;

  const [loading, setLoading] = useState<boolean>(false);

  const onClick = () => {
    if (!loading) {
      setLoading(true);
      documentService.downloadFile(documentService.getUrl(contractId, document.name, user.tenantNumber))
      // TODO : Trouver un meilleur moyen de récupérer l'information lorsque le fichier a été téléchargé
      setTimeout(() => setLoading(false), 3000);
    }
  }

  return (
    <Card
      tag="a"
      onClick={onClick}
      disabled={loading}
      className="CardDocument"
      rounded
      roundedRadius="md"
    >
      <Figure occupation="50">
        <>
          <CardImg src="/pdf.svg"/>
          <Overlay noBg>
            <Icon name="Download" />
          </Overlay>
        </>
      </Figure>
      <CardBody>
        <CardTitle>{document.name}</CardTitle>
        <CardText className="d-flex justify-content-between">
          <span className="date">{formatDateToStandardShort(document.terme)}</span>
        </CardText>
      </CardBody>
    </Card>
  )
}

export default CardDocument;
