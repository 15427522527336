import React, { FunctionComponent, ReactElement } from "react";

import { history } from "../../constants/History";
import { AGENCY_DETAIL_PATH } from "../../constants/routes/RoutePaths";
import Figure from "../atoms/Figure";
import { CardImg, CardTitle } from "reactstrap";
import { imageService } from "../../services/ImageService";
import { PLACEHOLDERS } from "../../assets/Assets";
import Overlay from "../atoms/Overlay";
import Card from "../atoms/Card";
import { Agency } from "../../interfaces/agencies/AgenciesInterfaces";

interface AgencyCardProps {
  agency: Agency;
  onClick?: (email: string) => void;
  selected?: boolean;
}

const AgencyCard: FunctionComponent<AgencyCardProps> = ({
  agency,
  onClick,
  selected
}): ReactElement => {
  const onCardClick = () => {
    if (onClick) {
      onClick(agency.email);
    } else {
      history.push(AGENCY_DETAIL_PATH.replace(":agencyKey", agency.urlKey))
    }
  }

  return (
    <Card tag="a" className={`bg-transparent ${selected ? "selected" : ""}`} onClick={onCardClick} rounded>
      <Figure ratio="3-4">
        <React.Fragment>
          <CardImg src={imageService.getImageUrlByFormat(agency.photo) || PLACEHOLDERS._450x600} />
          <Overlay>
            <CardTitle>
              {agency.name}
            </CardTitle>
          </Overlay>
        </React.Fragment>
      </Figure>
    </Card>
  )
}

export default AgencyCard;
