import Button from "components/atoms/Button";
import { DEMAND_SOCIAL_HOUSING_LINK, } from "constants/links/ExternalLinks";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { history } from "../../constants/History";
import { PROPERTIES_MAP_PATH } from "../../constants/routes/RoutePaths";


export const TestEligibilitySocial: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <>
      <p>{intl.formatMessage({ id: "form_result_eligible_message" })}
        <strong>{intl.formatMessage({ id: "form_result_eligible_type_social" })}</strong>
        <span>{intl.formatMessage({ id: "form_result_eligible_type_social_info" })}</span>
      </p>
      <Button className="mb-4" onClick={() => history.push(PROPERTIES_MAP_PATH)} color="primary">{intl.formatMessage({ id: "form_result_link_discover_more" })}</Button>
      <p>{intl.formatMessage({ id: "form_result_success_benefits_link_text" })}<br /><a rel="noopener noreferrer" target="_blank" href={DEMAND_SOCIAL_HOUSING_LINK} >{intl.formatMessage({ id: "form_result_success_benefits_link" })}</a></p>
      <p>
        <span>*</span>{intl.formatMessage({ id: "form_result_success_benefits_link_info" })}
      </p>
    </>
  )
}
