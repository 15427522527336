import React, { FunctionComponent, ReactElement } from "react";
import { KpiInterface } from "../../interfaces/common/CommonInterfaces";
import Figure from "../atoms/Figure";
import Img from "../atoms/Img";
import { imageService } from "../../services/ImageService";
import { LOGO_SERVICE } from "../../assets/Assets";

interface KpiProps {
  kpi: KpiInterface;
}

const Kpi: FunctionComponent<KpiProps> = ({
  kpi
}): ReactElement => {
  return (
    <div className="Kpi">
      <Figure>
        <Img src={imageService.getImageUrlByFormat(kpi.icon) || LOGO_SERVICE} />
      </Figure>
      <div className="Title">
        <strong>
          {kpi.title}
        </strong>
      </div>
      <div className="Description">
        {kpi.description}
      </div>
    </div>
  )
}

export default Kpi;
