import React, { FunctionComponent, ReactElement } from "react";
import { Button, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import Card from "../atoms/Card";
import { Article } from "../../interfaces/articles/ArticlesInterfaces";
import { formatDateToStandardShort } from "../../utils/dateUtils";
import { useIntl } from "react-intl";
import Truncate from "react-truncate";
import { ARTICLE_BY_ID_PATH } from "../../constants/routes/RoutePaths";
import { getRouteWithId } from "../../utils/routeUtils";
import Icon from "components/atoms/Icon";
import Figure from "components/atoms/Figure";
import Row from "components/atoms/Row";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Overlay from "components/atoms/Overlay";
import Badge from "components/atoms/Badge";
import Img from "components/atoms/Img";
import { PLACEHOLDERS } from "../../assets/Assets";
import { imageService } from "../../services/ImageService";
import { ImageFormat } from "../../constants/images/ImagesConstants";
import YoutubeVideoContainer from "./YoutubeVideoContainer";

// Styles : styles/molecules/cardArticle

interface CardArticleWideProps {
  article: Article;
  className?: string;
  noBorder?: boolean;
  ratio?: string
}

const CardArticleWide: FunctionComponent<CardArticleWideProps> = ({
  article,
  className,
  noBorder,
  ratio
}): ReactElement => {
  const intl = useIntl();

  return (
    <Card
      tag={"a"}
      href={getRouteWithId(ARTICLE_BY_ID_PATH, article.urlKey)}
      className={`CardArticleWide ${className}`}
      noBorder={noBorder}
    >
      <Row>
        <Col xs="12" md="6">
          {article.youtubeId ? (
            <Figure sizeAuto>
              <YoutubeVideoContainer videoID={article.youtubeId} />
            </Figure>
          ) : (
            <Figure ratio={ratio ? ratio : FIGURE_RATIOS.THREE_TWO}>
              <>
                <Img src={imageService.getImageUrlByFormat(article?.photo, ImageFormat.MEDIUM) || PLACEHOLDERS._600x400} />
                {article.podcast && (
                  <Overlay>
                    <Icon name="Play" />
                  </Overlay>
                )}
              </>
            </Figure>
          )}
        </Col>
        <Col xs="12" md="6">
          <CardHeader>
            <CardTitle className="h3" tag="h3">{article.title}</CardTitle>
          </CardHeader>
          <CardBody>
            <CardText className="lead">
              <Truncate lines={3} ellipsis="...">
                {article.chapeau}
              </Truncate>
            </CardText>

            <CardText>
              <span className="Date">{formatDateToStandardShort(article.created_at)}</span>
              {article.readingTime ? (
                <>
                  {" | "}
                  <span className="Duration">
                    {intl.formatMessage(
                      { id: "offer_detail_article_read_time" },
                      { count: article.readingTime }
                    )}
                  </span>
                </>
              ) : ""
              }
              <div>
                {article.podcast &&
                <Badge pill color="info">Podcast</Badge>
                }
              </div>
            </CardText>


            <a className="link" href={getRouteWithId(ARTICLE_BY_ID_PATH, article.urlKey)}>
              {intl.formatMessage({ id: "join_us_article" })}
            </a>

          </CardBody>
          <CardFooter>
            <Button className="More" color="link"><Icon name="More" /> <span
              className="Text">En savoir plus</span></Button>
          </CardFooter>
        </Col>
      </Row>
    </Card>
  )
}

export default CardArticleWide;
