import React, { FunctionComponent, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import { useQuery } from "@apollo/client";
import { PrivacyPolicyQuery } from "../interfaces/privacyPolicy/PrivacyPolicyInterfaces";
import { PRIVACY_POLICY_QUERY } from "../constants/queries/PrivacyPolicyQueries";
import { handleApolloError } from "../hooks/handleApolloError";
import gfm from "remark-gfm";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import { scrollTop } from "../utils/utilFunctions";
import { useQueryParams } from "../hooks/useQueryParams";

const PrivacyPolicyView: FunctionComponent<RouteProps> = () => {
  const { cookie } = useQueryParams(["cookie"]);
  const { data, error, loading } = useQuery<PrivacyPolicyQuery>(PRIVACY_POLICY_QUERY, {
    errorPolicy: "all"
  });
  handleApolloError("privacy_policy_query", error);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (!loading) {
      if (cookie === "true") {
        location.hash = "#cookie";
      }
    }
  }, [loading, cookie])

  return (
    <Layout>
      <Section id="PrivacyPolicy">
        {data?.privacyPolicy && (
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {data.privacyPolicy.content}
          </ReactMarkdownWithHtml>
        )}
      </Section>
    </Layout>
  )
}

export default PrivacyPolicyView;
