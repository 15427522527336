import { fetchWrapper } from "../utils/fetchWrapper";
import { DOCUMENT_DOWNLOAD_ROUTE, DOCUMENT_ROUTE } from "../constants/ApiRoutes";
import { DocumentInterface } from "../interfaces/documents/DocumentsInterfaces";

const findAllByContract = (contractId: string): Promise<DocumentInterface[]> => {
  return fetchWrapper.get(DOCUMENT_ROUTE + `?contractId=${contractId}`);
}

const getUrl = (contractId: string, name: string, tenantNumber: string): string => {
  return DOCUMENT_DOWNLOAD_ROUTE + `?contractId=${contractId}&tenantNumber=${tenantNumber}&name=${name}`;
}

const downloadFile = (path: string): void => {
  const a = document.createElement("a");
  a.href = path;
  a.download = path.substr(path.lastIndexOf("/") + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const sort = (a: DocumentInterface, b: DocumentInterface): number => {
  if (a.terme < b.terme) {
    return 1;
  }
  if (a.terme > b.terme) {
    return -1;
  }
  return 0;
}

export const documentService = {
  findAllByContract,
  getUrl,
  downloadFile,
  sort,
}
