import React, { FunctionComponent, useEffect } from "react"
import {RouteProps, useParams} from "react-router"
import { useQuery } from "@apollo/client"
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../components/atoms/Section";
import { EDITORIAL_PAGE_QUERY, EDITORIAL_GROUP_QUERY } from "../constants/queries/FooterQueries";
import { scrollTop } from "../utils/utilFunctions";
import { EditorialPageQueryInterface, EditorialGroupQueryInterface } from "../interfaces/footer/FooterInterfaces";
import { CONNECTED_HOUSINGS_PATH } from "../constants/routes/RoutePaths";
import Breadcrumb from "../components/molecules/Breadcrumb";
import { editorialService } from "../services/EditorialService";

interface RouteParams {
  connectedHousingUrlKey: string;
}

const ConnectedHousingsSpecificView: FunctionComponent<RouteProps> = () => {
  const { connectedHousingUrlKey } = useParams<RouteParams>()

  const {
    data: editorialData,
    error: editorialError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: connectedHousingUrlKey } });
  handleApolloError("editorial_page_query", editorialError);
  const editorialPage = editorialData?.editorialPages[0];

  const {
    data: editorialGroupData,
    error: editorialGroupError
  } = useQuery<EditorialGroupQueryInterface>(EDITORIAL_GROUP_QUERY, { variables: { urlKey: CONNECTED_HOUSINGS_PATH.split("/")[1] } });
  handleApolloError("editorial_group_query", editorialGroupError);
  const editorialGroup = editorialGroupData?.editorialGroups[0];

  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Layout>
      <Section>
        {editorialPage && (
          <>
            {
              editorialGroup && (
                <Breadcrumb firstText={editorialGroup.title} secondText={editorialPage.title} backLink={CONNECTED_HOUSINGS_PATH} />
              )
            }
            <div className="p-5">
              <h1 className="h1 mb-5">{editorialPage.title}</h1>
              <ReactMarkdownWithHtml
                allowDangerousHtml
                plugins={[gfm]}
                className="description"
              >
                {editorialService.handleCustomComponent(editorialPage.content)}
              </ReactMarkdownWithHtml>
            </div>
          </>
        )}
      </Section>
    </Layout>
  )
}

export default ConnectedHousingsSpecificView
