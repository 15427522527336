import { formatNumber } from "../utils/utilFunctions";
import { PageItem } from "../interfaces/pagination/PaginationInterfaces";

export const getPages = (current: number, total: number, showOffset: number): PageItem[] => {
  const pages = [{
    label: "1",
    value: 1,
  }] as PageItem[];
  if (current > 2 + showOffset) {
    if (2 === total - (showOffset * 2) - 1) {
      pages.push({
        label: formatNumber(2),
        value: 2,
      });
    } else if (current !== total || current - (showOffset * 2) !== 2) {
      pages.push({
        label: "...",
      });
    }
  }
  const start = Math.max(Math.min(current - showOffset, total - (showOffset * 2)), 1)
  for (let n = start ; n <= start + (showOffset * 2) ; n++) {
    if (n > 1 && n < total) {
      pages.push({
        label: formatNumber(n),
        value: n,
      });
    }
  }
  if (current < total - showOffset - 1 ) {
    if (total - 1 === (showOffset * 2) + 2 || (current === 1 ? 2 : current) + showOffset + 1 === total - 1) {
      pages.push({
        label: formatNumber(total - 1),
        value: total - 1,
      });
    } else if (current !== 1 || current + (showOffset * 2) !== total - 1) {
      pages.push({
        label: "...",
      });
    }
  }
  if (total > 1) {
    pages.push({
      label: formatNumber(total),
      value: total,
    })
  }
  return pages;
}
