import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import { scrollTop } from "../utils/utilFunctions";
import { useQuery } from "@apollo/client";
import { EditorialPageQueryInterface } from "../interfaces/footer/FooterInterfaces";
import { EDITORIAL_PAGE_QUERY } from "../constants/queries/FooterQueries";
import { handleApolloError } from "../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import ContactForm from "../components/molecules/ContactForm";
import { editorialService } from "../services/EditorialService";

const ContactUsView: FunctionComponent<RouteProps> = (): ReactElement => {

  const {
    data: editorialData,
    error: editorialError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: location.pathname.split("/")[1] } });
  handleApolloError("editorial_page_query", editorialError);
  const editorialPage = editorialData?.editorialPages[0];

  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Layout>
      <Section>
        {editorialPage && (
          <>
            <h1 className="h1 mb-5">{editorialPage.title}</h1>

            <ContactForm className="my-5" />

            <ReactMarkdownWithHtml
              allowDangerousHtml
              plugins={[gfm]}
              className="description"
            >
              {editorialService.handleCustomComponent(editorialPage.content)}
            </ReactMarkdownWithHtml>
          </>
        )}
      </Section>
    </Layout>
  )
}

export default ContactUsView;
