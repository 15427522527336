import React, { FunctionComponent, ReactElement } from "react";
import { MaintenanceStep } from "../../interfaces/footer/FooterInterfaces";
import Card from "../atoms/Card";
import { CardBody, CardText, CardTitle } from "reactstrap";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";

interface MaintenanceStepCardProps {
  step: MaintenanceStep;
  active: boolean;
  onClick: (id: number) => void;
}

const MaintenanceStepCard: FunctionComponent<MaintenanceStepCardProps> = ({
  step,
  active,
  onClick,
}): ReactElement => {
  return (
    <Card
      className="MaintenanceStepCard"
      onClick={() => onClick(step.id)}
      color={active ? "info" : ""}
    >
      <CardBody>
        <CardTitle>
          <strong>
            {step.title}
          </strong>
        </CardTitle>
        <CardText>
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {step.content}
          </ReactMarkdownWithHtml>
        </CardText>
      </CardBody>
    </Card>
  )
}

export default MaintenanceStepCard;
