import { gql } from "@apollo/client";

export const TAGS_QUERY = gql`
query Tags {
  tags {
    id,
    name,
    color
    offer_tag_category { label }
  }
}`
