import React, { FunctionComponent, ReactElement } from "react";

// Styles : styles/molecules/MenuNav

import Nav from "components/atoms/Nav";
import NavLinkLayout from "components/molecules/NavLinkLayout";
import { NEWS_PATH, JOIN_US_PATH, PROPERTIES_PATH } from "constants/routes/RoutePaths";

const MenuNav: FunctionComponent = (): ReactElement => {
  return (
    <Nav className="MenuNav">
      <NavLinkLayout path={PROPERTIES_PATH} message="property_page" />
      <NavLinkLayout path={NEWS_PATH} message="articles_page" />
      <NavLinkLayout path={JOIN_US_PATH} message="join_us_page" />
    </Nav>
  )
}

export default MenuNav;
