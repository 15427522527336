import { fetchWrapper } from "../utils/fetchWrapper";
import { CARETAKER_ROUTE } from "../constants/ApiRoutes";
import { Caretaker } from "../interfaces/caretakers/CaretakersInterfaces";

const getByContract = (contractId: string): Promise<Caretaker> => {
  return fetchWrapper.get(CARETAKER_ROUTE + `?contractId=${contractId}`)
}

export const caretakerService = {
  getByContract,
}