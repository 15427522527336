import { fetchWrapper } from "../utils/fetchWrapper";
import {BLOCS_PAIEMENT_ROUTE} from "../constants/ApiRoutes";
import {BlocPaiementInterface} from "../interfaces/blocPaiement/BlocPaiementInterface";

const findAll = (): Promise<BlocPaiementInterface[]> => {
    return fetchWrapper.get(BLOCS_PAIEMENT_ROUTE);
}

export const blocsPaiementService = {
    findAll,
}