
import { SUBMIT_ELIGIBILITY_ROUTE } from "constants/ApiRoutes"
import { FormStepperValues, SubmitEligibilityResponse } from "interfaces/formstepper/FormStepperInterfaces"
import { fetchWrapper } from "utils/fetchWrapper"

const submitEligibilityForm = (eligibilityFormValues: FormStepperValues): Promise<SubmitEligibilityResponse> => {
  return fetchWrapper.post<SubmitEligibilityResponse>(SUBMIT_ELIGIBILITY_ROUTE, eligibilityFormValues)
}

export const eligibilityService = {
  submitEligibilityForm,
}