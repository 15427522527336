import React, { FunctionComponent, ReactElement } from "react";
import { MaintenanceStepper } from "../../interfaces/footer/FooterInterfaces";
import Card from "../atoms/Card";
import { CardBody, CardText, CardTitle } from "reactstrap";
import Figure from "../atoms/Figure";
import { LOGO_SERVICE } from "../../assets/Assets";
import Img from "../atoms/Img";
import { imageService } from "../../services/ImageService";

interface MaintenanceStepperCardProps {
  stepper: MaintenanceStepper;
  active: boolean;
  onClick: (id: number) => void;
}

const MaintenanceStepperCard: FunctionComponent<MaintenanceStepperCardProps> = ({
  stepper,
  active,
  onClick,
}): ReactElement => {
  return (
    <Card
      className="MaintenanceStepperCard"
      onClick={() => onClick(stepper.id)}
      active={active}
      color={active ? "info" : ""}
      rounded
    >
      <Figure>
        <Img src={imageService.getImageUrlByFormat(stepper.icon) || LOGO_SERVICE} />
      </Figure>
      <CardBody>
        <CardTitle>
          <strong>
            {stepper.title}
          </strong>
        </CardTitle>
        <CardText>
          {stepper.subtitle}
        </CardText>
      </CardBody>
    </Card>
  )
}

export default MaintenanceStepperCard;
