import { DateTime } from "luxon";

// Lundi 6 Février
export const formatDateToLitteralDate = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  return datetime.toFormat("cccc d LLLL");
}

// Février 2021
export const formatDateToLitteralMonthYear = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  return datetime.toFormat("LLLL yyyy");
}

// 01/02/2021
export const formatDateToStandardShort = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  return datetime.toFormat("dd/LL/yyyy");
}

// 01/02/2021 à 14h50
export const formatDateToStandardShortWithTime = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  return datetime.toFormat("dd/LL/yyyy 'à' HH'h'mm");
}

// 14h50
export const formatDateToTime = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  return datetime.toFormat("H'h'mm");
}
