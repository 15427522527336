import React, { FunctionComponent, ReactElement } from "react";
import BasicModal from "./BasicModal";
import { FormattedMessage, useIntl } from "react-intl";
import gfm from "remark-gfm";
import ReactMarkdownWithHtml from "react-markdown/with-html";

interface PhoneModalProps {
  open: boolean;
  onClose: () => void;
}

const PhoneModal: FunctionComponent<PhoneModalProps> = ({
  open,
  onClose,
}): ReactElement => {
  const intl = useIntl();

  return (
    <BasicModal open={open} onClose={onClose} button="close" body={(
      <div>
        <h2>
          <FormattedMessage id="phone_tenant_center_title" />
        </h2>
        <p>
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {intl.formatMessage({ id: "phone_tenant_center" })}
          </ReactMarkdownWithHtml>
        </p>
        <hr />
        <h2>
          <FormattedMessage id="phone_on_call_title" />
        </h2>
        <p>
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {intl.formatMessage({ id: "phone_on_call" })}
          </ReactMarkdownWithHtml>
        </p>
      </div>
    )} />
  )
}

export default PhoneModal;
