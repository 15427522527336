import React, { FunctionComponent, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Col, FormGroup, Label } from "reactstrap";
import Row from "components/atoms/Row";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { StepProps } from "interfaces/formstepper/FormStepperInterfaces";
import Input from "components/atoms/Input";
import { useIntl } from "react-intl";
import Popover from "../atoms/Popover";


export const FormIncome: FunctionComponent<StepProps> = ({ formData, prevStep, submitForm }) => {

  const intl = useIntl();
  const validationSchema = Yup.object({
    income: Yup
      .number()
      .min(0, intl.formatMessage({ id: "error_msg_positive_number" }))
      .required(intl.formatMessage({ id: "mandatory_field" }))
  });
  const handlePrevClick = () => prevStep();
  const [popoverInfoOpen, setPopoverInfo] = useState(false)
  const togglePopoverInfo = () => setPopoverInfo((state: boolean) => !state);
  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
        }) => (
          <Form noValidate>
            <FormGroup className="text-center">
              <Label>{intl.formatMessage({ id: "form_question_income" })}</Label>
              <Button id="popInfo" type="button" outline size="xs" color="dark" icon pill className="mb-4">i</Button>
              <Row grid gutters="sm" justifyContent="center">
                <Col xs="6" md="4">
                  <Input
                    type="number"
                    invalid={touched.income && !!errors.income}
                    errorMessage={errors.income}
                    name="income"
                    placeholder={intl.formatMessage({ id: "form_question_income_placeholder" })}
                    value={values.income}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
              <Button
                className="align-self-center"
                type="button"
                color="link"
                onClick={handlePrevClick}
              >
                <Icon name="ChevronLeft" />
                <span className="Text">{intl.formatMessage({ id: "form_stepper_button_prev" })}</span>
              </Button>
              <Button
                type="submit"
                color="primary"
              >
                {intl.formatMessage({ id: "form_stepper_button_submit" })}
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <Popover isOpen={popoverInfoOpen} toggle={togglePopoverInfo}>
        {intl.formatMessage({ id: "form_info_income" })}
      </Popover>
    </>
  );
};
