import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CardBody, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import Card from "../atoms/Card";
import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button";
import Row from "components/atoms/Row";
import Icon from "../atoms/Icon";
import { rentService } from "../../services/RentService";
import {DOCUMENTS_PATH, PAYEMENT_RENT_PATH} from "../../constants/routes/RoutePaths";
import Price from "../atoms/Price";
import { navService } from "../../services/NavService";
import {Rent} from "../../interfaces/rents/RentsInterfaces";
import {DocumentInterface} from "../../interfaces/documents/DocumentsInterfaces";
import {formatDateToStandardShort} from "../../utils/dateUtils";
import {documentService} from "../../services/DocumentService";
import {authService} from "../../services/AuthService";
import {history} from "../../constants/History";

interface BalanceCardProps {
  rent?: Rent;
  receipt?: DocumentInterface;
  rentLoading: boolean;
  selectedContract: string;
  openModal: () => void;
}

const BalanceCard: FunctionComponent<BalanceCardProps> = ({
  rent,
  receipt,
  rentLoading,
  openModal,
  selectedContract,
}): ReactElement => {
  const intl = useIntl();
  const user = authService.getLoggedInUser().user;

  const [loading, setLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [balanceLoading, setBalanceLoading] = useState<boolean>(false);

  const onDownload = () => {
    if (!loading) {
      setLoading(true);
      documentService.downloadFile(documentService.getUrl(rent.contract_number.toString(), receipt.name, user.tenantNumber))
      // TODO : Trouver un meilleur moyen de récupérer l'information lorsque le fichier a été téléchargé
      setTimeout(() => setLoading(false), 3000);
    }
  }

  const onPaymentClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(PAYEMENT_RENT_PATH)
  };

  useEffect(() => {
    if (selectedContract) {
      setBalanceLoading(true);
      void rentService.getBalance(selectedContract)
        .then((response: number) => {
          setBalance(response);
        })
        .finally(() => {
          setBalanceLoading(false);
        });
    }
  }, [selectedContract]);

  const onBillClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navService.redirect(DOCUMENTS_PATH);
  }

  const paid = rent && rent.credit === rent.debit

  return (
    <Card rounded>
      <Row grid gutters="xl">
        <Col xs="auto">
          <CardHeader className="bg-primarylight d-flex justify-content-center align-items-center">
            <CardTitle>
              <Icon name="MyHome" size="6" className="d-block" />
              <FormattedMessage id="rent_my_balance" />
            </CardTitle>
          </CardHeader>
        </Col>
        <Col xs="auto">
          <CardBody onClick={openModal} className="cursor-pointer Balance">
            <CardTitle>
              <FormattedMessage id="rent_my_balance_to_pay" />
            </CardTitle>
            <div className="card-content">
              <Price price={balance} disabled={balanceLoading}>
                <Button id="popInfo" type="button" outline size="xs" color="dark" icon pill>i</Button>
              </Price>
              <CardText className="link">
                <a onClick={onBillClick} href="" title={intl.formatMessage({ id: "rent_access_bills" })}>
                  <FormattedMessage id="rent_access_bills" />
                </a>
              </CardText>
            </div>
          </CardBody>
        </Col>
        <Col xs="auto">
          <CardBody>
            <CardTitle>
              <FormattedMessage id="rent_my_quitance" />
            </CardTitle>
            <div className="card-content">
              <CardText className={`bill ${rentLoading ? "text-muted" : ""}`}>
                <small className="date">
                  <FormattedMessage id="rent_due_date" /> {formatDateToStandardShort(rent?.due_date || new Date().toISOString())}
                </small>
              </CardText>
              <Price price={rent?.debit - rent?.credit || 0} disabled={rentLoading}>
                <>
                  <Badge color={paid ? "success" : "danger"} pill onClick={!paid && onPaymentClick} className={!paid && "cursor-pointer"}>
                    <FormattedMessage id={paid ? "db_rent_paid" : "db_rent_to_pay"} />
                  </Badge>
                  {receipt && (
                    <Button disabled={loading} color="link" onClick={onDownload}>
                      <Icon name="Download" size="2" />
                    </Button>
                  )}
                </>
              </Price>
            </div>
          </CardBody>
        </Col>
      </Row>
    </Card>
  )
}

export default BalanceCard;
