import React, { FunctionComponent } from "react"
import { RouteProps } from "react-router"
import { useQuery } from "@apollo/client"
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import { CONNECTED_HOUSINGS_QUERY } from "../constants/queries/ConnectedHousingsQueries";
import { ConnectedHousingsQueryInterface } from "../interfaces/connectedHousings/ConnectedHousingsInterfaces";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../components/atoms/Section";
import { editorialService } from "../services/EditorialService";

const ConnectedHousingsView: FunctionComponent<RouteProps> = () => {
  const { data: connectedHousingsData, error: connectedHousingsQueryError } = useQuery<ConnectedHousingsQueryInterface>(CONNECTED_HOUSINGS_QUERY)
  handleApolloError("connected_housings_query", connectedHousingsQueryError);

  const connectedHousingPage = connectedHousingsData?.connectedHousing;

  return (
    <Layout>
      <Section>
        {
          connectedHousingPage && (
            <>
              <h1 className="h1 mb-5">{connectedHousingPage.title}</h1>
              <ReactMarkdownWithHtml
                allowDangerousHtml
                plugins={[gfm]}
                className="description"
              >
                {editorialService.handleCustomComponent(connectedHousingPage.content)}
              </ReactMarkdownWithHtml>
            </>
          )
        }
      </Section>
    </Layout>
  )
}

export default ConnectedHousingsView
