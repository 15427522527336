import React, { FunctionComponent, useEffect } from "react"
import { useIntl } from "react-intl"
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup"
import Button from "../atoms/Button";
import { DASHBOARD_PATH } from "../../constants/routes/RoutePaths";
import { authService } from "../../services/AuthService"
import Input from "../atoms/Input";
import { FormGroup } from "reactstrap";
import { history } from "../../constants/History"
import { toastService } from "../../services/ToastService";
import { useQueryParams } from "../../hooks/useQueryParams";

interface FormLoginProps {
  className?: string;
  toggleModalPass?: () => void;
}

interface FormValues extends FormikValues {
  emailLogin: string,
  passwordLogin: string
}

const FormLogin: FunctionComponent<FormLoginProps> = ({
                                                        className = "",
                                                        toggleModalPass,
                                                      }) => {
  const intl = useIntl()
  const { passwordReset } = useQueryParams(["passwordReset"]);
  const loginSchema = Yup.object({
    emailLogin: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    passwordLogin: Yup.string().required(intl.formatMessage({ id: "mandatory_field" }))
  })
  useEffect(() => {
    if ( passwordReset === "true") {
      toastService.success(intl.formatMessage({ id: "reset_password_success" }))
    }
  }, [])
  const onSubmitLogin = (
    { emailLogin, passwordLogin }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    authService
      .login(emailLogin, passwordLogin)
      .then(() => {
        const loggedInUser = authService.getLoggedInUser()
        if (loggedInUser && passwordReset === "true") {
          history.goBack()
        } else {
          history.push(DASHBOARD_PATH)
        }
      })
      .finally(() => setSubmitting(false))
  }
  
  return (
    <div className={className}>
      <h3 className="mb-3 text-uppercase">{intl.formatMessage({ id: "login" })}</h3>
      <Formik
        validationSchema={loginSchema}
        onSubmit={onSubmitLogin}
        initialValues={{ emailLogin: "", passwordLogin: "" }}
      >
        {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                name="emailLogin"
                placeholder={intl.formatMessage({ id: "login_email" })}
                value={values.emailLogin}
                onChange={handleChange}
                invalid={touched.emailLogin && !!errors.emailLogin}
                errorMessage={errors.emailLogin}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="passwordLogin"
                placeholder={intl.formatMessage({ id: "login_password" })}
                value={values.passwordLogin}
                onChange={handleChange}
                invalid={touched.passwordLogin && !!errors.passwordLogin}
                errorMessage={errors.passwordLogin}
              />
            </FormGroup>
            <p onClick={toggleModalPass} className="text-right">
              <a className="forgot-pass">{intl.formatMessage({ id: "login_forgot_password" })}</a>
            </p>
            <div className="d-flex flex-column align-items-center mb-5">
              <Button
                disabled={isSubmitting}
                size="lg"
                color="info"
                type="submit"
              >
                {intl.formatMessage({ id: "login_btn" })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormLogin
