import React, { FunctionComponent, ReactElement, useCallback } from "react";
import { CardBody, CardHeader, Col, Input } from "reactstrap";
import Icon from "../atoms/Icon";
import { FormattedMessage, useIntl } from "react-intl";
import Row from "../atoms/Row";
import Button from "../atoms/Button";
import ChooseContainer from "./ChooseContainer";
import { PropertyFilters, PropertyFiltersKey } from "../../interfaces/properties/PropertiesInterfaces";
import { NUMBER_OF_ROOM, PROJECTS, CATEGORIES } from "../../constants/properties/PropertiesConstants";
import { FilterItem } from "../../interfaces/form/FormsInterfaces";
import ReactHtmlParser from "react-html-parser";
import Card from "components/atoms/Card";
import TownSuggester from "./TownSuggester";

const FiltersCol = ({ children }) => (
  <Col className="mt-3" sm={6} md={12} lg={6}>
    {children}
  </Col>
)

interface AdvancedFiltersProps {
  show: boolean;
  onBack: () => void;
  onQueryChange: (value: string) => void;
  searchInput: string;
  resultCount: string;
  filters: PropertyFilters;
  onFilterChanged: (name: string, value: string | number | undefined) => void;
}

const AdvancedFilters: FunctionComponent<AdvancedFiltersProps> = ({
  show,
  onBack,
  onQueryChange,
  searchInput,
  resultCount,
  filters,
  onFilterChanged
}): ReactElement => {
  const intl = useIntl();

  const displayButtonFilters = useCallback((name: PropertyFiltersKey, filterList: FilterItem[]) =>
    filterList.map((filter: FilterItem) => {
      const selected = filters[name] === filter.value;
      return (
        <Button
          key={`${name}_${filter.label}`}
          outline={!selected}
          pill
          color={selected ? "primary" : "secondary"}
          onClick={() => onFilterChanged(name, filter.value)}
          className="buttonItem"
        >
          <FormattedMessage id={filter.label} />
        </Button>
      )
    })
    , [filters])

  return (
    <Card rounded roundedRadius="sm" className={`AdvancedFilters ${show ? "show" : ""}`}>
      <CardHeader>
        <Button onClick={onBack} color="link">
          <Icon name="ChevronLeft" />
          <FormattedMessage id="back" />
        </Button>
      </CardHeader>
      <CardBody>
        <TownSuggester
          currentValue={searchInput}
          onChange={onQueryChange}
          placeholderLabel={"where"}
          inputType={"search"}
          inputName={"search"}
          inputId={"search"}
          inputBsSize={"lg"}
          inputNoBorder={false}
        />
        <Row grid>
          <FiltersCol>
            <h5 className="h5 flex-grow-0">
              <FormattedMessage id="project_question" />
            </h5>
            <div className="d-flex align-items-start flex-wrap">
              {displayButtonFilters("project", PROJECTS)}
            </div>
          </FiltersCol>
          <FiltersCol>
            <h5 className="h5 flex-grow-0">
              <FormattedMessage id="type_question" />
            </h5>
            <div className="d-flex flex-wrap">
              {displayButtonFilters("category", CATEGORIES)}
            </div>
          </FiltersCol>
        </Row>
        <Row grid>
          <FiltersCol>
            <h5 className="h5">
              <FormattedMessage id="room_question" />
            </h5>
            <ChooseContainer
              name="numberOfRoom"
              items={NUMBER_OF_ROOM}
              onClick={onFilterChanged}
              selected={filters.numberOfRoom}
            />
          </FiltersCol>
        </Row>
        <Row className="mt-4">
          <Col>
            <h5 className="h5 text-primary">
              <FormattedMessage id={filters.project === "buy" ? "max_budget_question" : "max_rent_question"} />
            </h5>
            <Input
              placeholder={intl.formatMessage({ id: filters.project === "buy" ? "maxBudget" : "maxRent" })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilterChanged("maxRent", e.target.value) }
              value={filters.maxRent}
              type="number"
              name="maxRent"
              id="maxRent"
            />
          </Col>
        </Row>

        <p className="mt-5 text-primary font-weight-bold small">
          <FormattedMessage id="data_not_stored" />
        </p>

        <div className="d-flex justify-content-center mt-5">
          <Button onClick={onBack} color="primary" className="px-3 px-sm-5 mx-auto">{resultCount}</Button>
        </div>
        <p className="text-center mt-3 small text-secondary">
          {ReactHtmlParser(intl.formatMessage({ id: "conditions"}))}
        </p>
      </CardBody>
    </Card>
  );
}

export default AdvancedFilters;
