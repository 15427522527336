import React, { FunctionComponent, useState } from "react";
import { RouteProps } from "react-router"

import Layout from "components/layout/Layout";
import Section from "components/atoms/Section";
import Nav from "components/atoms/Nav";
import { CardBody, CardImg, CardText, CardTitle, Col, Collapse, FormGroup, Input, Label, NavItem, NavLink } from "reactstrap";
import Button from "components/atoms/Button";
import Row from "components/atoms/Row";
import Figure from "components/atoms/Figure";
import Img from "components/atoms/Img";
import Table from "components/atoms/Table";
import Card from "components/atoms/Card";
import Icon from "components/atoms/Icon";
import Overlay from "components/atoms/Overlay";
import DefinitionList from "components/atoms/DefinitionList";
import { FIGURE_RATIOS } from "../constants/common/CommonConstants";
import { PLACEHOLDERS } from "../assets/Assets";

const ComponentsDashboardView: FunctionComponent<RouteProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Layout className="ComponentsView" dashboard>
      <>
        <Section fluid>
          <Button color="info" tag="a" href="/components" className="float-right">Composants éditoriaux</Button>
          <h1 className="h1">Dashboard&apos;s components</h1>
          <p>Ce framework graphique est basé sur <a href="https://getbootstrap.com/docs/4.6/getting-started/introduction/" title="Bootstrap 4.6">Bootstrap 4.6</a> et <a href="https://reactstrap.github.io/" title="Reacstrap">Reactstrap</a></p>
          <p>Les styles sont packagé, nous n&apos;appelons pas tout bootstrap, chaque composant importe si nescessaire les éléments de <a href="https://getbootstrap.com/docs/4.6/getting-started/introduction/" title="Bootstrap 4.6">Bootstrap</a> puis surcharge</p>
          <p>Les composants reactstrap sont appelés par leurs équivalents spécifics, se référer à la doc <a href="https://reactstrap.github.io/" title="Reacstrap">Reactstrap</a> pour connaitre toutes les propriétés.</p>
          <Nav>
            <NavItem>
              <NavLink href="#">Composant</NavLink>
            </NavItem>
          </Nav>
        </Section>

        <hr className="Hr" />

        <Section fluid id="Types">
          <h2 className="h2">Types</h2>
          <hr className="Hr" />
          <Row grid>
            <Col xs="12" md="6">
              <h3 className="h3">Titre de section / h2</h3>
              <p>Paragraphe de texte dont éléments standards :<br/>
              <a href="" title="">ancre</a> <strong>strong</strong> <em>emphase</em></p>
            </Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section fluid>

          <h2 className="h2">Avatar</h2>

          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Figure ratio={FIGURE_RATIOS.ONE_ONE} circle>
                  <Img src={PLACEHOLDERS._200x200} alt=""/>
                </Figure>
                <Button size="sm" color="link">Modifier</Button>
              </FormGroup>
            </Col>
          </Row>


        </Section>

        <hr className="Hr" />

        <Section fluid>
          <h2 className="h2">FormsGroups</h2>
          <h3 className="h3">Readonly FormGroups</h3>
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Label</Label>
                <Input value="Value" readOnly />
              </FormGroup>
            </Col>
          </Row>
          <hr className="Hr"/>
          <h3 className="h3">Editable FormGroups</h3>
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Label <Button size="sm" color="link">Modifier</Button></Label>
                <Input value="Value" readOnly />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Label <Button size="sm" color="link">Modifier</Button></Label>
                <Input value="Value" placeholder="Placeholder" />
              </FormGroup>
            </Col>
          </Row>
          <hr className="Hr"/>
          <h3 className="h3">Select</h3>
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Select</Label>
                <Input type="select" name="select" id="exampleSelect">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label>Label <Button size="sm" color="link">Modifier</Button></Label>
                <Input value="Value" placeholder="Placeholder" />
              </FormGroup>
            </Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section fluid>
          <h2 className="h2">Tables</h2>

          <Table hover responsive>
            <thead>
              <tr>
                <th></th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Value</td>
                <td>Value</td>
                <td>Value</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Value</td>
                <td>Value</td>
                <td>Value</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Value</td>
                <td>Value</td>
                <td>Value</td>
              </tr>
            </tbody>
          </Table>

        </Section>

        <hr className="Hr" />

        <Section fluid>
          <h2 className="h2">Cards</h2>

          <Row>
            <Col xs="6" md="3">
              <Card tag="a">
                <Figure occupation="80">
                  <>
                    <CardImg src="/pdf.svg"/>
                    <Overlay>
                      <Icon name="Download" />
                    </Overlay>
                  </>
                </Figure>
                <CardBody>
                  <CardTitle>Nom de l&apos;élément</CardTitle>
                  <CardText className="d-flex justify-content-between"><span className="Date">01/12/2020</span> <span className="Size">1,2 Mo <Icon name="FilePdf"/></span></CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Section>

        <Section fluid>
          <h2 className="h2">Definition list</h2>
        
          <DefinitionList>
            <>
              <dt className="Toggle" onClick={toggle}><span className="Text">Lorem Ipsum</span> <Icon name="Plus"/></dt>
              <Collapse isOpen={isOpen}>
               <dd>Dolor sit amet.</dd>
              </Collapse>

              <dt className="Toggle" onClick={toggle}><span className="Text">Lorem Ipsum</span> <Icon name="Plus"/></dt>
              <Collapse isOpen={isOpen}>
               <dd>Dolor sit amet.</dd>
              </Collapse>
            </>
          </DefinitionList>
          
        </Section>

      </>
    </Layout>
  )
}

export default ComponentsDashboardView
