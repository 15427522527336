import React, { FunctionComponent, ReactElement } from "react";
import { useIntl } from "react-intl";

const NoResult: FunctionComponent = (): ReactElement => {
  const intl = useIntl();
  return (
    <p className="d-flex justify-content-center text-center w-100 m-5 font-weight-bold font-italic">
      {intl.formatMessage({ id: "no_result" })}
    </p>
  )
}

export default NoResult;