import React, { FunctionComponent, ReactElement } from "react"

// Styles : styles/atoms/carousel
import { Swiper } from "swiper/react";
// import { ReactIdSwiperChildren } from "react-id-swiper/lib/types";
import SwiperCore, { Navigation, Pagination, Keyboard } from "swiper"

SwiperCore.use([Navigation, Pagination, Keyboard])

interface CarouselProps {
  children: ReactElement[];
  className?: string;
}

const Carousel: FunctionComponent<CarouselProps> = ({
  children,
  className = ""
}) => {
  const CarouselClassName =
    (className ? " " + className : "")

  return (
    <Swiper
      className={`Carousel ${CarouselClassName}`}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      keyboard
      pagination={{
        clickable: true,
      }}
    >
      {children}
    </Swiper>
  )
}

export default Carousel

