import React, { FunctionComponent, ReactElement, useCallback, useEffect } from "react";
import { Popover as LibPopover, PopoverBody } from "reactstrap";

interface PopoverProps {
  children: ReactElement | string;
  isOpen: boolean;
  toggle: () => void;
  placement?: "right" | "top";
}

const Popover: FunctionComponent<PopoverProps> = ({
  children,
  isOpen,
  toggle,
  placement = "right",
}): ReactElement => {

  const onScroll = useCallback(() => {
    if (isOpen) {
      toggle()
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);
    return () => {
      window.removeEventListener("scroll", onScroll, true);
    }
  }, [onScroll])

  return (
    <LibPopover
      placement={placement}
      isOpen={isOpen}
      target="popInfo"
      toggle={toggle}
      trigger="legacy"
    >
      <PopoverBody>
        {children}
      </PopoverBody>
    </LibPopover>
  );
}

export default Popover;