import { Tenant } from "../interfaces/tenants/TenantsInterfaces";
import { fetchWrapper } from "../utils/fetchWrapper";
import { TENANT_CONTRACTS_ROUTE, TENANT_ADD_INSURANCE, TENANT_ROUTE } from "../constants/ApiRoutes";
import { Contract } from "../interfaces/contracts/ContractsInterfaces";

const findByCurrentUser = (): Promise<Tenant> => {
  return fetchWrapper.get<Tenant>(TENANT_ROUTE);
}
const addInsurance = (insurance: File, selectedContract: string): Promise<void> => {
  const formData = new FormData()
  formData.append("files", insurance)
  formData.append("contractNumber", selectedContract)

  return fetchWrapper.postFormData<void>(
    TENANT_ADD_INSURANCE,
    formData
  )
}

const getContracts = (): Promise<Contract[]> => {
  return fetchWrapper.get<Contract[]>(TENANT_CONTRACTS_ROUTE);
}

export const tenantService = {
  findByCurrentUser,
  getContracts,
  addInsurance
}
