export const truncateText = (text: string, textSize: number): string => {
  return text?.length > textSize ? text.substr(0, textSize - 1) + "..." : text;
};

export const formatNumber = (value: number, digits = 0): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const formatPriceTTC = (price: number, fees: number, digits = 2): string => {
  if (price === null || price === undefined) {
    return "";
  }
  return formatNumber(price + (fees || 0), digits);
}

export const normalizeSearchStr = (value: string): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}
export const formatExternalUrl = (value: string): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.startsWith("www") ? `https://${value}` : value;
}

export const formatPhoneNumber = (value: string): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1.")
}

export const formatStrapiError = (value: string): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.replace(/[.-]/g, "_")
}

export const scrollTop = (id = "Global"): void => {
  document.getElementById(id).scrollTo({ top: 0 })
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
}