import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import { FormattedMessage } from "react-intl";
import { rentService } from "../../services/RentService";
import { Rent } from "../../interfaces/rents/RentsInterfaces";
import { useContracts } from "../../hooks/useContracts";
import BalanceCard from "../../components/molecules/BalanceCard";
import PaymentSection from "../../components/molecules/PaymentSection";
import PaymentHistory from "../../components/molecules/PaymentHistory";
import { scrollTop } from "../../utils/utilFunctions";
import DebtModal from "../../components/molecules/DebtModal";
import {documentService} from "../../services/DocumentService";
import {DocumentInterface} from "../../interfaces/documents/DocumentsInterfaces";

const MyRentView: FunctionComponent<RouteProps> = ({}): ReactElement => {
  const { selectedContract, contractsDropdown } = useContracts();

  const [rents, setRents] = useState<Rent[]>([]);
  const [rentLoading, setRentLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentInterface[]>([]);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (selectedContract) {
      setRentLoading(true);
      void rentService.getRents(selectedContract)
        .then((response: Rent[]) => {
          setRents(response);
        })
        .finally(() => {
          setRentLoading(false);
        });

      void documentService.findAllByContract(selectedContract)
        .then((result: DocumentInterface[]) => {
          setDocuments(result);
        })
    }
  }, [selectedContract])


  const rent = rents[0];
  const receipt = documents.find((document: DocumentInterface): boolean => document.terme === rent.terme);
  const toggleModal = () => setOpenModal((state: boolean) => !state);

  return (
    <Layout dashboard className="MyRent">
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2">
              <FormattedMessage id="my_rent_page" />
            </h2>
            {contractsDropdown}
          </div>
          <BalanceCard
            selectedContract={selectedContract}
            rent={rent}
            receipt={receipt}
            rentLoading={rentLoading}
            openModal={toggleModal}
          />
        </Section>
        <Section>
          <PaymentSection selectedContract={selectedContract} />
        </Section>

        <Section>
          <PaymentHistory rents={rents.filter((rent: Rent) => rent.due_date)} loading={rentLoading} />
        </Section>

        <DebtModal
          open={openModal}
          onClose={toggleModal}
          rents={rents.filter((rent: Rent) => rent.debit !== rent.credit)}
        />
      </>
    </Layout>
  );
}

export default MyRentView;
