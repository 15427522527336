import React, { FunctionComponent } from "react"

// Styles : styles/components/overlay

type OverlayProps = {
  children?: JSX.Element
  className?: string
  noBg?: boolean
}

const Overlay: FunctionComponent<OverlayProps> = ({
  children,
  className="",
  noBg
}) => {
  return (
    <div className={`Overlay ${noBg ? " no-bg" : ""} ${className}`}>
      {children}
    </div>
  )
}

export default Overlay
