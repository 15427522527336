import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import { scrollTop } from "../utils/utilFunctions";
import Input from "../components/atoms/Input";
import FaqItem from "../components/molecules/FaqItem";
import { useQuery } from "@apollo/client";
import { handleApolloError } from "../hooks/handleApolloError";
import { FAQ_QUERY, FAQ_TAG_QUERY } from "../constants/queries/FaqQueries";
import { Faq, FaqQuery, FaqTag, FaqTagQuery } from "../interfaces/faq/FaqInterfaces";
import Button from "../components/atoms/Button";
import { useDebouncedInput } from "../hooks/useDebouncedInput";
import { FormattedMessage, useIntl } from "react-intl";

const FaqView: FunctionComponent = ({}): ReactElement => {
  const intl = useIntl();

  const [selectedTagId, setSelectedTagId] = useState<string>();
  const [query, setQuery] = useState<string>("");

  const { onChange } = useDebouncedInput((value: string) => setQuery(value));

  const { data: dataFaqTag, error: faqTagError } = useQuery<FaqTagQuery>(FAQ_TAG_QUERY);
  const {
    data: dataFaq,
    previousData: previousDataFaq,
    error: faqError
  } = useQuery<FaqQuery>(FAQ_QUERY, { variables: { query, tagId: selectedTagId } });
  handleApolloError("faq_tag_query", faqTagError);
  handleApolloError("faq_query", faqError);

  useEffect(() => {
    scrollTop();
  }, [])

  const onTagClick = (id: string) => {
    setSelectedTagId((state: string) => state === id ? undefined : id)
  }

  return (
    <Layout className="FaqView">
      <Section className="mt-5">
        <Input placeholder={intl.formatMessage({ id: "faq_placeholder"})} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)} />
        <div className="my-3">
          <FormattedMessage id="faq_frequent_topic" />
        </div>

        {dataFaqTag?.faqTags.map((faqTag: FaqTag) => (
          <Button
            key={`faq_tag_${faqTag.id}`}
            className="mr-3 mb-3"
            onClick={() => onTagClick(faqTag.id)}
            active={selectedTagId === faqTag.id}
            color="primary"
            pill
            outline
          >
            {faqTag.label}
          </Button>
        ))}

        <div className="Questions">
          {(dataFaq?.faqItems || previousDataFaq?.faqItems || []).map((faq: Faq) => (
            <FaqItem key={`faq_${faq.id}`} faq={faq} />
          ))}
        </div>
      </Section>
    </Layout>
  );
}

export default FaqView;