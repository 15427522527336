import { MarkerInterface, MarkerType } from "../../interfaces/map/MapInterfaces";
import L, { DivIcon, MarkerCluster } from "leaflet";
import { LOCATION_ICON_LRYE_MAP, LOCATION_ICON_MAP } from "../../assets/Assets";

export const LATITUDE_YVELINES_ESSONNE = 48.80305433812745
export const LONGITUDE_YVELINES_ESSONNE = 2.1790919279981935
export const ZOOM = 10
export const MAP_DETAIL_ZOOM = 14
export const MAX_ZOOM = 19
export const CLUSTER_SPIDERFY_DISTANCE = 3
export const MARKER_SELECT_ZOOM = 15
export const ATTRIBUTION = "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
// Carto
export const MAP_URLS = "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
// Standard OpenStreetMap
// export const MAP_URLS =  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

export const getPriceMarker = (marker: MarkerInterface): DivIcon => L.divIcon({
  iconSize: [100, 30],
  iconAnchor: [50, 30],
  className: "priceMarker",
  html: `<div class="marker ${marker.selected ? "selected" : ""}">${marker.title}</div>`
})

export const getSimpleMarker = (marker: MarkerInterface): DivIcon => {
  if (marker.type === MarkerType.agency_city) {
    return L.icon({
      iconUrl: LOCATION_ICON_LRYE_MAP,
      iconSize: [30, 30],
    })
  } else {
    return L.icon({
      iconUrl: LOCATION_ICON_MAP,
      iconSize: [30, 30],
      className: "simpleMarker",
    })
  }
}

export const clusterIconCreateFunction = (cluster: MarkerCluster): DivIcon => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "mycluster",
    iconSize: null
  });
}
