import { fetchWrapper } from "../utils/fetchWrapper";
import { GENERAL_ALERTS_ROUTE,COMPLEX_ALERTS_ROUTE } from "../constants/ApiRoutes";
import { Alert } from "../interfaces/alerts/AlertInterfaces";

const findGeneralAlerts = (): Promise<Alert[]> => {
    return fetchWrapper.get<Alert[]>(GENERAL_ALERTS_ROUTE);
}
const findComplexAlerts = (): Promise<Alert[]> => {
    return fetchWrapper.get<Alert[]>(COMPLEX_ALERTS_ROUTE);
}

export const alertService = {
    findGeneralAlerts,
    findComplexAlerts
}