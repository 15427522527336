import React, { FunctionComponent, useContext } from "react";
import { NavItem, NavLink } from "reactstrap";
import { useIntl } from "react-intl";
import { history } from "../../constants/History";
import { ContextMenu } from "../../contexts/contextMenu";
import Icon from "../atoms/Icon";

interface MenuDashboardLinkProps {
  path?: string;
  message: string;
  icon: string;
  onClick?: () => void;
}

const MenuDashboardLink: FunctionComponent<MenuDashboardLinkProps> = ({
  path,
  message,
  icon,
  onClick,
}) => {
  const intl = useIntl();
  const { toggleMenuOpen, menuOpen } = useContext(ContextMenu);

  const pathname = window.location.pathname;
  const linkContent = intl.formatMessage({ id: message });

  const onLinkClick = () => {
    if (menuOpen) {
      toggleMenuOpen();
    }
    if (onClick) {
      onClick();
    } else if (path) {
      history.push(path);
    }
  }

  return (
    <NavItem>
      <NavLink
        onClick={onLinkClick}
        title={intl.formatMessage({ id: message })}
        className="cursor-pointer"
        active={pathname.startsWith(path)}
      >
        <Icon name={icon} />
        <span className="Text text-center">
          {linkContent}
        </span>
      </NavLink>
    </NavItem>
  )
}

export default MenuDashboardLink;
