import React, { FunctionComponent, ReactElement } from "react";

// Styles : styles/molecules/cardArticle
import { CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap";
import Card from "../atoms/Card";
import { Article } from "../../interfaces/articles/ArticlesInterfaces";
import { formatDateToStandardShort } from "../../utils/dateUtils";
import { useIntl } from "react-intl";
import Truncate from "react-truncate";
import { ARTICLE_BY_ID_PATH } from "../../constants/routes/RoutePaths";
import { getRouteWithId } from "../../utils/routeUtils";
import { imageService } from "../../services/ImageService";
import Icon from "components/atoms/Icon";
import Figure from "components/atoms/Figure";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Button from "components/atoms/Button";
import Overlay from "components/atoms/Overlay";
import Img from "components/atoms/Img";
import Badge from "components/atoms/Badge";
import { PLACEHOLDERS } from "../../assets/Assets";
import YoutubeVideoContainer from "./YoutubeVideoContainer";

interface CardArticleProps {
  article: Article;
  className?: string;
  noBorder?: boolean;
  ratio?: string;
}

const CardArticle: FunctionComponent<CardArticleProps> = ({
  article,
  className = "",
  noBorder,
  ratio,
}): ReactElement => {
  const intl = useIntl();

  return article ? (
    <Card
      tag={"a"}
      href={getRouteWithId(ARTICLE_BY_ID_PATH, article.urlKey)}
      className={`CardArticle ${className}`}
      noBorder={noBorder}
    >
      <Figure ratio={ratio ? ratio : FIGURE_RATIOS.FOUR_THREE} className={article.youtubeId ? "align-items-start" : ""}>
        <>
          {article.youtubeId && (
            <YoutubeVideoContainer videoID={article.youtubeId} />
          )}
          {!article.youtubeId && (
            <>
              <Img src={imageService.getImageUrlByFormat(article?.photo) || PLACEHOLDERS._600x400} />
              {article.podcast && (
                <Overlay>
                  <Icon name="Play" />
                </Overlay>
              )}
            </>
          )}
        </>
      </Figure>
      <CardHeader>
        <CardTitle className="h3" tag="h3">{article.title}</CardTitle>
      </CardHeader>
      <CardBody>
        <CardText tag="div" className="lead mb-4">
          <Truncate lines={3} ellipsis="...">
            {article.chapeau}
          </Truncate>
        </CardText>

        <CardText tag="div">
          <span className="Date">{formatDateToStandardShort(article.created_at)}</span>
          {article.readingTime ? (
            <>
              {" | "}
              <span className="Duration">
                {intl.formatMessage(
                  { id: "offer_detail_article_read_time" },
                  { count: article.readingTime }
                )}
              </span>
            </>
          ) : ""
          }
          {article.podcast && (
            <div>
              <Badge pill color="info">Podcast</Badge>
            </div>
          )
          }
        </CardText>

      </CardBody>
      <CardFooter>
        <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
      </CardFooter>
    </Card>
  ) : null
}

export default CardArticle;
