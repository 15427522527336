import React, { FunctionComponent } from "react"

// Styles : styles/atoms/List


type ListProps = & {
  href?: string
  className?: string
  inline?: boolean
  unstyled?: boolean
}

const List: FunctionComponent<ListProps> = ({
  children,
  className = "",
  inline,
  unstyled
}) => {
  const ListClassName =
      (inline ? " list-inline" : "") +
      (unstyled ? " list-unstyled" : "") +
      (className ? " " + className : "")
  return (
    <ul className={`List ${ListClassName}`}>
      {children}
    </ul>
  )
}

export default List
