import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { DivIcon, latLngBounds, LatLngBoundsExpression } from "leaflet";
import { MarkerInterface } from "../../interfaces/map/MapInterfaces";
import MapCore from "./MapCore";
import {
  ATTRIBUTION,
  CLUSTER_SPIDERFY_DISTANCE,
  clusterIconCreateFunction,
  LATITUDE_YVELINES_ESSONNE,
  LONGITUDE_YVELINES_ESSONNE,
  MAP_URLS,
  ZOOM
} from "../../constants/map/MapConstants";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MapMarker from "./MapMarker";
import MapWithPopupMarker from "./MapWithPopupMarker";

interface MapBasicProps {
  zoom?: number;
  latitude?: number;
  longitude?: number;
  className?: string;
  markers?: (MarkerInterface | undefined)[];
  getIcon?: (marker: MarkerInterface) => DivIcon;
  onMarkerClick?: (id: string) => void;
  autoCenter?: boolean;
  setAutoCenter?: (autoCenter: boolean) => void;
  onMapClick?: () => void;
  maxClusterRadius?: (zoom: number) => number;
  flyOnClick?: boolean;
  hasPopup?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  hasCluster?: boolean;
}

const MapBasic: FunctionComponent<MapBasicProps> = ({
    zoom = ZOOM,
    latitude = LATITUDE_YVELINES_ESSONNE,
    longitude = LONGITUDE_YVELINES_ESSONNE,
    className = "",
    markers,
    getIcon,
    onMarkerClick,
    autoCenter = false,
    setAutoCenter,
    onMapClick,
    maxClusterRadius = 80,
    flyOnClick,
    hasPopup = false,
    size = "md",
    hasCluster = true,
  }
) => {
  const [bounds, setBounds] = useState<LatLngBoundsExpression>(undefined);

  useEffect(() => {
    const definedMarkers = markers?.filter((marker: MarkerInterface) => !!marker);
    setBounds(
      definedMarkers?.length > 0
        ? latLngBounds(definedMarkers.map((marker: MarkerInterface) => marker.position)).pad(0.05)
        : undefined
    );
  }, [markers]);

  const displayMarker = (marker: MarkerInterface, index: number): ReactElement | null => {
    if (marker.position) {
      if (!hasPopup) {
        return (
          <MapMarker
            key={`marker_${index}`}
            marker={marker}
            getIcon={getIcon}
            onMarkerClick={onMarkerClick}
          />
        )
      } else {
        return (
          <MapWithPopupMarker
            key={`marker_${index}`}
            marker={marker}
            getIcon={getIcon}
          />
        )
      }
    }
    return null;
  }

  const displayMarkers = (markerList: MarkerInterface[]): ReactElement[] => {
    return getIcon && markerList?.map((marker: MarkerInterface, index: number) => displayMarker(marker, index))
  }

  const definedMarkers = markers?.filter((marker: MarkerInterface) => !!marker)
  return (
    <MapContainer
      className={`map ${className} Map-${size}`}
      center={[latitude, longitude]}
      zoom={zoom}
      bounds={bounds}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution={ATTRIBUTION}
        url={MAP_URLS}
      />
      {hasCluster ?
        (
          <MarkerClusterGroup
            key={Math.random()}
            iconCreateFunction={clusterIconCreateFunction}
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={CLUSTER_SPIDERFY_DISTANCE}
            removeOutsideVisibleBounds={false}
            maxClusterRadius={maxClusterRadius}
          >
            {displayMarkers(definedMarkers)}
          </MarkerClusterGroup>
        ) : displayMarkers(definedMarkers)
      }
      <MapCore
        bounds={bounds}
        center={flyOnClick && definedMarkers?.find((marker: MarkerInterface) => marker.selected)}
        autoCenter={autoCenter}
        setAutoCenter={setAutoCenter}
        onMapClick={onMapClick}
      />
    </MapContainer>
  )
}

export default MapBasic;
