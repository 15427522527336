import React, { FunctionComponent } from "react"

// Styles : styles/components/grid

// Reactstrap
import { Row as LibRow, RowProps as LibRowProps } from "reactstrap"

type RowProps = LibRowProps & {
  grid?: boolean
  gutters?: "none" | "xs" | "sm" | "md" | "lg" | "xl"
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse"
  justifyContent?:
    | "start"
    | "end"
    | "center"
    | "around"
    | "between"
    | "flex-start"
    | "flex-end"
  flexWrap?: "wrap" | "nowrap" | "md-nowrap"
  flexFit?: boolean
  alignItems?: "start" | "end" | "center" | "stretch" | "baseline"
}

const Row: FunctionComponent<RowProps> = ({
  children,
  className = "",
  grid,
  flexFit,
  flexWrap,
  flexDirection,
  justifyContent,
  gutters,
  alignItems,
  style,
  ...rest
}) => {
  const RowClassName =
    className +
    (grid ? " grid" : "") +
    (flexFit ? " flex-fit" : "") +
    (flexWrap ? " flex-" + flexWrap : "") +
    (flexDirection ? " flex-" + flexDirection : "") +
    (justifyContent
      ? " justify-content-" + justifyContent
      : "") +
    (gutters ? " gutters-" + gutters : "") +
    (alignItems ? " align-items-" + alignItems : "")
    
  return (
    <LibRow style={style} className={`Row ${RowClassName}`} {...rest}>
      {children}
    </LibRow>
  )
}

export default Row
