import { gql } from "@apollo/client";

export const FOOTER_QUERY = gql`query Footer
  {
    footer {
      title_1
      title_2
      title_3
      editorialGroups_1(
        sort: "order"
      ) {
        id
        urlKey
        title
        url
        editorialPages(
          sort: "order"
        ) {
          id
          urlKey
          title
        }
      }
      editorialGroups_2(
        sort: "order"
      ) {
        id
        urlKey
        title
        url
        editorialPages(
          sort: "order"
        ) {
          id
          urlKey
          title
        }
      }
      editorialGroups_3(
        sort: "order"
      ) {
        id
        urlKey
        title
        url
        editorialPages(
          sort: "order"
        ) {
          id
          urlKey
          title
        }
      }
    }
  }`

export const EDITORIAL_GROUP_QUERY = gql`query EditorialGroup($urlKey: String)
  {
    editorialGroups(
      where: {
        urlKey: $urlKey
      }
    ) {
      id
      urlKey
      title
      url
      hideMenu
      editorialPages(
        sort: "order"
      ) {
        id
        urlKey
        title
      }
    }
  }`

export const EDITORIAL_PAGE_QUERY = gql`query EditorialPage($urlKey: String)
  {
    editorialPages(
      where: {
        urlKey: $urlKey
      }
    ) {
      id
      urlKey
      title
      content
      file {
        formats
        url
        mime
        name
      }
      stepper {
        id
        order
        title
        stepItems {
          id
          label
        }
      }
      maintenanceStepper {
        id
        title
        subtitle
        icon {
          url
          formats
        }
        step {
          id
          title
          content
          lrye
        }
      }
    }
  }`
