import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { languageUtils } from "./utils/languageUtils"
import Messages from "./constants/messages/Messages"
import { history } from "./constants/History"
import { IntlProvider } from "react-intl"
import { Router } from "react-router-dom"
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "lib/apollo";
import { Settings } from "luxon";

const language: string = languageUtils.getNavigatorLanguage()
const client = createApolloClient();
Settings.defaultLocale = language;

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={Messages[language]}>
      <Router history={history}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
      </Router>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
