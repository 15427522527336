import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import { Formik, FormikHelpers } from "formik";
import { PropertyCompany } from "interfaces/properties/PropertiesInterfaces";
import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { Col, FormGroup, Label, ModalBody, ModalHeader } from "reactstrap";
import { DropdownItem} from "reactstrap";
import * as Yup from "yup";
import Modal from "components/atoms/Modal";
import { contactFormService } from "services/ContactFormService";
import { toastService } from "../../services/ToastService";
import Form from "../atoms/Form";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DropdownSelect from "./DropdownSelect";

interface ContactFormValues {
  propertyCompany: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  residentNumber: number;
  uidNumber: string;
  monthlyIncome: number;
  prevTaxIncome: number;
  isTenant: boolean;
  professionalSituation: string;
}

interface ContactFormProps {
  classname?: string;
  propertyCompany: string;
  propertyId: string;
  to: string;
}


const PropertyContactForm: FunctionComponent<ContactFormProps> = ({
  propertyCompany,
  propertyId,
  to
}) => {
  const intl = useIntl();
  const phoneNumRegex = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
  const contactSchema = Yup.object({
    propertyCompany: Yup.string(),
    lastName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    firstName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    email: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    phoneNum: Yup.string().matches(phoneNumRegex, intl.formatMessage({ id: "invalid_phone" })),
    residentNumber: Yup.number(),
    uidNumber: Yup.string(),
    monthlyIncome: Yup.number().when("propertyCompany", {
      is: PropertyCompany.LLI,
      then: Yup.number().required(intl.formatMessage({ id: "mandatory_field" })),
    }),
    prevTaxIncome: Yup.number(),
    isTeanant: Yup.boolean(),
    professionalSituation: Yup.string()
    // ,
    // professionalSituation: Yup.string().when("propertyCompany", {
    //   is: PropertyCompany.OPI,
    //   then: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    // }),
  })

  const [modalInfoOpen, setModalInfoOpen] = useState(false);

  const toggleModalInfo = () => setModalInfoOpen(!modalInfoOpen);
  const onSubmit = (
    values: ContactFormValues,
    { setSubmitting }: FormikHelpers<ContactFormValues>
  ) => {
    const data = {
      user: JSON.stringify(values),
      propertyId: propertyId,
      to,
      from: values.email,
      path: window.location.href
    }
    contactFormService.submitPropertyContactForm(data)
      .then(() => {
        toastService.success(intl.formatMessage({ id: "contact_form_success" }))
      }).catch(() => {
        toastService.success(intl.formatMessage({ id: "contact_form_error" }))
      })
      .finally(() => setSubmitting(false))
  }
  return (
    <>
      <Formik
        validationSchema={contactSchema}
        initialValues={{
          propertyCompany: propertyCompany,
          firstName: "",
          lastName: "",
          email: "",
          phoneNum: "",
          residentNumber: 0,
          uidNumber: "Non renseigné",
          monthlyIncome: 0,
          prevTaxIncome: 0,
          isTenant: false,
          professionalSituation: intl.formatMessage({ id: "contact_form_professional_situation_undefined" })
        }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="m-2">
            <FormGroup row>
              <Col xs={6} sm={4} className="col-form-label">
                <Label className="required" for="firstName" >{intl.formatMessage({ id: "create_account_first_name" })}<span>{"*"}</span></Label>
              </Col>
              <Col className="m-0" >
                <Input
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  invalid={touched.firstName && !!errors.firstName}
                  errorMessage={errors.firstName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={6} sm={4} className="col-form-label">
                <Label className="required" for="lastName" >{intl.formatMessage({ id: "create_account_last_name" })}<span>{"*"}</span></Label>
              </Col>
              <Col className="m-0" >
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  invalid={touched.lastName && !!errors.lastName}
                  errorMessage={errors.lastName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={6} sm={4} className="col-form-label">
                <Label className="required" for="email" >{intl.formatMessage({ id: "create_account_email" })}<span>{"*"}</span></Label>
              </Col>
              <Col className="m-0" >
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  invalid={touched.email && !!errors.email}
                  errorMessage={errors.email}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={6} sm={4} className="col-form-label">
                <Label className="required" for="phoneNum" >{intl.formatMessage({ id: "contact_form_phone_number" })}<span>{"*"}</span></Label>
              </Col>
              <Col className="m-0" >
                <Input
                  name="phoneNum"
                  value={values.phoneNum}
                  onChange={handleChange}
                  invalid={touched.phoneNum && !!errors.phoneNum}
                  errorMessage={errors.phoneNum}
                />
              </Col>
            </FormGroup>
            {propertyCompany &&
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label className="required" for="residentNumber" >{intl.formatMessage({ id: "contact_form_number_of_residents" })}
                    <span>{
                    propertyCompany === PropertyCompany.OPI
                    }</span>
                  </Label>
                </Col>
                <Col className="m-0" >
                  <Input
                    name="residentNumber"
                    value={values.residentNumber}
                    onChange={handleChange}
                    invalid={touched.residentNumber && !!errors.residentNumber}
                    errorMessage={errors.residentNumber}
                  />
                </Col>
              </FormGroup>
            }
            {propertyCompany === PropertyCompany.LLI &&
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label className="required" for="monthlyIncome" >{intl.formatMessage({ id: "contact_form_monthly_income" })}
                  </Label>
                </Col>
                <Col className="m-0" >
                  <Input
                    name="monthlyIncome"
                    value={values.monthlyIncome}
                    onChange={handleChange}
                    invalid={touched.monthlyIncome && !!errors.monthlyIncome}
                    errorMessage={errors.monthlyIncome}
                  />
                </Col>
              </FormGroup>
            }
            {propertyCompany === PropertyCompany.LLI &&
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label className="required" for="prevTaxIncome" >{intl.formatMessage({ id: "contact_form_previous_tax_income" })}
                  </Label>
                </Col>
                <Col className="m-0" >
                  <Input
                    name="prevTaxIncome"
                    value={values.prevTaxIncome}
                    onChange={handleChange}
                    invalid={touched.prevTaxIncome && !!errors.prevTaxIncome}
                    errorMessage={errors.prevTaxIncome}
                  />
                </Col>
              </FormGroup>
            }
            {propertyCompany === PropertyCompany.OPI &&
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label for="uidNumber" >{intl.formatMessage({ id: "contact_form_unique_number" })}</Label>
                  <Button id="popInfo" type="button" outline size="xs" color="dark" icon pill onClick={() => toggleModalInfo()} className="PopoverTrigger">i</Button>
                </Col>
                <Col className="m-0" >
                  <Input
                    name="uidNumber"
                    value={values.uidNumber}
                    onChange={handleChange}
                    invalid={touched.uidNumber && !!errors.uidNumber}
                    errorMessage={errors.uidNumber}
                  />
                </Col>
              </FormGroup>  
            }
            {propertyCompany === PropertyCompany.OPI &&
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label className="required" for="isTenant" >{intl.formatMessage({ id: "contact_form_is_tenant" })}
                  </Label>
                </Col>
                <Col className="m-0" >
                <BootstrapSwitchButton
                    width={80}
                    checked={false}
                    onlabel={intl.formatMessage({ id: "contact_form_is_tenant_yes" })}
                    offlabel={intl.formatMessage({ id: "contact_form_is_tenant_no" })}
                    onChange={(checked: boolean) => {
                      values.isTenant = checked;
                    }}
                />
                </Col>
              </FormGroup>
            }
            {propertyCompany === PropertyCompany.OPI && (
              <FormGroup row>
                <Col xs={8} className="col-form-label">
                  <Label className="required" for="professionalSitualtion">
                    {intl.formatMessage({ id: "contact_form_professional_situation" })}<span></span>
                  </Label>
                </Col>
                
                  <Col className="m-0">
                    <DropdownSelect
                      bsSize="sm"
                      value={values.professionalSituation}
                      invalid={touched.professionalSituation && !!errors.residentNumber}
                    >
                      <DropdownItem
                        value={intl.formatMessage({ id: "contact_form_professional_situation_working" })}
                        onClick={() => {
                          values.professionalSituation = intl.formatMessage({ id: "contact_form_professional_situation_working" });
                        }}>
                        {intl.formatMessage({ id: "contact_form_professional_situation_working" })}
                      </DropdownItem>
                      <DropdownItem
                        value={intl.formatMessage({ id: "contact_form_professional_situation_jobless" })}
                        onClick={() => {
                          values.professionalSituation = intl.formatMessage({ id: "contact_form_professional_situation_jobless" });
                        }}>
                        {intl.formatMessage({ id: "contact_form_professional_situation_jobless" })}
                      </DropdownItem>
                    </DropdownSelect>
                  </Col>
              </FormGroup>
            )}

            <Button
              color="primary"
              type="submit"
              size="lg"
              style={{ minWidth: "100%", }}
              disabled={isSubmitting}
            >
              {intl.formatMessage({ id: "contact_form_submit" })}
            </Button>
          </Form>
        )}
      </Formik>
      <Modal isOpen={modalInfoOpen} toggle={toggleModalInfo}>
        <ModalHeader toggle={toggleModalInfo}>
          {intl.formatMessage({ id: "property_form_modal_title" })}
        </ModalHeader>
        <ModalBody >
          {intl.formatMessage({ id: "property_form_modal_description" })}
        </ModalBody>
      </Modal>
    </>
  )
}

export default PropertyContactForm
