import React, { FunctionComponent } from "react"

// Styles : styles/layout/menuEditorial

import Menu from "./Menu"
import MenuNav from "components/molecules/MenuNav";
import MenuSocial from "components/molecules/MenuSocial";

interface MenuEditorialProps {
  togglePhoneModalOpen: () => void;
}

const MenuEditorial: FunctionComponent<MenuEditorialProps> = ({ togglePhoneModalOpen }) => {

  return (
    <Menu className="MenuEditorial">
      <div className="MenuContainerEditorial">
        <MenuNav />
        <MenuSocial onPhoneClick={togglePhoneModalOpen} />
      </div>
    </Menu>
  )
}

export default MenuEditorial
