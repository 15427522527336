import React, { FunctionComponent, ReactElement } from "react";
import Card from "../atoms/Card";
import { CardBody, CardText } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Figure from "../atoms/Figure";
import { FIGURE_RATIOS } from "../../constants/common/CommonConstants";
import Icon from "../atoms/Icon";

interface CardTicketProps {
  selected: boolean;
  onClick: () => void;
  name: string;
  icon?: string;
}

const CardTicket: FunctionComponent<CardTicketProps> = ({
  onClick,
  selected,
  name,
  icon
}): ReactElement => {
  return (
    <Card onClick={onClick} className={`card-ticket ${selected ? "selected" : ""}`} rounded>
      {icon && (
        <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
          <Icon name={icon} />
        </Figure>
      )}
      <CardBody>
        <CardText className="lead">
          <FormattedMessage id={name} />
        </CardText>
      </CardBody>
    </Card>
  );
}

export default CardTicket;