import React, { FunctionComponent, ReactElement } from "react";
import Modal from "../atoms/Modal";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Button from "../atoms/Button";

interface BasicModalProps {
  open: boolean;
  header?: string;
  text?: string;
  body?: ReactElement;
  onClose: () => void;
  button?: string;
  backdrop?: boolean | "static";
}

const BasicModal: FunctionComponent<BasicModalProps> = ({
  open,
  header,
  text,
  body,
  onClose,
  button,
  backdrop,
}): ReactElement => {
  return (
    <Modal className="BasicModal" isOpen={open} toggle={onClose} backdrop={backdrop}>
      {header && (
        <ModalHeader>
          <FormattedMessage id={header} />
        </ModalHeader>
      )}
      <ModalBody>
        {text && <FormattedMessage id={text} />}
        {body}
      </ModalBody>
      {button && (
        <ModalFooter>
          <Button onClick={onClose} title={button} color="primary" />
        </ModalFooter>
      )}
    </Modal>
  );
}

export default BasicModal;