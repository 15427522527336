export const FIGURE_RATIOS = {
  SIXTEEN_NINE: "16-9",
  THREE_TWO: "3-2",
  FOUR_THREE: "4-3",
  ONE_ONE: "1-1",
  THREE_FOUR: "3-4",
  TWO_THREE: "2-3",
}

export const COMMUNITY_LIST = [
  "Ablis",
  "Achères",
  "Andresy",
  "Arpajon",
  "Athis-Mons",
  "Aubergenville",
  "Bennecourt",
  "Beynes",
  "Bois-d'Arcy",
  "Boissy-sous-Saint-Yon",
  "Bonnelles",
  "Bougival",
  "Boussy-Saint-Antoine",
  "Brétigny-sur-Orge",
  "Brunoy",
  "Buchelay",
  "Carrières-sous-Poissy",
  "Chanteloup-les-Vignes",
  "Chatou",
  "Chevreuse",
  "Conflans-Sainte-Honorine",
  "Corbeil-Essonnes",
  "Croissy",
  "Dammartin-en-Serve",
  "Dourdan",
  "Drocourt",
  "Ecquevilly",
  "Élancourt",
  "Élisabethville",
  "Épône",
  "Étampes",
  "Évry-Courcouronnes",
  "Follainville-Dennemont",
  "Fontenay-Mauvoisin",
  "Fontenay-Saint-Père",
  "Freneuse",
  "Gargenville",
  "Gometz-la-Ville",
  "Gommecourt",
  "Grigny",
  "Guerville",
  "Guyancourt",
  "Hardricourt",
  "Houdan",
  "Houilles",
  "Jouars-Pontchartrain",
  "Juvisy sur Orge",
  "Juziers",
  "La Celle-Saint-Cloud",
  "La Verrière",
  "La ville-du-Bois",
  "La Ferté-Alais",
  "Le Chesnay-Rocquencourt",
  "Le Mesnil-le-Roi",
  "Le Mesnil-Saint-Denis",
  "Le Pecq",
  "Le Vésinet",
  "Les Essarts-le-Roi",
  "Les Mureaux",
  "Les Ulis",
  "Leuville-sur-Orge",
  "Limay",
  "Lisses",
  "Longjumeau",
  "Longpont-sur-Orge",
  "Magnanville",
  "Magny-les-Hameaux",
  "Mantes-la-Jolie",
  "Mantes-la-Ville",
  "Marcoussis",
  "Marcq",
  "Mareil-Marly",
  "Marly-le-Roi",
  "Marolles-en-Hurepoix",
  "Massy",
  "Maurecourt",
  "Maurepas",
  "Meulan-en-Yvelines",
  "Mézières-sur-Seine",
  "Moigny-sur-École",
  "Montgeron",
  "Montlhéry",
  "Neauphle-le-Château",
  "Noisy-le-Roi",
  "Orgerus",
  "Ormoy",
  "Palaiseau",
  "Plaisir",
  "Poissy",
  "Porcheville",
  "Rambouillet",
  "Rolleboise",
  "Sailly",
  "Saint-Chéron",
  "Saint-Cyr-l'École",
  "Saint-Germain-lès-Arpajon",
  "Saint-Germain-lès-Corbeil",
  "Saint-Illiers-la-ville",
  "Saint-Léger-en-Yvelines",
  "Saint-Martin-la-Garenne",
  "Saint-Michel-sur-Orge",
  "Saint-Pierre-du-Perray",
  "Savigny-sur-Orge",
  "Trappes",
  "Triel-sur-Seine",
  "Vauhallan",
  "Vaux-sur-Seine",
  "Versailles",
  "Vigneux-sur-Seine",
  "Villabé",
  "Villebon-sur-Yvette",
  "Villepreux",
  "Villiers-le-Mahieu",
  "Villiers-Saint-Frédéric",
  "Villiers-sur-Orge",
  "Viroflay",
  "Viry-Châtillon",
  "Wissous",
  "Yerres",
];

export enum ANSWER {
  YES = "oui",
  NO = "non",
  SINGLE = "seul",
  INCOUPLE = "encouple"
}

export enum FORMATTED_ANSWERS {
  YES = "general_form_answer_yes",
  NO = "general_form_answer_no"
}

export const SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = { behavior: "smooth", block: "center" }
