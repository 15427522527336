import { useEffect, useState } from "react";
import { rentService } from "../services/RentService";
import { PaymentLinkResponse } from "../interfaces/rents/RentsInterfaces";

interface UsePaymentLink {
  paymentLink: string;
  paymentAmount: string;
  paymentLinkLoading: boolean;
}

export const usePaymentLink = (contractId: string, paymentAmount: string): UsePaymentLink => {
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (contractId) {
      setLoading(true);
      void rentService.getPaymentLink(contractId)
        .then((response: PaymentLinkResponse) => {
          setPaymentLink(response.link)
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [contractId])

  return {
    paymentLink,
    paymentAmount,
    paymentLinkLoading: loading
  };
}