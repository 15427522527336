import React, { FunctionComponent, ReactElement } from "react";
import Section from "../atoms/Section";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { useQuery } from "@apollo/client";
import { Podcast, PodcastQuery } from "../../interfaces/podcasts/PodcastsInterfaces";
import { PODCASTS_QUERY } from "../../constants/queries/PodcastsQuery";
import { handleApolloError } from "../../hooks/handleApolloError";
import { useIntl } from "react-intl";
import Heading from "./Heading";


const Podcasts: FunctionComponent = ({}): ReactElement => {
  const intl = useIntl();
  const {
    data: podcastsData,
    error: podcastError
  } = useQuery<PodcastQuery>(PODCASTS_QUERY)
  handleApolloError("podcasts_query", podcastError);

  const sortPodcast = (a: Podcast, b: Podcast): number => {
    if (!a || !a.order) {
      return 1;
    }
    if (!b || !b.order) {
      return -1;
    }
    if (a.order === b.order) {
      return 0;
    }
    return a.order < b.order ? 1 : -1;
  }

  return (
    <Section>
      <Heading
        upTitle={intl.formatMessage({ id: "podcasts_page_maintitle" })}
        mainTitle={intl.formatMessage({ id: "podcasts_page_subTitle" })}
      />
      <div className="d-flex flex-column flex-md-row">
        <div id="EditorialContent" className="flex-grow-1 px-5">
          {podcastsData?.podcasts?.slice().sort(sortPodcast).map((podcast, index) => (
            <ReactMarkdownWithHtml
              key={index}
              allowDangerousHtml
              plugins={[gfm]}
              className="description"
            >
              {podcast.iframe}
            </ReactMarkdownWithHtml>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default Podcasts;
