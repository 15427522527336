import { Params } from "../interfaces/api/ApiInterfaces"
import { authService } from "../services/AuthService"
import { toastService } from "../services/ToastService";
import { WsError } from "../interfaces/error/ErrorsInterfaces";

// helper functions
type AuthorizationHeader = {
  "x-authorization": string;
};

function authHeader(url: string): AuthorizationHeader | undefined {
  const user = authService.getLoggedInUser()
  const isLoggedIn = user && user.jwt
  const isApiUrl = url.includes("/api/")
  if (isLoggedIn && isApiUrl && user) {
    return { "x-authorization": `Bearer ${user.jwt}` }
  }
  return undefined
}

function handleResponseError(response: Response, data: WsError) {
  if ([401, 403].includes(response.status)) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    const user = authService.getLoggedInUser();
    if (user) {
      authService.logout()
    }
  }

  const error = data || response.statusText
  if (data) {
    toastService.errorWs(data)
  } else {
    toastService.error(response.statusText || response.status.toString())
  }
  return Promise.reject(error)
}

function handleResponse<T>(response: Response): Promise<T> {
  return response.text().then((text: string) => {
    let data;
    try {
      data = text && JSON.parse(text)
    } catch (e) {
      data = {
        statusCode: response.status,
        error: response.statusText,
        message: response.statusText,
      }
    }
    if (!response.ok) {
      return handleResponseError(response, data)
    }

    return data as Promise<T>
  })
}

function get<T>(url: string): Promise<T> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: { ...authHeader(url), Accept: "application/json" }
  }

  return fetch(url, requestOptions).then((response) => {
    return handleResponse<T>(response)
  })
}

function submit<T>(
  url: string,
  body: Params | null,
  method: string
): Promise<T> {
  const requestOptions: RequestInit = {
    method: method,
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: body && JSON.stringify(body)
  }
  return fetch(url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

function post<T>(url: string, body: Params | null): Promise<T> {
  return submit(url, body, "POST")
}

function put<T>(url: string, body: Params): Promise<T> {
  return submit(url, body, "PUT")
}

function patch<T>(url: string, body: Params): Promise<T> {
  return submit(url, body, "PATCH")
}

function postFormData<T>(url: string, body: FormData): Promise<T> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: body,
    headers: authHeader(url)
  }
  return fetch(url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string): Promise<Response> {
  const requestOptions: RequestInit = {
    method: "DELETE",
    headers: authHeader(url)
  }
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  })
}

export const fetchWrapper = {
  get,
  post,
  postFormData,
  put,
  patch,
  delete: _delete
}
