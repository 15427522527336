import { useState, useEffect } from "react"
import memoizeOne from "memoize-one"

export const gridBreakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1240
}

export type BreakPoints = {
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
  isXl: boolean

  isSmOrMore: boolean
  isMdOrMore: boolean
  isLgOrMore: boolean

  isSmOrLess: boolean
  isMdOrLess: boolean
  isLgOrLess: boolean
}

const memoizeBreakpoints = memoizeOne(
  (width: number | undefined): BreakPoints => {
    if (width === undefined) {
      return {
        isXs: true,
        isSm: true,
        isMd: true,
        isLg: true,
        isXl: true,

        isSmOrMore: true,
        isMdOrMore: true,
        isLgOrMore: true,

        isSmOrLess: true,
        isMdOrLess: true,
        isLgOrLess: true
      }
    }

    return {
      isXs: width < gridBreakpoints.sm,
      isSm: width >= gridBreakpoints.sm && width < gridBreakpoints.md,
      isMd: width >= gridBreakpoints.md && width < gridBreakpoints.lg,
      isLg: width >= gridBreakpoints.lg && width < gridBreakpoints.xl,
      isXl: width >= gridBreakpoints.xl,

      isSmOrMore: width >= gridBreakpoints.sm,
      isMdOrMore: width >= gridBreakpoints.md,
      isLgOrMore: width >= gridBreakpoints.lg,

      isSmOrLess: width < gridBreakpoints.md,
      isMdOrLess: width < gridBreakpoints.lg,
      isLgOrLess: width < gridBreakpoints.xl,
    }
  }
)

export const getBreakpoints = (): BreakPoints => {
  return memoizeBreakpoints(window.innerWidth)
}

export const useBreakpoints = (): BreakPoints => {
  const [breakpoints, setBreakpoints] = useState(getBreakpoints)

  useEffect(() => {
    const update = () => {
      setBreakpoints(getBreakpoints())
    }
    update()
    let onResizeTimeout: ReturnType<typeof setTimeout>
    const onResize = () => {
      if (onResizeTimeout) {
        clearTimeout(onResizeTimeout)
      }
      onResizeTimeout = setTimeout(update, 300)
    }
    window.addEventListener("resize", onResize)

    return () => {
      if (onResizeTimeout) {
        clearTimeout(onResizeTimeout)
      }
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return breakpoints
}
