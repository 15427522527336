import React, { FunctionComponent } from "react"

// Styles : styles/atoms/card
// Reactstrap
import { Card as LibCard, CardProps as LibCardProps } from "reactstrap"
import Loader from "./Loader";

type CardProps = LibCardProps & {
  noBorder?: boolean
  noShadow?: boolean
  noZoom?: boolean
  rounded?: boolean
  roundedRadius?: "sm" | "md" | "lg"
  noFade?: boolean
  disabled?: boolean;
}

const Card: FunctionComponent<CardProps> = ({
  children,
  className = "",
  noBorder,
  noShadow,
  noZoom,
  rounded,
  roundedRadius,
  noFade,
  disabled,
  ...rest
}) => {
  const CardClassName =
    (noBorder ? " no-border" : "") +
    (noShadow ? " no-shadow" : "") +
    (noZoom || disabled ? " no-zoom" : "") +
    (className ? " " + className : "") +
    (rounded ? roundedRadius ? " Rounded--" + roundedRadius : " Rounded--lg" : "") +
    (noFade ? " no-fade" : "") +
    (disabled ? " disabled" : "");
  return (
    <LibCard className={`Card ${CardClassName}`} {...rest}>
      {children}
      {disabled && (
        <div className="LoaderContainer">
          <Loader />
        </div>
      )}
    </LibCard>
  )
}

export default Card
