import React, { FunctionComponent, useContext } from "react"

// Styles : styles/layout/header
import { LOGO_LRYE, LOGO_LLI } from "../../assets/Assets"

import { NavbarBrand } from "reactstrap";

import Container from "components/atoms/Container";
import Navbar from "components/atoms/Navbar";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import Header from "./Header";
import { HOME_PATH, } from "../../constants/routes/RoutePaths";
import { useBreakpoints } from "utils/breakpoints";
import MenuNav from "components/molecules/MenuNav";
import MenuUser from "components/molecules/MenuUser";
import MenuSocial from "components/molecules/MenuSocial";
import { ContextMenu } from "contexts/contextMenu";
import { history } from "constants/History";
import { Complex } from "../../interfaces/complex/ComplexInterfaces";

interface HeaderEditorialProps {
  togglePhoneModalOpen: () => void,
  hasDashboard: boolean,
  complex?: Complex
}

const HeaderEditorial: FunctionComponent<HeaderEditorialProps> = ({
  togglePhoneModalOpen,
  hasDashboard,
  complex
}) => {
  const breakpoints = useBreakpoints()
  const { toggleMenuOpen } = useContext(ContextMenu)

  return (
    <Header className="HeaderEditorial" stickyTop>
      <>
        <Navbar light>
          <Container>
            <div className="NavbarLogo">
              {breakpoints.isMdOrLess ? (
                <Button className="NavbarToggler" color="link" onClick={toggleMenuOpen}>
                  <Icon name="Menu" />
                </Button>
              ) : undefined}
              {hasDashboard && complex ?(
                complex.short_code === "LLI"?
                  <NavbarBrand onClick={() => history.push(HOME_PATH)} className="NavbarBrand cursor-pointer">
                    <img src={LOGO_LLI} alt="app-logo" className="Logo" />
                  </NavbarBrand>
                :
                  <NavbarBrand onClick={() => history.push(HOME_PATH)} className="NavbarBrand cursor-pointer">
                      <img src={LOGO_LRYE} alt="app-logo" className="Logo" />
                  </NavbarBrand>
              ):(
                <NavbarBrand onClick={() => history.push(HOME_PATH)} className="NavbarBrand cursor-pointer">
                  <img src={LOGO_LRYE} alt="app-logo" className="Logo" />
                </NavbarBrand>
              )}
            </div>

            <div className="NavbarMenu">
              {breakpoints.isLgOrMore ? (
                <MenuNav />
              ) : undefined}

              <MenuUser />

              {breakpoints.isLgOrMore ? (
                <MenuSocial onPhoneClick={togglePhoneModalOpen} />
              ) : undefined}

            </div>
          </Container>
        </Navbar>
      </>
    </Header>
  )
}

export default HeaderEditorial
