import React, { FunctionComponent, ReactElement } from "react";
import { EditorialPage } from "../../interfaces/footer/FooterInterfaces";
import Nav from "../atoms/Nav";
import NavLinkLayout from "../molecules/NavLinkLayout";
import { EDITORIAL_PATH } from "../../constants/routes/RoutePaths";
import Section from "../atoms/Section";

const getPath = (groupUrlKey: string, pageUrlKey: string): string => {
  return EDITORIAL_PATH.replace(":groupUrlKey", groupUrlKey).replace(":pageUrlKey", pageUrlKey)
}

interface EditorialMenuProps {
  editorialPages: EditorialPage[];
  groupUrlKey: string;
}

const EditorialMenu: FunctionComponent<EditorialMenuProps> = ({ groupUrlKey, editorialPages }): ReactElement => {
  return (
    <Section id="EditorialMenu" className="bg-alt Rounded--lg">
      <Nav vertical>
        {editorialPages.map((editorialPage: EditorialPage) => (
          <NavLinkLayout
            key={editorialPage.id}
            message={editorialPage.title}
            path={getPath(groupUrlKey, editorialPage.urlKey)}
            disableTranslation
          />
        ))}
      </Nav>
    </Section>
  );
}

export default EditorialMenu;