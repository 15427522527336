import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";


export const TestEligibilityFail: FunctionComponent = () => {
  const intl = useIntl();

  return (
      <p>{intl.formatMessage({ id: "form_result_eligible_message_fail" })}
      </p>
  )
}