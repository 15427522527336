import React, { FunctionComponent } from "react";
import { Ticket } from "../../interfaces/tickets/TicketsInterfaces";
import { TICKET_STATUS } from "../../constants/tickets/TicketsConstants";

interface TicketProps {
  ticket: Ticket;
  onClick?: () => void;
}

const TicketComponent: FunctionComponent<TicketProps> = ({ ticket, onClick }) => {
  const closed = ticket.badge.status_id === TICKET_STATUS.CLOSED.id

  const name = closed ? (
    <span className="name">
      {ticket.name} :
    </span>
  ) : (
    <a onClick={() => onClick && onClick()} className="name cursor-pointer">
      {ticket.name} :
    </a>
  )

  return (
    <li className="ticket">
      {name}
      <span className={`status ${closed ? "closed" : "opened"}`}>
        {ticket.badge.label}
      </span>
    </li>
  )
}

export default TicketComponent