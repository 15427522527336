import { gql } from "@apollo/client";

export const AGENCIES_QUERY = gql`
query agency {
  agencies {
    id
    name
    email
    urlKey
    photo {
      url
      formats
    }
  }
} 
`

export const AGENCY_DETAIL_QUERY = gql`
  query agency($urlKey:String)
  {
    agencies(where:{ urlKey:$urlKey })
    {
      name
      email
      address
      zip_code
      city
      longitude
      latitude
      open_hours
      phone
      coordinates {
        name
        latitude
        longitude
      }
      articles   {
        id
        title
        chapeau
        urlKey
        created_at
        readingTime
        photo {
          formats
          url
        }
        highlight
      }
      photo {
        formats
        url
      } 
      director { 
        title
        subtitle
        description
        photo { url, formats }
      }
    }
  } 
`

export const OUR_AGENCIES_PAGE_QUERY = gql
  `query OurAgencies {
    ourAgency {
      title
      description
    }
  }`
