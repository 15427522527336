import { ComplexDetail, ComplexUrlKey, ComplexWithProperties } from "interfaces/complex/ComplexInterfaces";
import { MarkerInterface, MarkerType } from "interfaces/map/MapInterfaces";
import { LatLng } from "leaflet";
import { fetchWrapper } from "../utils/fetchWrapper";
import { COMPLEX_CONTRACT_ROUTE, COMPLEX_ROUTE, COMPLEX_WITH_PROPERTIES_ROUTE } from "../constants/ApiRoutes";

const mapComplexToMarkers = (complex: ComplexWithProperties | ComplexDetail, selectedId?: string): MarkerInterface => {
  if (!complex || complex.longitude === null || complex.latitude === null) {
    return null
  }
  return {
    id: complex.id,
    position: new LatLng(complex.latitude, complex.longitude),
    title: complex.label,
    type: MarkerType.complex,
    selected: complex.id === selectedId,
  }
}

const mapComplexWithPropertiesToMarkers = (complex: ComplexWithProperties, selectedId?: string): MarkerInterface => {
  if (!complex || complex.longitude === null || complex.latitude === null) {
    return null
  }
  return {
    ...mapComplexToMarkers(complex, selectedId),
    complex,
  }
}

const findAll = (): Promise<ComplexWithProperties[]> => {
  return fetchWrapper.get<ComplexWithProperties[]>(COMPLEX_ROUTE);
}

const findComplexUrlKeyByContractNumber = (contractId: string): Promise<ComplexUrlKey> => {
  return fetchWrapper.get<ComplexUrlKey>(COMPLEX_CONTRACT_ROUTE + `?contractId=${contractId}`);
}

const findWithProperties = (complexId: string): Promise<ComplexWithProperties> => {
  return fetchWrapper.get<ComplexWithProperties>(COMPLEX_WITH_PROPERTIES_ROUTE.replace(":id", complexId));
}

export const complexService = {
  mapComplexToMarkers,
  mapComplexWithPropertiesToMarkers,
  findAll,
  findComplexUrlKeyByContractNumber,
  findWithProperties
}
