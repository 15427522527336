import { useEffect } from "react";
import { toastService } from "../services/ToastService";
import { ApolloError } from "@apollo/client";

export const handleApolloError = (name: string, error?: ApolloError): void => {
  useEffect(() => {
    if (error) {
      toastService.errorApollo(name, error)
    }
  }, [error])
}