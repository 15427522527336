import React, { FunctionComponent, ReactElement } from "react";
import { useQuery } from "@apollo/client";
import { EditorialPageQueryInterface } from "../../interfaces/footer/FooterInterfaces";
import { EDITORIAL_PAGE_QUERY } from "../../constants/queries/FooterQueries";
import { handleApolloError } from "../../hooks/handleApolloError";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import Section from "../atoms/Section";
import { FormattedMessage } from "react-intl";
import PressDocument from "../atoms/PressDocument";

const Press: FunctionComponent = (): ReactElement => {
  const {
    data: editorialData,
    error: editorialError
  } = useQuery<EditorialPageQueryInterface>(EDITORIAL_PAGE_QUERY, { variables: { urlKey: "contact-presse" } });
  handleApolloError("editorial_page_query", editorialError);

  return (
    <Section>
      {editorialData?.editorialPages[0] && (
        <ReactMarkdownWithHtml
          allowDangerousHtml
          plugins={[gfm]}
          className="description"
        >
          {editorialData.editorialPages[0].content}
        </ReactMarkdownWithHtml>
      )}
      <h3>
        <FormattedMessage id="press_media" />
      </h3>
      <div className="d-inline-flex">
        <PressDocument url="/press/Logo blanc.png" name="press_logo" />
        <PressDocument url="/press/LRYE_CHARTE_2020.pdf" name="press_charte" />
        <PressDocument url="/press/LRYE_logo_CMJN.eps" name="press_logo_cmjn" />
        <PressDocument url="/press/La carte de notre parc immobilier.pdf" name="press_patrimoine" />
        <PressDocument url="/press/plaquette_inst_2023_pageapage.pdf" name="press_plaquette" />
      </div>
    </Section>
  );
}

export default Press;
