import React, { FunctionComponent, useState } from "react"
import { RouteProps } from "react-router"

import { LOGO_LRYE, PLACEHOLDERS } from "../assets/Assets";

import { CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col, Collapse, Container, CustomInput, DropdownItem, FormGroup, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button";
import Row from "components/atoms/Row";
import Section from "components/atoms/Section";
import Card from "components/atoms/Card";
import Layout from "../components/layout/Layout";
import Nav from "components/atoms/Nav";
import Navbar from "components/atoms/Navbar";
import Icon from "components/atoms/Icon";
import Img from "components/atoms/Img";
import Figure from "components/atoms/Figure";
import Overlay from "components/atoms/Overlay";
import Form from "components/atoms/Form";
import Label from "components/atoms/Label";
import { FIGURE_RATIOS } from "../constants/common/CommonConstants";
import Input from "components/atoms/Input";
import Carousel from "components/atoms/Carousel";
import DropdownSelect from "components/molecules/DropdownSelect";

const ComponentsView: FunctionComponent<RouteProps> = () => {

  const [menuOpen, setMenuOpen] = useState<boolean>(true);

  return (
    <Layout className="ComponentsView">
      <React.Fragment>
        <Section>
          <Button color="info" tag="a" href="/components-dashboard" className="float-right">Composants dashboard</Button>
          <h1 className="h1">Components</h1>
          <p>Ce framework graphique est basé sur <a href="https://getbootstrap.com/docs/4.6/getting-started/introduction/" title="Bootstrap 4.6">Bootstrap 4.6</a> et <a href="https://reactstrap.github.io/" title="Reacstrap">Reactstrap</a></p>
          <p>Les styles sont packagé, nous n&apos;appelons pas tout bootstrap, chaque composant importe si nescessaire les éléments de <a href="https://getbootstrap.com/docs/4.6/getting-started/introduction/" title="Bootstrap 4.6">Bootstrap</a> puis surcharge</p>
          <p>Les composants reactstrap sont appelés par leurs équivalents spécifics, se référer à la doc <a href="https://reactstrap.github.io/" title="Reacstrap">Reactstrap</a> pour connaitre toutes les propriétés.</p>
          <Nav>
            <NavItem>
              <NavLink href="#Utilities">Utilities</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Types">Types</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Navbar">Navbar</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Section">Section</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Grid">Grid</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Nav">Nav</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Icon">Icon</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Badge">Badge</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Button">Button</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Figure">Figure & Image</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Card">Card</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Form">Form</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Carousel">Carousel</NavLink>
            </NavItem>
          </Nav>
        </Section>

        <hr className="Hr" />

        <Section id="Utilities">
          <h2 className="h2">Utilities</h2>
          <h3 className="h3">Background</h3>
          <p>
            <span className="Block bg-primary">bg-primary</span>
            <span className="Block bg-secondary">bg-secondary</span>
            <span className="Block bg-tertiary">bg-tertiary</span>
            <span className="Block bg-info">bg-info</span>
            <span className="Block bg-dark">bg-dark</span>
            <span className="Block bg-light">bg-light</span>
          </p>

          <hr className="Hr" />
          <h3 className="h3">Color</h3>
          <p>
            <span className="Block text-primary">text-primary</span>
            <span className="Block text-secondary">text-secondary</span>
            <span className="Block text-tertiary">text-tertiary</span>
            <span className="Block text-info">text-info</span>
            <span className="Block text-dark">text-dark</span>
            <span className="Block text-light">text-light</span>
          </p>
        </Section>

        <hr className="Hr" />

        <Section id="Types">
          <h2 className="h2">Types</h2>
          <hr className="Hr" />
          <Row grid>
            <Col xs="12" md="6">
              <h2 className="h2">Titre de section / h2 <br/>
              <small>(tag modifiable pour SEO)</small></h2>
              <p>Paragraphe de texte dont éléments standards :<br/>
              <a href="" title="">ancre</a> <strong>strong</strong> <em>emphase</em></p>
            </Col>
            <Col xs="12" md="6">
            <Card>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Titre de card / h3<br/>
                  <small>(tag modifiable pour SEO)</small></CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Text de card</CardText>
                  <CardText><span className="Date">Date</span> | <span className="Duration">Temps de lecture</span></CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section id="Navbar">
          <h2 className="h2">Navbar</h2>
          <Navbar light>
            <Container>
              <NavbarBrand className="NavbarBrand">
                <img src={LOGO_LRYE} alt="app-logo" className="Logo" />
              </NavbarBrand>
              <NavbarToggler className="NavbarToggler" onClick={() => setMenuOpen(!menuOpen)} />
              <Collapse className="NavbarMenu" isOpen={menuOpen}>
                <Nav className="MenuNav">
                  <NavItem>
                    <NavLink href="" >Nos logements</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="" >Nos actualités</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="" >Nos réalisations</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="" >Nos fournisseurs</NavLink>
                  </NavItem>
                </Nav>
                <Nav className="MenuUser">
                  <NavItem>
                    <span className="UserText">Bonjour Nicolas !</span> <Button color={"primary"} pill icon><Img className="UserImage" src={PLACEHOLDERS._40x40} alt="" /></Button>
                  </NavItem>
                  <NavItem>
                    <Button tag={"a"} color={"primary"} outline pill icon><Icon name="Twitter" /></Button>
                  </NavItem>
                  <NavItem>
                    <Button tag={"a"} color={"primary"} outline pill icon><Icon name="Youtube" /></Button>
                  </NavItem>
                  <NavItem>
                    <Button tag={"a"} color={"primary"} outline pill icon><Icon name="Phone" /></Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </Section>

        <hr className="Hr" />

        <Section id="Section">
          <h2 className="h2">Section</h2>
          <p>Section reçoit un containeur par défaut</p>
        </Section>
        <Section bgAlt>
          <p>Section avec un arrière-plan alternatif (par defaut équivalent à l&apos;utilitie bg-light)</p>
        </Section>
        <Section bgColor="primary">
          <p>Section avec un arrière-plan spécifique (utilise l&apos;utilitie bg-primary)</p>
        </Section>
        <Section bgColor="tertiary">
          <p>Section avec un arrière-plan spécifique (utilise l&apos;utilitie bg-tertiary)</p>
        </Section>
        <Section containerFluid>
          <p>Section avec un container fluid</p>
        </Section>

        <hr className="Hr" />

        <Section id="Grid">
          <h2 className="h2">Grid</h2>
          <h3 className="h3">Grille classique pour les structures</h3>
          <Row>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="8"><div className="Block bg-light">Col</div></Col>
          </Row>
          <hr className="Hr" />
          <h3 className="h3">Grille multiligne pour les listes de vignettes</h3>
          <Row grid>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
            <Col xs="12" md="4"><div className="Block bg-light">Col</div></Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section id="Nav">
          <h2 className="h2">Nav</h2>
          <h3 className="h3">Standard</h3>
          <Nav>
            <NavItem>
              <NavLink href=""  active>Item 1</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" >Item 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" >Item 3</NavLink>
            </NavItem>
          </Nav>

          <hr className="Hr" />
          <h3 className="h3">Vertical</h3>
          <Nav vertical>
            <NavItem>
              <NavLink href=""  active>Item 1</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" >Item 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" >Item 3</NavLink>
            </NavItem>
          </Nav>

          <hr className="Hr" />
          <h3 className="h3">Tabs</h3>
          <Nav tabs>
            <NavItem>
              <NavLink href=""  active>Item 1</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="" >Item 2</NavLink>
            </NavItem>
          </Nav>

        </Section>

        <hr className="Hr" />

        <Section id="Icons">
          <h2 className="h2">Icons</h2>
          <Row grid style={{ fontSize: 40 }}>
            <Col><div className="text-center"><Icon name="ChevronRight" /></div></Col>
            <Col><div className="text-center"><Icon name="Success" /></div></Col>
            <Col><div className="text-center"><Icon name="Twitter" /></div></Col>
            <Col><div className="text-center"><Icon name="Youtube" /></div></Col>
            <Col><div className="text-center"><Icon name="Phone" /></div></Col>
            <Col><div className="text-center"><Icon name="More" /></div></Col>
            <Col><div className="text-center"><Icon name="Play" /></div></Col>
            <Col><div className="text-center"><Icon name="Search" /></div></Col>
            <Col><div className="text-center"><Icon name="Location" /></div></Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section id="Badge">
          <h2 className="h2">Badge</h2>
          <Row>
            <Col xs="12" md="4">
              <h3 className="h3">Badges</h3>
              <p>
                <Badge>Default (secondary)</Badge>{" "}
                <Badge color="primary">Primary</Badge>{" "}
                <Badge color="tertiary">Tertiary</Badge>{" "}
                <Badge color="info">Info</Badge>{" "}
                <Badge color="dark">Dark</Badge>{" "}
                <Badge color="light">Light</Badge>{" "}
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Badges Editorial</h3>
              <p>
                <Badge color="DarkOliveGreen">DarkOliveGreen</Badge>{" "}
                <Badge color="SlateGray">SlateGray</Badge>{" "}
                <Badge color="Tan">Tan</Badge>{" "}
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Badges Functionnal</h3>
              <p>
                <Badge color="alert">Aleter</Badge>{" "}
              </p>
            </Col>
          </Row>
          <hr className="Hr" />
          <Row>
            <Col xs="12" md="4">
              <h3 className="h3">Pill Badges</h3>
              <p>
                <Badge pill>Default (secondary)</Badge>{" "}
                <Badge pill color="primary">Primary</Badge>{" "}
                <Badge pill color="tertiary">Tertiary</Badge>{" "}
                <Badge pill color="info">Info</Badge>{" "}
                <Badge pill color="dark">Dark</Badge>{" "}
                <Badge pill color="light">Light</Badge>{" "}
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Pill Badges Editorial</h3>
              <p>
                <Badge pill color="DarkOliveGreen">DarkOliveGreen</Badge>{" "}
                <Badge pill color="SlateGray">SlateGray</Badge>{" "}
                <Badge pill color="Tan">Tan</Badge>{" "}
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Pill Badges Functionnal</h3>
              <p>
                <Badge pill color="alert">Aleter</Badge>{" "}
              </p>
            </Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section id="Button">
          <h2 className="h2">Button</h2>
          <h3 className="h3">Sizes</h3>
          <p>
            <Button size="sm">Small</Button>{" "}
            <Button>medium</Button>{" "}
            <Button size="lg">Large</Button>{" "}
          </p>
          <Row>
            <Col xs="12" md="6">
              <h3 className="h3">Button&apos;s Themes</h3>
              <p>
                <Button>Default (secondary)</Button>{" "}
                <Button color="primary">Primary</Button>{" "}
                <Button color="tertiary">Tertiary</Button>{" "}
                <Button color="info">Info</Button>{" "}
                <Button color="dark">Dark</Button>{" "}
                <Button color="light">Light</Button>{" "}
              </p>
            </Col>
            <Col xs="12" md="6">
              <h3 className="h3">Outline Button&apos;s Themes</h3>
              <p>
                <Button outline>Default (secondary)</Button>{" "}
                <Button outline color="primary">Primary</Button>{" "}
                <Button outline color="tertiary">Tertiary</Button>{" "}
                <Button outline color="info">Info</Button>{" "}
                <Button outline color="dark">Dark</Button>{" "}
                <Button outline color="light">Light</Button>{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <h3 className="h3">Pill Button&apos;s Themes</h3>
              <p>
                <Button pill>Default (secondary)</Button>{" "}
                <Button pill color="primary">Primary</Button>{" "}
                <Button pill color="tertiary">Tertiary</Button>{" "}
                <Button pill color="info">Info</Button>{" "}
                <Button pill color="dark">Dark</Button>{" "}
                <Button pill color="light">Light</Button>{" "}
              </p>
            </Col>
            <Col xs="12" md="6">
              <h3 className="h3">Pill Outline Button&apos;s Themes</h3>
              <p>
                <Button outline pill>Default (secondary)</Button>{" "}
                <Button outline pill color="primary">Primary</Button>{" "}
                <Button outline pill color="tertiary">Tertiary</Button>{" "}
                <Button outline pill color="info">Info</Button>{" "}
                <Button outline pill color="dark">Dark</Button>{" "}
                <Button outline pill color="light">Light</Button>{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <h3 className="h3">Icon only Button&apos;s Themes</h3>
              <p>
                <Button pill icon><Icon name="Twitter" /></Button>{" "}
                <Button pill icon color="primary"><Icon name="Twitter" /></Button>{" "}
                <Button pill icon color="tertiary"><Icon name="Twitter" /></Button>{" "}
                <Button pill icon color="info"><Icon name="Twitter" /></Button>{" "}
                <Button pill icon color="dark"><Icon name="Twitter" /></Button>{" "}
                <Button pill icon color="light"><Icon name="Twitter" /></Button>{" "}
              </p>
            </Col>
            <Col xs="12" md="6">
              <h3 className="h3">Icon only Outline Button&apos;s Themes</h3>
              <p>
                <Button outline pill icon><Icon name="Twitter" /></Button>{" "}
                <Button outline pill icon color="primary"><Icon name="Twitter" /></Button>{" "}
                <Button outline pill icon color="tertiary"><Icon name="Twitter" /></Button>{" "}
                <Button outline pill icon color="info"><Icon name="Twitter" /></Button>{" "}
                <Button outline pill icon color="dark"><Icon name="Twitter" /></Button>{" "}
                <Button outline pill icon color="light"><Icon name="Twitter" /></Button>{" "}
              </p>
            </Col>
          </Row>
        </Section>


        <hr className="Hr" />

        <Section id="Figure">
          <h2 className="h2">Figure, Image & Overlay</h2>
          <Row grid>
            <Col xs="12" md="4">
              <h3 className="h3">Figure + Image</h3>
              <Figure>
                <Img src={PLACEHOLDERS._600x400} alt="" />
              </Figure>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Figure + Image + Overlay</h3>
              <Figure>
                <React.Fragment>
                  <Img src={PLACEHOLDERS._600x400} alt="" />
                  <Overlay>
                    <p>OVERLAY</p>
                  </Overlay>
                </React.Fragment>
              </Figure>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Figure + Image + Overlay with Icon</h3>
              <Figure>
                <React.Fragment>
                  <Img src={PLACEHOLDERS["600x400"]} alt="" />
                  <Overlay>
                    <Icon name="Play" />
                  </Overlay>
                </React.Fragment>
              </Figure>
            </Col>
          </Row>

          <hr className="Hr" />
          <h2 className="h2">Figure ratios</h2>
          <Row grid>
            <Col xs="12" md="2">
              <h3 className="h3">16:9</h3>
              
                <Figure ratio={FIGURE_RATIOS.SIXTEEN_NINE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
            <Col xs="12" md="2">
              <h3 className="h3">3:2</h3>
              
                <Figure ratio={FIGURE_RATIOS.THREE_TWO}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
            <Col xs="12" md="2">
              <h3 className="h3">4:3</h3>
              
                <Figure ratio={FIGURE_RATIOS.FOUR_THREE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
            <Col xs="12" md="2">
              <h3 className="h3">1:1</h3>
              
                <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
            <Col xs="12" md="2">
              <h3 className="h3">3:4</h3>
              
                <Figure ratio={FIGURE_RATIOS.THREE_FOUR}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
            <Col xs="12" md="2">
              <h3 className="h3">2:3</h3>
              
                <Figure ratio={FIGURE_RATIOS.TWO_THREE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                
            </Col>
          </Row>
        </Section>

        <hr className="Hr" />

        <Section id="Card">
          <h2 className="h2">Card</h2>
          <Row grid>
            <Col xs="12" md="4">
              <h3 className="h3">Card main elements</h3>
              <Card>
                <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Card with link tag</h3>
              <Card tag="a">
                <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Card without border</h3>
              <Card tag="a" noBorder>
                <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Card rounded</h3>
              <Card tag="a" rounded>
                <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Card without border rounded</h3>
              <Card tag="a" noBorder rounded>
                <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr className="Hr" />
          <Row grid>
            <Col xs="12" md="4">
              <h3 className="h3">Card with more button, badge, date and duration</h3>
              <Card tag="a" noBorder>
                <Badge pill color="DarkOliveGreen">Culture</Badge>
                  <Figure>
                  <CardImg src={PLACEHOLDERS._600x400} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Titre de l&apos;article</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel libero ligula.</CardText>
                  <CardText><span className="Date">01/12/2020</span> | <span className="Duration">3 minutes de lecture</span></CardText>
                </CardBody>
                <CardFooter>
                  <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
                </CardFooter>
              </Card>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h3">Card<br />with overlay</h3>
              <Card tag="a" noBorder>
                <Figure>
                  <React.Fragment>
                    <CardImg src={PLACEHOLDERS._600x400} />
                    <Overlay>
                      <p>OVERLAY</p>
                    </Overlay>
                  </React.Fragment>
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Titre de l&apos;article</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel libero ligula.</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr className="Hr" />
          <h2 className="h2">Card figure ratios</h2>
          <Row grid>
            <Col xs="12" md="3">
              <h3 className="h3">3:2</h3>
              <Card tag="a" noBorder>
                <Figure ratio={FIGURE_RATIOS.THREE_TWO}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="3">
              <h3 className="h3">1:1</h3>
              <Card tag="a" noBorder>
                <Figure ratio={FIGURE_RATIOS.ONE_ONE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="3">
              <h3 className="h3">2:3</h3>
              <Card tag="a" noBorder>
                <Figure ratio={FIGURE_RATIOS.TWO_THREE}>
                  <CardImg src={PLACEHOLDERS._600x450} />
                </Figure>
                <CardHeader>
                  <CardTitle className="h3" tag="h3">Card header</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText className="lead">Card body</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr className="Hr" />
          <Row grid>
            <Col xs="12" md="6">
              <h3 className="h3">Card with horizontal layout</h3>
              <Card tag="a">
                <Row>
                  <Col xs="12" md="6">
                    <Figure>
                      <CardImg src={PLACEHOLDERS._600x400} />
                    </Figure>
                  </Col>
                  <Col xs="12" md="6">
                    <CardHeader>
                      <CardTitle className="h3" tag="h3">Card header</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <CardText className="lead">Card body </CardText>
                    </CardBody>
                    <CardFooter>
                      <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <h3 className="h3">Card with horizontal layout</h3>
              <Card tag="a" noBorder>
                <Row>
                  <Col xs="12" md="6">
                    <Figure>
                      <CardImg src={PLACEHOLDERS._600x400} />
                    </Figure>
                  </Col>
                  <Col xs="12" md="6">
                    <CardHeader>
                      <CardTitle className="h3" tag="h3">Card header</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <CardText className="lead">Card body</CardText>
                    </CardBody>
                    <CardFooter>
                      <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <Card tag="a">
                <Row>
                  <Col xs="12" md="6">
                    <Figure>
                      <CardImg src={PLACEHOLDERS._600x400} />
                    </Figure>
                  </Col>
                  <Col xs="12" md="6">
                    <CardHeader>
                      <CardTitle className="h3" tag="h3">Card header</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eleifend ante et ligula varius, a interdum turpis egestas. Quisque eget condimentum libero. Sed varius pharetra sapien, ac semper elit sagittis malesuada. Quisque interdum velit quis facilisis ultricies.</CardText>
                    </CardBody>
                    <CardFooter>
                      <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <Card tag="a" noBorder moreButton>
                <Row>
                  <Col xs="12" md="6">
                    <Figure>
                      <CardImg src={PLACEHOLDERS._600x400} />
                    </Figure>
                  </Col>
                  <Col xs="12" md="6">
                    <CardHeader>
                      <CardTitle className="h3" tag="h3">Card header</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <CardText className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eleifend ante et ligula varius, a interdum turpis egestas. Quisque eget condimentum libero. Sed varius pharetra sapien, ac semper elit sagittis malesuada. Quisque interdum velit quis facilisis ultricies.</CardText>
                    </CardBody>
                    <CardFooter>
                      <Button className="More" color="link"><Icon name="More" /> <span className="Text">En savoir plus</span></Button>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Section>
        <hr className="Hr" />

        <Section id="Form">
          <h2 className="h2">Form</h2>
          <h3 className="h3">Search input</h3>
          <Form>
            <FormGroup>
              <Label for="exampleSearch">Search</Label>
              <Input
                type="search"
                name="search"
                id="exampleSearch"
                placeholder="Où"
                noBorder
              />
            </FormGroup>
            <hr className="Hr"/>
            <h3 className="h3">Input</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="sm"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="lg"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="sm"
                    value="Value"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    value="Value"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="lg"
                    value="Value"
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="Hr"/>
            <h3 className="h3">Input noBorder</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="sm"
                    noBorder
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    noBorder
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="lg"
                    noBorder
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="sm"
                    noBorder
                    value="Value"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    noBorder
                    value="Value"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label for="">Input</Label>
                  <Input
                    type="text"
                    name="input"
                    placeholder="Input"
                    bsSize="lg"
                    noBorder
                    value="Value"
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="Hr"/>
            <h3 className="h3">Checkbox</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup check disabled>
                  <Label check>
                    <Input type="checkbox" name="checkbox1" /> Checkbox
                  </Label>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    name="checkbox2"
                    id="checkbox2"
                    label="Checkbox custom"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup check>
                  <Label check button bsSize="sm">
                    <Input type="checkbox" name="checkbox3"  bsSize="sm"/> <span>Checkbox button</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup check>
                  <Label check button>
                    <Input type="checkbox" name="checkbox3" /> <span>Checkbox button</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup check>
                  <Label check button bsSize="lg">
                    <Input type="checkbox" name="checkbox3"  bsSize="lg"/> <span>Checkbox button</span>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <hr className="Hr"/>
            <h3 className="h3">Radio</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup check disabled>
                  <Label check>
                    <Input type="radio" name="radio1" /> Radio
                  </Label>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <CustomInput
                    type="radio"
                    name="radio2"
                    id="radio2"
                    label="Radio custom"
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="Hr"/>
            <h3 className="h3">Select</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="sm">Select</Label>
                  <Input type="select" name="select" bsSize="sm">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label>Select</Label>
                  <Input type="select" name="select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">Select</Label>
                  <Input type="select" name="select" bsSize="lg">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="sm">Select</Label>
                  <Input type="select" name="select" bsSize="sm" noBorder>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label>Select</Label>
                  <Input type="select" name="select" noBorder>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">Select</Label>
                  <Input type="select" name="select" bsSize="lg" noBorder>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <hr className="Hr"/>
            <h3 className="h3">Select with dropdown</h3>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="sm">DropdownSelect</Label>
                  <DropdownSelect bsSize="sm" value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label>DropdownSelect</Label>
                  <DropdownSelect value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect bsSize="lg" value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">

              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect value="1" valid>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect value="1" invalid>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="sm">DropdownSelect</Label>
                  <DropdownSelect bsSize="sm" noBorder value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label>DropdownSelect</Label>
                  <DropdownSelect noBorder value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect bsSize="lg" noBorder value="1">
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">

              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect bsSize="lg" noBorder value="1" valid>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Label size="lg">DropdownSelect</Label>
                  <DropdownSelect bsSize="lg" noBorder value="1" invalid>
                    <DropdownItem>1</DropdownItem>
                    <DropdownItem>2</DropdownItem>
                    <DropdownItem>3</DropdownItem>
                    <DropdownItem>4</DropdownItem>
                    <DropdownItem>5</DropdownItem>
                  </DropdownSelect>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Section>

        <hr className="Hr" />

        <Section id="Carousel">
          <h2 className="h2">Carousel</h2>
          <p>Pour plus d&apos;options (pagination, boutons, etc) se référer à documentation de React ID Swiper :</p>
           <p><Button tag="a" outline color="info" target="_blank" href="https://react-id-swiper.ashernguyen.site/example/pagination">https://react-id-swiper.ashernguyen.site/example/pagination</Button></p>
          <Carousel>
            <div>
              <Figure>
                <Img src={PLACEHOLDERS._1200x600} alt="" />
              </Figure>
            </div>
            <div>
              <Figure>
                <Img src={PLACEHOLDERS._1200x600} alt="" />
              </Figure>
            </div>
            <div>
              <Figure>
                <Img src={PLACEHOLDERS._1200x600} alt="" />
              </Figure>
            </div>
          </Carousel>
        </Section>
      </React.Fragment>
    </Layout>
  )
}

export default ComponentsView
