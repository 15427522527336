import React, { FunctionComponent, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { AppRouteInterface, AppRoutes } from "./constants/routes/AppRoutes"
import PrivateRoute from "./components/routes/PrivateRoute"
import "./styles/app.scss"
import Toast from "./components/atoms/Toast";
import { ContextMenu } from "contexts/contextMenu"
import { Redirect } from "react-router";
import { HOME_PATH } from "./constants/routes/RoutePaths";
import { ContextContract } from "contexts/contextContract"
import { Contract } from "./interfaces/contracts/ContractsInterfaces";
import { Complex } from "interfaces/complex/ComplexInterfaces";

const App: FunctionComponent = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [selectedContractId, setSelectedContractId] = useState<string>();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [selectedContractComplex, setSelectedContractComplex] = useState<Complex>(null);

  const toggleMenuOpen = () => {
    setMenuOpen((state: boolean) => !state)
  }

  return (
    <ContextMenu.Provider value={{ menuOpen, toggleMenuOpen }}>
      <ContextContract.Provider value={{ selectedContractId, setSelectedContractId, contracts, setContracts, selectedContractComplex, setSelectedContractComplex }}>
        <div id="Global" className={`Global ${menuOpen ? "MenuOpen" : ""}`}>
          <div className="GlobalContainer">
            <Toast />
            <Switch>
              {
                AppRoutes.map((route: AppRouteInterface) =>
                  route.paths.map((path: string) =>
                    route.isPrivate ? (
                      <PrivateRoute key={path} exact path={path} component={route.component} />
                    ) : (
                      <Route key={path} exact path={path} component={route.component} />
                    )
                  )
                )
              }
              <Redirect to={HOME_PATH} />
            </Switch>
          </div>
        </div>
      </ContextContract.Provider>
    </ContextMenu.Provider>
  )
}

export default App
