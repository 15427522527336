import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useCallback, useState } from "react";
import Container from "../../../components/atoms/Container";
import Form from "../../../components/atoms/Form";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, FormGroup } from "reactstrap";
import Input from "../../../components/atoms/Input";
import CardTicket from "../../../components/molecules/CardTicket";
import Row from "../../../components/atoms/Row";
import Label from "../../../components/atoms/Label";
import BasicModal from "../../../components/molecules/BasicModal";
import { DEMAND_SOCIAL_HOUSING_LINK } from "../../../constants/links/ExternalLinks";

interface StepTransferProps {
  numberOfPerson?: string;
  setNumberOfPerson: Dispatch<SetStateAction<string>>;
  sne?: boolean;
  setSne: Dispatch<SetStateAction<boolean>>;
  sneId?: string;
  setSneId: (sneId: string) => void;
}

const StepTransfer: FunctionComponent<StepTransferProps> = ({
  numberOfPerson,
  setNumberOfPerson,
  sne,
  setSne,
  sneId,
  setSneId,
}): ReactElement => {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onSneClick = useCallback((value: boolean) => {
    if (value === false && sne !== false) {
      setModalOpen(true)
    }
    setSne((state: boolean | undefined) => state === value ? undefined : value)
  }, [sne])

  const onNumberOfPersonChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) {
      setNumberOfPerson(value)
    }
  }, [])

  const onSneIdChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d*$/.test(value)) {
      setSneId(value)
    }
  }, [])

  return (
    <Container className="h-100 d-flex flex-column justify-content-start">
      <Form>
        <h6 className="h6 mb-3">
          <FormattedMessage id="stepper_transfer" />
        </h6>
        <FormGroup>
          <Label for="numberOfPerson" className="mb-3">
            <FormattedMessage id="request_for_transfer_number_of_person_question" />
          </Label>
          <Input
            type="text"
            id="numberOfPerson"
            name="numberOfPerson"
            value={numberOfPerson}
            onChange={onNumberOfPersonChange}
          />
          <Label for="" className="mt-5">
            <FormattedMessage id="request_for_transfer_id_question" />
          </Label>
          <Row flexFit grid>
            <Col sm={6}>
              <CardTicket
                name="general_form_answer_yes"
                selected={sne}
                onClick={() => onSneClick(true)}
              />
            </Col>
            <Col sm={6}>
              <CardTicket
                name="general_form_answer_no"
                selected={sne === false}
                onClick={() => onSneClick(false)}
              />
            </Col>
          </Row>
          {sne && (
            <>
              <Label for="sneId" className="mt-5 mb-3">
                <FormattedMessage id="request_for_transfer_id" />
              </Label>
              <Input
                id="sneId"
                type="text"
                name="sneId"
                value={sneId}
                onChange={onSneIdChange}
                placeholder={intl.formatMessage({ id: "request_for_transfer_id" })}
              />
            </>
          )}
        </FormGroup>
        <BasicModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          header="request_for_transfer_request"
          body={
            <a href={DEMAND_SOCIAL_HOUSING_LINK} target="_blank" rel="noreferrer noopener">
              {DEMAND_SOCIAL_HOUSING_LINK}
            </a>
          }
          button="close"
        />
      </Form>
    </Container>
  );
}

export default StepTransfer;