import React, { FunctionComponent, ReactElement } from "react";
import { Rent } from "../../interfaces/rents/RentsInterfaces";
import BasicModal from "./BasicModal";
import { formatDateToLitteralMonthYear } from "../../utils/dateUtils";
import { capitalize, formatNumber } from "../../utils/utilFunctions";
import Row from "../atoms/Row";
import { Col } from "reactstrap";
import { FormattedMessage } from "react-intl";

interface DebtModalProps {
  open: boolean;
  onClose: () => void;
  rents: Rent[];
}

const DebtModal: FunctionComponent<DebtModalProps> = ({ open, onClose, rents }): ReactElement => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      header="rent_debts"
      body={
        rents.length > 0 ? (
          <>
            {rents.map((rent: Rent) => (
              <Row key={rent.id} className="my-5">
                <Col className="text-center" xs={6}>
                  <strong>{capitalize(formatDateToLitteralMonthYear(rent.terme))}</strong>
                </Col>
                <Col className="text-center" xs={6}>
                  <em>{formatNumber(rent.debit - rent.credit, 2)} €</em>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <p className="text-center">
            <FormattedMessage id="rent_empty" />
          </p>
        )}
      button="close"
    />
  );
}

export default DebtModal;