import React, { FunctionComponent, ReactElement } from "react";
import { Tag } from "../../interfaces/tags/TagsInterfaces";
import Button from "../atoms/Button";
import {TagColors} from "../../constants/colors/Colors";

interface TagProps {
  tag: Tag;
  className?: string;
  selected?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const TagPill: FunctionComponent<TagProps> = ({
  tag,
  className = "",
  onClick,
  selected
}): ReactElement => {
  return tag && (
    <Button
      onClick={onClick}
      outline
      pill
      size="sm"
      color={TagColors[tag.color]?.cssColorClass}
      selected={selected}
      className={`Tag ${className}`}
    >
      {tag.name}
    </Button>
  )
}

export default TagPill;
