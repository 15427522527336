import React, { FunctionComponent } from "react"

// Styles : styles/layout/footer

type FooterProps = {
  children: JSX.Element
  className?: string
}

const Footer: FunctionComponent<FooterProps> = ({
  children,
  className
}) => {
  return (
    <footer className={`Footer ${className}`}>
      {children}
    </footer>
  )
}

export default Footer
