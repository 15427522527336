import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import classnames from "classnames";

import Banner from "components/atoms/Banner";
import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Form from "components/atoms/Form";
import Icon from "components/atoms/Icon";
import Input from "components/atoms/Input";
import Nav from "components/atoms/Nav";
import Row from "components/atoms/Row";
import Section from "components/atoms/Section";
import Layout from "components/layout/Layout";
import Heading from "components/molecules/Heading";

import {
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink
} from "reactstrap";
import EligibilityStepper from "components/molecules/EligibilityStepper";
import { PROPERTIES_MAP_PATH } from "../constants/routes/RoutePaths";
import { history } from "../constants/History";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { propertyService } from "../services/PropertyService";
import {
  INITIAL_SEARCH_PROPERTIES,
  NUMBER_OF_ROOM,
  PROJECTS,
  PROPERTY_CATEGORIES_FILTER
} from "../constants/properties/PropertiesConstants";
import { PropertiesPhotoQuery, Property, SearchProperties } from "../interfaces/properties/PropertiesInterfaces";
import { PROPERTIES_PHOTO_QUERY } from "../constants/queries/PropertyQueries";
import HighlightProperties from "../components/molecules/HighlightProperties";
import { handleApolloError } from "../hooks/handleApolloError";
import Label from "components/atoms/Label";
import InputGroup from "components/atoms/InputGroup";
import { stringifyQuery } from "../utils/routeUtils";
import { useQueryParams } from "../hooks/useQueryParams";
import { formatExternalUrl, scrollTop, truncateText } from "../utils/utilFunctions";
import { isCategoryNotHousing } from "utils/propertyUtils";
import { HOUSING_DEFAULT_PHOTO, LOGO_SERVICE } from "../assets/Assets";
import TownSuggester from "../components/molecules/TownSuggester";
import { HomepageQueryInterface } from "../interfaces/homepage/HomePageInterfaces";
import { PARTIAL_HOMEPAGE_QUERY } from "../constants/queries/HomepageQueries";
import CardAdvisesAndServices from "../components/molecules/CardAdvisesAndServices";
import ChooseContainer from "../components/molecules/ChooseContainer";
import Figure from "../components/atoms/Figure";
import { imageService } from "../services/ImageService";

const HousingsView: FunctionComponent = () => {
  const intl = useIntl();
  const [queryFilter, setQueryFilter] = useState<string>("")
  const [typePropertyFilter, setTypePropertyFilter] = useState<string>("");
  const { eligibility } = useQueryParams(["eligibility"]);

  const [activeTabForm, setActiveTabForm] = useState<string>("1");
  const [searchProperties, setSearchProperties] = useState<SearchProperties>(INITIAL_SEARCH_PROPERTIES);
  const [properties, setProperties] = useState<Property[]>([]);
  const [propertyLoading, setPropertyLoading] = useState<boolean>(true);
  const [numberOfRoomsFilter, setNumberOfRoomsFilter] = useState<number[]>([])
  const [disabledNumberOfRooms, setDisableNumberOfRooms] = useState<boolean>(false)

  const { data: propertiesPhotoData, error: photoError, loading: photoLoading } = useQuery<PropertiesPhotoQuery>(
    PROPERTIES_PHOTO_QUERY,
    {
      variables: { ids: searchProperties.data.map((property: Property) => property.id.toString()) },
      skip: searchProperties.data.length === 0,
      errorPolicy: "all"
    }
  );
  handleApolloError("properties_photo_query", photoError);

  const { data: homeData, error: homepageQueryError } = useQuery<HomepageQueryInterface>(PARTIAL_HOMEPAGE_QUERY)
  handleApolloError("homepage_query", homepageQueryError);

  const handleChangeType = (value) => {
    value === typePropertyFilter ? setTypePropertyFilter("") : setTypePropertyFilter(value);
    if (isCategoryNotHousing(value)) {
      setDisableNumberOfRooms(true)
      setNumberOfRoomsFilter([])
    } else {
      setDisableNumberOfRooms(false)
    }
  }

  const handleNumberOfRoomsChange = (numberOfRooms: string | number | undefined) => {
    if (numberOfRooms && typeof numberOfRooms === "number") {
      if (numberOfRoomsFilter.includes(numberOfRooms)) {
        setNumberOfRoomsFilter((state: number[]) => state.filter((item: number) => item !== numberOfRooms))
      } else {
        setNumberOfRoomsFilter((state: number[]) => [...state, numberOfRooms])
      }
    }
  }
  useEffect(() => {
    scrollTop();
    void propertyService.getHighlights()
      .then((result: SearchProperties) => {
        setSearchProperties(result)
        if (result.count === 0) {
          setPropertyLoading(false);
        }
      })
  }, [])

  useEffect(() => {
    if (propertiesPhotoData) {
      setProperties(searchProperties.data.map(
        (property: Property) => propertyService.mapPropertyWithPhoto(property, propertiesPhotoData.properties))
      )
    }
  }, [searchProperties, propertiesPhotoData])

  useEffect(() => {
    if (properties.length > 0) {
      setPropertyLoading(false);
    }
  }, [properties])

  useEffect(() => {
    if (!photoLoading && !propertyLoading) {
      if (eligibility === "true") {
        location.hash = "#eligibility";
      }
    }
  }, [photoLoading, propertyLoading, eligibility])

  const onSubmitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const filters = {
      query: queryFilter,
      category: typePropertyFilter,
      numberOfRoom: numberOfRoomsFilter.join(","),
      project: activeTabForm === "1" ? PROJECTS[1].value.toString() : PROJECTS[0].value.toString()
    }
    history.push({
      pathname: PROPERTIES_MAP_PATH,
      search: `?${stringifyQuery(filters)}`
    })
  }

  const home = homeData?.home;

  return (
    <Layout className="HousingsView">
      <React.Fragment>
        <Banner src={HOUSING_DEFAULT_PHOTO} alt="" insideContent>
          <React.Fragment>
            <Heading mainTitle={intl.formatMessage({ id: "properties_top_heading" })} />
            <Nav tabs center>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTabForm === "1" })}
                  onClick={() => setActiveTabForm("1")}
                >
                  <FormattedMessage id="rent" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTabForm === "2" })}
                  onClick={() => setActiveTabForm("2")}
                >
                  <FormattedMessage id="buy" />
                </NavLink>
              </NavItem>
            </Nav>
            <Card rounded>
              <CardBody>
                <Row>
                  <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <Form onSubmit={onSubmitSearch}>
                      <FormGroup>
                        <Label for="search" bsSize="lg">{intl.formatMessage({ id: "location" })}</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <Icon name="Search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <TownSuggester
                            currentValue={queryFilter}
                            onChange={setQueryFilter}
                            placeholderLabel={"where"}
                            inputType={"search"}
                            inputName={"search"}
                            inputId={"search"}
                            inputBsSize={"lg"}
                            inputNoBorder={false}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label for="type" bsSize="lg">{intl.formatMessage({ id: "home_type_filter" })}</Label>
                        <Row gutters="md">
                          {Object.keys(PROPERTY_CATEGORIES_FILTER).map((value, index) => (
                            <Col key={index} xs="auto">
                              <FormGroup>
                                <Label check button>
                                  <Input
                                    type="checkbox"
                                    onChange={() => handleChangeType(value)}
                                    checked={typePropertyFilter === value}
                                  />
                                  <span>{intl.formatMessage({ id: PROPERTY_CATEGORIES_FILTER[value] })}</span>
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                      <Row grid>
                        <Col xs="12" md="7">
                          <FormGroup>
                            <Label for="typology">
                              <FormattedMessage id="home_number_of_rooms_filter" />
                            </Label>
                            <Row gutters="md">
                              <Col xs="auto">
                                <FormGroup>
                                  <ChooseContainer
                                    name="numberOfRoom"
                                    items={NUMBER_OF_ROOM}
                                    onClick={(name, roomNumber) => handleNumberOfRoomsChange(roomNumber)}
                                    selected={numberOfRoomsFilter}
                                    disabled={disabledNumberOfRooms}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="5">
                          <FormGroup>
                            <Label for="budget">
                              <FormattedMessage id="max_budget" />
                            </Label>
                            <InputGroup>
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  €
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                name="budget"
                                id="budget"
                                placeholder="Maximum"
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup className="FormFooter d-flex justify-content-center flex-column flex-sm-row">
                        <Button
                          type="submit"
                          color="info"
                          size="lg"
                        >
                          <FormattedMessage id="search" />
                        </Button>
                        <Button color="link" onClick={() => history.push(PROPERTIES_MAP_PATH)}>
                          <FormattedMessage id="advanced_search" />
                        </Button>
                      </FormGroup>
                      <p className="text-center">
                        <FormattedMessage id="properties_search_info" />
                      </p>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        </Banner>

        <Section>
          <Row justifyContent="center" grid>
            {home?.services.filter(service => service.displayHousingsPage).map(service => (
              <Col key={service.id} xs="12" md="4">
                <Card
                  tag="a"
                  href={formatExternalUrl(service.url)}
                  rounded
                  roundedSize="lg"
                  imgSizeAuto
                  noZoom
                >
                  <CardHeader>
                    <Figure sizeAuto className="justify-content-center">
                      <CardImg src={imageService.getImageUrlByFormat(service.icon) || LOGO_SERVICE} />
                    </Figure>
                    <CardTitle className="h3" tag="h3">{service.title}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>{truncateText(service.description, 100)}</CardText>
                  </CardBody>
                  <CardFooter>
                    <Button className="More" color="link"><Icon name="More" /> <span
                      className="Text">{intl.formatMessage({ id: "home_card_know_more" })}</span></Button>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </Section>

        <Section id="eligibility" className="EligibilityStepper" oblique="up" bgColor="light">
          <Heading mainTitle={intl.formatMessage({ id: "properties_eligibility_heading" })} center />
          <Row>
            <Col xs="12" md={{ size: 8, offset: 2 }}>
              <Card rounded>
                <CardBody>
                  <EligibilityStepper />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Section>

        <HighlightProperties properties={properties} />

        {home && (
          <Section oblique="down" obliquePosition="up">
            <CardAdvisesAndServices
              title={home.titleHelp}
              subtitle={home.subtitleHelp}
              content={home.textHelp}
              youtubeId={home.youtubeId}
            />
          </Section>
        )}
      </React.Fragment>

    </Layout>
  )
}

export default HousingsView
