import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CardBody, CardText, CardTitle, Col } from "reactstrap";
import Row from "../../atoms/Row";
import DbCurrentRent from "./DbCurrentRent";
import { formatDateToLitteralDate, formatDateToStandardShort, formatDateToTime } from "../../../utils/dateUtils";
import Card from "../../atoms/Card";
import { Rent } from "../../../interfaces/rents/RentsInterfaces";
import DbTicket from "./DbTicket";
import { ticketService } from "../../../services/TicketService";
import { Ticket } from "../../../interfaces/tickets/TicketsInterfaces";
import { authService } from "../../../services/AuthService";
import { FormattedMessage } from "react-intl";
import Loader from "../../atoms/Loader";

interface CardResumeProps {
  rent?: Rent;
  rentLoading: boolean;
  selectedContract?: string;
}

const CardResume: FunctionComponent<CardResumeProps> = ({ rent, rentLoading, selectedContract }): ReactElement => {
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const user = authService.getLoggedInUser().user;

  useEffect(() => {
    ticketService.findAppointmentsByTenantNumber(user.tenantNumber)
      .then((result: Ticket[]) => {
        return Promise.all(result.map((ticket) => {
          return ticketService.findById(ticket.id)
        })).then(results => {
          setTickets(results)
        })
      })
      .finally(() => setLoading(false))
  }, [user.tenantNumber]);

  const sortedTickets = tickets.sort(ticketService.sortIntervention);
  const nextTicket = sortedTickets[0];

  return (
    <Card color="primarylight" rounded className="CardResume">
      <CardBody>
        <Row grid gutters="xl" className="flex-wrap flex-xl-nowrap">
          <Col xs="auto">
            <CardTitle>
              <FormattedMessage id="db_your_appointments" />
            </CardTitle>
            {loading ?
              (
                <Loader className="m-0" />
              ) : (
              <div className="card-content">
                {nextTicket && nextTicket.intervention && (
                  <Row alignItems="center">
                    <Col xs="12" md="auto">
                      <CardText className="dateTime">
                        <strong className="time">
                          {formatDateToTime(nextTicket.intervention.intervention_date)}
                        </strong>
                        <span className="date">
                          {formatDateToLitteralDate(nextTicket.intervention.intervention_date)}
                        </span>
                      </CardText>
                    </Col>
                    {nextTicket.author && (
                      <Col xs="12" md="auto">
                        <CardText className="name">
                          {nextTicket.author.foreign_name_prefix} {nextTicket.author.firstname} {nextTicket.author.lastname}
                        </CardText>
                        <address className="address">
                          {nextTicket.author.full_address}
                        </address>
                      </Col>
                    )}
                  </Row>
                )}
                {!nextTicket && <i><FormattedMessage id="db_no_appointment" /></i>}
              </div>
            )}
          </Col>
          <Col xs="auto">
            <DbCurrentRent
              debit={rent?.debit - rent?.credit || 0}
              paid={rent && rent.credit === rent.debit}
              dueDate={formatDateToStandardShort(rent?.due_date || new Date().toISOString())}
              loading={rentLoading}
              selectedContract={selectedContract}
            />
          </Col>
          <Col xs="auto">
            <DbTicket />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default CardResume;
