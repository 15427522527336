import { toast } from "react-toastify"
import { WsError } from "../interfaces/error/ErrorsInterfaces";
import ToastWsError from "../components/atoms/ToastWsError";
import { ApolloError } from "@apollo/client";
import ToastApollo from "../components/atoms/ToastApollo";

function info(message: string): void {
  toast.configure()
  toast.info(message)
}

function success(message: string): void {
  toast.configure()
  toast.success(message)
}

function error(message: string): void {
  toast.configure()
  toast.error(message)
}

function errorWs(wsError: WsError): void {
  toast.configure()
  toast.error(ToastWsError({ wsError }))
}

function errorApollo(name: string, error: ApolloError): void {
  toast.configure()
  toast.error(ToastApollo({ name, error }))
}

export const toastService = {
  info,
  success,
  error,
  errorWs,
  errorApollo
}
