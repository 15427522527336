import React, { FunctionComponent, useState } from "react"

// Styles : styles/atoms/Dropdown

// Reactstrap
import { Dropdown as LibDropdown, DropdownProps as LibDropdownProps } from "reactstrap"

const Dropdown: FunctionComponent<LibDropdownProps> = ({
  children,
  className = "",
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <LibDropdown className={`Dropdown ${className}`} {...rest}
      isOpen={dropdownOpen} toggle={toggle}>
      {children}
    </LibDropdown>
  )
}

export default Dropdown
