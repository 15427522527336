import React, { FunctionComponent, ReactElement } from "react";
import { Water, WaterGraphLegendInterface } from "../../interfaces/water/WaterInterfaces";
import { graphService } from "../../services/GraphService";
import { FormattedMessage } from "react-intl";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { WATER_SUPPLIERS, WATER_TYPE_ENUM } from "../../constants/water/WaterConstants";
import { waterService } from "../../services/WaterService";
import { WaterGraph } from "../../interfaces/graph/GraphInterfaces";
import WaterGraphLegend from "../atoms/WaterGraphLegend";

interface WaterGraphChartProps {
  waterMap: Map<string, Water[]>;
  showUrl: boolean;
}

const WaterGraphChart: FunctionComponent<WaterGraphChartProps> = ({
  waterMap,
  showUrl
}): ReactElement => {
  const waterGraphs = graphService.mapWaterMapToGraph(waterMap);
  const waterDatas = waterGraphs.reduce((acc: Water[], cur: WaterGraph) => {
    const keys = Object.keys(cur);
    keys.forEach((key: string) => {
      if (key !== "date" && !key.includes("data") && acc.filter((waterGraph: Water) => waterGraph.meterNumber === key).length === 0) {
        acc.push(cur[`${key}_data`] as Water);
      }
    });
    return acc;
  }, []);
  let coldCount = 0, hotCount = 0;
  const water = (waterMap.entries().next().value as Record<string, Water[]>)[1][0];

  return (
    <div className="mt-5">
      <ResponsiveContainer className="WaterGraph">
        <BarChart
          data={waterGraphs}
          margin={{
            top: 30,
            bottom: 10,
          }}
        >
          <XAxis dataKey="date" />
          <YAxis unit="L" />
          <Tooltip cursor={{ fill: "#ffeeee" }} content={<></>} />
          <Legend content={(props: WaterGraphLegendInterface) =>
            <WaterGraphLegend props={props} waterDatas={waterDatas} />
          } />
          {waterDatas.map((waterData: Water): ReactElement => {
            if (waterData.type === WATER_TYPE_ENUM.HOT) {
              hotCount++;
            } else if (waterData.type === WATER_TYPE_ENUM.COLD) {
              coldCount++;
            }
            return (
              <Bar
                radius={[Infinity, Infinity, 0, 0]}
                key={waterData.meterNumber}
                dataKey={waterData.meterNumber}
                fill={waterService.getColor(waterData.type, waterData.type === WATER_TYPE_ENUM.HOT ? hotCount : coldCount)}
                label={true}
                maxBarSize={60}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
      {showUrl && (
        <div>
          <a href={water.url} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="water_more_informations" /> {WATER_SUPPLIERS[water.supplier]}
          </a>
        </div>
      )}
    </div>
  );
}

export default WaterGraphChart;