import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import Row from "../atoms/Row";
import CardContact from "./CardContact";
import Card from "../atoms/Card";
import { caretakerService } from "../../services/CaretakerService";
import { blocsContactService } from "../../services/BlocsContactService";
import { Caretaker } from "../../interfaces/caretakers/CaretakersInterfaces";
import { BlocContactInterface } from "../../interfaces/blocContact/BlocContactInterfaces";
import { FormattedMessage, useIntl } from "react-intl";

interface ContactsContainerProps {
  title: string;
  selectedContract: string;
}

const ContactsContainer: FunctionComponent<ContactsContainerProps> = ({ title, selectedContract }): ReactElement => {
  const intl = useIntl();

  const [caretaker, setCaretaker] = useState<Caretaker>();
  const [blocsContact, setBlocsContact] = useState<BlocContactInterface[]>();

  useEffect(() => {
    void blocsContactService.findAll()
        .then((result: BlocContactInterface[]) => {
          setBlocsContact(result);
        })
  }, [])

  useEffect(() => {
    if (selectedContract) {
      void caretakerService.getByContract(selectedContract)
        .then((result: Caretaker) => {
          setCaretaker(result);
        })
    }
  }, [selectedContract])

  return (
    <Card rounded>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id={title} />
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row grid gutters="md">
          {caretaker && (
            <Col xs="12" md="4">
              <CardContact
                title="db_caretaker"
                name={caretaker.name}
                email={caretaker.email}
                phone={caretaker.telephone}
                mobile={caretaker.mobile}
              />
            </Col>
          )}
          {
            blocsContact && blocsContact.map((blocContact : BlocContactInterface, index: number) =>
                <Col xs="12" md="4" key={index}>
                    <CardContact
                        name={blocContact.titre}
                        hours={blocContact.horaires}
                        contactMeans={blocContact.moyen}
                        formatPhone={false}
                    />
                </Col>
            )
          }
          <Col xs="12" md="4">
            <CardContact
              name={intl.formatMessage({ id: "db_address_default" })}
              phone="09.69.370.370"
              hours="08:00-18:00"
              formatPhone={false}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ContactsContainer;
