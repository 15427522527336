import React, { Dispatch, FunctionComponent, SetStateAction, useCallback } from "react";
import {OfferCategoriesTagsQuery, OfferTagCategory, Tag} from "interfaces/tags/TagsInterfaces";
import { useIntl } from "react-intl";
import TagPill from "./TagPill";
import NoResult from "./NoResult";
import Row from "../atoms/Row";
import { Col } from "reactstrap";
import {useQuery} from "@apollo/client";
import {OFFER_TAGS_CATEGORIES_QUERY} from "../../constants/queries/OfferQueries";
import {handleApolloError} from "../../hooks/handleApolloError";

interface TagFiltersProps {
  tags: Tag[];
  setSelectedTagIds: Dispatch<SetStateAction<string[]>>;
  selectedTagIds: string[];
  className?: string;
}

const TagFilters: FunctionComponent<TagFiltersProps> = ({
  tags,
  setSelectedTagIds,
  selectedTagIds,
}) => {
  const intl = useIntl()
  const handleClick = useCallback((tagId: string) => {
    if (selectedTagIds.includes(tagId)) {
      setSelectedTagIds(selectedTagIds.filter((selectedTagId: string) => selectedTagId !== tagId))
    } else {
      setSelectedTagIds((state: string[]) => [...state, tagId])
    }
  }, [selectedTagIds])

  const { data: tagCategoriesData, error: tagCategoryError } = useQuery<OfferCategoriesTagsQuery>(OFFER_TAGS_CATEGORIES_QUERY);
  handleApolloError("tag_category_query", tagCategoryError);

  const filteredTags = tags.filter((tag: Tag): boolean => !!tag)
  return tagCategoriesData && tagCategoriesData.offerTagCategories ? (
    <div className="TagFilters">
      <h4 className="py-5">{intl.formatMessage({ id: "search_by_keyword" })}</h4>
      <div className="d-flex justify-content-center flex-wrap">
        {filteredTags.length === 0 && <NoResult />}
        <Row grid>
          {tagCategoriesData.offerTagCategories.map((category: OfferTagCategory) => (
            <Col key={category.label}>
              <p className="text-center font-weight-bold pb-4">{category.label}</p>
              <Row grid justifyContent="center">
                {filteredTags.filter(tag => tag.offer_tag_category?.label === category.label).map((tag: Tag) => (
                  <TagPill
                    key={tag.id}
                    tag={tag}
                    className="m-2"
                    selected={selectedTagIds.includes(tag.id)}
                    onClick={() => handleClick(tag.id)}
                  />
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  ) : null
}

export default TagFilters
