import { fetchWrapper } from "../utils/fetchWrapper";
import {BLOCS_CONTACT_ROUTE} from "../constants/ApiRoutes";
import {BlocContactInterface} from "../interfaces/blocContact/BlocContactInterfaces";

const findAll = (): Promise<BlocContactInterface[]> => {
    return fetchWrapper.get(BLOCS_CONTACT_ROUTE);
}

export const blocsContactService = {
    findAll,
}