import React, { FunctionComponent } from "react"

// Styles : styles/atoms/Modal

// Reactstrap
import { Modal as LibModal, ModalProps as LibModalProps } from "reactstrap"

const Modal: FunctionComponent<LibModalProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibModal
      className={`Modal ${className}`}
      {...rest}
    >
      {children}
    </LibModal>
  )
}

export default Modal
