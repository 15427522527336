import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardHeader, CardText } from "reactstrap";
import List from "../atoms/List";
import Icon from "../atoms/Icon";
import Label from "../atoms/Label";
import Card from "../atoms/Card";
import { FormattedMessage } from "react-intl";
import { formatPhoneNumber } from "../../utils/utilFunctions";
import {MoyenInterface} from "../../interfaces/blocContact/BlocContactInterfaces";
import ReactHtmlParser from "react-html-parser";

interface CardContactProps {
  title?: string;
  name: string;
  email?: string;
  phone?: string;
  mobile?: string;
  hours?: string;
  formatPhone?: boolean;
  contactMeans?: MoyenInterface[];
}

const CardContact: FunctionComponent<CardContactProps> = ({
  title,
  name,
  email,
  phone,
  mobile,
  hours = "9:00-12:30 • 14:00-17:30",
  formatPhone= true,
  contactMeans
}): ReactElement => {
  return (
    <Card className="CardContact" rounded roundedRadius="md" color="primarylight" noShadow>
      <CardHeader>
        {title && (
          <CardText className="function">
            <FormattedMessage id={title} />
          </CardText>
        )}
        <CardText className="name">
          <strong className="name">{name}</strong>
        </CardText>
        <CardText className="hours">
          <small>{hours}</small>
        </CardText>
      </CardHeader>
      <CardBody>
        <List unstyled className="coordinates">
          {phone && (
            <li>
              <Icon name="Phone" size="3" />
              <span className="Text">
                <Label>
                  <FormattedMessage id="db_phone" />
                </Label>
                <span>{formatPhone ? formatPhoneNumber(phone) : phone}</span>
              </span>
            </li>
          )}
          {mobile && (
            <li>
              <Icon name="Phone" size="3" />
              <span className="Text">
                <Label>
                  <FormattedMessage id="db_mobile" />
                </Label>
                <span>{formatPhone ? formatPhoneNumber(mobile) : phone}</span>
              </span>
            </li>
          )}
          {email && (
            <li>
              <Icon name="Mail" size="3" />
              <span className="Text">
                <Label>
                  <FormattedMessage id="db_email" />
                </Label>
                <span>
                  <a href={`mailto:${email}`}>{email}</a>
                </span>
              </span>
            </li>
          )}
          {contactMeans && contactMeans.map((contactMean: MoyenInterface, key: number) =>
              <li key={key}>
                <img className="Icon IconSize--3" src={contactMean.icone.url} alt={contactMean.icone.alternativeText} />
                <span className="Text">
                <Label>
                  {contactMean.denomination}
                </Label>
                <span>
                  {ReactHtmlParser(contactMean.valeur)}
                </span>
              </span>
              </li>
          )}
        </List>
      </CardBody>
    </Card>
  );
}

export default CardContact;
