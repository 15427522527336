import React, { FunctionComponent, ReactElement } from "react";
import LibLoader from "react-loader-spinner";

interface LoaderProps {
  className?: string;
}

const Loader: FunctionComponent<LoaderProps> = ({ className = "" }): ReactElement => {
  return (
    <div className={`Loader d-flex justify-content-center ${className}`}>
      <LibLoader
        color="#6a1e76"
        height={50}
        width={50}
        type="TailSpin"
      />
    </div>
  )
};

export default Loader;
