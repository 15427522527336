import React, { FunctionComponent } from "react"

// Styles : styles/atoms/form

// Reactstrap
import { Form as LibForm, FormProps as LibFormProps, CSSModule } from "reactstrap"

export type FormProps = LibFormProps & {
  inline?: boolean
  innerRef?: React.Ref<HTMLFormElement>
  className?: string
  cssModule?: CSSModule
}

const Form: FunctionComponent<FormProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <LibForm className={`Form ${className}`} {...rest}>
      {children}
    </LibForm>
  )
}

export default Form
