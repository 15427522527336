import React, { FunctionComponent } from "react"

// Styles : styles/atoms/icon

// Data
import { ICONS_MAP } from "./IconsMap"

type IconProps = {
  name: string
  className?: string
  size?: "2" | "3" | "4" | "5" | "6"
}

const Icon: FunctionComponent<IconProps> = ({
  name,
  className = "",
  size
}) => {

  if (!!ICONS_MAP[name]) {
    const IconClassName =
      (size ? " IconSize--" + size : "") +
      (className ? " " + className : "")

    return (
      <span className={`Icon ${IconClassName}`}>
        {ICONS_MAP[name]}
      </span>
    )
  } else {
    return null
  }
}

export default Icon

