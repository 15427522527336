import {
  Property,
  PropertyFilters,
  PropertyPhoto,
  SearchProperties
} from "../interfaces/properties/PropertiesInterfaces";
import { fetchWrapper } from "../utils/fetchWrapper";
import { PROPERTY_ROUTE, PROPERTY_SHARE_ROUTE } from "../constants/ApiRoutes";
import { MarkerInterface } from "../interfaces/map/MapInterfaces";
import { LatLng } from "leaflet";
import { buildQueryParam } from "../utils/routeUtils";
import { HIGHLIGHT_LIMIT } from "../constants/properties/PropertiesConstants";
import { formatPriceTTC } from "../utils/utilFunctions";

const search = (query: string, page: number, limit: number, filters: PropertyFilters): Promise<SearchProperties> => {
  return fetchWrapper.get<SearchProperties>(
    PROPERTY_ROUTE + "?" +
    buildQueryParam("page", page) +
    buildQueryParam("limit", limit) +
    buildQueryParam("query", query) +
    buildQueryParam("project", filters.project) +
    buildQueryParam("category", filters.category) +
    buildQueryParam("types", filters.types) +
    filters.numberOfRoom.map((room: number) => buildQueryParam("numberOfRoom", room)).join("") +
    buildQueryParam("numberOfPerson", filters.numberOfPerson) +
    buildQueryParam("maxRent", filters.maxRent) +
    buildQueryParam("vacant", true)
    ,
  )
}

const getHighlights = (complexIds: string[] = []): Promise<SearchProperties> => {
  return fetchWrapper.get<SearchProperties>(
    PROPERTY_ROUTE + "?" +
    buildQueryParam("page", 1) +
    buildQueryParam("limit", HIGHLIGHT_LIMIT) +
    buildQueryParam("highlight", true) +
    complexIds.map((complexId: string) => buildQueryParam("complexIds", complexId)).join("")
  )
}

const mapPropertyToMarker = (property: Property, selectedId?: string): MarkerInterface => {
  const complex = property.complex;
  if (!complex || !complex.longitude || !complex.latitude) {
    return null;
  }
  return {
    id: property.id,
    position: new LatLng(complex.latitude, complex.longitude),
    title: property.price !== null ? `${formatPriceTTC(property.price, property.fees)} €` : "N/A",
    selected: property.id === selectedId,
  };
}

const share = (from: string, to: string, subject: string, values: Record<string, unknown>): Promise<void> => {
  return fetchWrapper.post(PROPERTY_SHARE_ROUTE,
    {
      from,
      to,
      subject,
      values: JSON.stringify(values)
    }
  )
}

const mapPropertyWithPhoto = (property: Property, propertiesPhoto: PropertyPhoto[]): Property => {
  return {
    ...property,
    photo: propertiesPhoto.find((propertyPhoto: PropertyPhoto) => propertyPhoto.id === property.id)?.photo[0]
  }
}

export const propertyService = {
  search,
  mapPropertyToMarker,
  getHighlights,
  mapPropertyWithPhoto,
  share,
}
