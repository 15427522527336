import React, { FunctionComponent, ReactElement, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { NavItem, NavLink } from "reactstrap";
import { ContextMenu } from "../../contexts/contextMenu";
import { navService } from "../../services/NavService";

interface NavLinkLayoutProps {
  path: string;
  message: string;
  disableTranslation?: boolean;
  active?: boolean;
}

const NavLinkLayout: FunctionComponent<NavLinkLayoutProps> = ({
  path,
  message,
  disableTranslation,
  active,
}): ReactElement => {
  const pathname = window.location.pathname;
  const { toggleMenuOpen, menuOpen } = useContext(ContextMenu);

  const onClick = () => {
    if (menuOpen) {
      toggleMenuOpen();
    }
    navService.redirect(path);
  }

  return (
    <NavItem>
      <NavLink onClick={onClick} className={(pathname.startsWith(path) || active) ? "active" : ""}>
        {disableTranslation ? message : <FormattedMessage id={message} defaultMessage={message} />}
      </NavLink>
    </NavItem>
  )
}

export default NavLinkLayout;