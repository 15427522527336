import React, { FunctionComponent, useEffect } from "react";
import { RouteProps } from "react-router";
import Layout from "../components/layout/Layout"
import { useIntl } from "react-intl";
import { authService } from "../services/AuthService";
import { history } from "../constants/History";
import { DASHBOARD_PATH } from "../constants/routes/RoutePaths";
import { scrollTop } from "../utils/utilFunctions";
import { toastService } from "../services/ToastService";
import { useQueryParams } from "../hooks/useQueryParams";
import { editorialService } from "../services/EditorialService";
import gfm from "remark-gfm";
import ReactMarkdownWithHtml from "react-markdown/with-html";

const LoginRegisterView: FunctionComponent<RouteProps> = () => {

  const { confirmed } = useQueryParams(["confirmed"]);
  const intl = useIntl();
  const user = authService.getLoggedInUser();

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    if (confirmed === "true") {
      toastService.success(intl.formatMessage({ id: "create_account_confirmation_success" }))
    }
  }, [confirmed])

  useEffect(() => {
    if (user && user.jwt) {
      history.push(DASHBOARD_PATH)
    }
  }, [user])

  return (
      <Layout className="EditorialView align-content-center">
        <div className="pt-0 d-flex flex-column login-container align-items-center justify-content-center">
          <div className="mx-5">
            <h3 className="mb-3 text-uppercase">{intl.formatMessage({id: "login"})}</h3>
            <p className="text-uppercase">{intl.formatMessage({id: "login_description"})}</p>
          </div>
          <div className="w-100 grid justify-content-center row">
            <ReactMarkdownWithHtml
              allowDangerousHtml
              plugins={[gfm]}
            >
              {editorialService.getBigButton("Locataire les résidences Yvelines Essonne", "https://monespace.lesresidences.fr/", "Se connecter")}
            </ReactMarkdownWithHtml>
            <ReactMarkdownWithHtml
              allowDangerousHtml
              plugins={[gfm]}
            >
              {editorialService.getBigButton("Locataire Logement Intermédiaire, LLI les Résidences", "https://monespacelli.lesresidences.fr/", "Se connecter")}
            </ReactMarkdownWithHtml>
          </div>
        </div>
      </Layout>
  )
}

export default LoginRegisterView
