import React, { FunctionComponent, ReactElement } from "react";

interface QuoteProps {
  size?: "md" | "xl";
  className?: string;
  marginDisabledDown?: "lg" | "md";
}

const Quote: FunctionComponent<QuoteProps> = ({
  size = "md",
  className = "",
  marginDisabledDown
}): ReactElement => {
  return (
    <div className={`Quote Quote-${size} ${marginDisabledDown ? marginDisabledDown + "-margin" : ""} ${className}`}>
      “
    </div>
  )
}

export default Quote;
