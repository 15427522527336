export enum DOCUMENT_NATURE {
  QUITTANCE = "QUIT",
  CHARGES = "AVCH",
}

export interface DocumentInterface {
  name: string;
  path: string;
  terme: string;
  nature: DOCUMENT_NATURE;
}
