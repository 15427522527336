import React, { FormEvent, FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react"
import { RouteProps } from "react-router"
import classnames from "classnames"

import {
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  DropdownItem,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import Row from "components/atoms/Row"
import Button from "components/atoms/Button"
import Section from "components/atoms/Section"
import Card from "components/atoms/Card"
import Map from "components/atoms/Map"
import Heading from "components/molecules/Heading";
import Icon from "components/atoms/Icon";
import Banner from "components/atoms/Banner";
import Form from "components/atoms/Form"
import { HomepageQueryInterface } from "interfaces/homepage/HomePageInterfaces"
import { HOMEPAGE_QUERIES } from "constants/queries/HomepageQueries"
import { useQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import {HOME_DEFAULT_PHOTO, LOGO_SERVICE} from "assets/Assets"
import { NEWS_PATH, PROPERTIES_MAP_PATH } from "constants/routes/RoutePaths"
import {formatExternalUrl, scrollTop, truncateText} from "utils/utilFunctions"
import MapBasic from "components/molecules/MapBasic";
import CardArticle from "../components/molecules/CardArticle";
import { ComplexWithProperties } from "interfaces/complex/ComplexInterfaces"
import { complexService } from "services/ComplexService"
import { getSimpleMarker } from "constants/map/MapConstants"
import Nav from "components/atoms/Nav"
import Label from "components/atoms/Label"
import { Agency } from "../interfaces/agencies/AgenciesInterfaces";
import Layout from "../components/layout/Layout";
import { handleApolloError } from "../hooks/handleApolloError";
import { stringifyQuery } from "../utils/routeUtils";
import {NUMBER_OF_ROOM, PROJECTS, PROPERTY_CATEGORIES_FILTER} from "../constants/properties/PropertiesConstants";
import { history } from "../constants/History";
import { MarkerInterface } from "../interfaces/map/MapInterfaces";
import TownSuggester from "../components/molecules/TownSuggester";
import DropdownSelect from "../components/molecules/DropdownSelect";
import AgencyCard from "../components/molecules/AgencyCard";
import CardAdvisesAndServices from "../components/molecules/CardAdvisesAndServices";
import ChooseContainer from "../components/molecules/ChooseContainer";
import {isCategoryNotHousing} from "../utils/propertyUtils";
import Figure from "../components/atoms/Figure";
import {imageService} from "../services/ImageService";

const HomeView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();

  const [activeTabForm, setActiveTabForm] = useState<string>("1");
  const [activeTabAgency, setActiveTabAgency] = useState<string>("1");
  const [complexes, setComplexes] = useState<ComplexWithProperties[]>([]);
  const [markers, setMarkers] = useState<MarkerInterface[]>([]);
  const [selectedComplexId, setSelectedComplexId] = useState<string>(undefined);
  const [typePropertyFilter, setTypePropertyFilter] = useState<string>("L");
  const [numberOfRoomsFilter, setNumberOfRoomsFilter] = useState<number[]>([])
  const [disabledNumberOfRooms, setDisableNumberOfRooms] = useState<boolean>(false)
  const [queryFilter, setQueryFilter] = useState<string>("");

  const { data: homeData, error: homepageQueryError } = useQuery<HomepageQueryInterface>(HOMEPAGE_QUERIES)
  handleApolloError("homepage_query", homepageQueryError);

  useEffect(() => {
    scrollTop();
    void complexService.findAll()
      .then((result: ComplexWithProperties[]) => {
        setComplexes(result);
      })
  }, []);

  useEffect(() => {
    setMarkers(complexes.map((complex: ComplexWithProperties) => {
      return complexService.mapComplexWithPropertiesToMarkers(complex, selectedComplexId)
    }));
  }, [complexes, selectedComplexId])

  const home = homeData?.home;

  const onMapClick = () => {
    setSelectedComplexId(undefined);
  }

  const handleChangeType = (value: string) => {
    setTypePropertyFilter(value);
    if (isCategoryNotHousing(value)) {
      setDisableNumberOfRooms(true)
      setNumberOfRoomsFilter([])
    } else {
      setDisableNumberOfRooms(false)
    }
  }

  const handleNumberOfRoomsChange = (numberOfRooms: string | number | undefined) => {
    if (numberOfRooms && typeof numberOfRooms === "number") {
      if (numberOfRoomsFilter.includes(numberOfRooms)) {
        setNumberOfRoomsFilter((state: number[]) => state.filter((item => item !== numberOfRooms)))
      } else {
        setNumberOfRoomsFilter((state: number[]) => [...state, numberOfRooms])
      }
    }
  }

  const onSubmitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const filters = {
      query: queryFilter,
      category: typePropertyFilter,
      numberOfRoom: numberOfRoomsFilter.join(","),
      project: activeTabForm === "1" ? PROJECTS[1].value.toString() : PROJECTS[0].value.toString()
    }
    history.push({
      pathname: PROPERTIES_MAP_PATH,
      search: `?${stringifyQuery(filters)}`
    })
  }

  const displayAgencies = (agencies: Agency[]): ReactElement => {
    return (
      <Row grid justifyContent="center">
        {agencies?.map((agency: Agency) => (
          <Col key={agency.id} xs="6" md="3">
            <AgencyCard agency={agency} />
          </Col>
        ))}
      </Row>
    )
  }

  const MapMemoized = useMemo<ReactElement>(() =>
      <MapBasic
        getIcon={getSimpleMarker}
        markers={markers}
        onMapClick={onMapClick}
        hasPopup
      />
    , [markers])

  return (
    <Layout className="HomeView">
      <>
        <Banner src={HOME_DEFAULT_PHOTO} alt="">
          <React.Fragment>
            <Nav tabs center>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTabForm === "1" })}
                  onClick={() => setActiveTabForm("1")}
                >
                  {intl.formatMessage({ id: "rent_tab" })}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTabForm === "2" })}
                  onClick={() => setActiveTabForm("2")}
                >
                  {intl.formatMessage({ id: "buy_tab" })}
                </NavLink>
              </NavItem>
            </Nav>
            <Card rounded roundedRadius="sm" className="search-block-card">
              <CardBody>
                <Form onSubmit={onSubmitSearch}>
                  <Row grid gutters="lg" className="flex-wrap flex-md-nowrap">
                    <Col xs="12" md="auto">
                      <FormGroup>
                        <Label for="type" bsSize="lg">{intl.formatMessage({ id: "home_type_filter" })}</Label>
                        <DropdownSelect
                          noBorder
                          bsSize="lg"
                          value={intl.formatMessage({ id: PROPERTY_CATEGORIES_FILTER[typePropertyFilter] })}
                        >
                          {Object.keys(PROPERTY_CATEGORIES_FILTER).map((value, index) => (
                            <DropdownItem key={index} value={value} onClick={() => handleChangeType(value)}>
                              {intl.formatMessage({ id: PROPERTY_CATEGORIES_FILTER[value] })}
                            </DropdownItem>
                          ))}
                        </DropdownSelect>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="auto">
                      <FormGroup>
                        <Label for="typology" bsSize="lg"><FormattedMessage id="home_number_of_rooms_filter" /></Label>
                        <Row gutters="xs" flexFit>
                          <Col xs="auto">
                            <FormGroup>
                              <ChooseContainer
                                name="numberOfRoom"
                                items={NUMBER_OF_ROOM}
                                onClick={(name, roomNumber) => handleNumberOfRoomsChange(roomNumber)}
                                selected={numberOfRoomsFilter}
                                disabled={disabledNumberOfRooms}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col xs="12" className="col-fit">
                      <FormGroup>
                        <Label for="search" bsSize="lg">{intl.formatMessage({ id: "location" })}</Label>
                        <TownSuggester
                          currentValue={queryFilter}
                          onChange={setQueryFilter}
                          placeholderLabel={"where"}
                          inputType={"search"}
                          inputName={"search"}
                          inputId={"search"}
                          inputBsSize={"lg"}
                          inputNoBorder={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="auto">
                      <FormGroup className="align-label">
                        <Button size="lg" color="primary" icon rounded hasShadow type="submit">
                          <Icon name="Search" size="3" />
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <p className="text-right">
              <Button color="link" onClick={() => history.push(PROPERTIES_MAP_PATH)}>
                <span className="Text">
                  <FormattedMessage id={"advanced_search"} />
                </span>
                <Icon name="ChevronRight" />
              </Button>
            </p>
          </React.Fragment>
        </Banner>

        {home && (
          <>
            <Section>
              <Heading upTitle={home?.titleService} mainTitle={home?.subtitleService} />
              <Row grid>
                {home?.services.map(service => (
                  <Col key={service.id} xs="12" md="4">
                    <Card
                      tag="a"
                      href={formatExternalUrl(service.url)}
                      rounded
                      roundedSize="lg"
                      imgSizeAuto
                      noZoom
                    >
                      <CardHeader>
                        <Figure sizeAuto className="justify-content-center">
                          <CardImg src={imageService.getImageUrlByFormat(service.icon) || LOGO_SERVICE} />
                        </Figure>
                        <CardTitle className="h3" tag="h3">{service.title}</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <CardText>{truncateText(service.description, 100)}</CardText>
                      </CardBody>
                      <CardFooter>
                        <Button className="More" color="link"><Icon name="More" /> <span
                          className="Text">{intl.formatMessage({ id: "home_card_know_more" })}</span></Button>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Section>
            <Section oblique="down" bgColor="light">
              <Heading upTitle={home?.titleResidence} mainTitle={home?.subtitleResidence} />
              <Card rounded>
                <Map mapHeight="lg">
                  <div className="text-center">
                    {MapMemoized}
                  </div>
                </Map>
              </Card>
            </Section>
            <Section oblique="up" obliquePosition="up" bgColor="tertiary">
              <Heading upTitle={home?.titleAgency} mainTitle={home?.subtitleAgency} />
              <Nav tabs center hasNet>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabAgency === "1" })}
                    onClick={() => setActiveTabAgency("1")}
                  >
                    Yvelines
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabAgency === "2" })}
                    onClick={() => setActiveTabAgency("2")}
                  >
                    Essonne
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTabAgency}>
                <TabPane tabId="1">
                  {displayAgencies(home.agenciesYvelines)}
                </TabPane>
                <TabPane tabId="2">
                  {displayAgencies(home.agenciesEssonne)}
                </TabPane>
              </TabContent>
            </Section>
            <Section oblique="down" obliquePosition="up">
              <Heading upTitle={home?.titleArticle} mainTitle={home?.subtitleArticle} />
              <Row grid>
                {home?.articles.map(article => (
                  <Col xs="12" md="4" key={article.id}>
                    <CardArticle article={article} noBorder />
                  </Col>
                ))}
              </Row>
              <p className="mt-4 text-right More">
                <Button href={NEWS_PATH} color="primary">
                  <span className="Text">{intl.formatMessage({ id: "to_all_articles_page" })}</span>
                  <Icon name="ChevronRight" />
                </Button>
              </p>
            </Section>
            {home && (
              <Section oblique="down" obliquePosition="down" bgColor="light">
                <CardAdvisesAndServices
                  title={home.titleHelp}
                  subtitle={home.subtitleHelp}
                  content={home.textHelp}
                  youtubeId={home.youtubeId}
                />
              </Section>
            )}
          </>
        )}
      </>
    </Layout>
  )
}

export default HomeView
