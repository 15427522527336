import React, { FunctionComponent } from "react"

// Styles : styles/layout/footer

import Footer from "components/layout/Footer";

const FooterDashboard: FunctionComponent = ({

}) => {
  return (
    <Footer className="FooterDashboard">
      <React.Fragment>
        
      </React.Fragment>
    </Footer>
  )
}

export default FooterDashboard
