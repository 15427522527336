import React, { FunctionComponent } from "react"

import { Col } from "reactstrap"

import Row from "components/atoms/Row"
import TagLabel from "./TagLabel"
import { Tag } from "../../interfaces/tags/TagsInterfaces";

// Styles : styles/atoms/heading

type HeadingProps = {
  children?: JSX.Element
  mainTitle?: string
  upTitle?: string
  center?: boolean
  className?: string
  h1?: boolean
  tags?: Tag[]
  uppercase?: boolean;
  primary?: boolean;
}

const Heading: FunctionComponent<HeadingProps> = ({
  children,
  mainTitle,
  upTitle,
  center,
  h1,
  tags,
  className= "",
  uppercase,
  primary,
}) => {
  const HeadingClassName =
      (className ? " " + className : "") +
      (center ? " text-center" : "") +
      (uppercase ? " text-uppercase" : "") +
      (primary ? " text-primary" : "")
  return (
    <div className={`Heading  ${HeadingClassName}`}>
      <p className="UpTitle">
        {upTitle}
      </p>
      <Row justifyContent="between" alignItems="baseline">
        <Col xs="12" md="auto">
          {h1 ?
            <>
              <h1 className="h1">
                <span className="MainTitle">{mainTitle}</span>
              </h1>
            </>
            :
            <h2 className="h2">
                <span className="MainTitle">{mainTitle}</span>
            </h2>
          }
        </Col>
        {children &&
          <>
            <Col xs="12" md="auto">
              {children}
            </Col>
          </>
        }
      </Row>
      {tags && tags.map((tag) => (
          <TagLabel className="mr-1" key={tag.id} tag={tag} />
      ))}
    </div>
  )
}

export default Heading

