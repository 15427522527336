import React, { FunctionComponent, useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { handleApolloError } from "../hooks/handleApolloError";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import gfm from "remark-gfm";
import { LEGAL_MENTIONS_QUERY } from "../constants/queries/LegalMentionsQueries";
import { LegalMentionsQuery } from "../interfaces/legalMentions/LegalMentionsInterfaces";
import { scrollTop } from "../utils/utilFunctions";

const LegalMentions: FunctionComponent<RouteProps> = () => {
  const { data, error } = useQuery<LegalMentionsQuery>(LEGAL_MENTIONS_QUERY, {
    errorPolicy: "all"
  });
  handleApolloError("legal_mentions_query", error);

  useEffect(() => {
    scrollTop();
  }, [])

  return (
    <Layout>
      <Section>
        {data?.legalMention && (
          <ReactMarkdownWithHtml
            allowDangerousHtml
            plugins={[gfm]}
            className="description"
          >
            {data.legalMention.content}
          </ReactMarkdownWithHtml>
        )}
      </Section>
    </Layout>
  )
}

export default LegalMentions;
