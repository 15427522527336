import Row from "components/atoms/Row";
import { Article } from "interfaces/articles/ArticlesInterfaces";
import React, { FunctionComponent } from "react";
import { Col } from "reactstrap";
import CardArticle from "./CardArticle";
import CardArticleWide from "./CardArticleWide";
import NoResult from "./NoResult";

interface ArticleListProps {
  classname?: string;
  articles: Article[];
  highlightArticles?: Article[];
}

const ListArticles: FunctionComponent<ArticleListProps> = ({
  articles,
  highlightArticles= []
}) => {
  
  const sortArticles = (articles: Article[]) => {
    const filteredArticle: Article[] = articles.filter((article: Article): boolean => !!article);
    return [
      ...filteredArticle.filter((article: Article) => article.order !== undefined).sort(((article: Article, nextArticle: Article) => article.order > nextArticle.order ? 1 : -1 )),
      ...filteredArticle.filter((article: Article) => article.order === undefined).sort(((article: Article, nextArticle: Article) => article.updated_at > nextArticle.updated_at ? -1 : 1 ))
    ]
  }
  
  const filteredArticles = sortArticles(articles);
  const filteredHighlightArticles = sortArticles(highlightArticles);
  
  return (
    <>
      {filteredArticles.length === 0 && filteredHighlightArticles.length === 0 && <NoResult />}

      <Row grid>
        {filteredHighlightArticles.map((article) =>
          <Col xs="12" md="12" key={article.id} >
            <CardArticleWide article={article} noBorder/>
          </Col>
        )}
        {filteredArticles.map((article) =>
          <Col xs="12" md="4" key={article.id} >
            <CardArticle article={article} noBorder/>
          </Col>
        )}
      </Row>
    </>
  )
}

export default ListArticles
