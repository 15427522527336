import React, { FunctionComponent, ReactElement } from "react";
import { CardBody, CardText, Col } from "reactstrap";
import Row from "../atoms/Row";
import Heading from "./Heading";
import Card from "../atoms/Card";
import YoutubeVideoContainer from "./YoutubeVideoContainer";
import Figure from "../atoms/Figure";

interface CardAdvisesAndServicesProps {
  title: string;
  subtitle: string;
  content: string;
  youtubeId: string;
}

const CardAdvisesAndServices: FunctionComponent<CardAdvisesAndServicesProps> = ({
  title,
  subtitle,
  content,
  youtubeId,
}): ReactElement => {

  return (
    <Card>
      <CardBody className="p-md-5">
        <Row>
          <Col xs="12" md="6">
            <Heading upTitle={title} mainTitle={subtitle} />
            <CardText className="lead">
              {content}
            </CardText>
          </Col>
          {youtubeId && (
            <Col xs="12" md="6">
              <Figure sizeAuto>
                <YoutubeVideoContainer videoID={youtubeId} />
              </Figure>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

export default CardAdvisesAndServices;
