import React, { FunctionComponent, ReactElement } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Section from "../components/atoms/Section";
import { useQuery } from "@apollo/client";
import { handleApolloError } from "../hooks/handleApolloError";
import { DISCOVER_QUERIES } from "../constants/queries/DiscoverPageQueries";
import { DiscoverInterface, DiscoverItem, DiscoverQueryInterface, DiscoverService } from "../interfaces/discover/DiscoverPageInterfaces";
import DiscoverQuoteSection from "../components/molecules/DiscoverQuoteSection";
import DiscoverSectionSection from "../components/molecules/DiscoverSectionSection";
import Kpi from "../components/molecules/Kpi";
import Heading from "../components/molecules/Heading";
import Card from "../components/atoms/Card";
import { CardBody, CardHeader, CardText, CardTitle, Col } from "reactstrap";
import Figure from "../components/atoms/Figure";
import { imageService } from "../services/ImageService";
import { PLACEHOLDERS } from "../assets/Assets";
import Row from "../components/atoms/Row";
import Img from "../components/atoms/Img";
import DiscoverCard from "../components/molecules/DiscoverCard";
import { Media } from "../interfaces/common/CommonInterfaces";
import {useIntl} from "react-intl";

const DiscoverView: FunctionComponent<RouteProps> = (): ReactElement => {
  const intl = useIntl()
  const { data: discoverData, error: discoverQueryError } = useQuery<DiscoverQueryInterface>(DISCOVER_QUERIES)
  handleApolloError("discover_query", discoverQueryError);

  const discover: DiscoverInterface | null = discoverData?.discoverUs;

  return (
    <Layout className="Discover">
      {discover && (
        <>
          <Section className="Presidency bg-light">
            <Heading mainTitle={intl.formatMessage({ id: "our_presidency" })} primary uppercase />
            <Row grid justifyContent="center">
              {discover.presidency.map((presidencyMember: DiscoverItem) => (
                <Col key={presidencyMember.title} xs="12" sm="6">
                  <Card noBorder className="Member">
                    <Row>
                      <Col xs="12" md="6">
                        <Figure className="justify-content-center justify-content-lg-end">
                          <Img
                            className="Rounded--lg"
                            src={imageService.getImageUrlByFormat(presidencyMember.photo) || PLACEHOLDERS._450x600}
                          />
                        </Figure>
                      </Col>
                      <Col xs="12" md="6">
                        <CardHeader>
                          <CardTitle className="h4 text-primary">
                            {presidencyMember.title}
                          </CardTitle>
                        </CardHeader>
                        <CardBody className="pb-0">
                          <CardText className="h5">
                            {presidencyMember.subtitle}
                          </CardText>
                          <CardText className="mt-5">
                            {presidencyMember.description}
                          </CardText>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Section>

          <Section className="Supervisory-Board pt-5">
            <Heading mainTitle={intl.formatMessage({ id: "our_supervisory_board" })} primary uppercase />
            <Row grid justifyContent="start">
              {discover.supervisory_board.map((supervisory_boardMember: DiscoverItem) => (
                <Col key={supervisory_boardMember.title} xs="12" sm="4">
                  <Card noBorder className="Member">
                    <Row>
                      <Col xs="12" xl="4" md="6">
                        <Figure className="justify-content-center justify-content-lg-end">
                          <Img
                            className="Rounded--lg"
                            src={imageService.getImageUrlByFormat(supervisory_boardMember.photo) || PLACEHOLDERS._450x600}
                          />
                        </Figure>
                      </Col>
                      <Col xs="12" xl="8" md="6">
                        <CardHeader>
                          <CardTitle className="h4 text-primary">
                            {supervisory_boardMember.title}
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <CardText className="h5">
                            {supervisory_boardMember.subtitle}
                          </CardText>
                          <CardText className="mt-5">
                            {supervisory_boardMember.description}
                          </CardText>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Section>

          <DiscoverQuoteSection discover={discover} />

          <Section className="Board pb-8">
            <Heading mainTitle={intl.formatMessage({ id: "our_board" })} primary uppercase />
            <Row grid justifyContent="start">
              {discover.board.map((boardMember: DiscoverItem) => (
                  <Col key={boardMember.title} xs="12" sm="4">
                    <Card noBorder className="Member">
                      <Row>
                        <Col xs="12" xl="4" md="6">
                          <Figure className="justify-content-center justify-content-lg-end">
                            <Img
                                className="Rounded--lg"
                                src={imageService.getImageUrlByFormat(boardMember.photo) || PLACEHOLDERS._450x600}
                            />
                          </Figure>
                        </Col>
                        <Col xs="12" xl="8" md="6">
                          <CardHeader>
                            <CardTitle className="h4 text-primary">
                              {boardMember.title}
                            </CardTitle>
                          </CardHeader>
                          <CardBody>
                            <CardText className="h5">
                              {boardMember.subtitle}
                            </CardText>
                            <CardText className="mt-5">
                              {boardMember.description}
                            </CardText>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
              ))}
            </Row>
          </Section>

          <DiscoverSectionSection section={discover.sections[0]} titlePrimary>
            <div className="DiscoverKpiContainer">
              {discover.kpi.map((kpi: DiscoverService) => (
                <Kpi key={kpi.title} kpi={kpi} />
              ))}
            </div>
          </DiscoverSectionSection>

          <Section bgColor="light" className="oblique-">
            <Heading mainTitle={discover.identityTitle} primary uppercase />
            <Row grid justifyContent="center" gutters="md">
              {discover.identities.map((identity: DiscoverService) => (
                <Col key={identity.title} xs="12" sm="6" md="4" xl="3">
                  <DiscoverCard service={identity} />
                </Col>
              ))}
            </Row>
          </Section>

          <DiscoverSectionSection
            section={discover.sections[1]}
            titlePrimary
            oblique="up"
            obliquePosition="up"
          />

          <DiscoverSectionSection className="pt-5" section={discover.sections[2]} bgColor="tertiary" oblique="down" />

          <Section bgColor="light" className="oblique- PartnerSection">
            <Heading mainTitle={discover.partnerTitle} primary uppercase />
            <div className="d-flex flex-wrap flex-row justify-content-around">
              {discover.partners.map((partner: Media) => (
                <Figure key={partner.url} className="justify-content-center w-auto m-3">
                  <Img src={imageService.getImageUrlByFormat(partner)} />
                </Figure>
              ))}
            </div>
          </Section>

          <Section oblique="down" obliquePosition="up">
            <Row grid justifyContent="center" gutters="md">
              {discover.services.map((service: DiscoverService) => (
                <Col key={service.title} xs="12" sm="6" md="4" xl="3">
                  <DiscoverCard service={service} />
                </Col>
              ))}
            </Row>
          </Section>
        </>
      )}
    </Layout>
  );
}

export default DiscoverView;
