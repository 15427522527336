export enum StrapiTagColor {

    LIGHT_GREEN = "Vert_clair",
    DARK_GREEN = "Vert_fonce",
    GREY = "Gris",
    TAN = "Beige",
    ORANGE = "Orange",
    RED = "Rouge",
    PLUM = "Mauve",
    PURPLE = "Violet",
    LIGHT_BLUE = "Bleu_clair",
    DARK_BLUE = "Bleu_fonce"
}

interface TagColorType {
    [key: string] : {
        cssColorClass: string
    }
}

export const TagColors: TagColorType = {
    [StrapiTagColor.LIGHT_GREEN] : {
        cssColorClass: "YellowGreen"
    },
    [StrapiTagColor.DARK_GREEN] : {
        cssColorClass: "DarkOliveGreen"
    },
    [StrapiTagColor.GREY] : {
        cssColorClass: "SlateGray"
    },
    [StrapiTagColor.TAN] : {
        cssColorClass: "Tan"
    },
    [StrapiTagColor.ORANGE] : {
        cssColorClass: "Coral"
    },
    [StrapiTagColor.RED] : {
        cssColorClass: "Firebrick"
    },
    [StrapiTagColor.PLUM] : {
        cssColorClass: "Plum"
    },
    [StrapiTagColor.PURPLE] : {
        cssColorClass: "Purple"
    },
    [StrapiTagColor.LIGHT_BLUE] : {
        cssColorClass: "SkyBlue"
    },
    [StrapiTagColor.DARK_BLUE] : {
        cssColorClass: "RoyalBlue"
    }
}

export enum PRIMARY_COLORS {
    RED = "RED",
    GREEN = "GREEN",
    BLUE = "BLUE",
}
