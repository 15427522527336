import { AUSHA_PLAYER_ID, AUSHA_PLAYER_LINK } from "../constants/links/ExternalLinks";

const getSourceFromIframe = (iframe: string): string => {
  const podcastId = iframe.substring(
    iframe.lastIndexOf("podcastId=") + 10,
    iframe.lastIndexOf("&playlist=")
  );
  const hasPlaylist = iframe.substring(
    iframe.lastIndexOf("playlist=") + 9,
    iframe.lastIndexOf("&color=")
  );
  return AUSHA_PLAYER_LINK
    .replace(":podcastId", podcastId)
    .replace(":playlist", hasPlaylist)
    .replace(":playerId", AUSHA_PLAYER_ID);
}

export const podcastUtils = {
  getSourceFromIframe,
};
