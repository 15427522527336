import React, { FunctionComponent } from "react"

// Styles : styles/components/map

type MapProps = {
  className?: string
  mapHeight?: "sm" | "md" | "lg" | "xl"
}

const Map: FunctionComponent<MapProps> = ({
  children,
  mapHeight,
  className = ""
}) => {
  return (
    <div className={`Map ${mapHeight ? `MapHeight-${mapHeight}` : ""} ${className}`}>
      {children}
    </div>
  )
}

export default Map
