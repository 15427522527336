/**
 * File listing every route of the application
 */
import { ComponentType } from "react"
import HomeView from "../../views/HomeView"
import ComponentsView from "../../views/ComponentsView"
import AdvancedFilteringView from "../../views/AdvancedFilteringView"
import {
  AGENCIES_PATH,
  AGENCY_DETAIL_PATH,
  ARTICLE_BY_ID_PATH,
  COMPLEX_DETAIL_PATH,
  COMPLEX_PATH,
  COMPONENTS_DASHBOARD_PATH,
  COMPONENTS_PATH,
  CONNECTED_HOUSINGS_PATH,
  CONNECTED_HOUSINGS_SPECIFIC_PATH,
  CONTACT_US_PATH,
  CREATE_TICKET_PATH,
  PAYEMENT_RENT_PATH,
  DASHBOARD_PATH,
  DISCOVER_PATH,
  DOCUMENTS_PATH,
  EDITORIAL_PATH,
  FAQ_PATH,
  HOME_PATH,
  HOUSING_PATH,
  JOIN_US_PATH,
  LEGAL_MENTIONS_PATH,
  LOGIN_REGISTER_PATH,
  NEWS_PATH,
  OFFER_DETAIL_PATH,
  OUR_COMMITMENTS_PATH,
  OUR_COMMITMENTS_SPECIFIC_PATH,
  OUR_HOUSING_STOCK_PATH,
  PERSONAL_INFORMATION_PATH, PRIVACY_POLICY_PATH,
  PROPERTIES_MAP_PATH,
  PROPERTIES_PATH,
  PROPERTY_DETAIL_PATH,
  RENT_PATH,
  RESET_PASSWORD,
  SURVEYS_PATH,
  TICKETS_PATH, LLI_LOGIN_REGISTER_PATH
} from "./RoutePaths"
import LoginRegisterView from "../../views/LoginRegisterView"
import HousingsView from "views/HousingsView"
import HousingDetailView from "views/HousingDetailView"
import ArticleDetailView from "views/ArticleDetailView"
import NewsView from "views/NewsView"
import { RouteProps } from "react-router-dom";
import JoinUsView from "../../views/JoinUsView";
import OfferDetailView from "../../views/OfferDetailView";
import ResetPassword from "../../views/ResetPassword";
import ComplexDetailView from "../../views/ComplexDetailView";
import AgencyDetailView from "../../views/AgencyDetailView";
import ComponentsDashboardView from "../../views/ComponentsDashboardView";
import DashboardView from "../../views/tenantspace/DashboardView";
import PersonalInformationView from "../../views/tenantspace/PersonalInformationView";
import MyRentView from "../../views/tenantspace/MyRentView";
import MyDocumentsView from "../../views/tenantspace/MyDocumentsView";
import MyTicketsView from "../../views/tenantspace/MyTicketsView";
import MyHousingView from "../../views/tenantspace/MyHousingView";
import SurveysView from "../../views/tenantspace/SurveysView";
import YourComplexView from "../../views/tenantspace/YourComplexView";
import CreateTicketView from "../../views/tenantspace/tickets/CreateTicketView";
import EditorialView from "../../views/EditorialView";
import FaqView from "../../views/FaqView";
import DiscoverView from "views/DiscoverView"
import OurHousingView from "../../views/OurHousingStockView";
import ConnectedHousingsView from "../../views/ConnectedHousingsView";
import OurCommitmentsView from "../../views/OurCommitmentsView";
import OurAgenciesView from "../../views/AgenciesView";
import ContactUsView from "../../views/ContactUsView";
import ConnectedHousingsSpecificView from "../../views/ConnectedHousingsSpecificView";
import OurCommitmentsSpecificView from "../../views/OurCommitmentsSpecificView";
import PrivacyPolicyView from "../../views/PrivacyPolicyView";
import LegalMentions from "../../views/LegalMentions";
import MyPaymentRentView from "../../views/tenantspace/MyPaymentRentView";
import LLILoginRegisterView from "../../views/LLILoginRegisterView";

export interface AppRouteInterface {
  paths: string[];
  isPrivate: boolean;
  component: ComponentType<RouteProps>;
}

export const AppRoutes: AppRouteInterface[] = [
  {
    paths: [LOGIN_REGISTER_PATH],
    isPrivate: false,
    component: LoginRegisterView
  },
  {
    paths: [LLI_LOGIN_REGISTER_PATH],
    isPrivate: false,
    component: LLILoginRegisterView
  },
  {
    paths: [COMPLEX_DETAIL_PATH],
    isPrivate: false,
    component: ComplexDetailView
  },
  {
    paths: [PROPERTIES_MAP_PATH],
    isPrivate: false,
    component: AdvancedFilteringView
  },
  {
    paths: [AGENCY_DETAIL_PATH],
    isPrivate: false,
    component: AgencyDetailView
  },
  {
    paths: [PROPERTIES_PATH],
    isPrivate: false,
    component: HousingsView
  },
  {
    paths: [PROPERTY_DETAIL_PATH],
    isPrivate: false,
    component: HousingDetailView
  },
  {
    paths: [ARTICLE_BY_ID_PATH],
    isPrivate: false,
    component: ArticleDetailView
  },
  {
    paths: [NEWS_PATH],
    isPrivate: false,
    component: NewsView
  },
  {
    paths: [COMPONENTS_PATH],
    isPrivate: false,
    component: ComponentsView
  },
  {
    paths: [HOME_PATH],
    isPrivate: false,
    component: HomeView
  },
  {
    paths: [JOIN_US_PATH],
    isPrivate: false,
    component: JoinUsView
  },
  {
    paths: [OFFER_DETAIL_PATH],
    isPrivate: false,
    component: OfferDetailView
  },
  {
    paths: [RESET_PASSWORD],
    isPrivate: false,
    component: ResetPassword
  },
  {
    paths: [COMPONENTS_DASHBOARD_PATH],
    isPrivate: false,
    component: ComponentsDashboardView
  },
  {
    paths: [DASHBOARD_PATH],
    isPrivate: true,
    component: DashboardView
  },
  {
    paths: [PERSONAL_INFORMATION_PATH],
    isPrivate: true,
    component: PersonalInformationView
  },
  {
    paths: [RENT_PATH],
    isPrivate: true,
    component: MyRentView
  },
  {
    paths: [PAYEMENT_RENT_PATH],
    isPrivate: true,
    component: MyPaymentRentView
  },
  {
    paths: [DOCUMENTS_PATH],
    isPrivate: true,
    component: MyDocumentsView
  },
  {
    paths: [TICKETS_PATH],
    isPrivate: true,
    component: MyTicketsView
  },
  {
    paths: [HOUSING_PATH],
    isPrivate: true,
    component: MyHousingView
  },
  {
    paths: [SURVEYS_PATH],
    isPrivate: true,
    component: SurveysView
  },
  {
    paths: [COMPLEX_PATH],
    isPrivate: true,
    component: YourComplexView
  },
  {
    paths: [CREATE_TICKET_PATH],
    isPrivate: true,
    component: CreateTicketView
  },
  {
    paths: [EDITORIAL_PATH],
    isPrivate: false,
    component: EditorialView
  },
  {
    paths: [FAQ_PATH],
    isPrivate: false,
    component: FaqView
  },
  {
    paths: [DISCOVER_PATH],
    isPrivate: false,
    component: DiscoverView
  },
  {
    paths: [OUR_HOUSING_STOCK_PATH],
    isPrivate: false,
    component: OurHousingView
  },
  {
    paths: [OUR_COMMITMENTS_PATH],
    isPrivate: false,
    component: OurCommitmentsView
  },
  {
    paths: [OUR_COMMITMENTS_SPECIFIC_PATH],
    isPrivate: false,
    component: OurCommitmentsSpecificView
  },
  {
    paths: [CONNECTED_HOUSINGS_PATH],
    isPrivate: false,
    component: ConnectedHousingsView
  },
  {
    paths: [CONNECTED_HOUSINGS_SPECIFIC_PATH],
    isPrivate: false,
    component: ConnectedHousingsSpecificView
  },
  {
    paths: [AGENCIES_PATH],
    isPrivate: false,
    component: OurAgenciesView
  },
  {
    paths: [CONTACT_US_PATH],
    isPrivate: false,
    component: ContactUsView
  },
  {
    paths: [PRIVACY_POLICY_PATH],
    isPrivate: false,
    component: PrivacyPolicyView
  },
  {
    paths: [LEGAL_MENTIONS_PATH],
    isPrivate: false,
    component: LegalMentions
  }
]
