import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/atoms/Section";
import AlertComponent from "components/atoms/Alert";
import { alertService } from "services/AlertService";
import { Alert } from "interfaces/alerts/AlertInterfaces";
import { scrollTop } from "../../utils/utilFunctions";
import { useContracts } from "../../hooks/useContracts";
import { rentService } from "../../services/RentService";
import { Rent } from "../../interfaces/rents/RentsInterfaces";
import DbSecondRow from "../../components/molecules/dashboard/DbSecondRow";
import CardResume from "../../components/molecules/dashboard/CardResume";
import ContactsContainer from "../../components/molecules/ContactsContainer";
import { FormattedMessage } from "react-intl";
import { documentService } from "../../services/DocumentService";
import { DocumentInterface } from "../../interfaces/documents/DocumentsInterfaces";

const DashboardView: FunctionComponent<RouteProps> = ({}) => {
  const { selectedContract, contractsDropdown } = useContracts();

  const [alerts, setAlerts] = useState<Alert[]>([])
  const [rents, setRents] = useState<Rent[]>([]);
  const [rentLoading, setRentLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<DocumentInterface[]>([]);

  useEffect(() => {
    scrollTop();
    void alertService.findGeneralAlerts()
      .then(response => setAlerts(response))
  }, [])

  useEffect(() => {
    if (selectedContract) {
      setRentLoading(true)
      rentService.getRents(selectedContract)
        .then((response: Rent[]) => {
          setRents(response);
        })
        .finally(() => {
          setRentLoading(false)
        });
      
      void documentService.findAllByContract(selectedContract)
        .then((result: DocumentInterface[]) => {
          setDocuments(result);
        })
    }
  }, [selectedContract])

  const rent = rents[0];
  const receipt = documents.find((document: DocumentInterface): boolean => document?.terme === rent?.terme);
  return (
    <Layout dashboard>
      <>
        <Section>
          <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h2 className="h2">
              <FormattedMessage id="db_title" />
            </h2>
            {contractsDropdown}
          </div>

          {alerts.map((alert, index) => (
            <AlertComponent key={index} dismiss>
              <h4 className="alert-heading">
                {alert.description}
              </h4>
            </AlertComponent>
          ))}
          <CardResume rent={rent} rentLoading={rentLoading} selectedContract={selectedContract}/>
        </Section>

        <Section>
          <DbSecondRow selectedContract={selectedContract} lastRent={rent} receipt={receipt} />
        </Section>

        <Section>
          <ContactsContainer title="db_contacts" selectedContract={selectedContract} />
        </Section>
      </>
    </Layout>
  );
}

export default DashboardView;
