import React, { FunctionComponent, ReactElement } from "react";
import Card from "../atoms/Card";
import { Rent } from "../../interfaces/rents/RentsInterfaces";
import { FormattedMessage } from "react-intl";
import PaymentItem from "./PaymentItem";
import { CardBody, CardHeader, CardTitle, Col } from "reactstrap";
import Row from "components/atoms/Row";
import Loader from "../atoms/Loader";

interface PaymentHistoryProps {
  rents: Rent[];
  loading: boolean;
}

const PaymentHistory: FunctionComponent<PaymentHistoryProps> = ({
  rents,
  loading,
}): ReactElement => {
  return (
    <Card rounded>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="rent_history" />
        </CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <Loader />}
        <Row grid gutters="md">
          {!loading && rents.map((rent: Rent) => (
            <Col key={rent.id} xs="12">
              <PaymentItem rent={rent} />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
}

export default PaymentHistory;
