const toHex = (value: number): string => {
  const hex = value.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

const toDecimal = (hex: string): number => {
  return parseInt(hex, 16);
}

const computeRGB = (r: number, g: number, b: number): string => {
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const colorService = {
  toHex,
  toDecimal,
  computeRGB,
}