import { Step } from "../interfaces/footer/FooterInterfaces";

const sortSteps = (a: Step, b: Step): number => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}

export const footerService = {
  sortSteps,
}
