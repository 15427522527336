import React, { FunctionComponent, ReactElement } from "react";
import { Formik, FormikHelpers } from "formik";
import { Col, Form, FormGroup, Label } from "reactstrap";
import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import Row from "../atoms/Row";
import Heading from "./Heading";
import { applicationService } from "services/ApplicationService";
import { toastService } from "../../services/ToastService";

interface ApplicationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  description: string;
  CV: File;
  letter: File;
  offerTitle: string;
}

interface ApplicationFormProps {
  modalTitle: string;
  offerTitle?: string
  closeModal: () => void;
}

const getFileName = (file: File): string => file ? file.name : "";

const ApplicationForm: FunctionComponent<ApplicationFormProps> = ({
  modalTitle,
  offerTitle,
  closeModal,
}): ReactElement => {
  const intl = useIntl();
  const contactSchema = Yup.object({
    lastName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    firstName: Yup.string().required(intl.formatMessage({ id: "mandatory_field" })),
    email: Yup.string().email(intl.formatMessage({ id: "invalid_email" })).required(intl.formatMessage({ id: "mandatory_field" })),
    phoneNum: Yup.number().required(intl.formatMessage({ id: "mandatory_field" })),
    CV: Yup.mixed().required(intl.formatMessage({ id: "mandatory_field" })),
  })

  const onSubmit = (
    values: ApplicationFormValues,
    { setSubmitting }: FormikHelpers<ApplicationFormValues>
  ) => {
    applicationService.applicationApply(values)
      .then(() => {
        toastService.success(intl.formatMessage({ id: "application_success" }))
        closeModal();
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Col>
        <Heading h1 className="text-left offer-detail" mainTitle={modalTitle} />
      </Col>

      <Formik
        validationSchema={contactSchema}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNum: "",
          description: "",
          CV: undefined,
          letter: undefined,
          offerTitle: offerTitle
        }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="m-2">
            <Row>
              <Col>
                <FormGroup row>
                  <Col xs={6} sm={4} className="col-form-label">
                    <Label className="required"
                           for="firstName">{intl.formatMessage({ id: "create_account_first_name" })}<span>{"*"}</span></Label>
                  </Col>
                  <Col className="m-0">
                    <Input
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      invalid={touched.firstName && !!errors.firstName}
                      errorMessage={errors.firstName}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={6} sm={4} className="col-form-label">
                    <Label className="required"
                           for="lastName">{intl.formatMessage({ id: "create_account_last_name" })}<span>{"*"}</span></Label>
                  </Col>
                  <Col className="m-0">
                    <Input
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      invalid={touched.lastName && !!errors.lastName}
                      errorMessage={errors.lastName}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={6} sm={4} className="col-form-label">
                    <Label className="required"
                           for="email">{intl.formatMessage({ id: "create_account_email" })}<span>{"*"}</span></Label>
                  </Col>
                  <Col className="m-0">
                    <Input
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      invalid={touched.email && !!errors.email}
                      errorMessage={errors.email}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={6} sm={4} className="col-form-label">
                    <Label className="required"
                           for="phoneNum">{intl.formatMessage({ id: "contact_form_phone_number" })}<span>{"*"}</span></Label>
                  </Col>
                  <Col className="m-0">
                    <Input
                      name="phoneNum"
                      value={values.phoneNum}
                      onChange={handleChange}
                      invalid={touched.phoneNum && !!errors.phoneNum}
                      errorMessage={errors.phoneNum}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Input className="cursor-pointer btn btn-link input-file required" name="CV" type="file"
                         onChange={(event) => {
                           setFieldValue("CV", event.currentTarget.files[0])
                         }}
                         invalid={touched.CV && !!errors.CV}
                         errorMessage={errors.CV}
                  />
                  <div className="input-file-label"><span className="label"><FormattedMessage id="application_add_cv" /></span>
                    <span className="required">{"*"}</span></div>
                  <div className="input-file-name">{getFileName(values.CV)}</div>
                </FormGroup>
                <FormGroup>
                  <Input className="cursor-pointer btn btn-link input-file required" name="letter" type="file"
                         onChange={(event) => {
                           setFieldValue("letter", event.currentTarget.files[0])
                         }}
                  />
                  <div className="input-file-label"><span className="label"><FormattedMessage
                    id="application_add_motivational_letter" /></span></div>
                  <div className="input-file-name">{getFileName(values.letter)}</div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Col xs={6} sm={4} className="col-form-label">
                    <Label
                      for="description">{intl.formatMessage({ id: "application_description" })}</Label>
                  </Col>
                  <Col className="m-0">
                    <Input
                      className="text-description"
                      type="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      invalid={touched.description && !!errors.description}
                      errorMessage={errors.description}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Col md="3" className="float-right mt-n5">
              <Button
                disabled={isSubmitting}
                className="submit-application"
                color="primary"
                type="submit"
                size="lg"
              >
                {intl.formatMessage({ id: "send" })}
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ApplicationForm;
